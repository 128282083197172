import {forwardRef, useEffect, useState} from "react";
import {getInsiderTransactions, getStockGrades} from "../../api/data/DataProvider";
import {CTable} from "@coreui/react";

const InsiderTransTable = forwardRef((props, ref) => {
    const columns = [
        {
            key: 'transaction_date',
            label: 'Transaction Date',
            _props: { scope: 'col' },
        },
        {
            key: 'transaction_type',
            label: 'Transaction Type',
            _props: { scope: 'col' },
        },
        {
            key: 'type_of_owner',
            label: 'Owner Type',
            _props: { scope: 'col' },
        },
        {
            key: 'securities_owned',
            label: 'Securities Owned',
            _props: { scope: 'col' },
        },
        {
            key: 'securities_transacted',
            label: 'Securities Transacted',
            _props: { scope: 'col' },
        },
        {
            key: 'price',
            label: 'Price',
            _props: { scope: 'col' },
        },
        {
            key: 'security_type',
            label: 'Security Type',
            _props: { scope: 'col' },
        },
        {
            key: 'link',
            label: 'Link',
            _props: { scope: 'col' },
        }
    ];

    const [insiderTransactions, setInsiderTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getInsiderTransactions(props.activeTicker)
            .then((res) => {
                let insiderTrans = []
                for (let i=0; i<10; i++) {
                    let transaction = res.data[i];
                    insiderTrans.push({
                        transaction_date: transaction.transactionDate,
                        transaction_type: transaction.transactionType,
                        type_of_owner: transaction.typeOfOwner,
                        securities_owned: transaction.securitiesOwned,
                        securities_transacted: transaction.securitiesTransacted,
                        price: transaction.price,
                        security_type: transaction.securityName,
                        link: transaction.link
                    });
                }

                setInsiderTransactions(insiderTrans);
                setIsLoading(false);
            }).catch((err) => {
            console.error("Failed to load stock grades", err);
        })
    }, [props.activeTicker])

    return(<center>
            <div style={{width: "90%", marginTop: 60}} ref={isLoading ? null: ref} >
                <h4>Company Insider Transactions</h4>
                <CTable striped columns={columns} items={insiderTransactions} />
            </div></center>
    )
})

export default InsiderTransTable;