import axios from 'axios';
import {useContext, useEffect, useRef, useState} from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts/highstock';
import LoadingOverlay from 'react-loading-overlay';
import {CAlert} from "@coreui/react";
// import HC_debugger from 'highcharts/modules/debugger';
import {SeriesTypes} from '../../api/data/SeriesTypes';
import {computationService} from '../../api/Clients';
import {HighChartsFormatters, toolTipNumberFormatter} from "../../utils/FinDataUtils";
import {RefreshContext} from "../oracle/commonContexts/RefreshContextProvider";
import {debounce} from "../../utils/StudioChartUtils";

// HC_debugger(Highcharts);

function fillMissingPointsForMultipleSeries(seriesList) {
    const allTimestamps = new Set();
    const seriesMaps = seriesList.map(series => {
        const map = new Map();
        series.forEach(point => {
            map.set(point[0], point[1]);
            allTimestamps.add(point[0]);
        });
        return map;
    });

    const filledSeriesList = seriesMaps.map(seriesMap => {
        const filledSeries = [];
        allTimestamps.forEach(timestamp => {
            filledSeries.push([timestamp, seriesMap.has(timestamp) ? seriesMap.get(timestamp) : 0]);
        });
        // Sort the series by timestamp
        filledSeries.sort((a, b) => a[0] - b[0]);
        return filledSeries;
    });

    return filledSeriesList;
}


// Function to fetch data from the API
const fetchData = async (ticker, ratio) => {
    const response = await computationService.get(`/v1/multiples/ticker/surprises/charts?tickerId=${ticker}&ratio=${ratio}`);
    return response.data;
};

function leftJoin(mainSeries, otherSeriesList) {
    // Create a map to store the combined data points with unique timestamps
    const combinedDataMap = new Map();

    // First, add all points from the main series to the map
    mainSeries.data.forEach(point => {
        combinedDataMap.set(point[0], point);
    });

    // Iterate over each series in the otherSeriesList
    otherSeriesList.forEach(series => {
        series.data.forEach(point => {
            // Only add the point if it does not exist in the map
            if (!combinedDataMap.has(point[0])) {
                combinedDataMap.set(point[0], point);
            }
        });
    });

    // Convert the map back to an array sorted by timestamp
    const combinedData = Array.from(combinedDataMap.values());
    combinedData.sort((a, b) => a[0] - b[0]);

    // Return a new series object based on the main series with the combined data
    return {
        ...mainSeries,
        data: combinedData
    };
}


const viewData = null;
const reversePopulateChartConfig = () => { }
const isMiniView = false;
const chartHeightOverride = null;
const updateChartRange = () => { }


// Function to create a series with given parameters
function createSeries({ data, name, stack, yAxis, id, type, lineWidth, negativeColor, positiveColor, index = null, dashStyle = null, enableMouseTracking = true, showInTooltip = true }) {
    let zIndex;
   
    const seriesData =  {
        data,
        name,
        showInLegend: true,
        lineWidth: lineWidth || 1,
        stack,
        yAxis,
        visible: true,
        id,
        type,
        zIndex,
        index,
        negativeColor,
        positiveColor,
        enableMouseTracking,
        showInTooltip,
        color: positiveColor
    };

    if (dashStyle) {
        seriesData.dashStyle = dashStyle;
    }

    if (type == 'column') {
        seriesData.zIndex = 3;
    } else {
        seriesData.zIndex = 1;
    }
    return seriesData;
}


const RATIO_TO_LABELS = {
    [SeriesTypes.P_EPS]: {
        "multiple": "P/E",
        "estimate": "EPS Diluted"
    },
    [SeriesTypes.EV_SALES_xLEASE]: {
        "multiple": "EV ex-Lease / Sales",
        "estimate": "SALES"
    },
    [SeriesTypes.EV_EBITDA_xLEASE]: {
        "multiple": "EV ex-Lease / EBITDA ex-Lease",
        "estimate": "EBITDA Adj. ex-Lease"
    }
}

const RATIO_TO_EST_TYPE = {
    [SeriesTypes.P_EPS]: "EPSDILUTED",
    [SeriesTypes.EV_SALES_xLEASE]: "SALES",
    [SeriesTypes.EV_EBITDA_xLEASE]: "EBITDA_xLEASE"
}

const calculateIdioData = (idioData, startTimestamp) => {
    if (!idioData || idioData.length === 0) {
        return null;
    }

    startTimestamp = startTimestamp || idioData[0][0];

    let idioPrev = 0.0;
    let idioCurr;
    let newIdioSeries = [];

    //TODO Map startTimestamp to timestamp coming in
    for (let i = 0; i < idioData.length; i++) {
        if (idioData[i][0] <= startTimestamp) {
            newIdioSeries.push([idioData[i][0], 0]);
        } else {
            idioCurr = (1.0 + idioPrev) * idioData[i][1] + idioPrev;
            newIdioSeries.push([idioData[i][0], idioCurr]);
            idioPrev = idioCurr;
        }
    }
    return newIdioSeries;
};

const StudioCandleChart = ({ activeTicker, ratio }) => {
    const [activeSeries, setActiveSeries] = useState([]);
    const [chartOptions, setChartOptions] = useState(null);
    const [isStacked, setIsStacked] = useState(false); // State to manage stacking
    const [sData, setSData] = useState(null); // State to manage stacking
    const [isLoading, setIsLoading] = useState(false); // State for loading indicator
    const [error, setError] = useState(null); // State for storing error message
    const [idioData, setIdioData] = useState(null);

    const {refresh} = useContext(RefreshContext);

    const chartRef = useRef(null);

    useEffect(() => {
        if (activeTicker) {
            setIsLoading(true);
            setError(null);
            setSData(null);
            setIdioData(null);
            fetchData(activeTicker, ratio)
                .then(data => {
                    setSData(data);
                    setIdioData(data['idio']);
                    setIsLoading(false);
                })
                .catch(err => {
                    console.error('Error fetching data:', err);
                    setError('Error fetching data: ' + err.message);
                    setIsLoading(false);
                });
        }
    }, [activeTicker, ratio, refresh]);

    // Calculate the height of the charts and the widths of the legends:
    let chartHeight = '720px';

    // Manage maintaining the extremities, since these will be modified within the chart:
    // const extreminitiesRef = useRef({ xMin: viewData?.extremities.xMin, xMax: viewData?.extremities.xMax });

    const paddingSet = useRef(false);

    // const extreminitiesRef = useRef({xMin: viewData?.extremities.xMin, xMax: viewData?.extremities.xMax});
    const getSeries = () => {
        let result;
        console.log("GETSERIES", ratio, Object.keys(RATIO_TO_LABELS));
        if (sData == null) { return; }
        // Example usage to generate all series
        const priceImpactReported = createSeries({
            data: sData['price_reaction'],
            name: "Price Reaction",
            stack: "price",
            yAxis: "price_column",
            id: "price_impact",
            type: "column",
            negativeColor: "#990000",
            positiveColor: "#1f7a1f"
        });
        // priceImpactReported.pointRange = 10 * 24 * 3600 * 1000;
        const rollForwardChange = createSeries({
            data: sData['time'],
            name: "RollForward " + RATIO_TO_LABELS[ratio].estimate + " %c",
            stack: "grouped",
            yAxis: "percent_column",
            id: "time_impact",
            type: "column",
            negativeColor: "rgba(242,242,85,0.8)",//"rgba(89, 40, 2, 0.8)",
            positiveColor: "rgba(242,242,85,0.8)",
            index: 1
        });
        const estimateNtmChange = createSeries({
            data: sData['estimate'],
            name: "NTM "+RATIO_TO_LABELS[ratio].estimate + " %c",
            stack: "grouped",
            yAxis: "percent_column",
            id: "estimate_impact",
            type: "column",
            negativeColor: "rgba(112, 242, 85, 0.8)",//"rgba(99, 63, 0, 0.8)",
            positiveColor: "rgba(112, 242, 85, 0.8)",
            index: 2
        });
        const multiplePercentChange = createSeries({
            data: sData['multiple'],
            name: "Multiple "+RATIO_TO_LABELS[ratio].multiple + " %c",
            stack: "grouped",
            yAxis: "percent_column",
            id: "multiple_impact",
            type: "column",
            negativeColor: "rgba(43, 183, 217, 0.8)",//"#ffcc66",
            positiveColor: "rgba(43, 183, 217, 0.8)",
            index: 6
        });
        const stockPriceSeries = createSeries({
            data: sData['stock_price'],
            name: "Stock Price",
            yAxis: "stock_price_axis",
            id: "stock_price",
            type: "line",
            positiveColor: "#03a9f4",
        });

        const idioSeries = createSeries({
            data: [],
            name: "Idio",
            yAxis: "idio_axis",
            id: "idio",
            type: "line",
            lineWidth: 0.8,
            dashStyle: 'LongDash',
            positiveColor: "#636efa",
            enableMouseTracking: false, // Add this line
            showInTooltip: false // Add this line
        });
    
        const estimatesNtm = createSeries({
            data: sData['estimates_ntm'],
            name: RATIO_TO_LABELS[ratio].estimate + " NTM",
            yAxis: RATIO_TO_EST_TYPE[ratio],
            id: "estimates_ntm",
            type: "line",
            positiveColor: "#e52b50",
            // dashStyle: 'LongDash'
        });
        
        const multipleNtm = createSeries({
            data: sData['multiples_ntm'],
            name: RATIO_TO_LABELS[ratio].multiple + " NTM",
            yAxis: "multiple_series_axis",
            id: "multiples_ntm",
            type: "line",
            positiveColor: "#08e474"
        });
    
        const estimateChartData = sData['estimates_series'] ? sData['estimates_series']['series'].map(series => ({
            ...series,
            lineWidth: 1
        })):[];
        
        const lineCharts = [estimatesNtm, multipleNtm, idioSeries];
        const columnCharts = [stockPriceSeries, rollForwardChange, priceImpactReported, estimateNtmChange, multiplePercentChange];
        return columnCharts.concat(lineCharts).concat(estimateChartData);
    }

    const lstoreKey = 'exp_selectedRange_' + ratio;

    useEffect(() => {
        if (sData !== null) {
            const chartSeries = getSeries()
            const newChartOptions = {
                ...chartOptions || {},
                "lang": { "thousandsSep": "," },
                "chart": {
                    "zoomType": null, "scrollablePlotArea": { "minWidth": 600 },
                    "zooming": { "mouseWheel": { "enabled": false } },
                    "height": Number(chartHeight.replace('px', '')),
                    "backgroundColor": "#FFFFFF",
                    // "type": "column",
                    "plotBackgroundColor": "#FFFFFF", "panning": { "enabled": false, "type": "x" },
                    "panKey": "shift", "reflow": false, "animation": false,
                    events: {
                        render: function() {
                            // Adjust the groupPadding here
                            const chart = this;
                            const colSeries = chart.series.filter(s => ['time_impact', 'estimate_impact', 'multiple_impact'].indexOf(s.options.id) > -1);
                            colSeries.forEach(s => {
                                let xChange = 0;
                                if(s.options.id === 'time_impact'){
                                    xChange = 56;
                                } 
                                if(s.options.id === 'estimate_impact'){
                                    xChange = 0;
                                } 
                                if(s.options.id === 'multiple_impact'){
                                    xChange = 28;
                                }
                                if(s.points && s.points.length > 0){
                                    for(let point of s.points){
                                        point.graphic.attr({x: point.graphic.x + xChange, width: 26});
                                        point.update({pointWidth: 26}, false)
                                    } 
                                }
                            });
                        },
                        load: function() {
                            // Wait for the next tick to ensure the chart is fully rendered
                            setTimeout(() => {
                                const xAxis = this.xAxis[0];
                                const currentExtremes = xAxis.getExtremes();
                                // Set the same extremes to trigger afterSetExtremes
                                xAxis.setExtremes(currentExtremes.min, currentExtremes.max);
                            }, 0);
                        }
                    }
                },
                series: chartSeries,
                plotOptions: {
                    area: {
                        animation: false,
                        enableMouseTracking: false,
                        stickyTracking: true,
                        // shadow: false,
                        // dataLabels: { style: { textShadow: false } },
                        // stacking: 'normal'
                    },
                    arearange: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    areaspline: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    areasplinerange: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    bar: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    boxplot: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    bubble: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    column: {
                        animation: false,
                        enableMouseTracking: true,
                        stickyTracking: true,
                        shadow: false,
                        // borderWidth: 0, 
                        pointWidth: 26,
                        // pointPadding: 0.1,
                        grouping: false,
                        pointRange: 10 * 24 * 3600 * 1000,
                        groupPadding: isStacked ? -0.6 : -0.65,
                        stacking: null,
                        dataLabels: {
                            enabled: false,
                            // Enable data labels
                            formatter: function () {
                                return this.series.name[0]; // Return the name of the series for each column
                            },
                            style: {
                                color: '#fff', // Set text color (optional)
                                textOutline: '0.5px black' // Remove outline (optional)
                            },
                            verticalAlign: 'bottom', // Align labels at the bottom
                            y: 5 // Adjust the vertical position to fit within the chart area
                        }
                    },
                    columnrange: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    errorbar: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    funnel: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    gauge: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    heatmap: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    line: { animation: false, enableMouseTracking: true, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    pie: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    polygon: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    pyramid: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    scatter: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    series: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    solidgauge: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    spline: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    treemap: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    waterfall: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                    candlestick: {
                        animation: false,
                        pointWidth: 40, // Sets the width of each candle to 10px
                        pointPadding: 0.1 // Minimal padding between points
                    }
                },
                legend: {
                    enabled: true, // Enable the legend
                    align: 'bottom', // Optional: Align it to the right
                    verticalAlign: 'bottom', // Optional: Align it to the top
                    layout: 'horizontal' // Optional: Layout vertical
                },
                xAxis: {
                    type: 'datetime',
                    id: 'time_xaxis',
                    minPadding: 2,
                    dateTimeLabelFormats: {
                        day: "%e %b %y", // Display format for days
                        month: "%b '%y", // Display format for months
                        year: "%Y" // Display format for years
                    },
                    minRange: 24 * 3600 * 1000,
                    // labels: { enabled: true },
                    // tickInterval: 45 * 24 * 3600 * 1000,
                    crosshair: {
                        color: 'black',
                        dashStyle: 'shortdot'
                    },
                    events: {
                        afterSetExtremes: debounce((e) => {
                            let chart = e?.target?.chart;
                            if (chart && idioData) {
                                let startTimestamp = e.min;
                                
                                let newIdioData = calculateIdioData(idioData, startTimestamp);
                                if (newIdioData) {
                                    let idioSeriesObject = chart?.series.find(s => s.options.id === "idio");
                                    if (idioSeriesObject) {
                                        idioSeriesObject.setData(newIdioData, true);
                                        // chart.redraw();
                                    }
                                }
                            }
                        }, 150)
                    }
                },
                yAxis: [
                    {
                        "id": "percent_column",
                        "height": "75%",
                        "top": "0%",
                        "labelFormatter": "Percent",
                        min: -70,
                        "title": { "text": "% Change" },
                        "visible": true,
                        "opposite": false,
                        "gridLineWidth": 1,
                        "labels": {}
                    },
                    {
                        "id": "price_column",
                        "height": "40%",
                        "labelFormatter": "Percent",
                        "top": "60%",
                        "title": { "text": "Price Reaction %" },
                        "visible": true,
                        "opposite": false,
                        "gridLineWidth": 1,
                        "labels": {}
                    },
                    {
                        "id": "stock_price_axis",
                        "height": "100%",
                        "top": "0%",
                        "title": { "text": "Stock Price" },
                        "visible": false,
                        "opposite": true,
                    },
                    {
                        "id": RATIO_TO_EST_TYPE[ratio],
                        "height": "100%",
                        "top": "0%",
                        "title": { "text": "Estimate Value" },
                        "visible": true,
                        "opposite": true,
                    },
                    {
                        "id": "multiple_series_axis",
                        "height": "100%",
                        "top": "0%",
                        "labelFormatter": "SmallFraction",
                        "title": { "text": "Multiple Value" },
                        "visible": true,
                        "opposite": true,
                    },
                    {
                        "id": "idio_axis",
                        "height": "100%",
                        "top": "0%",
                        "title": { "text": "Idio" },
                        "visible": false,
                        "opposite": true,
                    }
                ],
                navigator: {
                    series: {
                        boostThreshold: 1,
                        data: [],
                        // showInNavigator: false,
                        type: 'line',
                    }
                    // series: {
                    //     // boostThreshold: 1,
                    //     // type: 'column',
                    //     // dataGrouping: {
                    //     //     enabled: false
                    //     // }
                    // }
                },
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                accessibility: {
                    enabled: false
                },
                tooltip: {
                    "shared": true,
                    "split": true,
                    "inactiveOtherSeries": false,
                    "valueSuffix": "",
                    "xDateFormat": "%A, %e %B, %Y",
                    "shadow": false,
                    "animation": false,
                    "outside": false,
                    "backgroundColor": "rgba(255,255,255,0.7)",
                    "hideDelay": 0,
                    "pointFormatter": toolTipNumberFormatter
                },
                rangeSelector: {
                    // "selected": localStorage.getItem(lstoreKey) === null ? 1 : parseInt(localStorage.getItem(lstoreKey), 10),
                    selected: 1,
                    buttons: [
                        { type: 'year', count: 1, text: '1y', events: { click: function() { localStorage.setItem(lstoreKey, '0'); } } },
                        { type: 'month', count: 18, text: '1.5y', events: { click: function() { localStorage.setItem(lstoreKey, '1'); } } },
                        { type: 'year', count: 2, text: '2y', events: { click: function() { localStorage.setItem(lstoreKey, '2'); } } },
                        { type: 'year', count: 3, text: '3y', events: { click: function() { localStorage.setItem(lstoreKey, '3'); } } },
                        { type: 'year', count: 4, text: '4y', events: { click: function() { localStorage.setItem(lstoreKey, '4'); } } },
                        { type: 'all', text: 'All', events: { click: function() { localStorage.setItem(lstoreKey, '5'); } } }
                    ]
                },
            };
            newChartOptions.yAxis = newChartOptions.yAxis.map((axis) => ({
                ...axis,
                labels: {
                    formatter: function(){
                        return HighChartsFormatters[axis.labelFormatter || "DefaultFormatter"](this.value, 0);
                    }
                }
            }));
            setChartOptions(newChartOptions);
        }
    }, [isStacked, sData]);
    // Note: for the above, we intentionally do not add dependency on extremities, since we only want to use that information on next re-render, and we don't want change of extremities to cause a re-render.


    return (
        <div style={{ height: 800, minHeight: 800, marginTop: 10, marginBottom: 10 }}>
            <LoadingOverlay
                active={isLoading && !error}
                spinner={!error}
                text={"Loading..."}
            >
                {error && (
                    <CAlert color="danger">
                        Failed to load - Earning Surprises.
                        Data Not Configured - {error}
                    </CAlert>
                )}
                {!error && (
                    <div className="studioChartContainer" style={{ width: 'auto', marginLeft: 100, marginRight: 100, display: 'block' }}>
                        <h4 style={{ fontSize: '24px' }}>{RATIO_TO_LABELS[ratio]['multiple']}</h4>
                        <div className="chartContainer">
                            {chartOptions !== null && !isLoading && (
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    constructorType={'stockChart'}
                                    options={chartOptions}
                                    ref={chartRef}
                                />
                            )}
                        </div>
                    </div>
                )}
            </LoadingOverlay>
        </div>
    );
};

export default StudioCandleChart;