import {forwardRef, memo, useContext, useEffect, useMemo, useRef, useState} from "react";
import {AgGridReact} from '@ag-grid-community/react';
import LoadingOverlay from 'react-loading-overlay';
import {Divider, Select, Space} from 'antd';
import IndexDetails from '../IndexDetails';
import * as d3 from 'd3';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import '../css/correlation.css'
import {monitor} from "../../../event_handler/KeyPressMonitor";
import {CorrelationReportContext} from "../CorrelationReportContextProvider";
import {CustomFilter} from "./CutomFilter";

const _ = require("lodash");

// Color gradient logic here
const colorScale = d3.scaleLinear().domain([-1, 0, 1]).range(['#C21E56', '#FFFFFF', '#1034A6']);

// Function to format cell value to two decimal digits
function formatTwoDecimalValue(params) {
    if (typeof params.value === 'number') {
        let absValue = Math.round((Math.abs(params.value) + Number.EPSILON) * 100) / 100;
        // if (params.value < -0.001) {
        //     absValue = '(' + absValue + ')';
        // }
        return absValue;
    }
    return params.value;
}

const  CorrelationReport = memo(forwardRef((props, ref) => {
    const [rowData, setRowData] = useState(null);
    const [columnDefs, setColumnDefs] = useState();
    const [allDependentVariables, setAllDependentVariables] = useState([]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        marryChildren: true,
        resizable: true,
        sizeColumnsToFit: true,
        skipHeaderOnAutoSize: true,
        suppressMovable: true,
        suppressDragLeaveHidesColumns: true,
        menuTabs: [],
    }), []);

    // Store references to ag-Grid instances
    const agGridInstances = [];

    const onGridReady = (params) => {
        // Access the API for further configuration
        const gridApi = params.api;

        // Register a callback to update filters when they change
        gridApi.addEventListener("filterChanged", () => onFilterChanged(gridApi));
    };

    // Debounce function
    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    // Event handler for filter change
    const handleFilterChange = debounce(() => {
        monitor.enableHotKeys(true); // User stopped typing
    }, 5000); // Adjust delay as needed

    const onFilterChanged = (changedGridApi) => {
        const filterModel = changedGridApi.getFilterModel();

        // Disable Shortcuts
        monitor.enableHotKeys(false);
        handleFilterChange();

        // Propagate the filter state to all ag-Grid instances
        agGridInstances.forEach((instance) => {
            if (instance.api !== changedGridApi) {
                instance.api.setFilterModel(filterModel);
            }
        });
    };

    const indexWeightsMap = props?.indexWeights?.weightDetails?.weightsByTimePeriod;
    const ccIndexWeightsMap = props?.ccIndexWeights?.weightDetails?.weightsByTimePeriod;

    function reShapeData(inputData) {
        let customInputData = inputData.data.map((d) => {
            let customColumnName = `custom;${d.dependentVariable};${d.additionalDetail.variableValueType};${d.additionalDetail.metric};${d.additionalDetail.lagPeriod}`
            return {
                ...d,
                [customColumnName]: d.value
            };
        });

        let magnitudeDataMap = new Map();
        if (inputData.relativeMagnitudeData) {
            magnitudeDataMap = inputData.relativeMagnitudeData.reduce((a, o) => {
                return a.set(o.variable, o.magnitudeFormatted);
            }, new Map());
        }

        let result = [];
        customInputData.forEach(item => {
            let independentVariable = item.independentVariable;
            let numOfTimePeriod = item.additionalDetail.numOfTimePeriod;
            let magnitude = magnitudeDataMap.get(independentVariable);
            let indexWeight = indexWeightsMap &&
                indexWeightsMap[numOfTimePeriod]?.find(s => s.seriesName === independentVariable)?.weight;
            let indexWeightFormatted = indexWeight && '' + Math.round(indexWeight * 100) + '%' || null;

            let ccIndexWeight = ccIndexWeightsMap &&
                ccIndexWeightsMap[numOfTimePeriod]?.find(s => s.seriesName === independentVariable)?.weight;
            let ccIndexWeightFormatted = ccIndexWeight && '' + Math.round(ccIndexWeight * 100) + '%' || null;

            if (!result.find(r => r.independentVariable === independentVariable && r.numOfTimePeriod === numOfTimePeriod)) {
                result.push({
                    indexWeight: indexWeightFormatted,
                    ccIndexWeight: ccIndexWeightFormatted,
                    magnitude: magnitude,
                    independentVariable: independentVariable,
                    numOfTimePeriod: numOfTimePeriod
                });
            }

            const foundIndex = result.findIndex(r => r.independentVariable === independentVariable
                && r.numOfTimePeriod === numOfTimePeriod);

            Object.entries(item).forEach(([key, value]) => {
                if (key.startsWith('custom;')) {
                    result[foundIndex][key] = value;
                }
            });

        });

        // Custom sorting like show 4 quarters first, then based on group of Gtrends, SW etc
        result.sort((x, y) => {
            if (x.numOfTimePeriod !== y.numOfTimePeriod) {
                return x.numOfTimePeriod - y.numOfTimePeriod;
            }

            const xVar = x.independentVariable, yVar = y.independentVariable;

            if (getSeriesBucket(xVar) !== getSeriesBucket(yVar)) {
                return getSeriesBucket(xVar) - getSeriesBucket(yVar);
            }

            // Gtrends sorting
            if (xVar.startsWith('gtrends_') && yVar.startsWith('alt_gtrends_')) {
                return -1;
            } else if (xVar.startsWith('alt_gtrends_') && yVar.startsWith('gtrends_')) {
                return 1;
            }

            // SW sorting
            if (0 !== sortStrings(xVar, yVar, ['visits_', 'unique_visits_'], 'STARTS')) {
                return sortStrings(xVar, yVar, ['visits_', 'unique_visits_'], 'STARTS');
            }

            if (0 !== sortStrings(xVar, yVar, ['visits_', 'seg: '], 'STARTS')) {
                return sortStrings(xVar, yVar, ['visits_', 'seg: '], 'STARTS');
            }

            if (0 !== sortStrings(xVar, yVar, ['unique_visits_', 'seg: '], 'STARTS')) {
                return sortStrings(xVar, yVar, ['unique_visits_', 'seg: '], 'STARTS');
            }

            if (0 !== sortStrings(xVar, yVar, ['desktop', 'mobile'], 'CONTAINS')) {
                return sortStrings(xVar, yVar, ['desktop', 'mobile'], 'CONTAINS');
            }

            // Location sorting
            if (xVar.endsWith('_ww') && yVar.endsWith('_us')) {
                return -1;
            } else if (xVar.endsWith('_us') && yVar.endsWith('_ww')) {
                return 1;
            }

            if (xVar.endsWith('_world') && yVar.endsWith('_us')) {
                return -1;
            } else if (xVar.endsWith('_us') && yVar.endsWith('_world')) {
                return 1;
            }

            if (0 !== sortStrings(xVar, yVar, [' ww ', ' us '], 'CONTAINS')) {
                return sortStrings(xVar, yVar, [' ww ', ' us '], 'CONTAINS');
            }

            if (0 !== sortStrings(xVar, yVar, [' ww ', ' ex-us '], 'CONTAINS')) {
                return sortStrings(xVar, yVar, [' ww ', ' ex-us '], 'CONTAINS');
            }

            if (0 !== sortStrings(xVar, yVar, [' us ', ' ex-us '], 'CONTAINS')) {
                return sortStrings(xVar, yVar, [' us ', ' ex-us '], 'CONTAINS');
            }

            // Vela sorting
            if (xVar.startsWith('vela-velorum') && yVar.startsWith('vela-gamma')) {
                return -1;
            } else if (xVar.startsWith('vela-gamma') && yVar.startsWith('vela-velorum')) {
                return 1;
            }

            // CC Data sorting
            if (xVar.includes('total') && !yVar.includes('total')) {
                return -1;
            } else if (yVar.includes('total') && !xVar.includes('total')) {
                return 1;
            }

            if (xVar.includes('sales')) {
                return -1;
            } else if (yVar.includes('sales')) {
                return 1;
            }

            return 0;
        });

        const groupedResult = _.groupBy(result, (o) => o.numOfTimePeriod);
        return groupedResult;
    }

    function sortStrings(xVar, yVar, order, method) {
        if (method === 'STARTS') {
            if (xVar.startsWith(order[0]) && yVar.startsWith(order[1])) {
                return -1;
            } else if (xVar.startsWith(order[1]) && yVar.startsWith(order[0])) {
                return 1;
            }
        } else if (method === 'ENDS') {
            if (xVar.endsWith(order[0]) && yVar.endsWith(order[1])) {
                return -1;
            } else if (xVar.endsWith(order[1]) && yVar.endsWith(order[0])) {
                return 1;
            }
        } else if (method === 'CONTAINS') {
            if (xVar.includes(order[0]) && yVar.includes(order[1])) {
                return -1;
            } else if (xVar.includes(order[1]) && yVar.includes(order[0])) {
                return 1;
            }
        }

        return 0;
    }

    // We have six buckets to sort by - gt, sw, vela, orion, sm, others
    function getSeriesBucket(seriesName) {
        if (seriesName.includes('gtrends_')) {
            return 1;
        } else if (seriesName.includes('visits_') || seriesName.startsWith('seg: ')) {
            return 2;
        } else if (seriesName.startsWith('vela-')) {
            return 3;
        } else if (seriesName.startsWith('orion')) {
            return 4;
        } else if (seriesName.startsWith('sm_')) {
            return 5;
        }

        return 6;
    }

    function getCellStyle(thickness) {
        return (params) => {
            const backgroundColor = colorScale(params.value);
            const textColor = params.value < 0
                ? (params.value < -0.5 ? 'white' : 'black')
                : (params.value > 0.5 ? 'white' : 'black');
            let cellStyle = { 'color': textColor, 'background-color': backgroundColor };

            if (null != thickness) {
                cellStyle['borderRight'] = `${thickness} solid black`;
            }

            return cellStyle;
        };
    }

    function reCalculateColumns(inputData) {
        const depVariables = [...new Set(inputData.data.map(d => d.dependentVariable))];
        const valueTypes = [
            { data: 'YOY', col: 'yoy' },
            { data: 'YOY2', col: '2ystack' },
            { data: 'YOY3', col: '3ystack' },
            { data: 'LEVELS', col: 'levels' }
        ];
        const metrics = [
            { data: 'RSQUARED', col: 'RSQ' },
            { data: 'HIT_RATE', col: 'Hit Rate' },
            { data: 'HIT_STREAK', col: 'Hit Streak' },
            { data: 'BLEND', col: 'Blend' }
        ];
        const lagPeriods = [
            { data: '0', col: '0' },
            { data: '1', col: '-1' },
            { data: '2', col: '-2' }
        ];

        let borderThickness;

        let groupedColumnDefs = [];
        for (const depVariable of depVariables) {
            let depVarColumn = { headerName: depVariable, headerClass: 'grid-value-type-borders', children: [] }
            for (const valueType of valueTypes) {
                let valTypeColumn = { headerName: valueType.col, headerClass: 'grid-value-type-borders', children: [] }
                for (const [i, metric] of metrics.entries()) {
                    let metricColumn = { headerName: metric.col, headerClass: 'grid-metric-borders', children: [] }
                    if (i == metrics.length - 1) {
                        metricColumn.headerClass = 'grid-value-type-borders';
                    }
                    for (const [j, lagPeriod] of lagPeriods.entries()) {
                        let lagHeaderClass = '';
                        if (i === metrics.length - 1 && j === lagPeriods.length - 1) {
                            borderThickness = '4px';
                            lagHeaderClass = 'grid-value-type-borders';
                        } else if (j === lagPeriods.length - 1) {
                            borderThickness = '2px';
                            lagHeaderClass = 'grid-metric-borders';
                        } else {
                            borderThickness = null;
                        }
                        let customColumnName = `custom;${depVariable};${valueType.data};${metric.data};${lagPeriod.data}`;
                        metricColumn.children.push({ headerName: lagPeriod.col, headerClass: lagHeaderClass, field: customColumnName, valueFormatter: formatTwoDecimalValue, width: 37, cellStyle: getCellStyle(borderThickness) });
                    }
                    valTypeColumn.children.push(metricColumn);
                }
                depVarColumn.children.push(valTypeColumn);
            }
            groupedColumnDefs.push(depVarColumn);
        }

        return [
            {
                headerName: 'Index Weight',
                field: 'indexWeight',
                tooltipField: 'indexWeight',
                cellStyle: { textAlign: 'left' },
                width: 35,
            },
            {
                headerName: 'CC Index Weight',
                field: 'ccIndexWeight',
                tooltipField: 'ccIndexWeight',
                cellStyle: { textAlign: 'left' },
                width: 35,
            },
            {
                headerName: 'Magnitude',
                field: 'magnitude',
                tooltipField: 'magnitude',
                cellStyle: { textAlign: 'left' },
                width: 50,
            },
            {
                headerName: 'Independent Variable',
                field: 'independentVariable',
                tooltipField: 'independentVariable',
                filter: CustomFilter,
                floatingFilterComponent: CustomFilter,
                floatingFilter: true,
                cellStyle: { textAlign: 'left' },
                width: 240,
            },
            ...groupedColumnDefs
        ];
    }

    const[calulated, setCalulated] = useState(false);

   useEffect(() =>  {
       if(props.chartData == null) return;
       setRowData(reShapeData(props.chartData.data));
       setColumnDefs(reCalculateColumns(props.chartData.data));
       setAllDependentVariables([...new Set(props.chartData.data.allDependentVariablesAvailable)].sort())

   }, [props.chartData])

    const [isScrollUsed, setIsScrollUsed] = useState(false);


    const scrollableDivRef = useRef(null);
    const agGridsMountedRef = useRef(null);
// Todo: 1. Fix the x-axis as well. Ideally isolate  the correlreport to a seperate context provider, and directly use the context over here.

    useEffect(() => {
        if(calulated && scrollableDivRef.current != null && agGridsMountedRef.current != null && !isScrollUsed) {
            // window.scrollTo(0, correlReportScrollPosition);
            setTimeout(() => scrollableDivRef.current.scrollTop =props.correlReportScrollPosition.current, 0 );
            // window.scrollTo(correlReportScrollPosition);
            setIsScrollUsed(true)
        }
    }, [calulated])

    return (
        <div>
            <LoadingOverlay
                active={props.loading && !props.isError}
                spinner={!props.isError}
                text={"Loading..."}
            >
                {props.activeBrand == null && null != rowData &&
                    <div className="correlation-grid ag-theme-alpine"
                         style={{ marginLeft: 10, height: window.innerHeight, overflow: 'auto' }}
                         onScroll={e => props.setCorrelReportScrollPosition(e.target.scrollTop)}
                         ref={scrollableDivRef}
                    >
                        <CustomHeaderGroupWithDropdown
                            columnDefs={columnDefs}
                            setColumnDefs={setColumnDefs}
                            selectedDepVariable={props.correlReportSelectedDepVariable}
                            setSelectedDepVariable={props.setCorrelReportSelectedDepVariable}
                            allDependentVariables={allDependentVariables}
                        />
                        {[4, 6, 8, 12, 16].map((numPeriod, i) => {
                            return <div className={"correl-container"} style={{width: '100%', overflow: 'scroll'}} >
                                <IndexDetails name='Total Index' weights={indexWeightsMap && indexWeightsMap[numPeriod]} />
                                <IndexDetails name='CC Index' weights={ccIndexWeightsMap && ccIndexWeightsMap[numPeriod]} />
                                <Divider orientation="left" orientationMargin={20} style={{ margin: '5px' }}><p style={{ 'font-size': 'larger', margin: 0 }}>{numPeriod} Quarters</p></Divider>
                                <AgGridReact
                                   /* ref={gridRef}*/
                                    onGridReady={(params) => {
                                        agGridInstances[i] = params;
                                        onGridReady(params);
                                        setCalulated(true);
                                    }}
                                    rowData={rowData[numPeriod]}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    animateRows={true}
                                    showLoadingOverlay={true}
                                    rowSelection='multiple'
                                    alwaysShowVerticalScroll={true}
                                    debounceVerticalScrollbar={true}
                                    suppressColumnVirtualisation={true}
                                    domLayout="print"
                                    ref={agGridsMountedRef}
                                />
                            </div>;
                        })}
                    </div>
                }
            </LoadingOverlay>
        </div>
    )
}))

function CustomHeaderGroupWithDropdown(props) {
    const allColumnDefs = props.columnDefs;

    const handleChangeDepVariable = (value) => {
        props.setSelectedDepVariable(value);
    };

    useEffect(() => {
        for (let columnDef of allColumnDefs) {
            if (['Index Weight', 'CC Index Weight', 'Magnitude', 'Quarters', 'Independent Variable'].includes(columnDef.headerName)
                || columnDef.headerName === props.selectedDepVariable) {
                columnDef.hide = false;
            } else {
                columnDef.hide = true;
            }
        }

        props.setColumnDefs(allColumnDefs);
    }, [props.selectedDepVariable]);

    const allDropdownOptions = props.allDependentVariables.map(c => { return { 'value': c } });

    return (
        <Space wrap>
            <Select
                value={props.selectedDepVariable}
                onChange={handleChangeDepVariable}
                options={allDropdownOptions}
                popupMatchSelectWidth={false}
            />
        </Space>
    );

};

export const ContextConnectedCorrelationReport = (props) => {
    const {dataState, correlReportSelectedDepVariable, setCorrelReportSelectedDepVariable, correlReportScrollPosition, setCorrelReportScrollPosition} = useContext(CorrelationReportContext);

    return (<CorrelationReport {...props}
                               chartData={dataState.chartData}
                               loading={dataState.loading}
                               isError={dataState.isError}
                               correlReportSelectedDepVariable={correlReportSelectedDepVariable}
                               setCorrelReportSelectedDepVariable={setCorrelReportSelectedDepVariable}
                               correlReportScrollPosition={correlReportScrollPosition}
                               setCorrelReportScrollPosition={setCorrelReportScrollPosition}
    />)
}


export default CorrelationReport;