import {
    dollarCellRendererFunction,
    nonNullComparator,
    numberValueFormatter,
    percentageCellRendererFunction
} from "../../../../utils/StudioAgGridUtils";

// helper function to generate column defintion
const generateColumnDefinition = (headerName, field) => {
    return {
        headerName: headerName,
        field: field,
        cellStyle: {"vertical-align": "middle", textAlign: "right"},
        headerClass: "ag-right-aligned-header",
        filter: false,
        width: 80,
        sortable: true,
        comparator: nonNullComparator,
        cellRenderer: percentageCellRendererFunction(2),
    };
};

export const transformationMap = new Map([
    ['allData',
        [
            ['tickerId', 'tickerId'],
            ['studioTicker', 'studioTicker'],
            ['asOfTime', 'asOfTime'],
            ['stockReturn', 'stockReturn'],
            ['idioReturn', 'idioReturn'],
            ['factorReturn', 'factorReturn'],
            ['stockPrice', 'stockPrice'],
            ['accumulatedVolume', 'accumulatedVolume'],
            ['ratioOfNav', 'ratioOfNav'],
            ['eodMarketValue', 'eodMarketValue'],
            ['profitability', 'profitability'],
            ['divYield', 'divYield'],
            ['size', 'size'],
            ['leverage', 'leverage'],
            ['value', 'value'],
            ['momentum', 'momentum'],
            ['growth', 'growth'],
            ['volume', 'volume'],
            ['earnYield', 'earnYield'],
            ['volatility', 'volatility'],
            ['si', 'si'],
        ]
    ],
])

export const columnDefs = [
    {
        headerName: "Ticker",
        field: "studioTicker",
        headerClass: "center-aligned-header",
        filter: true,
        width: 120,
        sortable: true,
        pinned: 'left',
    },
    {
        headerName: "Price",
        field: "stockPrice",
        headerClass: "ag-right-aligned-header",
        filter: false,
        cellStyle: {verticalAlign: "middle", textAlign: 'right'},
        valueFormatter: numberValueFormatter(2),
        width: 80,
        sortable: true,
        comparator: nonNullComparator,
    },
    {
        headerName: "Trade Volume",
        field: "accumulatedVolume",
        headerClass: "ag-right-aligned-header",
        filter: false,
        cellStyle: {verticalAlign: "middle", textAlign: 'right'},
        valueFormatter: numberValueFormatter(),
        width: 120,
        sortable: true,
        comparator: nonNullComparator,
    },
    {
        headerName: "%Net Liq",
        field: "ratioOfNav",
        headerClass: "ag-right-aligned-header",
        filter: false,
        cellStyle: {verticalAlign: "middle", textAlign: 'right'},
        cellRenderer: percentageCellRendererFunction(1),
        width: 80,
        sortable: true,
        comparator: nonNullComparator,
    },
    {
        headerName: "Market Value",
        field: "eodMarketValue",
        headerClass: "ag-right-aligned-header",
        filter: false,
        cellStyle: {verticalAlign: "middle", textAlign: 'right'},
        cellRenderer: dollarCellRendererFunction(),
        width: 110,
        sortable: true,
        comparator: nonNullComparator,
    },
    generateColumnDefinition("Stock", "stockReturn"),
    generateColumnDefinition("Idio", "idioReturn"),
    generateColumnDefinition("Factor", "factorReturn"),
    {
        headerName: "Style Factors Wise Split",
        headerClass: "center-aligned-header",
        children: [
            {...generateColumnDefinition("Growth", "growth"), width: 110},
            generateColumnDefinition("Volatility", "volatility"),
            generateColumnDefinition("Value", "value"),
            generateColumnDefinition("SI", "si"),
            generateColumnDefinition("Momentum", "momentum"),
            generateColumnDefinition("Size", "size"),
            generateColumnDefinition("Leverage", "leverage"),
            generateColumnDefinition("EarnYield", "earnYield"),
            generateColumnDefinition("DivYield", "divYield"),
            generateColumnDefinition("Profitability", "profitability"),
            generateColumnDefinition("Volume", "volume"),
        ]
    },

];
