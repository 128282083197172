import {useEffect, useState} from "react";
import {getDataLatency} from "../../../api/data/DataProvider";

export const colorMap = {
    'GREEN': '#00ba37',
    'RED': '#d76568',
    'WHITE': '#ffffff',
    'BLANK': null,
}

export const GOOGLE_TRENDS = 'GOOGLE_TRENDS';
export const SIMILAR_WEB = 'SIMILAR_WEB';
export const BBG_SECONDMEASURE = 'BBG_SECONDMEASURE';
export const EARNEST_ORION_DEBIT = 'EARNEST_ORION_DEBIT';
export const EARNEST_ORION_CREDIT = 'EARNEST_ORION_CREDIT';
export const EARNEST_VELA_VELORUM = 'EARNEST_VELA_VELORUM';
export const EARNEST_VELA_GAMMA = 'EARNEST_VELA_GAMMA';
export const APPTOPIA_APP_DATA = 'APPTOPIA_APP_DATA';
export const YIPIT = 'YIPIT';

const defaultDataLatency = {
    [GOOGLE_TRENDS]: '-1',
    [SIMILAR_WEB]: '-1',
    [BBG_SECONDMEASURE]: '-1',
    [EARNEST_ORION_DEBIT]: '-1',
    [EARNEST_ORION_CREDIT]: '-1',
    [EARNEST_VELA_VELORUM]: '-1',
    [EARNEST_VELA_GAMMA]: '-1',
    [APPTOPIA_APP_DATA]: '-1',
    [YIPIT]: '-1'
};


export function useDataLatencyHook(activeTick, brand, refreshTrigger){
    const [dataLatency, setDataLatency] = useState(defaultDataLatency);
    const [loadingSla, setLoadingSla] = useState(false);

    useEffect(() => {
        setLoadingSla(true);
        getDataLatency(activeTick?.id, brand?.id)
            .then((res) => {
                let dataLatencyJson = {...defaultDataLatency};
                for (const e in res.data) {
                    let num = res.data[e].daysInWeek
                    let str;

                    // If the number has a fractional part (the number is not an integer),
                    // a comma will replace the decimal point.
                    // Any integer result will be converted to a string, omitting the decimal part.
                    if (num % 1 !== 0) {
                        str = num.toString().replace('.', ',');
                    } else {
                        str = num.toFixed(0);
                    }
                    dataLatencyJson[res.data[e].dataSource] = (res.data[e].daysInWeek === 7 ? '7' :  str) + (res.data[e].isLatestWeek ? '' : '*');
                }
                setLoadingSla(false);
                setDataLatency((prev) => {
                    return dataLatencyJson;
                })
            })
            .catch((err) => {
                console.error("Failed to resolve data SLA: {}", err);
                setLoadingSla(false);
            })
    }, [activeTick, brand, refreshTrigger]);

    const getLatencySuffix = (dataSeries) => {
        if (loadingSla) {
            return '(?)';
        }

        if (dataLatency[dataSeries] === '') {
            return <span style={{color: "green", fontWeight: "bold"}}>7</span>;
        }

        return dataLatency[dataSeries] !== '-1'
            ? dataLatency[dataSeries].includes('*')
                ? <span style={{
                    color: "red",
                    fontWeight: "bold"
                }}>{' (' + dataLatency[dataSeries].replace("*", "") + ')'}</span>
                : <span style={{
                    color: "green",
                    fontWeight: "bold"
                }}>{' (' + dataLatency[dataSeries].replace("*", "") + ')'}</span>
            : <span>{'(-)'}</span>;
    };

    return {dataLatency, loadingSla, getLatencySuffix};

}