import _, {cloneDeep} from "lodash";
import {useEffect, useMemo, useState} from "react";
import highChartToSvgDashStyleConvertor from "./HighChartToSvgDashStyleConvertor";
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts/highstock';
import {reducePxValue} from "./utilityFunctions";

const SVG_LEGEND_LINE_CODE = "M 0 10 L 16 10";
const SVG_DIAMOND_CODE = "M 8 5.5 L 12.6 10 L 8 14.7 L 3.5 10 L 8 5.5 Z";

const LegendFilters = ({ filters, onFilterChanged }) => {
    const handleFilterClick = (filterId) => {
      onFilterChanged(filters.filter(f => f.filterId === filterId).map(f => f.filterId));
    };

    const handleClearFilters = () => {
      onFilterChanged([]);
    };
  
    return (
      <div className="legendFilters">
        
        {filters.map((filter) => (
          <button
            key={filter.filterId}
            className={`filterButton ${filter.selected ? 'selected' : ''}`}
            onClick={() => handleFilterClick(filter.filterId)}
          >
            {filter.filterText}
          </button>
        ))}
        {filters.length > 0 && (
          <button
            className="clearFiltersButton"
            onClick={handleClearFilters}
          >
            Clear ×
          </button>
        )}
      </div>
    );
  };

const CustomLegend = ({series, toggleSeries, chartHeight, onKeyUp, onKeyDown, width, setHoverForSeries, filters, onFilterChanged }) => (
    <div className="divWithCustomScroll" style={{height: reducePxValue(chartHeight, 60), width: width, position: 'relative'}}
         onKeyUp={onKeyUp} onKeyDown={onKeyDown} tabIndex="0">
       
         {filters.length > 0 && (
            <div style={{position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white'}}>
                <LegendFilters filters={filters} onFilterChanged={onFilterChanged} />
            </div>
         )}
          
         <div style={{overflowY: 'auto', maxHeight: '100%'}}>
            {series.map((s, i) =>
                <LegendItem key={i} index={i} series={s} toggleSeries={toggleSeries} setHoverForSeries={setHoverForSeries} />
            )}
         </div>
    </div>
);

const LegendItem = ({series, index, toggleSeries, setHoverForSeries}) => {
    const [isHovered, setIsHovered] = useState(false);

    const onMouseEnter = (index) => {
        setIsHovered(true);
        setHoverForSeries(index, true);
    }

    const onMouseLeave = (index) => {
        setIsHovered(false);
        setHoverForSeries(index, false);
    }

    if (!series.showInLegend) return null;
    const isActive = series.visible;
    const textColor = isActive ? 'black' : '#666666';

    let colourToUse = isHovered ? 'black' : textColor;

    return (
        <span key={index} onClick={() => toggleSeries(index)} className="textSpan"
              style={{cursor: 'pointer', "marginTop": "2px"}}
              onMouseEnter={() => onMouseEnter(index)}
              onMouseLeave={() => onMouseLeave(index)}
        >
            <div>
                {getSvgElementForSeries(series)}
            </div>
            <div>
            <text className="legendText" style={{
                color: colourToUse
            }}>{series.name}</text>
            </div>
        </span>
    )
}

const getSvgElementForSeries = series => {
    let isActive = series.visible;
    let legendColor = isActive ? series.color : '#666666';
    const isMarkerSeries = series && series.marker && series.marker.enabled;
    const strokeStyle = highChartToSvgDashStyleConvertor(series.dashStyle);
    const strokeWidth = series.lineWidth;
    return (
        <svg className="legendSVG">
            <path fill="none" className="highcharts-graph" strokeWidth={strokeWidth}
                  strokeDasharray={strokeStyle}
                  d={SVG_LEGEND_LINE_CODE} stroke={legendColor}></path>
            {isMarkerSeries && (
                <path fill={legendColor} d={SVG_DIAMOND_CODE} stroke="#ffffff" strokeWidth="0"
                      opacity="1"></path>
            )}
        </svg>
    )
}

const StudioStackChart = ({chartData, isMiniView, chartHeightOverride, legendWidthOverride}) => {
    // Initialize all visible series as active
    const [activeSeries, setActiveSeries] = useState(
        _.keys(_.pickBy(chartData.series, (item) => item.visible)).map(indexString => Number(indexString))
    );

    const [selectedFilterIds, setSelectedFilterIds] = useState(
        chartData?.filters?.filter(f => f.selected).map(f => f.filterId) || []
    );

    const onFilterChanged = (selectedFilters) => {
        setSelectedFilterIds(selectedFilters);

        const visibleSeriesNames = chartData.series
            .filter(series => 
                selectedFilters.length === 0 || selectedFilters.some(filterId => chartData.filters.find(f => f.filterId === filterId)?.seriesNames.includes(series.name))
            )
            .map(series => series.name);
        
        setChartOptions(prevOptions => ({
            ...prevOptions,
            series: prevOptions.series.map(series => ({
                ...series,
                visible: visibleSeriesNames.includes(series.name)
            }))
        }));
    };

    const [keysPressed, setKeysPressed] = useState({zKey: false, xKey: false, cKey: false});

    const handleKeyDown = (event) => {
        const newKeysStatus = {...keysPressed};
        if (['z', 'x', 'c'].includes(event.key)) {
            newKeysStatus[`${event.key}Key`] = true;
            setKeysPressed(newKeysStatus);
        }
    };

    const handleKeyUp = (event) => {
        const newKeysStatus = {...keysPressed};
        if (['z', 'x', 'c'].includes(event.key)) {
            newKeysStatus[`${event.key}Key`] = false;
            setKeysPressed(newKeysStatus);
        }
    };

    // Handler to toggle series on click + Code to deal with shortcuts for chart legend:
    const toggleSeries = (index) => {
        if (keysPressed.xKey) {
            // toggle the Y-axis visibility for the series:
            let newActiveSeries = activeSeries;
            if (!activeSeries.includes(index)) newActiveSeries = [...activeSeries, index]
            setActiveSeries(newActiveSeries);
            setChartOptions(prevOptions => {
                const updatedOptions = cloneDeep(prevOptions);
                updatedOptions.yAxis[updatedOptions.series[index].yAxis].visible = !updatedOptions.yAxis[updatedOptions.series[index].yAxis].visible;
                updatedOptions.series[index].visible = true;
                return updatedOptions;
            })
            return;
        }
        if (keysPressed.cKey) {
            // Clear all the visible axes:
            const updatedOptions = cloneDeep(chartOptions);
            updatedOptions.yAxis.forEach((axis) => {
                axis.visible = false;
            });
            setChartOptions(updatedOptions);
            setActiveSeries((prevState) => [...prevState, index]);
            return;
        }
        let newActiveSeries;
        let isDisablingSeries;
        if (keysPressed.zKey) {
            let idioIndex = 0;
            // Validate that this above heuristic is valid in this case:
            if (chartData.series[idioIndex].name !== 'idio') {
                idioIndex = _.findIndex(chartData.series, (series) => series.name.toLowerCase() === 'idio');
            }
            // To preserve the scale, we always need to preserve the ghost series:
            const ghostIndex = _.findIndex(chartData.series, (series) => series.name.toLowerCase() === 'ghost');
            const ghostDailyIndex = _.findIndex(chartData.series, (series) => series.name.toLowerCase() === 'ghost_daily');
            // Disable all the series, apart from the current one and idio:
            isDisablingSeries = false;
            if (index === idioIndex)
                newActiveSeries = [index, ghostIndex, ghostDailyIndex];
            else
                newActiveSeries = [idioIndex, index, ghostIndex, ghostDailyIndex];
        } else {
            // No key is pressed, simply toggle the values:
            if (activeSeries.includes(index)) {
                newActiveSeries = activeSeries.filter((seriesName) => seriesName !== index);
                isDisablingSeries = true;
            }
            else {
                newActiveSeries = [...activeSeries, index];
                isDisablingSeries = false;
            }
        }
        setActiveSeries(newActiveSeries);
        // disable the visible axis for all inactive series:
        setChartOptions(prevOptions => {
            let newOptions =  {
                ...prevOptions,
                series: prevOptions.series.map((seriesItem, index) => ({
                    ...seriesItem,
                    visible: newActiveSeries.includes(index)
                })),
                yAxis: prevOptions.yAxis.map((series, i) => ({
                    ...series,
                    visible: series.visible && newActiveSeries.includes(i)
                }))
            }
            return modifyOpacityOfSeries(index, newOptions, !isDisablingSeries);
        })
    };

    const [chartOptions, setChartOptions] = useState(chartData);
    // If the chartData gets changed, chart options should be updated:
    useEffect(() => {
        setChartOptions(chartData)
        setActiveSeries((
            _.keys(_.pickBy(chartData.series, (item) => item.visible)).map(indexString => Number(indexString))
        ))
    }, [chartData]);

    const setHoverForSeries = (index, isHovering) => {
        if(!activeSeries.includes(index)) return;
        setChartOptions((prev) => modifyOpacityOfSeries(index, prev, isHovering));
    }

    const modifyOpacityOfSeries = (index, chartOptions, highlightOneSeries) => {
        let newOptions = {
            ...chartOptions
        }
        newOptions.series = newOptions.series.map((seriesItem) => ({
            ...seriesItem,
            opacity: highlightOneSeries ? 0.1: 1
        }));
        newOptions.series[index].opacity = 1;
        return newOptions;
    }

    // Calculate the height of the charts and the widths of the legends:
    let chartHeight = isMiniView ? '450px' : '600px';
    if (chartHeightOverride !== null && chartHeightOverride !== undefined) chartHeight = chartHeightOverride;
    let legendWidth = isMiniView ? '200px' : '300px';
    if (legendWidthOverride !== null && legendWidthOverride !== undefined) legendWidth = legendWidthOverride;

    // Manage maintaining the extremities, since these will be modified within the chart:
    const [extremities, setExtremities] = useState({
        minX: null,
        maxX: null
    });

    const [disableRangeSelector, setDisableRangeSelector] = useState(false);

    const rightSideTooltipPositioner = (labelWidth, labelHeight, point) => {
        let toolTipX = point.isHeader === true ? point.plotX : point.plotX + 30;
        return {
            x: toolTipX,
            y: point.plotY
        }
    }

    const memoizedChartOptions = useMemo(() => {
        let newOptions = {
            ...chartOptions,
            chart: {
                ...chartOptions.chart,
                height: Number(chartHeight.replace('px', ''))
            },
            legend: {
                ...chartOptions.legend,
                enabled: false
            },
            xAxis: {
                ...chartOptions.xAxis,
                events: {
                    setExtremes: (e) => {
                        setExtremities({
                            xMin: e.min,
                            xMax: e.max,
                        });
                        setDisableRangeSelector(true);
                    }
                },
                crosshair:{
                    color: 'black',
                    dashStyle: 'shortdot'
                }
            },
            tooltip: {
                ...chartOptions.tooltip,
                positioner: rightSideTooltipPositioner,
                shadow: false,
                animation: false,
                outside: true,
                valueSuffix: ' %',
                backgroundColor: 'rgba(255,255,255,0.7)',
                hideDelay: 0
            },
        };
        if(extremities.minX && extremities.maxX) {
            newOptions.xAxis.min = extremities.minX;
            newOptions.xAxis.max = extremities.maxX;
        }
        return newOptions;
    }, [chartOptions, chartHeight, disableRangeSelector]);
    // Note: for the above, we intentionally do not add dependnecy on extremities, since we only want to use that information on next re-render, and we don't want change of extremities to cause a re-render.

    return (
        <div className="studioChartContainer" style={{height: chartHeight}}>
            <div className="chartContainer">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={memoizedChartOptions}
                />
            </div>


            <CustomLegend series={memoizedChartOptions.series}
                          toggleSeries={toggleSeries} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}
                          width={legendWidth}
                          filters={chartData.filters.map(filter => ({...filter, selected: selectedFilterIds.includes(filter.filterId)}))}
                          onFilterChanged={onFilterChanged}
                          chartHeight={chartHeight}
                          setHoverForSeries={setHoverForSeries}
            />

        </div>
    );
};

export default StudioStackChart;