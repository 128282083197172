import {forwardRef, memo, useEffect, useMemo, useState} from "react";
import {getData, getDataForBrand} from "../../api/data/DataProvider";
import LoadingOverlay from 'react-loading-overlay';
import {CAlert} from "@coreui/react";
import StudioHighChart from "../studio_high_chart/StudioHighChart";

const DailyCharts = memo((props) =>  {

    const chartConfiguration = useMemo(() => {
        if(props.chartData == null) return null;
        let titleText = props.activeTicker + (props.activeBrand != null ? ' - ' + props.activeBrandName : '') + '-' + props.chartData?.data?.title?.text;

        return {
            ...props.chartData.data,
            title: {
                text: titleText
            },
            rangeSelector: {
                buttons: [
                    { type: 'day', count: 1, text: '1d' },
                    { type: 'week', count: 1, text: '1w' },
                    { type: 'week', count: 2, text: '2w' },
                    { type: 'month', count: 1, text: '1m' },
                    { type: 'month', count: 2, text: '2m' },
                    { type: 'month', count: 3, text: '3m' },
                    { type: 'month', count: 6, text: '6m' },
                    { type: 'year', count: 1, text: '1y' },
                    { type: 'year', count: 2, text: '2y' },
                    { type: 'ytd', text: 'YTD' },
                    { type: 'all', text: 'All' }
                ],
                selected: 6
            },
            chart: {
                ...props.chartData.data.chart,
                scrollablePlotArea: { minWidth: 600 },
                zooming: { mouseWheel: { enabled: false } },
                zoomType: 'x',
                height: 600,
                plotBackgroundColor: '#E5ECF6',
                panning: { enabled: true, type: 'x' },
                panKey: 'shift'
            }
        };
    }, [props.chartData, props.activeTicker, props.activeBrand]);

    const chartHeight = '600px';

    return (
        <div>
            <a id={props.queryType} href={""}> </a>
            <LoadingOverlay
                styles={{
                    overlay: (base) => ({
                        ...base,
                        height: chartHeight,
                        width: '100%'
                    })
                }}
                active={props.loading && !props.isError}
                spinner={!props.isError}
                text={"Loading..."}
            >
                {chartConfiguration == null && !props.isError && <div style={{height: chartHeight, width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}><h1 style={{color: 'white'}}>Chart is loading. Please wait.</h1></div>}
                {props.isError && <div>
                    <CAlert color="danger">
                        Failed to load - {props.queryType}.
                        Data Not Configured
                    </CAlert>
                </div>}
                {chartConfiguration != null && !props.isError && chartConfiguration.series?.length === 0 && <div>
                    <CAlert color="warning">
                        Data Not Available - {props.queryType}
                    </CAlert>
                </div>}
                {chartConfiguration != null && !props.isError && chartConfiguration.series?.length !== 0 && <center><div style={{ marginTop: 10, paddingLeft: 10 }}>
                    <StudioHighChart
                        chartData={chartConfiguration}
                        isMiniView={props.showMiniView}
                        queryType={props.queryType}
                        chartConfiguration={props.chartConfiguration}
                    />
                   </div></center>}
            </LoadingOverlay>

        </div>
    )
})
export default DailyCharts;