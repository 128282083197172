import {AgGridReact} from "@ag-grid-community/react";
import {Col, DatePicker, Row} from "antd";
import * as dayjs from 'dayjs';
import {useEffect, useMemo, useState} from "react";
import {getTickers} from "../../../api/data/DataProvider";
import {
    getAllPositions,
    getDriftStockAndIdioReturn,
    getLatestPerformanceDate,
    getStockAndIdioReturn
} from "../../../api/data/PortfolioDataProvider";
import {columnDefs, defaultColDef, transformationMap} from "./DashboardColumns";
import './css/dashboard.css';

const _ = require('lodash');

const applyTransformation = (rowData, type, source) => {
    let updatedRows = [];
    for (let transformKey of transformationMap.get(type)) {
        let value = null;
        let transformationLogic = transformKey[1];
        if (transformationLogic instanceof Function) {
            value = transformationLogic(source);
        } else if (typeof transformationLogic == 'string') {
            value = _.get(source, transformationLogic, null);
        }

        if (value !== null) {
            _.set(rowData, transformKey[0], value);
            if (updatedRows.length === 0) {
                updatedRows.push(rowData);
            }
        }
    }

    return updatedRows;
}

function PortfolioDashboard(props) {
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [defaultDate, setDefaultDate] = useState(null);
    const [isPositionLoaded, setIsPositionLoaded] = useState(false);

    const onDateChange = (date, dateStr) => {
        props.setReportDate(dateStr);
    }

    const getRowId = useMemo(() => {
        return (params) => params.data.tickerId;
    }, []);

    useEffect(() => {
        const abortController = new AbortController();
        getLatestPerformanceDate(abortController)
            .then((res) => {
                if (!props.reportDate) {
                    props.setReportDate(res.data);
                    setDefaultDate(res.data);
                }
            })
            .catch((err) => {
                console.error(err);
            })

        return () => abortController.abort();
    }, []);

    useEffect(() => {
        getTickers()
            .then((res) => {
                console.log('Ticker list called successfully', res.data);
                setRowData(res.data);
            })
            .catch((err) => {
                console.error("Failed to get all tickers!", err);
            })

        return;
    }, []);

    useEffect(() => {
        const abortController = new AbortController();
        setIsPositionLoaded(false);
        getAllPositions(props.reportDate, [], abortController)
            .then((res) => {
                let data = res.data;
                if (gridApi) {
                    for (let position of data) {
                        let tickerNode = gridApi.getRowNode(position['tickerId']);
                        if (tickerNode) {
                            let data = applyTransformation(tickerNode.data, 'position', position);
                            gridApi.applyTransaction({ update: data });
                        }
                    }
                }
                setIsPositionLoaded(true);
            })
            .catch((err) => {
                console.error("Failed to get all positions!", err);
            })

        return () => abortController.abort();
    }, [props.reportDate, rowData]);

    useEffect(() => {
        const batchSize = 20; // Number of tickerIds to process in each batch
        const allTickerIds = [];
        const abortController = new AbortController();
        if (gridApi && isPositionLoaded) {
            gridApi.forEachNodeAfterFilterAndSort(node => {
                allTickerIds.push(node.data.tickerId);
            });

            // Split tickerIds into batches
            const tickerIdBatches = [];
            for (let i = 0; i < allTickerIds.length; i += batchSize) {
                tickerIdBatches.push(allTickerIds.slice(i, i + batchSize));
            }

            // Process each batch sequentially
            for (const tickerIdBatch of tickerIdBatches) {
                setTimeout(async () => {
                    try {
                        const res = await getStockAndIdioReturn(props.reportDate, tickerIdBatch, abortController);
                        const data = res.data;
                        for (const returnObj of data) {
                            const tickerNode = gridApi.getRowNode(returnObj['tickerId']);
                            if (tickerNode) {
                                let updatedData = applyTransformation(tickerNode.data, 'stockReturn', returnObj);
                                gridApi.applyTransaction({ update: [updatedData] });
                                gridApi.refreshCells({ rowNodes: [tickerNode] })
                            }
                        }

                    } catch (err) {
                        console.error("Failed to get stock and idio returns for batch:", tickerIdBatch, err);
                    }
                }, 100);
            }
        }

        return () => abortController.abort();
    }, [isPositionLoaded]);

    useEffect(() => {
        const batchSize = 20; // Number of tickerIds to process in each batch
        const allTickerIds = [];
        const abortController = new AbortController();
        if (gridApi && isPositionLoaded) {
            gridApi.forEachNodeAfterFilterAndSort(node => {
                allTickerIds.push(node.data.tickerId);
            });

            // Split tickerIds into batches
            const tickerIdBatches = [];
            for (let i = 0; i < allTickerIds.length; i += batchSize) {
                tickerIdBatches.push(allTickerIds.slice(i, i + batchSize));
            }

            // Process each batch sequentially
            for (const tickerIdBatch of tickerIdBatches) {
                setTimeout(async () => {
                    try {
                        const res = await getDriftStockAndIdioReturn(props.reportDate, tickerIdBatch, abortController);
                        const data = res.data;
                        for (const returnObj of data) {
                            const tickerNode = gridApi.getRowNode(returnObj['tickerId']);
                            if (tickerNode) {
                                let updatedData = applyTransformation(tickerNode.data, 'driftReturn', returnObj);
                                gridApi.applyTransaction({ update: [updatedData] });
                                gridApi.refreshCells({ rowNodes: [tickerNode] })
                            }
                        }

                    } catch (err) {
                        console.error("Failed to get stock and idio returns for batch:", tickerIdBatch, err);
                    }
                }, 100);
            }
        }

        return () => abortController.abort();
    }, [isPositionLoaded]);

    return (
        <>
            <div id="portfolio-dashboard" style={{ marginTop: 50 }}>
                <Row align="middle">
                    <Col span={24}>
                        <DatePicker value={props.reportDate ? dayjs(props.reportDate) : dayjs(defaultDate)} onChange={onDateChange} size='large' />
                    </Col>
                </Row>
                <Row>
                    <div className="portfolio-dashboard ag-theme-alpine" style={{ height: '90vh', width: '96%', marginLeft: '2%' }}>
                        <AgGridReact
                            onGridReady={(params) => {
                                setGridApi(params.api);
                            }}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowData={rowData}
                            getRowId={getRowId}
                        />
                    </div>
                </Row>
            </div>
        </>
    )
}

export default PortfolioDashboard;