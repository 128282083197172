import {useEffect, useRef, useState} from "react";
import {Select} from 'antd';
import {TICKER_NAME} from "../DashboardReport/DashboardConstants";
import {monitor} from "../../../event_handler/KeyPressMonitor";

// Ticker Dropdown
export function TickerDropdown(props) {
    const searchParams = new URLSearchParams(document.location.search);
    let[tickLoading, setTickLoading] = useState(false);
    let [openTickerDropDown, setOpenTickerDropDown] = useState(searchParams.get(TICKER_NAME) === null && props.activeTick.id == null);
    const tickSelectRef = useRef(null);

    useEffect(() => {
        if(props.activeTick?.id == null){
            tickSelectRef.current.focus();
        }
    }, [props.activeTick])

    const _onSelect = (value, element) => {
        monitor.enableHotKeys(true);
        setTickLoading(true);
        setOpenTickerDropDown(undefined);

        if (element.label === "MACRO") {
            props.setShowMacroView(true);
        } else {
            props.setShowMacroView(false);
            props.setActiveTick({
                name: element.label,
                id: element.value
            });
            props.setActiveBrand({
                name: "Select Brand",
                id: null
            });
        }

        if (tickSelectRef.current) {
            tickSelectRef.current.blur();
        }
        setTimeout(() => {
            setTickLoading(false)
        }, 3000)
    }

    const _filter = (inputValue, option) => {
        return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
    };

    const _sort = (optionA, optionB) => {
        if(optionA.label === "Select Brand"){
            return 1;
        } else if (optionB.label === "Select Brand"){
            return -1;
        }

        return optionA.label.length - optionB.label.length;
    };

    return (
        <center>
            <Select
                ref={tickSelectRef}
                style={{width: 150}}
                showSearch
                defaultOpen={openTickerDropDown}
                onFocus={() => {
                    monitor.enableHotKeys(false);
                }}
                onBlur={() => monitor.enableHotKeys(true)}
                loading={tickLoading}
                defaultValue={{
                    label: props.activeTick.name,
                    value: props.activeTick.value
                }}
                value={{
                    label: props.activeTick.name,
                    value: props.activeTick.id
                }}
                filterOption = {_filter}
                filterSort = {_sort}
                onChange={_onSelect}
                optionFilterProp={"label"}
                options={props.tickerOptions} />
        </center>
    );
}