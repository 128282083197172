import {AgGridReact} from "@ag-grid-community/react";
import {useEffect, useMemo, useState} from "react";
import LoadingOverlay from "react-loading-overlay";
import {getWashsaleInfo} from "../../../api/data/PortfolioDataProvider";
import {columnDefs} from "./configuration/WashsaleConfiguration";

function WashsaleTable(props) {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        marryChildren: true,
        resizable: true,
        sizeColumnsToFit: true,
        skipHeaderOnAutoSize: true,
        suppressMovable: true,
        suppressDragLeaveHidesColumns: true,
    }), []);

    useEffect(() => {
        const abortController = new AbortController();
        setLoading(true);
        getWashsaleInfo(props.reportDate, props.accountGroup?.accountIds, abortController)
            .then((res) => {
                let data = res.data;
                setRowData(data.stocks);
                setLoading(false);
            })
            .catch((err) => {
                console.error("Failed to get all positions!", err);
                setLoading(false);
            })

        return () => abortController.abort();
    }, [props.reportDate, props.accountGroup]);

    return (
        <div id='position-table' style={{ marginTop: 10 }}>
            <LoadingOverlay active={loading} text={"Loading..."}>
                <div className="nav-grid ag-theme-alpine" style={{ height: '40vh', width: '700px', margin: '0px auto' }}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowData={rowData}
                        pagination={false}
                    />
                </div>
            </LoadingOverlay>
        </div>
    )
}

export default WashsaleTable;