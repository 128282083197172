// Value formatter with number of decimal places
export function numberValueFormatter(numberOfDecimals) {
    if (!numberOfDecimals) {
        numberOfDecimals = 0;
    }

    const options = {
        style: 'decimal',
        minimumFractionDigits: numberOfDecimals,
        maximumFractionDigits: numberOfDecimals,
    };

    return (params) => {
        if (params.value && typeof params.value === 'number') {
            let number = params.value;

            return number < 0
                ? `(${Math.abs(number).toLocaleString("en-US", options)})`
                : `${number.toLocaleString("en-US", options)}` + ' ';
        }

        return params.value;
    }
}

// Render a cell with decimal value as percentages upto certain decimal digits
export function percentageCellRendererFunction(numberOfDecimals) {
    return (params) => {
        if (params.value != null) {
            let number = params.value;
            if (number >= 0) {
                return <span style={{color: 'green'}}>{(number * 100).toFixed(numberOfDecimals)}%&nbsp;</span>;
            } else {
                return <span style={{color: 'red'}}>({(Math.abs(number) * 100).toFixed(numberOfDecimals)}%)</span>;
            }
        } else {
            return <></>;
        }
    }
}

// Renders dollar value columns with required numberOfDecimals, defaults to no decimal digits
export function dollarCellRendererFunction(numberOfDecimals) {
    if (!numberOfDecimals) {
        numberOfDecimals = 0;
    }

    return (params) => {
        if (params.value != null) {
            let number = params.value;

            if(number >= 0) {
                return <span style={{color: "green"}}>
                {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    maximumFractionDigits: numberOfDecimals,
                    currency: 'USD'
                }).format(number)}&nbsp;</span>;
            } else {
                return <span style={{color: "red"}}>
                ({new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    maximumFractionDigits: numberOfDecimals,
                    currency: 'USD'
                }).format(Math.abs(number))})</span>;
            }
        } else {
            return <></>;
        }
    }
}

// Comparator to use in AG Grid while sorting column with null and non-null values
export function nonNullComparator(a, b, nodeA, nodeB, isInverted) {
    if (a === b) {
        return 0;
    }
    // for null
    else if (a == null) {
        return isInverted ? -1 : 1;
    } else if (b == null) {
        return isInverted ? 1 : -1;
    } else {
        return (a > b) ? 1 : -1;
    }
}

// Render a cell with decimal value as bps
export function bpsCellRendererFunction() {
    return (params) => {
        if (params.value != null) {
            let number = params.value;
            if (number >= 0) {
                return <span style={{color: 'green'}}>{(number * 10000).toFixed(0)}&nbsp;</span>;
            } else {
                return <span style={{color: 'red'}}>({(Math.abs(number) * 10000).toFixed(0)})</span>;
            }
        } else {
            return <></>;
        }
    }
}