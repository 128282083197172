import {createContext, useContext, useEffect, useRef, useState} from "react";
import {getIntradayOverview} from "../../../api/data/DataProvider";
import {RefreshContext} from "../../oracle/commonContexts/RefreshContextProvider";

export const IntradayStockPriceContext = createContext(null);



export const IntradayStockPriceDataProvider = ({children, activeTicker}) => {

    const {refresh} = useContext(RefreshContext);

    const [intradayOverviewData, setIntradayOverviewData] = useState({
        data: null,
        loading: true,
        isError: false
    });

    useEffect(() => {
        if(activeTicker?.id == null) return;
        const abortController = new AbortController();
        setIntradayOverviewData({
            data: null,
            loading: true,
            isError: false
        });

        getIntradayOverview(activeTicker.id, abortController, null)
            .then(res =>
            {
                setIntradayOverviewData({
                    data: res.data,
                    loading: false,
                    isError: false
                });
            })
            .catch(err => {
                if(err.code === 'ERR_CANCELED') {
                    //Do nothing
                } else {
                    setIntradayOverviewData({
                        data: null,
                        loading: false,
                        isError: true
                    });
                }
            });
        return () => {
            abortController.abort();
        }

    }, [activeTicker, refresh])

    const [chartOptions, setChartOptions] = useState({});

    const workerRef = useRef(null);

    useEffect(() => {
        workerRef.current = new Worker(new URL('../workers/intraday-stock-price-worker.js', import.meta.url));

        workerRef.current.onmessage = (event) => {
            setChartOptions(event.data);
        }
        workerRef.current.postMessage({tickerId: activeTicker?.id, text: activeTicker?.name});

        return () => {

            workerRef.current?.postMessage({command: 'terminate'})
            setChartOptions(null);
        }


    }, [activeTicker, refresh]);


    const loading = intradayOverviewData.loading;
    const isError  = intradayOverviewData.isError;

    return (
        <IntradayStockPriceContext.Provider value={{ chartOptions, loading, isError}}>
            {children}
        </IntradayStockPriceContext.Provider>
    )
}