import React, {useEffect, useState} from 'react';
import {Button, Input, Layout} from 'antd';
import StudioHighChart from "../studio_high_chart/StudioFinHighChart";
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import {computationService} from '../../api/Clients';
import {AgGridReact} from "@ag-grid-community/react";

const SeriesDebuggerPage = () => {
    const [seriesId, setSeriesId] = useState('404__PRICE__a_levels');
    const [chartData, setChartData] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [seriesDesc, setSeriesDesc] = useState({});
    const [columnDefs, setColumnDefs] = useState([]);

    const fetchData = async () => {
        try {
            const response = await computationService.get(`/v1/multiples/series/debug?series_id=${seriesId}`);
            setChartData(response.data.chart);
            setTableData(response.data.data);
            setSeriesDesc(response.data.desc);
            // Update column definitions based on the first item in the data array
            if (response.data.data.length > 0) {
                const columns = Object.keys(response.data.data[0]).map(key => ({
                    field: key,
                    sortable: true,
                    filter: true
                }));
                setColumnDefs(columns);
            }
        } catch (error) {
            console.error('Failed to fetch series data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []); // Remove seriesId from dependency array to prevent auto-fetch on id change

    const handleSeriesIdChange = (event) => {
        setSeriesId(event.target.value);
    };

    const handleFetchClick = () => {
        fetchData();
    };

    return (
        <Layout>
            <div style={{ padding: '20px' }}>
                <Input
                    value={seriesId}
                    onChange={handleSeriesIdChange}
                    placeholder="Enter Series ID"
                    style={{ width: 300, marginRight: '10px' }}
                />
                <Button onClick={handleFetchClick} type="primary">
                    Fetch Series
                </Button>
            </div>
            {seriesDesc && Object.keys(seriesDesc).length > 0 && (
                <div style={{ padding: '20px', marginBottom: '20px' }}>
                    <table style={{ width: '100%', marginBottom: '20px' }}>
                        <tbody>
                            {Object.entries(seriesDesc).map(([key, value]) => (
                                <tr key={key}>
                                    <td style={{ fontWeight: 'bold', paddingRight: '10px' }}>{key}:</td>
                                    <td>{JSON.stringify(value)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
           { chartData && <div style={{ }}>
                <StudioHighChart chartData={chartData} />
            </div>}
            {tableData && <div className="ag-theme-alpine" style={{ height: '400px', width: '100%', marginTop: '20px' }}>
                <AgGridReact
                    rowData={tableData}
                    columnDefs={columnDefs}
                    domLayout='autoHeight'
                    pagination={true}
                    paginationPageSize={50}
                />
            </div> }
        </Layout>
    );
};

export default SeriesDebuggerPage;