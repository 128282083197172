import MacroStackCharts from "../line_chart/macro/MacroStackCharts";
import {Layout, Menu, Space, Switch, Tooltip} from "antd";
import MacroLineChart from "../line_chart/macro/MacroLineChart";
import HC_exporting from "highcharts/modules/exporting";
import Highcharts from "highcharts";
import HighchartsStock from "highcharts/modules/stock";
import MarketFactorChart from "../line_chart/macro/MarketFactorChart";
import {CAlert} from "@coreui/react";
import MacroSectorLineChart from "../line_chart/macro/MacroSectorLineChart";
import {useContext} from "react";
import {RefreshContext} from "./commonContexts/RefreshContextProvider";

const { Header, Content } = Layout;


function MacroReport(props) {

    const {refresh} = useContext(RefreshContext);

    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'instant' });
        }
    };


    return (
        <div>
            {HC_exporting(Highcharts)}
            {require("highcharts/modules/export-data")(Highcharts)}
            {HighchartsStock(Highcharts)}
            <Layout>
                <Header style={{ height: 45, position: "fixed", padding: '0 10px', width: "100%", zIndex: 1000 }}>
                    <div className="logo" style={{ float: "left", marginTop: -10, color: "#ffffff" }}>
                        <img style={{ height: 25, marginRight: 15 }} src={"/dashboard_logo.png"} />
                    </div>
                    <Menu mode={"horizontal"} theme={"dark"} style={{ height: 45, alignItems: "center", overflow: "hidden" }}>
                        <Menu.Item key="1" onClick={() => scrollToElement('WEEKLY_YOY')}>
                           Weekly YoY
                        </Menu.Item>
                        <Menu.Item key="2" onClick={() => scrollToElement('QTR_YOY_LAG0')}>
                            Qtr YoY
                        </Menu.Item>
                        <Menu.Item key="3" onClick={() => scrollToElement('SW_VISITS_MACRO_WEEKLY_WW')}>
                            SW
                        </Menu.Item>
                        <Menu.Item key="4" onClick={() => scrollToElement('SM_SALES_MACRO_WEEKLY')}>
                            SM
                        </Menu.Item>
                        <Menu.Item key="5" onClick={() => scrollToElement('ER_ORION_SALES_MACRO_WEEKLY')}>
                            Orion
                        </Menu.Item>
                        <Menu.Item key="6" onClick={() => scrollToElement('ER_VELA_GAMMA_SALES_MACRO_WEEKLY')}>
                            Gamma
                        </Menu.Item>
                        <Menu.Item key="7" onClick={() => scrollToElement('ER_VELA_VELORUM_SALES_MACRO_WEEKLY')}>
                            Velorum
                        </Menu.Item>
                        <Menu.Item key="8" onClick={() => scrollToElement('SM_PX_MACRO_WEEKLY')}>
                            Px
                        </Menu.Item>
                        <Menu.Item key="9" onClick={() => scrollToElement('LN_EMPLOYEE_COUNT_YOY')}>
                            Linkedin
                        </Menu.Item>
                        <Menu.Item key="10" onClick={() => scrollToElement('FACTOR_LOADINGS')}>
                            Factor
                        </Menu.Item>
                        <Menu.Item key="11" onClick={() => scrollToElement('SUB_SECTOR_INDEXES')}>
                            Sector CC
                        </Menu.Item>
                        <Menu.Item disabled>
                            <Space direction="horizontal" style={{ color: 'white' }}>
                                <Tooltip title="Press Ctrl+M to toggle">
                                    <Switch
                                        checked={props.showMacroView}
                                        checkedChildren="ON"
                                        unCheckedChildren="OFF"
                                        onChange={(checkValue) => props.setShowMacroView(checkValue)}
                                    /> Macro View</Tooltip>
                            </Space>
                        </Menu.Item>
                    </Menu>
                </Header>
                <Content style={{marginTop: 50}}>
                    <br/>
                    <h4> Macro Stack Views </h4>
                    <MacroLineChart queryType={"WEEKLY_YOY"} refresh={refresh}/>
                    <MacroSectorLineChart sectorRegex={undefined} queryType={"WEEKLY_CC_INDEX"} refresh={refresh} />
                    <MacroLineChart queryType={"WEEKLY_YOY2"} refresh={refresh} />
                    <MacroLineChart queryType={"WEEKLY_YOY3"} refresh={refresh} />
                    <MacroLineChart queryType={"WEEKLY_PROFIT_YOY_LAG0"} refresh={refresh} />
                    <MacroLineChart queryType={"WEEKLY_PRICING_YOY_LAG0"} refresh={refresh} />
                    <MacroLineChart queryType={"WEEKLY_TRANSACTIONS_YOY_LAG0"} refresh={refresh} />
                    <MacroLineChart queryType={"WEEKLY_PROFIT_YOY2_LAG0"} refresh={refresh} />
                    <MacroLineChart queryType={"WEEKLY_PRICING_YOY2_LAG0"} refresh={refresh} />
                    <MacroLineChart queryType={"WEEKLY_TRANSACTIONS_YOY2_LAG0"} refresh={refresh} />
                    <MacroLineChart queryType={"WEEKLY_MULTI_YEAR_ORACLE_CC_INDEX"} refresh={refresh} />
                    <MacroLineChart queryType={"WEEKLY_LEVELS"} refresh={refresh} />
                    <MacroLineChart queryType={"QTR_YOY_LAG0"} refresh={refresh} />
                    <MacroLineChart queryType={"QTR_YOY2_LAG0"} refresh={refresh} />
                    <MacroLineChart queryType={"QTR_YOY3_LAG0"} refresh={refresh} />
                    <MacroLineChart queryType={"QTR_LEVELS_LAG0"} refresh={refresh} />
                    <MacroStackCharts queryType={"SW_VISITS_MACRO_WEEKLY_WW"} refresh={refresh} />
                    <MacroStackCharts queryType={"SW_VISITS_MACRO_WEEKLY_US"} refresh={refresh} />
                    <MacroStackCharts queryType={"SM_SALES_MACRO_WEEKLY"} refresh={refresh} />
                    <MacroStackCharts queryType={"SM_TRANSACTIONS_MACRO_WEEKLY"} refresh={refresh} />
                    <MacroStackCharts queryType={"ER_ORION_SALES_MACRO_WEEKLY"} refresh={refresh} />
                    <MacroStackCharts queryType={"ER_ORION_TRANSACTIONS_MACRO_WEEKLY"} refresh={refresh} />
                    <MacroStackCharts queryType={"ER_VELA_GAMMA_SALES_MACRO_WEEKLY"} refresh={refresh} />
                    <MacroStackCharts queryType={"ER_VELA_GAMMA_TRANSACTIONS_MACRO_WEEKLY"} refresh={refresh} />
                    <MacroStackCharts queryType={"ER_VELA_VELORUM_SALES_MACRO_WEEKLY"} refresh={refresh} />
                    <MacroStackCharts queryType={"ER_VELA_VELORUM_TRANSACTIONS_MACRO_WEEKLY"} refresh={refresh} />
                    <MacroStackCharts queryType={"SM_PX_MACRO_WEEKLY"} refresh={refresh} />
                    <MacroStackCharts queryType={"ER_ORION_PX_MACRO_WEEKLY"} refresh={refresh} />
                    <MacroStackCharts queryType={"ER_VELA_GAMMA_PX_MACRO_WEEKLY"} refresh={refresh} />
                    <MacroStackCharts queryType={"ER_VELA_VELORUM_PX_MACRO_WEEKLY"} refresh={refresh} />
                    <MacroStackCharts queryType={"LN_EMPLOYEE_COUNT_YOY"} refresh={refresh} />
                    <CAlert color={"warning"}>The Cumulative Returns are calculated as the sum of daily returns from the start date to the plot date.</CAlert>
                    <MarketFactorChart queryType={"FACTOR_LOADINGS"} refresh={refresh}/>
                    <div id={"SUB_SECTOR_INDEXES"} />
                    <CAlert color={"info"}>Sector Specific Credit Card Indexes</CAlert>
                    <MacroSectorLineChart sectorRegex={"s.consumer.retail"} queryType={"WEEKLY_CC_INDEX"} refresh={refresh} />
                    <MacroSectorLineChart sectorRegex={"s.consumer.brands"} queryType={"WEEKLY_CC_INDEX"} refresh={refresh} />
                    <MacroSectorLineChart sectorRegex={"s.consumer.leisure"} queryType={"WEEKLY_CC_INDEX"} refresh={refresh} />
                    <MacroSectorLineChart sectorRegex={"s.consumer.restaurant"} queryType={"WEEKLY_CC_INDEX"} refresh={refresh} />
                    <MacroSectorLineChart sectorRegex={"s.consumer.staples"} queryType={"WEEKLY_CC_INDEX"} refresh={refresh} />
                    <MacroSectorLineChart sectorRegex={"s.consumer.other"} queryType={"WEEKLY_CC_INDEX"} refresh={refresh} />
                </Content>
            </Layout>
        </div>
    )
}

export default MacroReport;