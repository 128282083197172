import {pfService} from "../Clients";


export let getIdioData = (tickerId, controller, scheduler = null, priority = 1) => {
    const dataFetchCall = () => pfService.get("/portfolio/idio/" + tickerId, {
        signal: controller?.signal
    });
    if(scheduler === null) return dataFetchCall();
    return scheduler.scheduleApiCall(dataFetchCall, tickerId, priority, true);
}

export let getAllPositions = (date, accountIds, controller) => {
    return pfService.get("/portfolio/position/all?asOf=" + date + "&accountIds=" + accountIds.join(','), {
        signal: controller?.signal
    });
}

export let getIntradayPositions = (controller) => {
    return pfService.get("/portfolio/position/intraday", {
        signal: controller?.signal
    });
}

export let getLatestPerformanceDate = (controller) => {
    return pfService.get("/portfolio/performance/latest-date", {
        signal: controller?.signal
    });
}

export let getLatestPerformanceTime = (controller) => {
    return pfService.get("/portfolio/performance/latest-time", {
        signal: controller?.signal
    });
}

export let getExposureData = (date, accountIds, betaAdjusted, controller) => {
    return pfService.get("/portfolio/performance/exposure?asOf=" + date + "&accountIds=" + accountIds.join(',') + "&betaAdjusted=" + betaAdjusted, {
        signal: controller?.signal
    });
}

export let getIntradayExposureData = (accountIds, betaAdjusted, controller) => {
    return pfService.get("/portfolio/performance/intraday/exposure?accountIds=" + accountIds.join(',') + "&betaAdjusted=" + betaAdjusted, {
        signal: controller?.signal
    });
}

export let getFactorWeightsForTicker = (tickerId, controller) => {
    return pfService.get(`/factor/${tickerId}`, {
        signal: controller?.signal
    });
}

export let getPnlData = (date, accountIds, controller) => {
    return pfService.get("/portfolio/performance/pnl?asOf=" + date + "&accountIds=" + accountIds.join(','), {
        signal: controller?.signal
    });
}

export let getSpreadData = (accountIds, indicesToInclude, controller) => {
    return pfService.get(`/portfolio/performance/spread?accountIds=${accountIds.join(',')}&indicesToInclude=${indicesToInclude.join(',')}`, {
        signal: controller?.signal
    });
}

export let getWashsaleInfo = (date, accountIds, controller) => {
    return pfService.get("/portfolio/performance/washsale?asOf=" + date + "&accountIds=" + accountIds.join(','), {
        signal: controller?.signal
    });
}

export let getWashsaleInfoForTicker = (date, tickerId, controller) => {
    return pfService.get("/portfolio/performance/washsale/ticker?tickerId=" + tickerId + "&asOf=" + date, {
        signal: controller?.signal
    });
}

export let getCompanyProfileForTicker = (tickerId, controller) => {
    return pfService.get(`/overview/stock/${tickerId}`, {
        signal: controller?.signal
    });
}

export let getStockAndIdioReturn = (date, tickerIdList, controller) => {
    return pfService.post("/portfolio/dashboard/stock-return?asOf=" + date, tickerIdList, {
        signal: controller?.signal
    });
}

export let getRtStockAndIdioReturn = (filterPositions, filterTime, controller) => {
    let url = "/intraday/return?filterPositions=" + filterPositions;

    if (filterTime) {
        url += "&asOfTime=" + new Date(filterTime).toISOString();
    }

    return pfService.get(url, {
        signal: controller?.signal
    });
}
export let getIntradayFactorLoadings = (filterDate) => {
    let url = "/intraday/factor?";
    if (filterDate) {
        url += "asOf=" + filterDate.toISOString();
    }
    return pfService.get(url);
}

export let getDriftStockAndIdioReturn = (date, tickerIdList, controller) => {
    return pfService.post("/portfolio/dashboard/drift-return?asOf=" + date, tickerIdList, {
        signal: controller?.signal
    });
}

export let getAllFactorLoadings = (filterPositions) => {
    return pfService.get("/factor/loadings/all?filterPositions=" + filterPositions);
}

export let getAccountSummaryData = (accountIds, controller) => {
    return pfService.get(`/portfolio/performance/account-summary?accountIds=${accountIds.join(',')}`, {
        signal: controller?.signal
    });
}
