import {memo, useMemo} from "react";
import LoadingOverlay from 'react-loading-overlay';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {CAlert} from "@coreui/react";
import {Col, Row} from "antd";

const queryTypeList = [
    'WEEKLY_STACK_GT_TOP_REGIONS_1',
    'WEEKLY_STACK_GT_TOP_REGIONS_2',
    'WEEKLY_STACK_GT_TOP_REGIONS_3',
    'WEEKLY_STACK_GT_TOP_REGIONS_4',
    'WEEKLY_STACK_GT_TOP_REGIONS_5',
    'WEEKLY_STACK_GT_TOP_REGIONS_6',
    'WEEKLY_STACK_GT_TOP_REGIONS_7',
    'WEEKLY_STACK_GT_TOP_REGIONS_8',
    'WEEKLY_STACK_GT_TOP_REGIONS_9',
    'WEEKLY_STACK_GT_TOP_REGIONS_10',
    'WEEKLY_STACK_GT_TOP_REGIONS_11',
    'WEEKLY_STACK_GT_TOP_REGIONS_12',
    'WEEKLY_STACK_GT_TOP_REGIONS_13',
    'WEEKLY_STACK_GT_TOP_REGIONS_14',
    'WEEKLY_STACK_GT_TOP_REGIONS_15',
    'WEEKLY_STACK_GT_TOP_REGIONS_16',
    'WEEKLY_STACK_GT_TOP_REGIONS_17',
    'WEEKLY_STACK_GT_TOP_REGIONS_18',
    'WEEKLY_STACK_GT_TOP_CONTINENTS_1'
];

function sharedMouseOver() {
    let currentX = this.x
    Highcharts.charts.forEach(function (chart, index) {
        chart?.series.forEach(function (series, seriesIndex) {
            series?.points.forEach(function (point, pointsIndex) {
                if (point.x === currentX) {
                    point.setState('hover');
                }
            })
        });
    });
}

function sharedMouseOut() {
    Highcharts.charts.forEach(function (chart, index) {
        chart?.series.forEach(function (series, seriesIndex) {
            series?.points.forEach(function (point, pointsIndex) {
                point.setState('');
            });
        });
    });
}

const GtTopRegionsGrid = memo(({
                                          chartData,
                                          activeTicker,
                                          activeBrand,
                                          activeBrandName,
                                          queryType,
                                          isError,
                                          loading
                                      }) => {

    const buildChartData = (chartData, activeTicker, activeBrand, activeBrandName) => {
        if (chartData == null) return null;
        const hasBrand = activeBrand !== null;
        const entityTitle = hasBrand ? `${activeTicker} - ${activeBrandName}` : activeTicker;
        const chartHeight = 200;

        const chartList = [];
        for (let i = 0; i < 19; ++i) {
            if (null == chartData[i]) {
                chartList.push(null);
            } else {
                chartList.push({
                    ...chartData[i].data,
                    title: {
                        text: `${entityTitle} - ${chartData[i].data.title.text}`,
                        margin: 0,
                        style: {
                            fontSize: '16px'
                        }
                    },
                    chart: {
                        ...chartData[i].data.chart,
                        zooming: {
                            mouseWheel: {enabled: false},
                        },
                        zoomType: 'x',
                        height: chartHeight,
                        plotBackgroundColor: '#E5ECF6',
                        panning: {enabled: true, type: 'x'},
                        panKey: 'shift',
                        marginLeft: 10
                    },
                    exporting: {
                        enabled: false
                    },
                    legend: {
                        enabled: false,
                    },
                    plotOptions: {
                        series: {
                            point: {
                                events: {
                                    mouseOver: sharedMouseOver,
                                    mouseOut: sharedMouseOut
                                }
                            },
                            states: {
                                inactive: {
                                    opacity: 1,
                                },
                            },
                        }
                    },
                    xAxis: {
                        ...chartData[i].data.xAxis,
                        overscroll: '25px',
                    },
                });
            }
        }

        // Turn off y axis completely
        chartList.forEach(c => null != c && c.yAxis.map(a => a.visible = false));

        return chartList;
    }

    const memoizedChartData = useMemo(() => {
        return buildChartData(chartData, activeTicker, activeBrand, activeBrandName);
    }, [chartData, activeTicker, activeBrand, queryType])

    const getChartHtml = (index) => {
        return (
            <LoadingOverlay
                active={loading && !isError}
                spinner={!isError}
                text={"Loading..."}
            >
                {isError && <div>
                    <CAlert color="danger">
                        Failed to load - {queryTypeList[index]}.
                        Data Not Configured
                    </CAlert>
                </div>}
                {(memoizedChartData == null || memoizedChartData[index] == null || memoizedChartData[index].series.length === 0)
                    && !isError &&
                    <div>
                        <CAlert color="warning">
                            Data Not Available - {queryTypeList[index]}
                        </CAlert>
                    </div>}
                {memoizedChartData != null && memoizedChartData[index] != null && !isError
                    && memoizedChartData[index].series.length !== 0 && <center>
                        <div style={{paddingLeft: 10}}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={memoizedChartData[index]}
                            /></div>
                    </center>}
            </LoadingOverlay>
        )
    }

    return (
        <>
            <a id={queryType} href={""}> </a>
            <div id="gt-top-regions-grid" style={{marginTop: "10px"}}>
                <text textAnchor="middle" className="highcharts-title"
                      style={{fontSize: "1.2em", color: "rgb(51, 51, 51)", fontWeight: "bold", fill: "rgb(51, 51, 51)"}}
                      >GTrends Top Regions Grid
                </text>
                <CAlert color={"warning"}>
                    EU Weights: GB and DE are <b>0.28</b>, FR is <b>0.18</b>, IT is <b>0.12</b>, ES is <b>0.10</b>, and NL is <b>0.04</b>.
                </CAlert>
                <Row>
                    <Col span={8}></Col>
                    <Col span={8}>{getChartHtml(18)}</Col>
                    <Col span={8}></Col>
                </Row>
                <Row>
                    <Col span={8}>{getChartHtml(0)}</Col>
                    <Col span={8}>{getChartHtml(1)}</Col>
                    <Col span={8}>{getChartHtml(2)}</Col>
                </Row>
                <Row>
                    <Col span={8}>{getChartHtml(3)}</Col>
                    <Col span={8}>{getChartHtml(4)}</Col>
                    <Col span={8}>{getChartHtml(5)}</Col>
                </Row>
                <Row>
                    <Col span={8}>{getChartHtml(6)}</Col>
                    <Col span={8}>{getChartHtml(7)}</Col>
                    <Col span={8}>{getChartHtml(8)}</Col>
                </Row>
                <Row>
                    <Col span={8}>{getChartHtml(9)}</Col>
                    <Col span={8}>{getChartHtml(10)}</Col>
                    <Col span={8}>{getChartHtml(11)}</Col>
                </Row>
                <Row>
                    <Col span={8}>{getChartHtml(12)}</Col>
                    <Col span={8}>{getChartHtml(13)}</Col>
                    <Col span={8}>{getChartHtml(14)}</Col>
                </Row>
                <Row>
                    <Col span={8}>{getChartHtml(15)}</Col>
                    <Col span={8}>{getChartHtml(16)}</Col>
                    <Col span={8}>{getChartHtml(17)}</Col>
                </Row>
                <Row>
                    <Col span={8}></Col>
                    <Col span={8}>{getChartHtml(18)}</Col>
                </Row>
            </div>
        </>
    )
})

export default GtTopRegionsGrid;

