import {useCallback, useState} from "react";

const useChartRefs = () => {
    const [mapOfChartRefs, setMapOfChartRefs] = useState({});

    const addToMapOfChartRefs = useCallback((chartRef, queryName) => {
        setMapOfChartRefs(prev => ({
            ...prev,
            [queryName]: [...(prev[queryName] ?? []), chartRef]
        }));
    }, []);

    const removeFromMapOfChartRefs = useCallback((chartRef, queryName) => {
        setMapOfChartRefs(prev => ({
            ...prev,
            [queryName]: (prev[queryName] ?? []).filter(ref => ref !== chartRef),
        }));
    }, []);

    return {
        mapOfChartRefs,
        addToMapOfChartRefs,
        removeFromMapOfChartRefs
    };
};

export default useChartRefs;