import {MinPriorityQueue,} from '@datastructures-js/priority-queue';

export class AsyncTaskPriorityBasedScheduler{

    constructor(parallelRequestCount) {
        this.queue = new MinPriorityQueue((item) => item.priority);
        // Number of requests that can be active at a time.
        this.parallelRequestCount = parallelRequestCount;
        this.activeRequestCount = 0;
    }

    resetPendingTaskList(){
        // Task cancellation is handled outside the scheduler using explicit AbortControllers attached to a set of API Calls:
        this.queue.clear();
        // Is this the ideal approach or should we integrate the cancellation of the tasks to be handled here itself?
    }

    dequeue(){
        if(this.queue.isEmpty() === true) {
            console.error("Trying to dequeue on an empty priority queue. Something is wrong!");
            return null;
        }
        return this.queue.dequeue();
    }

    enqueue(apiCallObject){
        this.queue.push(apiCallObject);
    }

    async processApiCall(apiCall) {
        this.activeRequestCount++;
        try {
            return await apiCall();
        } finally {
            this.activeRequestCount--;
            this.next();
        }
    }

    next() {
        if (this.queue.isEmpty() === false && this.activeRequestCount < this.parallelRequestCount) {
            const apiCallObject = this.dequeue();
            return this.processApiCall(apiCallObject.wrappedApiCall);
        }
    }

    // minheap based approach, lower the absolute priority value, more critical the task:
    makeRequest(apiCall, priority) {

        return new Promise((resolve, reject) => {
            const wrappedApiCall = async () => {
                try {
                    const response = await apiCall();
                    resolve(response);
                } catch (error) {
                    reject(error);
                }
            };
            this.enqueue({
                wrappedApiCall,
                priority
            });
            this.next();

        });
    }

}