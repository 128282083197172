import {
    APPTOPIA_APP_DATA,
    BBG_SECONDMEASURE,
    EARNEST_ORION_DEBIT,
    EARNEST_ORION_CREDIT,
    EARNEST_VELA_GAMMA,
    EARNEST_VELA_VELORUM,
    GOOGLE_TRENDS,
    SIMILAR_WEB,
    useDataLatencyHook
} from "../commonHooks/DataLatencyHook";
import {useContext} from "react";
import {RefreshContext} from "../commonContexts/RefreshContextProvider";

export const DataLagDisplay = ({activeTick, brand}) => {

    const {refresh} = useContext(RefreshContext);


    const {getLatencySuffix} = useDataLatencyHook(activeTick, brand, refresh);

    return (
        <span className="data-lag-bar">
                Data Lag: GT{getLatencySuffix(GOOGLE_TRENDS)} | SW{getLatencySuffix(SIMILAR_WEB)} | OR D{getLatencySuffix(EARNEST_ORION_DEBIT)} OR C{getLatencySuffix(EARNEST_ORION_CREDIT)}  V{getLatencySuffix(EARNEST_VELA_VELORUM)} G{getLatencySuffix(EARNEST_VELA_GAMMA)}  SM{getLatencySuffix(BBG_SECONDMEASURE)} | A{getLatencySuffix(APPTOPIA_APP_DATA)}
            </span>
    )
}