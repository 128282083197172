import {useEffect, useState} from "react";
import LoadingOverlay from 'react-loading-overlay';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {CAlert} from "@coreui/react";
import {getMarketFactorsData} from "../../../api/data/DataProvider";
import {monitor} from "../../../event_handler/KeyPressMonitor";


function MarketFactorChart(props) {
    const [chartData, setChartData] = useState(null)
    const [loading, setLoading] = useState(chartData == null)
    const [statusMsg, setStatusMsg] = useState('Loading your content...')
    const [isError, setIsError] = useState(false)


    const subtractYearsFromDate = (date, years) => {
        let resultDate = new Date(date);
        resultDate.setFullYear(date.getFullYear() - years);
        return resultDate;
    }

    const formatDateToYMD = (date) => {
        return date.toISOString().substring(0, 10);
    }

    const resolveMktFactorDateRange = () => {
        let today = new Date();
        let oneYearAgo = subtractYearsFromDate(today, 1);
        return {
            fromDate: formatDateToYMD(oneYearAgo),
            toDate: formatDateToYMD(today)
        };
    }

    const getColor = (series) => {
        switch (series) {
            case "Profitability": return "#88c44c";
            case "Div Yield": return "#d2cb5c";
            case "Size": return "#e5812c";
            case "Leverage": return "#d72020";
            case "Value": return "#1266ef";
            case "Momentum": return "#FFFFFF";
            case "Growth": return "#66cebd";
            case "Volume": return "#0324be";
            case "Earn Yield": return "#b575bd";
            case "Volatility": return "#ad3ad3";
            case "SI": return "#e566b5";
            default: return "#FFFFFF";
        }
    }

    const resolveChartData = (data) => {
        const series = {};
        if (data.length <= 0) {
            return [];
        }

        data.forEach(dataItem => {
            let date = Date.parse(dataItem.dataDate);
            Object.entries(dataItem).forEach(([key, value]) => {
                if (key !== 'dataDate') {
                    if (!series[key]) {
                        series[key] = {
                            name: key,
                            data: [],
                            color: getColor(key)
                        };
                    }
                    series[key].data.push([date, value]);
                }
            });
        });
        return Object.values(series);
    }

    useEffect(() => {
        const abortController = new AbortController();
        setIsError(false);
        setLoading(true)
        let dateRange = resolveMktFactorDateRange();
        getMarketFactorsData(dateRange.fromDate, dateRange.toDate, abortController)
            .then((res) => {
                let data = res.data;
                setChartData((prev) => {
                    return {
                        title: {
                            text: 'Factor Returns'
                        },
                        xAxis: {
                            type: 'datetime',
                            labels: {
                                enabled: true
                            },
                            title: {
                                text: 'Date'
                            },
                            overscroll: 60 * 1000 * 60 * 24 * 8
                        },
                        yAxis: { // formatting y-axis
                            visible: true,
                            opposite: false,
                            gridLineWidth: 0.1,
                            labels: {
                                format: '{value:,.0f}',
                            }
                        },
                        tooltip: { // formatting tooltip
                            shared: true,
                            split: true,
                            inactiveOtherSeries: false,
                            xDateFormat: '%A, %e %B, %Y',
                            valueSuffix: ' %'
                        },
                        series: resolveChartData(data),
                        rangeSelector: {
                            // inputEnabled: false,
                            buttons: [
                                {
                                    type: 'day',
                                    count: 1,
                                    text: '1d'
                                },
                                {
                                    type: 'week',
                                    count: 1,
                                    text: '1w'
                                },
                                {
                                    type: 'week',
                                    count: 2,
                                    text: '2w'
                                },
                                {
                                    type: 'month',
                                    count: 1,
                                    text: '1m'
                                }, {
                                    type: 'month',
                                    count: 2,
                                    text: '2m'
                                },
                                {
                                    type: 'month',
                                    count: 3,
                                    text: '3m'
                                },
                                {
                                    type: 'month',
                                    count: 6,
                                    text: '6m'
                                },
                                {
                                    type: 'year',
                                    count: 1,
                                    text: '1y'
                                },
                                {
                                    type: 'year',
                                    count: 2,
                                    text: '2y'
                                },
                                {
                                    type: 'ytd',
                                    text: 'YTD'
                                },
                                {
                                    type: 'all',
                                    text: 'All'
                                }],
                            selected: 7
                        },
                        // title: props.activeTicker + ' ' + res.data.title,
                        chart: {
                            type: 'line',
                            zooming: {
                                mouseWheel: {
                                    enabled: false
                                }
                            },
                            zoomType: 'x',
                            height: 800,
                            plotBackgroundColor: '#000000',
                            panning: {
                                enabled: true,
                                type: 'x'
                            },
                            panKey: 'shift'
                        },
                        legend: {
                            enabled: true,
                            layout: 'vertical',
                            align: 'right',
                            verticalAlign: 'middle',
                            itemStyle: {
                                color: "#000000"
                            },
                            itemHiddenStyle: {
                                textDecoration: 'none'  // Disable strikethrough effect
                            }
                        },
                        plotOptions: {
                            series: {
                                events: {
                                    legendItemClick: function() {
                                        const series = this.chart.series;
                                        if (monitor.isPressed('z')) { // 16 is code for shift key
                                            series.forEach(s => {
                                                s !== this ? s.hide() : s.show()
                                            }, this);

                                            return false;
                                        }

                                        if (monitor.isPressed('x')) {
                                            const yAxisIndex = this.yAxis.index;
                                            const yAxis = this.chart.yAxis[yAxisIndex];
                                            if (yAxis.visible) {
                                                yAxis.update({visible: false});
                                            } else {
                                                yAxis.update({visible: true});
                                            }

                                            return false;
                                        }

                                        if (monitor.isPressed('c')) {
                                            series.forEach(s => {
                                                const yAxisIndex = s.yAxis.index;
                                                const yAxis = this.chart.yAxis[yAxisIndex];
                                                yAxis.update({visible: false});
                                            }, this);

                                            return false;
                                        }

                                        return true;
                                    }
                                }
                            },
                            marker: {
                                enabled: false
                            }
                        }
                    }
                });
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                if (err.code === "ERR_CANCELED") {
                    // do nothing for now
                } else {
                    setStatusMsg("Failed to load data for the chart. Looks like this data is not available.")
                    setIsError(true);
                    setLoading(false);
                }
            })

        return () => abortController.abort();
    }, [props.refresh])

    return (
        <>
            <a id={props.queryType} href={""}> </a>
            <LoadingOverlay
                active={loading && !isError}
                spinner={!isError}
                text={"Loading..."}
            >
                {isError && <div>
                    <CAlert color="danger">
                        Failed to load - {props.queryType}.
                        Data Not Configured
                    </CAlert>
                </div>}
                {chartData == null && !isError && <p style={{height: "400px", width: "90%"}}> ...</p>}
                {chartData != null && !isError && chartData.series.length === 0 && <div>
                    <CAlert color="warning">
                        Data Not Available - {props.queryType}
                    </CAlert>
                </div>}
                {chartData != null && !isError && chartData.series.length !== 0 &&
                    <center><div style={{marginTop: 10, paddingLeft: 10}}>
                    <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={chartData} />
                </div></center>}
            </LoadingOverlay>

        </>
    )
}

export default MarketFactorChart;