import axios from "axios";
import {connectionInfo} from "./Config";
import axiosRetry from "axios-retry";

const createService = (baseURL) => {
    let service = axios.create({baseURL});
    axiosRetry(service, {retries: 3, retryDelay: axiosRetry.exponentialDelay});
    return service;
}

export let dwService = createService(connectionInfo.url);
export let llmService = createService(connectionInfo.llmUrl);

export let fmdService = createService(connectionInfo.fin_mkt_url);

export let macroService = createService(connectionInfo.macro_api_url);

export let pfService = createService(connectionInfo.portfolio_service_url);

export let pipelineService = createService(connectionInfo.pipeline_service_url);

export let computationService = createService(connectionInfo.computation_service_url);
computationService.interceptors.request.use(config => {
    const authenticatedUser = localStorage.getItem('studio_internal_authenticated_user');
    if (authenticatedUser) {
        config.headers['X-Authenticated-User'] = authenticatedUser;
    }
    return config;
});