import {generateLinkForOracleClickout} from "../../studio_high_chart/utilityFunctions";
import {
    bpsCellRendererFunction,
    dollarCellRendererFunction,
    percentageCellRendererFunction
} from "../../../utils/StudioAgGridUtils";

export function tickerCellRenderer(params) {
    if (params.value != null) {
        const ticker = params.value;
        const url = generateLinkForOracleClickout(ticker);
        return <a href={url} target="_blank">{ticker}</a>;

    } else {
        return <></>;
    }
}

const nonNullComparator = (a, b, nodeA, nodeB, isInverted) => {
    if (a === b) {
        return 0;
    }
    // for null
    else if (a == null) {
        return isInverted ? -1 : 1;
    }
    else if (b == null) {
        return isInverted ? 1 : -1;
    }
    else {
        return (a > b) ? 1 : -1;
    }

};

const oneDecimalPercentageColDef = (headerName, field, headerTooltip) => {
    return {
        headerName: headerName,
        field: field,
        cellRenderer: percentageCellRendererFunction(1),
        cellStyle: { verticalAlign: "middle", textAlign: 'right' },
        headerClass: "ag-right-aligned-header",
        width: 65,
        sortable: true,
        comparator: nonNullComparator,
        headerTooltip: headerTooltip,
    };
};

const zeroDecimalPercentageColDef = (headerName, field, headerTooltip) => {
    return {
        headerName: headerName,
        field: field,
        cellRenderer: percentageCellRendererFunction(0),
        cellStyle: { verticalAlign: "middle", textAlign: 'right' },
        headerClass: "ag-right-aligned-header",
        width: 50,
        sortable: true,
        comparator: nonNullComparator,
        headerTooltip: headerTooltip,
    };
};

export const transformationMap = new Map([
    ['position',
        [
            ['position.positionDelta', 'eodMarketValue'],
            ['position.ratioOfNav', 'ratioOfNav'],
            ['position.gainLossValue', 'unrealizedPnl'],
            ['position.gainLossRatio', (pos) => {
                if (pos && pos.eodMarketValue) {
                    return pos.unrealizedPnl / (pos.eodMarketValue - pos.unrealizedPnl);
                }
            }],
        ]
    ],
    ['stockReturn',
        [
            ['stockReturn.1d', 'stockReturn.1d'],
            ['stockReturn.5d', 'stockReturn.5d'],
            ['stockReturn.1m', 'stockReturn.1m'],
            ['stockReturn.3m', 'stockReturn.3m'],
            ['stockReturn.1y', 'stockReturn.1y'],
            ['stockReturn.ytd', 'stockReturn.ytd'],
            ['idioReturn.1d', 'idioReturn.1d'],
            ['idioReturn.5d', 'idioReturn.5d'],
            ['idioReturn.1m', 'idioReturn.1m'],
            ['idioReturn.3m', 'idioReturn.3m'],
            ['idioReturn.1y', 'idioReturn.1y'],
            ['idioReturn.ytd', 'idioReturn.ytd'],
        ]
    ],
    ['driftReturn',
        [
            ['c.driftIdioReturn', 'c.driftIdioReturn'],
            ['c.driftStockReturn', 'c.driftStockReturn'],
            ['p1.driftIdioReturn', 'p1.driftIdioReturn'],
            ['p1.driftStockReturn', 'p1.driftStockReturn'],
            ['p1.earningsIdioReturn', 'p1.earningsIdioReturn'],
            ['p1.earningsStockReturn', 'p1.earningsStockReturn'],
            ['p2.driftIdioReturn', 'p2.driftIdioReturn'],
            ['p2.driftStockReturn', 'p2.driftStockReturn'],
            ['p2.earningsIdioReturn', 'p2.earningsIdioReturn'],
            ['p2.earningsStockReturn', 'p2.earningsStockReturn'],
            ['p3.driftIdioReturn', 'p3.driftIdioReturn'],
            ['p3.driftStockReturn', 'p3.driftStockReturn'],
            ['p3.earningsIdioReturn', 'p3.earningsIdioReturn'],
            ['p3.earningsStockReturn', 'p3.earningsStockReturn'],
        ]
    ],
])

export const defaultColDef = {
    marryChildren: true,
    suppressMovable: true,
    suppressDragLeaveHidesColumns: true,
}

export const columnDefs = [
    {
        headerName: 'tickerId',
        field: 'tickerId',
        hide: true,
        cellStyle: { verticalAlign: "middle" },
        width: 100,
    },
    {
        headerName: 'Ticker',
        field: 'tick',
        cellRenderer: tickerCellRenderer,
        headerClass: 'center-aligned-header',
        pinned: 'left',
        width: 100,
        sortable: true,
        filter: true,
    },
    {
        headerName: 'Idio Return',
        headerClass: 'center-aligned-header',
        children: [
            oneDecimalPercentageColDef('1d%', 'idioReturn.1d'),
            oneDecimalPercentageColDef('5d%', 'idioReturn.5d'),
            oneDecimalPercentageColDef('1m%', 'idioReturn.1m'),
            oneDecimalPercentageColDef('3m%', 'idioReturn.3m'),
            oneDecimalPercentageColDef('ytd%', 'idioReturn.ytd'),
            oneDecimalPercentageColDef('1y%', 'idioReturn.1y'),
        ],
    },
    {
        headerName: 'Stock Return',
        headerClass: 'center-aligned-header',
        children: [
            { headerName: '', field: '', width: 10, columnGroupShow: 'closed' },
            { ...oneDecimalPercentageColDef('1d%', 'stockReturn.1d'), columnGroupShow: 'open' },
            { ...oneDecimalPercentageColDef('5d%', 'stockReturn.5d'), columnGroupShow: 'open' },
            { ...oneDecimalPercentageColDef('1m%', 'stockReturn.1m'), columnGroupShow: 'open' },
            { ...oneDecimalPercentageColDef('3m%', 'stockReturn.3m'), columnGroupShow: 'open' },
            { ...oneDecimalPercentageColDef('ytd%', 'stockReturn.ytd'), columnGroupShow: 'open' },
            { ...oneDecimalPercentageColDef('1y%', 'stockReturn.1y'), columnGroupShow: 'open' },
        ],
    },
    {
        headerName: 'Factor Return',
        headerClass: 'center-aligned-header',
        children: [
            { headerName: '', field: '', width: 10, columnGroupShow: 'closed' },
            { ...oneDecimalPercentageColDef('1d%'), valueGetter: 'data.stockReturn["1d"] - data.idioReturn["1d"]', columnGroupShow: 'open' },
            { ...oneDecimalPercentageColDef('5d%'), valueGetter: 'data.stockReturn["5d"] - data.idioReturn["5d"]', columnGroupShow: 'open' },
            { ...oneDecimalPercentageColDef('1m%'), valueGetter: 'data.stockReturn["1m"] - data.idioReturn["1m"]', columnGroupShow: 'open' },
            { ...oneDecimalPercentageColDef('3m%'), valueGetter: 'data.stockReturn["3m"] - data.idioReturn["3m"]', columnGroupShow: 'open' },
            { ...oneDecimalPercentageColDef('ytd%'), valueGetter: 'data.stockReturn["ytd"] - data.idioReturn["ytd"]', columnGroupShow: 'open' },
            { ...oneDecimalPercentageColDef('1y%'), valueGetter: 'data.stockReturn["1y"] - data.idioReturn["1y"]', columnGroupShow: 'open' },
        ],
    },
    {
        headerName: 'Positions',
        headerClass: 'center-aligned-header',
        children: [
            {
                headerName: 'Pos $',
                field: 'position.positionDelta',
                cellRenderer: dollarCellRendererFunction(),
                cellStyle: { verticalAlign: "middle", textAlign: 'right' },
                headerClass: "ag-right-aligned-header",
                width: 105,
                sort: 'desc',
                sortable: true,
                comparator: nonNullComparator,
            },
            {
                headerName: 'Pos bps',
                field: 'position.ratioOfNav',
                cellRenderer: bpsCellRendererFunction(),
                cellStyle: { verticalAlign: "middle", textAlign: 'right' },
                headerClass: "ag-right-aligned-header",
                width: 50,
                sortable: true,
                comparator: nonNullComparator,
            },
            {
                headerName: 'PNL $',
                field: 'position.gainLossValue',
                cellRenderer: dollarCellRendererFunction(),
                cellStyle: { verticalAlign: "middle", textAlign: 'right' },
                headerClass: "ag-right-aligned-header",
                width: 110,
                sortable: true,
                comparator: nonNullComparator,
            },
            {
                headerName: 'PNL %',
                field: 'position.gainLossRatio',
                cellRenderer: percentageCellRendererFunction(2),
                cellStyle: { verticalAlign: "middle", textAlign: 'right' },
                headerClass: "ag-right-aligned-header",
                width: 70,
                sortable: true,
                comparator: nonNullComparator,
            },
        ],
    },
    {
        headerName: 'Period wise Idio Return',
        headerClass: 'center-aligned-header',
        children: [
            zeroDecimalPercentageColDef('C D%', 'c.driftIdioReturn', 'Idio returns since 2 days past last earnings report'),
            zeroDecimalPercentageColDef('P1 E%', 'p1.earningsIdioReturn', 'Idio returns for past quarter for 2 days after earnings date'),
            zeroDecimalPercentageColDef('P1 D%', 'p1.driftIdioReturn', 'Idio returns for past quarter till earnings date'),
            zeroDecimalPercentageColDef('P2 E%', 'p2.earningsIdioReturn', 'Idio returns for quarter -2 for 2 days after earnings date'),
            zeroDecimalPercentageColDef('P2 D%', 'p2.driftIdioReturn', 'Idio returns for quarter -2 till earnings date'),
            zeroDecimalPercentageColDef('P3 E%', 'p3.earningsIdioReturn', 'Idio returns for quarter -3 for 2 days after earnings date'),
            zeroDecimalPercentageColDef('P3 D%', 'p3.driftIdioReturn', 'Idio returns for quarter -3 till earnings date'),
        ],
    },
    {
        headerName: 'Period wise Stock Return',
        headerClass: 'center-aligned-header',
        children: [
            { headerName: '', field: '', width: 10, columnGroupShow: 'closed' },
            { ...zeroDecimalPercentageColDef('C D%', 'c.driftStockReturn', 'Stock returns since 2 days past last earnings report'), columnGroupShow: 'open' },
            { ...zeroDecimalPercentageColDef('P1 E%', 'p1.earningsStockReturn', 'Stock returns for past quarter for 2 days after earnings date'), columnGroupShow: 'open' },
            { ...zeroDecimalPercentageColDef('P1 D%', 'p1.driftStockReturn', 'Stock returns for past quarter till earnings date'), columnGroupShow: 'open' },
            { ...zeroDecimalPercentageColDef('P2 E%', 'p2.earningsStockReturn', 'Stock returns for quarter -2 for 2 days after earnings date'), columnGroupShow: 'open' },
            { ...zeroDecimalPercentageColDef('P2 D%', 'p2.driftStockReturn', 'Stock returns for quarter -2 till earnings date'), columnGroupShow: 'open' },
            { ...zeroDecimalPercentageColDef('P3 E%', 'p3.earningsStockReturn', 'Stock returns for quarter -3 for 2 days after earnings date'), columnGroupShow: 'open' },
            { ...zeroDecimalPercentageColDef('P3 D%', 'p3.driftStockReturn', 'Stock returns for quarter -3 till earnings date'), columnGroupShow: 'open' },
        ],
    },
    {
        headerName: 'Period wise Factor Return',
        headerClass: 'center-aligned-header',
        children: [
            { headerName: '', field: '', width: 10, columnGroupShow: 'closed' },
            { ...zeroDecimalPercentageColDef('C D%', undefined, 'Factor returns since 2 days past last earnings report'), valueGetter: 'data.c.driftStockReturn - data.c.driftIdioReturn', columnGroupShow: 'open' },
            { ...zeroDecimalPercentageColDef('P1 E%', undefined, 'Factor returns for past quarter for 2 days after earnings date'), valueGetter: 'data.p1.earningsStockReturn - data.p1.earningsIdioReturn', columnGroupShow: 'open' },
            { ...zeroDecimalPercentageColDef('P1 D%', undefined, 'Factor returns for past quarter till earnings date'), valueGetter: 'data.p1.driftStockReturn - data.p1.driftIdioReturn', columnGroupShow: 'open' },
            { ...zeroDecimalPercentageColDef('P2 E%', undefined, 'Factor returns for quarter -2 for 2 days after earnings date'), valueGetter: 'data.p2.earningsStockReturn - data.p2.earningsIdioReturn', columnGroupShow: 'open' },
            { ...zeroDecimalPercentageColDef('P2 D%', undefined, 'Factor returns for quarter -2 till earnings date'), valueGetter: 'data.p2.driftStockReturn - data.p2.driftIdioReturn', columnGroupShow: 'open' },
            { ...zeroDecimalPercentageColDef('P3 E%', undefined, 'Factor returns for quarter -3 for 2 days after earnings date'), valueGetter: 'data.p3.earningsStockReturn - data.p3.earningsIdioReturn', columnGroupShow: 'open' },
            { ...zeroDecimalPercentageColDef('P3 D%', undefined, 'Factor returns for quarter -3 till earnings date'), valueGetter: 'data.p3.driftStockReturn - data.p3.driftIdioReturn', columnGroupShow: 'open' },
        ],
    },
];
