import {createContext, useContext, useEffect, useState} from "react";
import {computationService} from "../../../api/Clients";
import {RefreshContext} from "../commonContexts/RefreshContextProvider";

const fetchFinRatiosData = async (tickerId) => {
    const response = await computationService.get(`/v1/multiples/ticker/finratios/charts?tickerId=${tickerId}`);
    return response.data;
}

const fetchEventsData = async (tickerId) => {
    const response = await computationService.get(`/v1/multiples/ticker/events?tickerId=${tickerId}`);
    return response.data;
}

const fetchFinEstimatesChartData = async (tickerId) => {
    const response = await computationService.get(`/v1/multiples/ticker/estimates/charts?tickerId=${tickerId}`);
    return response.data;
}

export const TradingMultiplesContext = createContext(null);

export const TradingMultiplesDataProvider = ({activeTicker, children}) => {

    const {refresh} = useContext(RefreshContext);

    const [multipleChartData, setMultipleChartData] = useState({
        loading: true,
        error: null,
        data: null
    });

    const [estimatesChartData, setEstimatesChartData] = useState({
        loading: true,
        error: null,
        data: null
    });
    const [eventsData, setEventsData] = useState({
        loading: true,
        error: null,
        data: null
    });

    useEffect(() => {
        if (activeTicker) {
            fetchFinRatiosData(activeTicker.id).then((data) => {
                setMultipleChartData({
                    loading: false,
                    error: null,
                    data: data
                });
            }).catch((error) => {
                setMultipleChartData({
                    loading: false,
                    error: error,
                    data: null
                });
            });
        }
    }, [activeTicker, refresh]);

    useEffect(() => {
        if (activeTicker) {
            fetchEventsData(activeTicker.id).then((data) => {
                setEventsData({
                    loading: false,
                    error: null,
                    data: data
                });
            }).catch((error) => {
                setEventsData({
                    loading: false,
                    error: error,
                    data: null
                });
            });
        }
    }, [activeTicker, refresh]);

    useEffect(() => {
        if (activeTicker) {
            fetchFinEstimatesChartData(activeTicker.id).then((data) => {
                setEstimatesChartData({
                    loading: false,
                    error: null,
                    data: data
                });
            }).catch((error) => {
                setEstimatesChartData({
                    loading: false,
                    error: error,
                    data: null
                });
            });
        }
    }, [activeTicker, refresh]);

    return (
        <TradingMultiplesContext.Provider value={{
            multipleChartData, estimatesChartData, eventsData
        }}>
            {children}
        </TradingMultiplesContext.Provider>
    )
}