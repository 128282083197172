import {useContext, useEffect, useState} from "react";
import {ViewContextStore} from "../DashboardReport/views/ViewContext";
import useMessage from "antd/es/message/useMessage";
import {Chip, Grid} from "@mui/material";
import {Divider, Select} from "antd";
import Checkbox from "@mui/material/Checkbox";

export const ChartControlsPane = () => {

    const {
        completeMetadata,
        bulkToggleChartSettings
    } = useContext(ViewContextStore);

    const chartNames = Object.keys(completeMetadata);

    const chartOptions = chartNames
        .map(chart => ({
            label: chart,
            value: chart
        }));

    const [combineAxes, setCombineAxes] = useState(false);

    const [selectedCharts, setSelectedCharts] = useState([]);
    const [allCharts, setAllCharts] = useState(false);
    const [weeklyCharts, setWeeklyCharts] = useState(false);
    const [mosaics, setMosaics] = useState(false);
    const [unionCharts, setUnionCharts] = useState([]);

    const [messageApi, context] = useMessage();

    useEffect(
        () => {
            if (allCharts) {
                setUnionCharts([...chartNames]);
                return;
            }
            let chartsList = [...selectedCharts];
            if (weeklyCharts) {
                const weeklyCharts = [...chartNames].filter(name => name.includes('WEEKLY'));
                chartsList = [...new Set([...chartsList, ...weeklyCharts])];
            }
            if (mosaics) {
                const mosaicCharts = [...chartNames].filter(name => name.includes('QTR'));
                chartsList = [...new Set([...chartsList, ...mosaicCharts])];
            }
            setUnionCharts(chartsList);
        },
        [selectedCharts, allCharts, weeklyCharts, mosaics]
    )

    const clearAll = () => {
        setSelectedCharts([]);
        setAllCharts(false);
        setWeeklyCharts(false);
        setMosaics(false);
        setUnionCharts([]);
    }

    const apply = () => {
        if(unionCharts.length === 0){
            messageApi.open({
                type: 'warning',
                content: 'No Charts in target list!',
                duration: 2
            });
            return;
        }
        bulkToggleChartSettings(unionCharts, combineAxes);
        messageApi.open({
            type: 'success',
            content: 'Applied Changes!',
            duration: 2
        });
        clearAll();
    }

    return (
        <>
            <Grid container spacing={2}>
                {context}
                <Divider textAlign="left">Select Options for Charts</Divider>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        Combine Axes:
                    </Grid>
                    <Grid item xs={2}>
                        <Checkbox
                            checked={combineAxes}
                            onChange={e =>
                                setCombineAxes(e.target.checked)
                            }
                        />
                    </Grid>
                </Grid>
                <Divider textAlign="left">Chart Selection</Divider>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        All Charts:
                    </Grid>
                    <Grid item xs={2}>
                        <Checkbox
                            checked={allCharts}
                            onChange={e => setAllCharts(e.target.checked)}
                        />
                    </Grid>
                </Grid>
                {!allCharts && <>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            Mosaics Series:
                        </Grid>
                        <Grid item xs={2}>
                            <Checkbox
                                checked={mosaics}
                                onChange={e => setMosaics(e.target.checked)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            Weekly Series:
                        </Grid>
                        <Grid item xs={2}>
                            <Checkbox
                                checked={weeklyCharts}
                                onChange={e => setWeeklyCharts(e.target.checked)}
                            />
                        </Grid>
                    </Grid>
                    <Divider variant="middle" component="li"> + </Divider>
                    <Grid item xs={12}>
                        Pick Charts by Name:
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder={'Select target charts'}
                            onChange={(value) => setSelectedCharts(value)}
                            options={chartOptions}
                            style={{
                                width: '80%'
                            }}
                        />
                    </Grid>
                </>}
                <Divider variant="middle" component="li"/>
                <Grid item xs={6}>
                    <button className={'button-factors'} onClick={apply}>Apply</button>
                </Grid>
                <Grid item xs={6}>
                    <button className={'button-cancel'} onClick={clearAll}>Clear</button>
                </Grid>
                <Divider variant="middle" component="li"/>
                {unionCharts.length > 0 && <><Grid item xs={12}>Selected Target Charts: </Grid>
                    <Grid item xs={12}>
                        {unionCharts.map(item => <Chip label={item} variant="outlined"/>)}
                    </Grid></>}
            </Grid>

        </>
    )
}