import {createContext, useCallback, useContext, useEffect, useState} from "react";
import {
    DefaultSeriesPairs,
    generateNewActiveProjectionsBasedOnAvailableProjections,
    transformChartMapToProjectionsMap,
    updateProjectionsForQueryType
} from "./defaultProjectionsUtility";
import _ from "lodash";
import {ViewContextStore} from "../views/ViewContext";
import {DashboardQueryTypes} from "../../../../api/data/DashboardQueryTypes";

export const Projections_Eligible_Charts = [
    [DashboardQueryTypes.WEEKLY_LEVELS],
    [DashboardQueryTypes.WEEKLY_GP_LEVELS],
]

//Determines which  source metadata is used for which of the target charts
const ProjectionsMetadataToChartsMap = {
    [DashboardQueryTypes.WEEKLY_LEVELS]: {
        first: DashboardQueryTypes.QTR_YOY_LAG0,
        second: DashboardQueryTypes.QTR_YOY2_LAG0,
    },
    [DashboardQueryTypes.WEEKLY_GP_LEVELS]: {
        first: DashboardQueryTypes.PROFIT_YOY_LAG0,
        second: DashboardQueryTypes.PROFIT_YOY2_LAG0
    }
}

//Layout generation code assumes that each series will only have two charts as associated projections:
const generateChartsLayout = (activeProjections, queryLayoutPairs) => {
    const chartLayout = [];

    queryLayoutPairs.forEach(seriesPair => {
        const chartPair = {
            rhs: null,
            lhs: null
        }
        if (activeProjections[seriesPair[0]] != null) {
            chartPair.lhs = {
                seriesName: seriesPair[0],
                projections: activeProjections[seriesPair[0]]
            };
        }
        if (activeProjections[seriesPair[1]] != null) {
            chartPair.rhs = {
                seriesName: seriesPair[1],
                projections: activeProjections[seriesPair[1]]
            };
        }
        if (chartPair.rhs != null || chartPair.lhs != null) chartLayout.push(chartPair);
    });
    return chartLayout;
}

const getAddableProjections = (activeProjections, availableProjections) => {

    const addableProjections = Object.assign({}, transformChartMapToProjectionsMap(availableProjections));
    // Remove the active projections from the list:
    Object.keys(activeProjections).forEach(activeSeries => delete addableProjections[activeSeries]);
    return addableProjections;
}

//Stores the high level data about the active and available projections etc. The Actual api data is not stored in this context
export const ProjectionsInfoContext = createContext(null);

export const ProjectionsInfoProvider = ({tick, brand, children}) => {

    const {
        metadataMap
    } = useContext(ViewContextStore);

    const [availableProjectionsUpdated, setAvailableProjectionsUpdated] = useState(false);
    const [availableProjections, setAvailableProjections] = useState({});
    const [activeProjections, setActiveProjections] = useState({});

    const [projectionsScreenActive, setProjectionsScreenActive] = useState(false);

    useEffect(() => {
        //Reset the variable so that the UI waits for all projections to be available before determining available projections:
        setAvailableProjectionsUpdated(false);
    }, [tick, brand]);

    useEffect(() => {
        if(availableProjectionsUpdated || metadataMap == null) return;
        //Check if all the charts for projections have their metadata available
        if(!Projections_Eligible_Charts.every(chartName => metadataMap[chartName] != null)){
            return; // Ensure that all the charts have their metadata available. Todo: add logic for showing error if any of the api fails?
        }

        // Update the available projections:
        const updatedAvailableProjectionsMap = {};
        Projections_Eligible_Charts.forEach(chartName => {
            updatedAvailableProjectionsMap[ProjectionsMetadataToChartsMap[chartName].first] = updateProjectionsForQueryType(metadataMap[chartName]);
            updatedAvailableProjectionsMap[ProjectionsMetadataToChartsMap[chartName].second] = updateProjectionsForQueryType(metadataMap[chartName]);
        });
        setAvailableProjections(updatedAvailableProjectionsMap);
        setAvailableProjectionsUpdated(true);

    }, [metadataMap])



    //Remove projections no longer available from activeProjections and factor in default projections:
    useEffect(() => {
        setActiveProjections(generateNewActiveProjectionsBasedOnAvailableProjections(availableProjections, activeProjections));
    }, [availableProjections])

    const addToActiveProjections = useCallback((seriesName) => {
        if (seriesName == null) return;
        const existingItem = activeProjections[seriesName];
        if (!existingItem) {
            // add the new projections:
            setActiveProjections(prev => {
                const newObj = { ...prev };
                newObj[seriesName] = _.cloneDeep(transformChartMapToProjectionsMap(availableProjections)[seriesName]);
                return newObj;
            });
        }
    }, [activeProjections, availableProjections]);

    const removeActiveProjections = useCallback((seriesName) => {
        // Does not factor in the algo at the moment:
        setActiveProjections(prev => {
            const newObj = { ...prev };
            delete newObj[seriesName];
            return newObj;
        });
    }, []);
    const [addableProjections, setAddableProjections] = useState(getAddableProjections(activeProjections, availableProjections));

    useEffect(() => {
        setAddableProjections(getAddableProjections(activeProjections, availableProjections));
    }, [activeProjections, availableProjections]);

    const [queryLayoutPairs, setQueryLayoutPairs] = useState(DefaultSeriesPairs);

    const [chartLayout, setChartLayout] = useState(generateChartsLayout(activeProjections, queryLayoutPairs));

    useEffect(() => {
        //Change the chart layout to reflect the latest chart layout:
        setChartLayout(generateChartsLayout(activeProjections, queryLayoutPairs));
    }, [activeProjections, queryLayoutPairs]);

    const addProjections = useCallback((lhsSeries, rhsSeries) => {
        if (lhsSeries != null) addToActiveProjections(lhsSeries);
        if (rhsSeries != null) addToActiveProjections(rhsSeries);
        setQueryLayoutPairs(prev => ([...prev, [lhsSeries, rhsSeries]]));
    }, [addToActiveProjections]);

    const removeProjections = useCallback((lhsSeries, rhsSeries) => {
        const item = [lhsSeries, rhsSeries];
        setQueryLayoutPairs(prev => prev.filter(projection => projection !== item));
        if (lhsSeries != null) removeActiveProjections(lhsSeries);
        if (rhsSeries != null) removeActiveProjections(rhsSeries);
    }, [removeActiveProjections]);

    return (
        <ProjectionsInfoContext.Provider value={{
            availableProjections,
            activeProjections,
            availableProjectionsUpdated,
            chartLayout,
            addProjections,
            removeProjections,
            addableProjections,
            projectionsScreenActive,
            setProjectionsScreenActive
        }}>
            {children}
        </ProjectionsInfoContext.Provider>
    )
}