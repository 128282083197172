import {useEffect, useState} from "react";
import LoadingOverlay from 'react-loading-overlay';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {CAlert} from "@coreui/react";
import {getMacroSectorResults} from "../../../api/data/DataProvider";
import {monitor} from "../../../event_handler/KeyPressMonitor";


function MacroSectorLineChart(props) {
    const [chartData, setChartData] = useState(null)
    const [loading, setLoading] = useState(chartData == null)
    const [statusMsg, setStatusMsg] = useState('Loading your content...')
    const [isError, setIsError] = useState(false)


    const formatData = (data) => {
        // Add overscroll
        data.xAxis.overscroll = 60 * 1000 * 60 * 24 * 8;
        // Remove redundant prefix
        data.series.forEach(s => {
            s.name = s.name.replace('sales_weighted_cc_', '');
            if (props.sectorRegex) {
                s.name = s.name.replace(props.sectorRegex, '');
            }

            if (props.sectorRegex && (props.sectorRegex.includes("s.consumer.brands") || props.sectorRegex.includes("s.consumer.retail"))) {
                s.visible = true;
            }
        });
        return data;
    }

    const combineSectorAxes = (data) => {
        data.series.forEach(s => s.yAxis = 0);
        return data;
    }

    useEffect(() => {
        const abortController = new AbortController();
        setIsError(false);
        setLoading(true)
        getMacroSectorResults(props.sectorRegex, props.queryType, abortController)
            .then((res) => {
                let data = res.data;
                data = combineSectorAxes(formatData(data));
                setChartData((prev) => {
                    return {
                        ...data,
                        title: {
                            text: props.sectorRegex ? props.sectorRegex.toUpperCase() + ' - ' + ' (YOY)':  props.queryType + ' (YOY)'
                        },
                        rangeSelector: {
                            // inputEnabled: false,
                            buttons: [
                                {
                                    type: 'day',
                                    count: 1,
                                    text: '1d'
                                },
                                {
                                    type: 'week',
                                    count: 1,
                                    text: '1w'
                                },
                                {
                                    type: 'week',
                                    count: 2,
                                    text: '2w'
                                },
                                {
                                    type: 'month',
                                    count: 1,
                                    text: '1m'
                                }, {
                                    type: 'month',
                                    count: 2,
                                    text: '2m'
                                },
                                {
                                    type: 'month',
                                    count: 3,
                                    text: '3m'
                                },
                                {
                                    type: 'month',
                                    count: 6,
                                    text: '6m'
                                },
                                {
                                    type: 'year',
                                    count: 1,
                                    text: '1y'
                                },
                                {
                                    type: 'year',
                                    count: 2,
                                    text: '2y'
                                },
                                {
                                    type: 'ytd',
                                    text: 'YTD'
                                },
                                {
                                    type: 'all',
                                    text: 'All'
                                }],
                            selected: 7
                        },
                        chart: {
                            ...res.data.chart,
                            zooming: {
                                mouseWheel: {
                                    enabled: false
                                }
                            },
                            zoomType: 'x',
                            height: 600,
                            plotBackgroundColor: '#E5ECF6',
                            panning: {
                                enabled: true,
                                type: 'x'
                            },
                            panKey: 'shift'
                        },
                        legend: {
                            ...res.data.legend,
                            itemStyle: {
                                color: "#000000"
                            },
                            itemHiddenStyle: {
                                textDecoration: 'none'  // Disable strikethrough effect
                            }
                        },
                        plotOptions: {
                            series: {
                                events: {
                                    legendItemClick: function() {
                                        const series = this.chart.series;
                                        if (monitor.isPressed('z')) { // 16 is code for shift key
                                            series.forEach(s => {
                                                s !== this ? s.hide() : s.show()
                                            }, this);

                                            return false;
                                        }

                                        if (monitor.isPressed('x')) {
                                            const yAxisIndex = this.yAxis.index;
                                            const yAxis = this.chart.yAxis[yAxisIndex];
                                            if (yAxis.visible) {
                                                yAxis.update({visible: false});
                                            } else {
                                                yAxis.update({visible: true});
                                            }

                                            return false;
                                        }

                                        if (monitor.isPressed('c')) {
                                            series.forEach(s => {
                                                const yAxisIndex = s.yAxis.index;
                                                const yAxis = this.chart.yAxis[yAxisIndex];
                                                yAxis.update({visible: false});
                                            }, this);

                                            return false;
                                        }

                                        return true;
                                    }
                                }
                            },
                            marker: {
                                enabled: false
                            }
                        }
                    }
                });
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                if (err.code === "ERR_CANCELED") {
                    // do nothing for now
                } else {
                    setStatusMsg("Failed to load data for the chart. Looks like this data is not available.")
                    setIsError(true);
                    setLoading(false);
                }
            })

        return () => abortController.abort();
    }, [props.refresh])

    return (
        <>
            <a id={props.queryType} href={""}> </a>
            <LoadingOverlay
                active={loading && !isError}
                spinner={!isError}
                text={"Loading..."}
            >
                {isError && <div>
                    <CAlert color="danger">
                        Failed to load - {props.queryType}.
                        Data Not Configured
                    </CAlert>
                </div>}
                {chartData == null && !isError && <p style={{height: "400px", width: "90%"}}> ...</p>}
                {chartData != null && !isError && chartData.series.length === 0 && <div>
                    <CAlert color="warning">
                        Data Not Available - {props.queryType}
                    </CAlert>
                </div>}
                {chartData != null && !isError && chartData.series.length !== 0 &&
                    <center><div style={{marginTop: 10, paddingLeft: 10}}>
                    <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={chartData} />
                </div></center>}
            </LoadingOverlay>

        </>
    )
}

export default MacroSectorLineChart;