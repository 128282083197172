import { Alert } from 'antd';

function IndexDetails(props) {
    let prefix = '';
    if (props.name) {
        prefix = props.name + ' : ';
    }

    let message = props?.weights?.sort((a, b) => b.weight - a.weight)
        .reduce((str, a) => { return str.concat((Math.round((a.weight + Number.EPSILON) * 100) / 100), '*', a.seriesName, ' + ') }, '')
        .slice(0, -3);

    return (
        <>
            {message && <Alert message={prefix + message} type="info" />}
        </>
    );

}

export default IndexDetails;