import {createContext, useEffect, useState} from "react";
import {TickerApiScheduler} from "../../../api/queueing/TickerApiScheduler";

export const RefreshContext = createContext();

const PARALLEL_TASK_COUNT = 6;

//Todo: Rename this. Avoiding this for now due to excessive changes to all the files:
export const RefreshContextProvider = ({children, tick}) => {

    const [refresh, setRefresh] = useState(false);

    const toggleRefresh = () => setRefresh(prev => !prev);
    const [apiScheduler, setApiScheduler] = useState(tick?.name == null ? null :
        new TickerApiScheduler(tick.name, PARALLEL_TASK_COUNT));

    useEffect(() => {
        if(tick?.name != null)
        setApiScheduler(new TickerApiScheduler(tick.name, PARALLEL_TASK_COUNT));
    }, [tick]);


    return (
        <RefreshContext.Provider value={{ refresh, toggleRefresh, apiScheduler }}>
            {children}
        </RefreshContext.Provider>
    )
}