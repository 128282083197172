import {Col, Row} from "antd";
import {useContext, useEffect, useState} from "react";
import {TwitterOutlined} from "@ant-design/icons";
import {NextEarningsDateContext} from "../oracle/commonContexts/NextEarningsDateProvider";
import {getVisibleAlphaClickoutUrl} from "../../api/data/DataProvider";

function SentieoBar(props) {
    const {earningsInfo, daysToEarning} = useContext(NextEarningsDateContext);
    const [clickoutUrl, setClickoutUrl] = useState(undefined);

    const parseSentieoSegUrl = () => {
        return clickoutUrl;
    }

    const parseSentieoGeoSegUrl = () => {
        return parseSentieoSegUrl();
    }

    const parseOptionChainsUrl = () => {
        // return "https://finance.yahoo.com/quote/" + props.activeTick.name + "/options?p=BOOT&straddle=true";
        return "https://www.nasdaq.com/market-activity/stocks/" + props.activeTick.name + "/option-chain";
    }

    useEffect(() => {
        if (props.activeTick!==null && props.activeTick.id !== null) {
            getVisibleAlphaClickoutUrl(props.activeTick.id).then((res) => {
                setClickoutUrl(res.data);
            }).catch((err) => {
                console.error("[SentieoBar] | Failed to resolve Visible Alpha clickout URL.", err);
            })
        }
    }, [props.activeTick.id]);

    return (
        <div>
            <Row gutter={16} style={{height: 40}}>
                <Col span={2}></Col>
                <Col style={{ border: '0.5px solid #ffffff', padding: '10px', backgroundColor: "#111a2c", color: "#ffffff" }} span={3}>
                    <span title={'As of ' + earningsInfo.asOf}>{earningsInfo.nextEarningsDate}</span>
                </Col>
                <Col style={{ border: '0.5px solid #ffffff', padding: '10px',  backgroundColor: "#111a2c", color: "#ffffff" }} span={3}>
                    <span>{daysToEarning}</span>
                </Col>
                <Col style={{ border: '0.5px solid #ffffff', padding: '10px',  backgroundColor: "#111a2c", color: "#ffffff" }} span={3}>
                    <span>
                        <a style={{
                            textDecoration: clickoutUrl ? "none" : "line-through",
                            color: "inherit",
                            pointerEvents: clickoutUrl ? "auto" : "none",
                            opacity: clickoutUrl ? "1" : "0.4",
                        }}
                           href={clickoutUrl}
                           onClick={(e) => {
                               if (!clickoutUrl) {
                                   e.preventDefault();
                               }
                           }}
                           target="_blank">
                                Segment Data
                        </a>
                    </span>
                </Col>
                <Col style={{ border: '0.5px solid #ffffff', padding: '10px',  backgroundColor: "#111a2c", color: "#ffffff" }} span={3}>
                    <span>
                        <a style={{
                            textDecoration: clickoutUrl ? "none" : "line-through",
                            color: "inherit",
                            pointerEvents: clickoutUrl ? "auto" : "none",
                            opacity: clickoutUrl ? "1" : "0.4",
                        }}
                           href={clickoutUrl}
                           onClick={(e) => {
                               if (!clickoutUrl) {
                                   e.preventDefault();
                               }
                           }}
                           target="_blank">
                                Geo Segment Data
                        </a>
                    </span>
                </Col>
                <Col style={{
                    border: '0.5px solid #ffffff',
                    padding: '10px',
                    backgroundColor: "#111a2c",
                    color: "#ffffff"
                }} span={3}>
                    <span><a style={{textDecoration: "none", color: "inherit"}} href={parseOptionChainsUrl()}
                             target={"_blank"}>Options Chain</a></span>
                </Col>
                <Col style={{
                    border: '0.5px solid #ffffff',
                    padding: '10px',
                    backgroundColor: "#111a2c",
                    color: "#ffffff"
                }} span={3}>
                    <span><a style={{textDecoration: "none", color: "inherit"}}
                             href={"https://twitter.com/search?q=%24" + props.activeTick.name + "&src=typed_query"}
                             target={"_blank"}>
                        <TwitterOutlined /><span style={{marginLeft: 5}}>
                    </span>Twitter</a></span>
                </Col>
                <Col span={2}></Col>
            </Row>
        </div>
    )
}

export default SentieoBar;