import {Col} from "antd";
import {DashboardQueryTypes} from "../../../api/data/DashboardQueryTypes";
import {ApiContextConnectedIdioChart} from "../../line_chart/IdioChart";
import ClickOut from "../../clickout/ClickOut";
import React, {useRef} from "react";
import {getChartForQueryType, getYearsToShowForQueryType} from "../../../api/data/QueryTypeToChartMapping";
import Measure from "react-measure";
import * as PropTypes from "prop-types";
import {TickerBar} from "../compactView/TickerBar";
import {ListSelectionBar} from "../compactView/ListSelectionBar";
import {NextEarningsDateProvider} from "../commonContexts/NextEarningsDateProvider";
import {SplitContextsConsumerWrapper} from "./views/SplitContextsConsumerWrapper";

const QUERY_2x2 = [
    DashboardQueryTypes.WEEKLY_YOY,
    DashboardQueryTypes.QTR_YOY_LAG0,
    DashboardQueryTypes.WEEKLY_YOY2,
    DashboardQueryTypes.QTR_YOY2_LAG0
]

const ESTIMATED_INITIAL_HEIGHT = 450;

MiniView2x2.propTypes = {
    tick: PropTypes.shape({name: PropTypes.string, id: PropTypes.any}),
    brand: PropTypes.shape({name: PropTypes.string, id: PropTypes.any}),
    showMiniView: PropTypes.bool
};

export function MiniView2x2(props) {

    const chartHeight = useRef(ESTIMATED_INITIAL_HEIGHT);
    const chartWidth = useRef(ESTIMATED_INITIAL_HEIGHT);

    const getChartForQuery = (query) => {
        return < SplitContextsConsumerWrapper
            key={`ChartGrid-SplitConsumer-${query}`}
            queryType={query}
            RawComponent={getChartForQueryType(query)}
            activeTicker={props.tick?.name}
            activeBrandName={props.brand?.name}
            activeBrand={props.brand.id}
            chartHeightOverride={chartHeight.current + 'px'}
            chartWidth={chartWidth.current + 'px'}
            yearsToShow={getYearsToShowForQueryType(query)}
            isMiniMode={true}
        />
    }

    //Since All charts are same height, we can use a single ref here

    const HeightObserverWrappedGridChart = (index) => {
        return < Measure
            bounds
            onResize={(contentRect) => {
                chartHeight.current = contentRect.bounds.height;
                chartWidth.current = contentRect.bounds.width;
            }}>
            {
                ({measureRef}) => <div ref={measureRef} key={`ChartGrid-Chart-${index}`}
                                       className="grid-chart-container-2x2">
                    {getChartForQuery(index)}
                </div>
            }
        </Measure>
    }

    return <>

        <NextEarningsDateProvider activeTick={props.tick}>
            <ListSelectionBar activeTick={props.tick} brand={props.brand} showViewControls={false} showDataLag={false}
                              tooltipMode={false}/>
            <TickerBar activeTick={props.tick} brand={props.brand} setBrand={props.setBrand} showPositionsTooltips={false}
                       showViewControls={false} showDataLag={false}/>
        </NextEarningsDateProvider>
        <div className="grid-container-2x2">
            {QUERY_2x2.map(query => HeightObserverWrappedGridChart(query))}
        </div>
        <Col span={24}>
            <ApiContextConnectedIdioChart activeTicker={props.tick.name}
                                          activeTickerId={props.tick.id}
                                          showMiniView={props.showMiniView}
                                          isMiniMode={false}
                                          queryType={DashboardQueryTypes.IDIO}
            />
        </Col>
        <Col span={4}></Col>
        <Col span={8}>
            <ClickOut activeTickerId={props.tick.id} activeBrandId={props.brand.id}
                      clickOutType={"GT_WW"}
                      displayName={"GT WW Trends Click"}/>
        </Col>
        <Col span={8}>
            <ClickOut activeTickerId={props.tick.id} activeBrandId={props.brand.id}
                      clickOutType={"GT_US"}
                      displayName={"GT US Trends Click"}/>
        </Col>
        <Col span={4}></Col>

    </>;
}