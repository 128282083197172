import React, {useEffect, useState} from 'react';
import {Radio, Select} from 'antd';
import {formatNumber, formatPercent, formatCurrency} from '../../utils/FinDataUtils';
import {AgGridReact} from "@ag-grid-community/react";
import { SeriesTypes } from '../../api/data/SeriesTypes';


const defaultSeriesOptions = {
    Rev: { label: "Operational Metrics", options: [{
        value: SeriesTypes.SALES,
        label: "Total Revenue"
    },
    {
        value: SeriesTypes.GROSSPROFIT,
        label: "Gross Profit"
    },
    {
        value: SeriesTypes.EBITDA_xLEASE,
        label: "EBITDA Adj. ex-Lease"
    },
    {
        value: SeriesTypes.EPSDILUTED,
        label: "EPS Diluted"
    }] },
    Liq: { label: "Assets & Liabilities", options: [] },
    Fin: { label: "Financials", options: [] }
  };


// Object.entries({
//     [SeriesTypes.SALES]: "Total Revenue",
//     [SeriesTypes.EPSDILUTED]: "EPS Diluted",
//     [SeriesTypes.EBITDA]: "EBITDA",
//     [SeriesTypes.GROSSPROFIT]: "Gross Profit",
//     [SeriesTypes.COGS]: "Cost of Goods Sold",
//     [SeriesTypes.IS_DIVIDENDSPERSHARE]: "Dividends Per Share",
//     [SeriesTypes.FCF]: "Free Cash Flow",
//     [SeriesTypes.DnA]: "Depreciation & Amortization",
//     [SeriesTypes.SGnA]: "Selling, General & Administrative",
//     [SeriesTypes.SALESPERSHARE]: "Sales Per Share"
// }).map(([key, label]) => ({ value: key, label: label }));


const getGroupedSeriesOptions = (lineItems) => {
    if(!lineItems || lineItems.length === 0){
        return Object.values(defaultSeriesOptions);
    }
  const groups = {
    Rev: { label: "Operational Metrics", options: [] },
    Margin: { label: "Profit & Margin", options: [] },
    Liq: { label: "Assets & Liabilities", options: [] },
    Fin: { label: "Financials", options: [] }
  };

  lineItems.forEach(item => {
    if (item.studiogroupid in groups) {
      groups[item.studiogroupid].options.push({
        value: item.line_item_id,
        label: item.name,
      });
    }
  });

  return Object.values(groups).filter(group => group.options.length > 0);
};

const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const onSearch = (value) => {
    console.log("onSearch",value);
}

const SelectComponent = ({ percentOption, seriesOption, viewMode, currSymbol, onPercentChange, onSeriesChange, onViewModeChange, disabled, error, lineItems, showViewMode }) => {
    const selectClass = error ? 'select-error' : '';
    const groupedOptions = getGroupedSeriesOptions(lineItems);

    return (
        <div style={{ textAlign: 'left', marginBottom: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Select
                className={selectClass}
                size="large"
                style={{ width: 420, marginRight: 10, opacity: disabled ? 0.4 : 1 }}
                placeholder="Select an option"
                showSearch
                filterOption={(input, option) => {
                    try {
                        //ignore option Groups 
                        if (!option.options) {
                            // This is an OptGroup
                            return option.children.toLowerCase().includes(input.toLowerCase());
                        }

                    } catch (error) {
                        console.error('Error in filterOption:', error);
                        return false;
                    }
                }}
                optionFilterProp="children"
                value={seriesOption}
                onChange={onSeriesChange}
                disabled={disabled}
                listHeight={600}
                placement="bottomLeft"
            >
                {groupedOptions.map(group => (
                    <Select.OptGroup key={group.label} label={group.label}>
                        {group.options.map(option => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select.OptGroup>
                ))}
            </Select>
            { showViewMode && 
                <div 
                    onClick={() => onViewModeChange(viewMode === 'Q' ? 'S' : 'Q')}
                    style={{ 
                        cursor: 'pointer',
                        fontSize: '14px',
                        color: '#1890ff',
                        padding: '8px 12px',
                        border: '1px solid #1890ff',
                        borderRadius: '4px',
                        backgroundColor: 'white',
                        transition: 'all 0.3s',
                        display: 'inline-block',
                        marginRight: '10px',
                        ':hover': {
                            backgroundColor: '#e6f7ff'
                        }
                    }}
                >
                    {viewMode === 'Q' ? 'Semi-Annual data is available. Click here.' : 'Showing Semi-Annual. Click to see Quarterly.'}
                </div>
            }

            <Radio.Group size="large" onChange={onPercentChange} value={percentOption}>
                        
                        <Radio.Button value={'YoY'}>% YoY</Radio.Button>
                        <Radio.Button value={'QoQ'}>% QoQ</Radio.Button>

            </Radio.Group>
        </div>
    );
};


const customCellRenderer = (params) => {
    const value = params.value.value;
    const percentValue = params.value.percentValue;
    const reportType = params.value.reportType;
    const diagonalStyle = reportType === 'E' ? { borderTop: '50px solid rgba(33, 150, 243, 0.15)', borderRight: '74px solid transparent' } : {};

    return (
        <div style={{ height: '50px', width: '75px', backgroundColor: 'rgb(255, 255, 255)' }}>
            <div className="custom-cell">
                <div className="diagonal_cell_border" style={diagonalStyle}></div>
                <div className="top-left">{value}</div>
                <div className="bottom-right">{percentValue}</div>
            </div>
        </div>
    );
};

const getRowClass = (params) => {
    return params.node.rowIndex === 4 ? 'divider-row' : ''; // Row index is zero-based
  };

const getRowHeight = (params) => {
    return params.node.rowIndex === 4 ? 56 : 50;
}

const TableComponent = ({ rowData, columnDefs }) => {
    const cellStyle = params => {
        const rowIndex = params.node.rowIndex;
        const colIndex = params.columnApi.getAllColumns().indexOf(params.column);
        return (rowIndex % 2 === 0 && colIndex % 2 === 0) || (rowIndex % 2 !== 0 && colIndex % 2 !== 0)
            ? { background: '#f0f0f0' }
            : { background: '#ffffff' };
    };

    const onGridReady = params => {
        // Capture the grid API
        const gridApi = params.api;

        // Find column with colId as currentYear
        const allColumns = gridApi.getAllGridColumns();
        const currentYear = (new Date().getFullYear() + 3).toString();
        let targetColumn = allColumns.find(column => column.getColId() === currentYear);
        if (!targetColumn) {
            targetColumn = allColumns[allColumns.length - 3];
        }
        gridApi.ensureColumnVisible(targetColumn, 'end');

    };


    return (
        <div className="ag-grid-findata ag-theme-alpine finmatrix-table" style={{ width: '100%', overflowX: 'hidden' }}>
            <AgGridReact
                headerHeight={30}
                rowData={rowData}
                getRowHeight={getRowHeight}
                columnDefs={columnDefs}
                domLayout='autoHeight'
                getRowClass={getRowClass}
                defaultColDef={{
                    minWidth: 75,
                    maxWidth: 75,
                    // cellStyle: cellStyle,
                }}
                onGridReady={onGridReady}
            />
        </div>
    );
};

const getColumnDefFromPeriod = (period) => {
    return {
        headerName: period,
        field: period,
        width: 55,
        sortable: false,
        headerClass: "finmatrix-header",
        cellRenderer: customCellRenderer,
        valueGetter: function(params) {
            // Check if the 'name' field is present and not null
            return period in params.data && params.data[period] != null ? params.data[period] : 0;
          }
    }
}

//
const quarterMonthToLabel = (quarterNumber, period_end_date) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(period_end_date);
    const month = monthNames[date.getMonth()];
    return `Q${quarterNumber} ${month}`;    
}

const halfYearToLabel = (halfYear, period_end_date) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = period_end_date ? new Date(period_end_date) : null;
    const month = date ? monthNames[date.getMonth()] : halfYear === 1 ? 'Jun' : 'Dec';
    return `H${halfYear} ${month}`;
};

//{"fyyear": 2018, "quarter": 1, "quarter_month": 3, "period": "1QFY-2018", "value": 1291, "currency": "USD", "reportType": "reported", "qoq": 12.1, "yoy": 12.1}
//result: q1: { "2018": 1291, "2017": 1291, "2016": 1291, "2015": 1291, "FY": 1291 } q2: 
//each row has all the years - 5 rows for each quarter and fy
const transformDataForAgGrid = (seriesData, pOpt, decimals) => {
    const columns = new Set();
    const fyRow = {};
    const quarterRows = [{},{},{},{}];
    const halfYearRows = [{},{},{},{}];
    let hasQuarterValues = false;
    let hasHalfYearValues = false;
    
    for(let i=0; i<seriesData.length; i++) {
        const row = seriesData[i];
        columns.add(row.fyyear.toString());
        
        if(row.quarter) {
            const percentValue = pOpt === 'QoQ' ? row.qoq : row.yoy;
            quarterRows[row.quarter - 1][row.fyyear.toString()] = { 
                value: formatNumber(row.value, decimals), 
                percentValue: formatPercent(percentValue), 
                reportType: row.reportType 
            };
            if(row.value) {
                hasQuarterValues = true;
            }
            if(row.period_end_date) {
                quarterRows[row.quarter - 1]['label'] = quarterMonthToLabel(row.quarter, row.period_end_date);
            }
        } else if(row.halfyear) {
            const percentValue = pOpt === 'QoQ' ? row.qoq : row.yoy;
            halfYearRows[(row.halfyear * 2) - 1][row.fyyear.toString()] = {
                value: formatNumber(row.value, decimals),
                percentValue: formatPercent(percentValue),
                reportType: row.reportType
            };
            if(row.value) {
                hasHalfYearValues = true;
            }
            if(row.period_end_date) {
                halfYearRows[(row.halfyear * 2) - 1]['label'] = halfYearToLabel(row.halfyear, row.period_end_date);
            }
        } else {
            fyRow[row.fyyear.toString()] = { 
                value: formatNumber(row.value, decimals), 
                percentValue: formatPercent(row.yoy), 
                reportType: row.reportType 
            };
        }
    }
    
    fyRow['label'] = 'FY';
    const sortedColumns = Array.from(columns).sort();
    sortedColumns.unshift('label');
    
    return {
        fyRow,
        quarterRows,
        halfYearRows,
        columns: sortedColumns,
        hasQuarterValues,
        hasHalfYearValues
    };
};

//provided the data will be pushed from above
const FinMatrix = ({ dataState, seriesOption, onSeriesOptionChange, lineItems }) => {
    const { loading, isError, data } = dataState;
    const [percentOption, setPercentOption] = useState('YoY');
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState(null);
    const currency = data && data.company_info && data.company_info.currency
        ? data.company_info.currency : 'USD';
    
    const lineItemUnit = lineItems?.find(item => item.line_item_id === seriesOption)?.unit;
    const lineItemMagnitude = lineItems?.find(item => item.line_item_id === seriesOption)?.magnitude;
    const [viewMode, setViewMode] = useState('Q'); // 'quarterly' or 'halfyearly'
    const [showViewMode, setShowViewMode] = useState(false);

    //only recompute if data changes - series change will trigger data change
    useEffect(() => {
        const decimals = lineItemMagnitude == "Abs" ? 2 : 1;
        
        if (data?.data && !isError && !loading) {
            const { columns, quarterRows, halfYearRows, fyRow } = transformDataForAgGrid(data.data, percentOption, decimals);
            
            // Set row data based on view mode
            const periodRows = viewMode === 'S' ? halfYearRows: quarterRows;
            setRowData([...periodRows, fyRow]);
            
            const defaultColumnDefs = columns.map(column => {
                if(column === 'label') {
                    return { 
                        headerName: '', 
                        headerClass: "finmatrix-header", 
                        field: 'label', 
                        width: 50, 
                        pinned: 'left',
                        sortable: false,
                        cellStyle: {fontWeight: 'bold', fontSize: '12px'},
                    };
                }
                return getColumnDefFromPeriod(column);
            });
            setColumnDefs(defaultColumnDefs);
        }
    }, [percentOption, seriesOption, dataState, viewMode]);

    useEffect(() => {
        if (data?.data && !isError && !loading) {
            const decimals = lineItemMagnitude == "Abs" ? 2 : 1;
            const fund_per = data?.company_info?.fund_per;
            if(fund_per === 'S') {
                const { hasHalfYearValues, hasQuarterValues } = transformDataForAgGrid(data.data, percentOption, decimals);
                if (!hasQuarterValues){
                    setViewMode('S');
                }else{
                    setViewMode('Q');
                }
                if(hasHalfYearValues && hasQuarterValues){
                    setShowViewMode(true);
                }else{
                    setShowViewMode(false);
                }
            } else {
                setShowViewMode(false);
                setViewMode('Q');
            }
        }
    }, [dataState]);

    const handleSeriesChange = value => {
        onSeriesOptionChange(value);
    };

    const handleViewModeChange = value => {
        setViewMode(value);
    };

    const handlePercentChange = e => {
        setPercentOption(e.target.value);
    };

    return (
        <div style={{ marginLeft: '20px', marginBottom: '30px' }}>
            <SelectComponent currSymbol={formatCurrency(currency)} disabled={loading} error={isError} seriesOption={seriesOption} percentOption={percentOption} viewMode={viewMode} 
                            onPercentChange={handlePercentChange} onSeriesChange={handleSeriesChange} onViewModeChange={handleViewModeChange} lineItems={lineItems}  
                            showViewMode={showViewMode}/>
            { columnDefs && <TableComponent rowData={rowData} columnDefs={columnDefs} viewMode={ viewMode } />}
        </div>
    );
};

export default FinMatrix;