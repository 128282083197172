import {MINI_VIEW, TICKER_NAME} from "../oracle/DashboardReport/DashboardConstants";

export const reducePxValue = (pxString, pxReductionAmount) => {
    const pxValues = parseInt(pxString.replaceAll('px', ''))
    const reducedValue = pxValues - pxReductionAmount;
    return reducedValue + 'px';
}

export const generateLinkForOracleClickout = (ticker) => {
    const currentUrl = window.location.href;
    const parsedUrl = new URL(currentUrl);
    const params = new URLSearchParams(parsedUrl.search);
    // Remove the pathname
    parsedUrl.pathname = '';
    params.set(TICKER_NAME, ticker);
    params.set(MINI_VIEW, 'false');
    // params.set('apiKey', 'Axc142BNas56lMn09');
    parsedUrl.search = params.toString();
    return parsedUrl.origin + '?' + params.toString();
}

export const generateColorGradient  = (startColor, endColor, color_to_generate) => {
    // Helper function to convert hex color to RGB
    const hexToRgb = hex =>
        hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                ,(m, r, g, b) => '#' + r + r + g + g + b + b)
            .substring(1).match(/.{2}/g)
            .map(x => parseInt(x, 16));

    // Helper function to convert RGB color to hex
    const rgbToHex = (r, g, b) =>
        "#" + [r, g, b].map(x => x.toString(16).padStart(2, '0')).join('');

    // Parse the start and end colors to their RGB components
    let startRgb = hexToRgb(startColor);
    let endRgb = hexToRgb(endColor);

    // Calculate the step amount for each color component
    let stepR = (endRgb[0] - startRgb[0]) / (color_to_generate - 1);
    let stepG = (endRgb[1] - startRgb[1]) / (color_to_generate - 1);
    let stepB = (endRgb[2] - startRgb[2]) / (color_to_generate - 1);

    let gradientColors = [];

    // Generate the colors
    for(let i = 0; i < color_to_generate; i++) {
        let r = Math.round(startRgb[0] + (stepR * i));
        let g = Math.round(startRgb[1] + (stepG * i));
        let b = Math.round(startRgb[2] + (stepB * i));

        // Add the current color to the gradient array
        gradientColors.push(rgbToHex(r, g, b));
    }

    return gradientColors;
}