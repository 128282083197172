import {createContext, useContext, useEffect, useState} from "react";
import {getAvlUrls, getSwVisitsStack} from "../../../api/data/DataProvider";
import {RefreshContext} from "../../oracle/commonContexts/RefreshContextProvider";

export const DynSWStackContext = createContext(null);

export const DynSWStackProvider = ({ children, activeTicker  }) => {

    const {refresh} = useContext(RefreshContext);

    const [reload, setReload] = useState(false);
    const [clear, setClear] = useState(true);
    const [loading, setLoading] = useState(false);

    const [avlUrls, setAvlUrls] = useState([])
    const [selectedUrl, setSelectedUrl] = useState(null)

    const [mode, setMode] = useState("SW_METRICS");

    useEffect(() => {
        const abortController = new AbortController();
        setSelectedUrl(null);
        setClear(true);
        getAvlUrls(activeTicker.id, mode)
            .then((res) => {
                setAvlUrls(res.data);
            }).catch((err) => {
            if (err.code === "ERR_CANCELED") {
                // do nothing for now
            } else {
                console.error("Failed to fetch alt terms.", err);
            }
        });

        return () => abortController.abort();
    }, [activeTicker, mode, refresh]);

    const _onClick = () => {
        setClear(false);
        setLoading(true);
        setReload((prev) => !prev);
    }

    const [dataState, setDataState] = useState({
        'us': {
            chartData: null,
            isError: false,
            loading: false
        },
        'world': {
            chartData: null,
            isError: false,
            loading: false
        }
    });

    useEffect(() => {
            if(selectedUrl == null) return;
            //Load the data for the stack charts once a term has been selected:
            setDataState({
                'us': {
                    chartData: null,
                    isError: false,
                    loading: true
                },
                'world': {
                    chartData: null,
                    isError: false,
                    loading: true
                }
            });
            const abortController = new AbortController();

            getSwVisitsStack(selectedUrl.value, 'us',  mode, abortController)

                .then(res => setDataState( prev => ({
                    ...prev,
                    'us': {
                        chartData: res,
                        isError: false,
                        loading: false
                    }
                })))
                .catch(err => {
                    console.err(err);
                    if (err.code === "ERR_CANCELED") {
                        // do nothing for now
                    } else {
                        setDataState( prev => ({
                            ...prev,
                            'us': {
                                chartData: null,
                                isError: true,
                                loading: false
                            }
                        }))
                    }
                });
            getSwVisitsStack(selectedUrl.value, 'world',  mode, abortController)
                .then(res => setDataState( prev => ({
                    ...prev,
                    'world': {
                        chartData: res,
                        isError: false,
                        loading: false
                    }
                })))
                .catch(err => {
                    console.err(err);
                    if (err.code === "ERR_CANCELED") {
                        // do nothing for now
                    } else {
                        setDataState( prev => ({
                            ...prev,
                            'world': {
                                chartData: null,
                                isError: true,
                                loading: false
                            }
                        }))
                    }
                });
        },
        [selectedUrl, reload, refresh]);

    useEffect(() => {
        setDataState({
            'us': {
                chartData: null,
                isError: false,
                loading: false
            },
            'world': {
                chartData: null,
                isError: false,
                loading: false
            }
        })
    }, [clear])

    return (
        <DynSWStackContext.Provider value={{selectedUrl, setSelectedUrl, mode, setMode, avlUrls, _onClick, dataState}}>
            {children}
        </DynSWStackContext.Provider>
    )

}