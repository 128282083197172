import {useContext, useEffect, useState} from "react";
import {getAltGtTerms, getData, getDataForBrand, getDynAltGtData} from "../../../api/data/DataProvider";
import LoadingOverlay from 'react-loading-overlay';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { CAlert } from "@coreui/react";
import {Button, Select} from "antd";
import AltGtStackChart from "./AltGtStackChart";
import {AltGtStackContext} from "./AltGtStackContext";


function DynAltGtStackChartsWrapper(props) {
    const {selectedTerm, setSelectedTerm, altTerms, _onClick} = useContext(AltGtStackContext);

    const clickOutUrl = "https://trends.google.com/trends/explore?date=2024-01-01%202024-12-31,2023-01-01%202023-12-31,2022-01-01%202022-12-31&geo=,,&q=";
    const usClickOutUrl = "https://trends.google.com/trends/explore?date=2024-01-01%202024-12-31,2023-01-01%202023-12-31,2022-01-01%202022-12-31&geo=US,US,US&q=";
    const enabledLinkStyle = {marginLeft: 50};
    const disabledLinkStyle = {marginLeft: 50, pointerEvents: "none", opacity: 0.6, color: "black"};

    const defTerm = {
        label: "Select Alt Gt Term",
        value: null
    }

    const parseOptions = () => {
        return altTerms.map(term => {
            return {
                value: term.queryTerm,
                label: term.queryTermAlias
            };
        });
    }

    return (
        <>
                <div>
                    <Select
                        value={selectedTerm == null ? defTerm: {label: selectedTerm.label, value: selectedTerm.value}}
                        showSearch
                        onSelect={(value, element) => setSelectedTerm(element)}
                        style={{width: 500}}
                        options={parseOptions()} />
                    <Button type="primary"
                            style={{backgroundColor: "#1677ff", marginLeft: 15}}
                            disabled={selectedTerm==null}
                            onClick={_onClick}>
                        Load Alt Gt Graph
                    </Button>
                    <a style={selectedTerm==null ? disabledLinkStyle : enabledLinkStyle} href={clickOutUrl.replaceAll("+", "%2B") + selectedTerm?.value.replaceAll("+", "%2B")} target={"_blank"}>Alt Gt Clickout WW</a>
                    <a style={selectedTerm==null ? disabledLinkStyle : enabledLinkStyle} href={usClickOutUrl.replaceAll("+", "%2B") + selectedTerm?.value.replaceAll("+", "%2B")} target={"_blank"}>Alt Gt Clickout US</a>
                    <AltGtStackChart
                        activeTicker={props.activeTicker}
                        queryType={props.queryType}
                        selectedTerm={selectedTerm}
                        location={"WW"} />
                    <AltGtStackChart
                        activeTicker={props.activeTicker}
                        queryType={props.queryType}
                        selectedTerm={selectedTerm}
                        location={"US"} />
                </div>
        </>
    )
}

export default DynAltGtStackChartsWrapper;