import {useContext} from "react";
import LoadingOverlay from "react-loading-overlay";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import {IntradayStockPriceContext} from "./contextsForCharts/IntradayStockPriceWorker";
import _ from "lodash";

/*mouseWheelZoom(Highcharts)*/

export const convertToLocalDate = (dateString) => {
    if (dateString == null || dateString === "") return null;
    // Input string format: 09:00 a.m. CEST
    const timeString = dateString.substring(0, 5); //Get 09:00
    const [hourString, minuteString] = timeString.split(":");

    const timeModifier = dateString.substring(6, 10)
        .replaceAll('.', '')
        .toLowerCase(); // Get am or pm:

    let hours = parseInt(hourString, 10);
    let minutes = parseInt(minuteString, 10);

    if (timeModifier === 'pm' && hours !== 12) {
        hours += 12;
    } else if (timeModifier === 'am' && hours === 12) {
        hours = 0;
    }


    const now = new Date();
    const date = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
    return date.valueOf(); // Return the epoch time.

}

export const StudioIntradayPriceChart = ({chartHeight, chartWidth}) => {

    const {chartOptions, loading, isError} = useContext(IntradayStockPriceContext);
    const localData = _.cloneDeep(chartOptions);
    let height = 850;

    if(chartHeight != null ) {
        if (localData?.chart != null) localData.chart.height = chartHeight;
        height = chartHeight;
    }

    if(chartWidth != null  && localData?.chart != null) {
        localData.chart.width = chartWidth;
    }

    if(localData?.tooltip != null){
        localData.tooltip.formatter= function () {
            return this.series.name +': <b>'+ this.y.toFixed(1) +'</b>';
        }
    }

    return (
        <LoadingOverlay
            active={loading && !isError}
            spinner={!isError}
            text={"Loading ..."}
            styles={{
                overlay: (base) => ({
                    ...base,
                    height: height,
                    width: '100%'
                })
            }}
        >
            {!loading && !isError && localData != null && <HighchartsReact
                highcharts={Highcharts}
                constructorType={'stockChart'}
                options={localData}
            />}

        </LoadingOverlay>
    )
}