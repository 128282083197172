import {Modal} from "antd";
import {ViewControlIcons} from "../oracle/compactView/ViewControlIcons";
import {useContext, useEffect, useRef, useState} from "react";
import {ViewContextStore} from "../oracle/DashboardReport/views/ViewContext";
import Switch from "@mui/material/Switch";
import {getChartForQueryType, getYearsToShowForQueryType} from "../../api/data/QueryTypeToChartMapping";
import {getBoundChart, isABoundPairChart} from "../oracle/DashboardReport/views/pairedChartsUtility";
import {SplitContextsConsumerWrapper} from "../oracle/DashboardReport/views/SplitContextsConsumerWrapper";
import useChartRefs from "../oracle/commonHooks/UseChartRefs";

// Function to extract parts using the main regex or fallback to the first number
const extractParts = (str) => {

    // Regular expression to extract the parts, making suffix optional
    const regex = /^(.*_)(YOY\d*)(_LAG\d+)?$/;
    const simpleNumberRegex = /\d+/; // Simple regex to extract the first number in the string

    const match = str.match(regex);
    if (match) {
        const [, prefix, yoy, suffix = ""] = match;
        const yoyNumber = yoy === 'YOY' ? 0 : parseInt(yoy.replace('YOY', ''), 10);
        return [prefix, yoyNumber, suffix];
    } else {
        const simpleMatch = str.match(simpleNumberRegex);
        const simpleNumber = simpleMatch ? parseInt(simpleMatch[0], 10) : Number.MAX_SAFE_INTEGER; // Use a large number if no number is found
        return [str, simpleNumber, ""];
    }
};

export const MaximizedChartsModal = ({modalState, setModalState, queryType, pairedChart, dataStoreContext}) => {

    const {
        modalViewModePaired, setModalViewModePaired, tick, brand, mapOfChartRefs:  mapOfChartRefsGlobal, viewState
    } = useContext(ViewContextStore);

    const [chartsToShow, setChartsToShow] = useState([queryType]);
    const [haveToShowPair, setHaveToShowPair] = useState(false);

    const { mapOfChartRefs, addToMapOfChartRefs, removeFromMapOfChartRefs } = useChartRefs();

    // useRef to store the previous modalState
    const prevModalStateRef = useRef();

    // useEffect to handle modalState transition from true to false
    useEffect(() => {
        if (prevModalStateRef.current && !modalState) {
            // Explicitly set the ranges on the other charts:
            const {xMin = null , xMax = null } = viewState[queryType]?.extremities || {};
            if(xMin != null && xMax != null){
                let listOfChartRefs = [...(mapOfChartRefsGlobal[queryType] ?? [])];
                if (isABoundPairChart(queryType) || pairedChart != null) {
                    listOfChartRefs = [...listOfChartRefs, ...(mapOfChartRefsGlobal[getBoundChart(queryType) ?? pairedChart] ?? []) ];
                }
                listOfChartRefs.forEach(chartRef => {
                    const chart = chartRef.current?.chart;
                    if (!chart) return;
                    if (chart.xAxis[0].setExtremes) { // It is null while updating
                        chart.xAxis[0].setExtremes(
                            xMin,
                            xMax,
                            undefined,
                            false,
                            {trigger: 'syncExtremes'}
                        );
                    }
                });

            }


        } else if (!prevModalStateRef.current && modalState){
            const {xMin = null , xMax = null } = viewState[queryType]?.extremities || {};
            if(xMin != null && xMax != null){
                let listOfChartRefs = [...(mapOfChartRefs[queryType] ?? [])];
                if (isABoundPairChart(queryType) || pairedChart != null) {
                    listOfChartRefs = [...listOfChartRefs, ...(mapOfChartRefs[getBoundChart(queryType) ?? pairedChart] ?? []) ];
                }
                listOfChartRefs.forEach(chartRef => {
                    const chart = chartRef.current?.chart;
                    if (!chart) return;
                    if (chart.xAxis[0].setExtremes) { // It is null while updating
                        chart.xAxis[0].setExtremes(
                            xMin,
                            xMax,
                            undefined,
                            false,
                            {trigger: 'syncExtremes'}
                        );
                    }
                });

            }
        }

        prevModalStateRef.current = modalState;
    }, [modalState]);

    useEffect(() => {
        let charts = [queryType];

        const haveToShowPair = modalViewModePaired && (isABoundPairChart(queryType) || pairedChart != null);

        if (haveToShowPair) {
            charts.push(getBoundChart(queryType) ?? pairedChart);

            // Custom sort function
            charts = charts.sort((a, b) => {
                const [prefixA, yoyNumberA, suffixA] = extractParts(a);
                const [prefixB, yoyNumberB, suffixB] = extractParts(b);

                // Compare prefixes
                if (prefixA !== prefixB) {
                    return prefixA.localeCompare(prefixB);
                }

                // Compare YOY parts
                if (yoyNumberA !== yoyNumberB) {
                    return yoyNumberA - yoyNumberB;
                }

                // Compare suffixes
                return suffixA.localeCompare(suffixB);
            });
        }
        setHaveToShowPair(haveToShowPair);
        setChartsToShow(charts);
    }, [modalViewModePaired, queryType, pairedChart]);

    const viewIconsRef = useRef(null);

    useEffect(() => {
        if (viewIconsRef.current) {
            viewIconsRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, [viewIconsRef.current])


    return (
        <Modal width={2500} open={modalState} onOk={setModalState}
               onCancel={setModalState}>
            <div ref={viewIconsRef}>
                <ViewControlIcons bgColour={'blue'}/>
                Paired View Mode:
                <Switch
                    checked={modalViewModePaired}
                    onChange={e => setModalViewModePaired(e.target.checked)}
                />
            </div>
            {
                <>
                    {chartsToShow.map((chart, index) => (
                        <SplitContextsConsumerWrapper
                            key={index}
                            queryType={chart}
                            RawComponent={getChartForQueryType(chart)}
                            yearsToShow={getYearsToShowForQueryType(chart)}
                            activeTicker={tick?.name}
                            activeBrandName={brand?.name}
                            activeBrand={brand.id}
                            chartHeightOverride={haveToShowPair ? (window.innerHeight) / 2 : (window.innerHeight)}
                            legendWidthOverride={300}
                            enableModal={false}
                            dataStoreContext={dataStoreContext}
                            pairedChart={chartsToShow[(index === 0) ? 1 : 0]}
                            mapOfChartRefs={mapOfChartRefs}
                            addToMapOfChartRefs={addToMapOfChartRefs}
                            removeFromMapOfChartRefs={removeFromMapOfChartRefs}
                        />
                    ))}
                </>
            }
        </Modal>
    )
}
