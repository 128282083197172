
export const MainVPTMetrics = {
    PRICE: 'PRICE',
    MARKETCAP: 'MARKETCAP',
    // EV: 'EV',
    EV_xLEASE: 'EV_xLEASE',
    // NETDEBT: 'NETDEBT',
    NETDEBT_xLEASE: 'NETDEBT_xLEASE',
    TOTALDEBT: 'TOTALDEBT',
    SHARESOUTSTANDINGDILUTED: 'SHARESOUTSTANDINGDILUTED',
    CASHnSHORTTERMINVESTMENT: 'CASHnSHORTTERMINVESTMENT',
    TOTALEQUITYINCMINORITY: 'TOTALEQUITYINCMINORITY',
    CURRENTSHARESOUTSTANDING: 'CURRENTSHARESOUTSTANDING'
}

export const ValuationMetrics = {
    EV_SALES: 'EV_SALES',
    EV_SALES_xLEASE: 'EV_SALES_xLEASE',
    EV_EBIT: 'EV_EBIT',
    EV_EBITDA: 'EV_EBITDA',
    EV_EBITDA_xLEASE: 'EV_EBITDA_xLEASE',
    P_EPS: 'P_EPS',
    P_BVALUE: 'P_BVALUE',
    P_TBVALUE: 'P_TBVALUE',
    FCF_YIELD: 'FCF_YIELD',
    DIVIDEND_YIELD: 'DIVIDEND_YIELD'
}

export const ValuationMetricsOrder = [
    // [ValuationMetrics.EV_SALES],
    [ValuationMetrics.EV_SALES_xLEASE],
    [ValuationMetrics.EV_EBIT],
    // [ValuationMetrics.EV_EBIT_xLEASE],
    // [ValuationMetrics.EV_EBITDA],
    [ValuationMetrics.EV_EBITDA_xLEASE],
    [ValuationMetrics.P_EPS],
    [ValuationMetrics.P_BVALUE],
    // [ValuationMetrics.P_TBVALUE],
    [ValuationMetrics.FCF_YIELD],
    [ValuationMetrics.DIVIDEND_YIELD],
]


export const VPT_METRIC_UNITS = {
    AMOUNT: 'Amount',
    PERCENT: '%',
    Number: '#',
    RATIO: 'x'
};

export const VPT_TABLE_MODES = {
    FULL: 'Full Table',
    VALUATION: 'Valuation Metrics',
    MINI: 'Mini'
}

export const VPT_AMOUNT_UNITS = {
    THOUSANDS: 1000,
    MILLIONS: 1000000,
    BILLIONS: 1000000000
}

export const VPT_TIME_PERIODS = {
    YEAR: 'YEAR',
    QUARTER: 'QUARTER',
    HALFYEAR: 'HALFYEAR'
}

export const TIME_PERIOD_TYPE = {
    ACTUAL: 'A',
    EXPECTED: 'E'
};

export const FinancialSummaryMetric = {
    SALES: 'SALES',
    GROSSPROFIT: 'GROSSPROFIT',
    EBIT: 'EBIT',
    EBITDA_xLEASE: 'EBITDA_xLEASE',
    // EBITDA_ADJ: 'EBITDA_ADJ',
    EPSDILUTED: 'EPSDILUTED',
    BOOKVALUEPERSHARE: 'BOOKVALUEPERSHARE',
    // TANGIBLEBOOKVALUEPERSHARE: 'TANGIBLEBOOKVALUEPERSHARE',
    FREECASHFLOWPERSHARE: 'FREECASHFLOWPERSHARE',
    DIVIDENDSPERSHARE: 'DIVIDENDSPERSHARE',
    ROE_TTM: 'ROE_TTM',
    ROA_TTM: 'ROA_TTM',
}

export const LeaseSummaryMetrics = {
    COST_CAPITALIZED_OPERATING_LEAS: 'COST_CAPITALIZED_OPERATING_LEAS',
    EBITDA_ADJ: 'EBITDA_ADJ',
    BS_TOTAL_OPERATING_LEASE_LIABS: 'BS_TOTAL_OPERATING_LEASE_LIABS',
    BS_TOTAL_FINANCE_OPERATING_LEASE_LIABS: 'BS_TOTAL_FINANCE_OPERATING_LEASE_LIABS',
    NETDEBT: 'NETDEBT',
    EV: 'EV',
}

export const FinancialSummarySecondaryMetrics = {
    SALES_YoY: 'SALES_YoY',
    GROSSMARGIN: 'GROSSMARGIN',
    EBITMARGIN: 'EBITMARGIN',
    EBITAMARGIN: 'EBITAMARGIN',
    EPSDILUTED_YoY: 'EPSDILUTED_YoY',
    BOOKVALUEPERSHARE_YoY: 'BOOKVALUEPERSHARE_YoY',
    TANGIBLEBOOKVALUEPERSHARE_YoY: 'TANGIBLEBOOKVALUEPERSHARE_YoY',
    FREECASHFLOWPERSHARE_YoY: 'FREECASHFLOWPERSHARE_YoY',
    DIVIDENDSPERSHARE_YoY: 'DIVIDENDSPERSHARE_YoY',
    EBITDA_YoY: 'EBITDA_YoY',
    EBITDA_ADJ_YoY: 'EBITDA_ADJ_YoY'
}

export const FinancialSummarySecondaryMetricMap = {
    [FinancialSummaryMetric.SALES]: [FinancialSummarySecondaryMetrics.SALES_YoY],
    [FinancialSummaryMetric.EBITDA_ADJ]: [FinancialSummarySecondaryMetrics.EBITDA_ADJ_YoY],
    [FinancialSummaryMetric.EPSDILUTED]: [FinancialSummarySecondaryMetrics.EPSDILUTED_YoY],
    [FinancialSummaryMetric.BOOKVALUEPERSHARE]: [FinancialSummarySecondaryMetrics.BOOKVALUEPERSHARE_YoY],
    [FinancialSummaryMetric.TANGIBLEBOOKVALUEPERSHARE]: [FinancialSummarySecondaryMetrics.TANGIBLEBOOKVALUEPERSHARE_YoY],
    [FinancialSummaryMetric.FREECASHFLOWPERSHARE]: [FinancialSummarySecondaryMetrics.FREECASHFLOWPERSHARE_YoY],
    [FinancialSummaryMetric.DIVIDENDSPERSHARE]: [FinancialSummarySecondaryMetrics.DIVIDENDSPERSHARE_YoY]
}

export const MiniVPTValuationMetrics = [
    ValuationMetrics.P_EPS,
    ValuationMetrics.EV_EBITDA_xLEASE,
    ValuationMetrics.EV_SALES_xLEASE,
    ValuationMetrics.FCF_YIELD,
    ValuationMetrics.DIVIDEND_YIELD
];

export const MiniVPTMainMetrics = [
    MainVPTMetrics.PRICE,
    MainVPTMetrics.MARKETCAP,
    MainVPTMetrics.EV_xLEASE
];

export const MiniVPTFinancialSummaryMetrics = [
    FinancialSummaryMetric.SALES,
    FinancialSummaryMetric.EBITDA_xLEASE,
    FinancialSummaryMetric.EPSDILUTED
]