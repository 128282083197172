import React, {useEffect, useRef, useState} from 'react';
import {Input, Button, List, Typography, Card, Spin, Space} from 'antd';
import {askRogo} from "../../../../api/data/LLMDataProvider";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {monitor} from "../../../../event_handler/KeyPressMonitor";

const { TextArea } = Input;
const { Text } = Typography;

const ChatComponent = (props) => {
    const [question, setQuestion] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const listEndRef = useRef(null);

    // Effect to scroll to the latest question/answer when chat history changes
    useEffect(() => {
        if (listEndRef.current) {
            listEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatHistory]);

    // Mock function to simulate getting an answer
    const getAnswer = async (question) => {
        try {
            // Call the askRogo function which returns a promise (e.g., axios.post)
            const response = await askRogo(question);
            return response.data; // Adjust this based on the structure of your API response
        } catch (error) {
            console.error('Error fetching the answer:', error);
            return 'Sorry, something went wrong. Please try again later.';
        }
    };

    const handleAsk = async () => {
        if (question.trim()) {
            setLoading(true);
            const answer =  await getAnswer(question);
            setChatHistory([...chatHistory, { question, answer }]);
            setQuestion(''); // Clear input after asking
            setLoading(false);
        }
    };

    const handlePredefinedQuestion = (template) => {
        const ticker = props.tick;
        setQuestion(template.replace('{ticker}', ticker.name));
    };

    return (
        <Card style={{width: '100%', maxWidth: 1600, margin: '20px auto', borderRadius: '8px'}}>
            <h2 style={{textAlign: 'center', marginBottom: '20px'}}>Oracle AI</h2>
            <Space style={{ marginBottom: '10px' }}>
                <Button color="primary" variant="outlined" onClick={() => handlePredefinedQuestion('What is the retail and wholesale revenue mix for {ticker}?')}>
                    Wholesale/Retail Mix
                </Button>
                <Button color="primary" variant="outlined" onClick={() => handlePredefinedQuestion('What is the geographic revenue split for {ticker}?')}>
                    Geographic Mix
                </Button>
            </Space>
            <List
                bordered
                dataSource={chatHistory}
                style={{maxHeight: 600, overflowY: 'auto', marginBottom: '20px'}}
                renderItem={(item, index) => (
                    <List.Item key={index}>
                        <div>
                            <span style={{fontSize: 20}}><Text strong>Q:</Text> <b>{item.question}</b></span>
                            <br/>
                            <Text strong>A:</Text>
                            <span style={{fontSize: 20}}><ReactMarkdown remarkPlugins={[remarkGfm]}>{item.answer}</ReactMarkdown></span>
                        </div>
                    </List.Item>
                )}
            />
            {/* A div at the end of the list to ensure scrollIntoView works */}
            <div ref={listEndRef}/>
            <TextArea
                rows={2}
                value={question}
                onFocus={() => monitor.enableHotKeys(false)}
                onBlur={() => monitor.enableHotKeys(true)}
                onChange={(e) => {
                    setQuestion(e.target.value);
                }}
                placeholder="Type your question..."
                style={{marginBottom: '10px'}}
                disabled={loading}
            />
            <Button type="primary" onClick={handleAsk} block disabled={loading}>
                Ask
            </Button>
            {loading && (
                <div style={{textAlign: 'center', marginTop: '20px'}}>
                    <Spin tip="Thinking..."/>
                    <span style={{marginLeft: 5}}>Thinking...</span>
                </div>
            )}
        </Card>
    );
};

export default ChatComponent;