import {Col, DatePicker, Row, Select, Switch} from "antd";
import * as dayjs from 'dayjs';
import {useEffect, useState} from "react";
import {getLatestPerformanceDate, getLatestPerformanceTime} from "../../../api/data/PortfolioDataProvider";

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import './css/navscreen.css';
import ExposureTable from './ExposureTable';
import PnlTable from './PnlTable';
import SpreadChart from "./SpreadChart";
import PositionTable from "./PositionTable";
import WashsaleTable from "./WashsaleTable";
import AccountSummaryTable from "./AccountSummaryTable";

let utc = require('dayjs/plugin/utc');
let timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
let localizedFormat = require("dayjs/plugin/localizedFormat");

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat)

const accountGroupMap = new Map([
    ['master', {name: 'Master', accountIds: []}],
    ['total_shah', {name: 'Total Shah', accountIds: ['U10516305', 'U10525656', 'U2319809', 'U615280']}],
    ['shah_v2', {name: 'Shah V2', accountIds: ['U2319809', 'U615280']}],
    ['trust', {name: 'Trust', accountIds: ['U10516305', 'U10525656']}],
])

const accounts = ['U2319809', 'U615280', 'U10516305', 'U10525656', 'U3812243', 'U3732752', 'U2275992', 'U4168317', 'U4366920', 'U4093727', 'U3774454'];
for (const accountId of accounts) {
    accountGroupMap.set(accountId, {
        name: accountId,
        accountIds: [accountId]
    });
}

const accountSelectorOptions = [...accountGroupMap.entries()].map(([k, v]) => {
    return {value: k, label: v.name};
});

function NavScreen() {
    const [reportDate, setReportDate] = useState('');
    const [reportTime, setReportTime] = useState('');
    const [isLiveData, setIsLiveData] = useState(true);
    const [frequency] = useState(30);
    const [selectedAccountId, setSelectedAccountId] = useState('master');

    const getLocalReportTime = () => {
        return reportTime ? dayjs(reportTime).utc().local() : null;
    }

    const onDateChange = (date, dateStr) => {
        setReportDate(dateStr);
    }

    const onLiveChange = (checked) => {
        setIsLiveData(checked);
    }

    const onAccountChange = (accountGroupId) => {
        setSelectedAccountId(accountGroupId);
    }

    const fetchReportTime = (abortController) => {
        getLatestPerformanceTime(abortController)
            .then((res) => {
                setReportTime(res.data);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    useEffect(() => {
        const abortController = new AbortController();
        let intervalId;

        if (isLiveData) {
            fetchReportTime(abortController);
            intervalId = setInterval(() => {
                fetchReportTime(abortController);
            }, frequency * 1000);
        } else if (!reportDate) {
            getLatestPerformanceDate(abortController)
                .then((res) => {
                    setReportDate(res.data);
                })
                .catch((err) => {
                    console.error(err);
                });
        }

        return () => {
            abortController.abort();
            clearInterval(intervalId);
        }
    }, [isLiveData, frequency]);

    return (
        <div id="nav-screen" style={{marginTop: 50}}>
            <Row align="middle">
                <Col flex={1}>
                    <Select options={accountSelectorOptions} defaultValue={'master'} popupMatchSelectWidth={true}
                            style={{margin: '10px', width: '150px'}} size={"large"} onChange={onAccountChange}/>

                    {!isLiveData && <DatePicker value={dayjs(reportDate)} onChange={onDateChange} size='large'
                                                style={{margin: '10px'}}/>}

                    {isLiveData && <span title={dayjs(reportTime).format('LLLL')} style={{
                        color: 'red', fontWeight: 'bold', fontSize: '20px', margin: '10px'
                    }}>{getLocalReportTime()?.format('hh:mm:ss A') || ''}</span>}

                    <Switch defaultChecked style={{margin: '10px'}} checkedChildren={"Live"}
                            unCheckedChildren={"EOD"} onChange={onLiveChange}/>
                </Col>
            </Row>
            <Row gutter={16} justify="center">
                <Col flex={1}>
                    <h4>PNL</h4>
                    <PnlTable accountGroup={accountGroupMap.get(selectedAccountId)}
                              reportDate={isLiveData ? '' : reportDate}
                              reportTime={reportTime}/>
                </Col>
                <Col flex={1}>
                    <h4>Exposure</h4>
                    <ExposureTable
                        accountIdMap={new Map(Array.from(accountGroupMap).filter(([key]) => key === selectedAccountId))}
                        reportDate={isLiveData ? '' : reportDate}
                        reportTime={reportTime}/>
                </Col>
                <Col flex={1}>
                    <h4>Beta Adjusted Exposure</h4>
                    <ExposureTable
                        accountIdMap={new Map(Array.from(accountGroupMap).filter(([key]) => key === selectedAccountId))}
                        reportDate={isLiveData ? '' : reportDate}
                        reportTime={reportTime}
                        betaAdjusted={true}/>
                </Col>
            </Row>
            <hr/>
            <Row gutter={16} justify="center">
                <Col flex={1}>
                    <h4>Position List</h4>
                    <PositionTable accountGroup={accountGroupMap.get(selectedAccountId)}
                                   reportDate={isLiveData ? '' : reportDate}
                                   reportTime={reportTime}/>
                </Col>
                <Col flex={1}>
                    {isLiveData && <><h4>Account Summary</h4>
                        <AccountSummaryTable accountGroup={accountGroupMap.get(selectedAccountId)}
                                             reportTime={reportTime}/></>}
                    <h4>Washsale Info</h4>
                    <WashsaleTable accountGroup={accountGroupMap.get(selectedAccountId)}
                                   reportDate={isLiveData ? '' : reportDate}/>
                </Col>
            </Row>
            <hr/>
            <Row gutter={16} justify="center">
                <Col span={24}>
                    <SpreadChart accountGroup={accountGroupMap.get(selectedAccountId)}/>
                </Col>
            </Row>
        </div>
    )
}

export default NavScreen;