// helper function to generate column defintion
const generateColumnDefinition = (headerName, field) => {
    return {
        headerName: headerName,
        field: field,
        cellStyle: colorCellStyle,
        filter: true,
        width: 100,
        sortable: true,
        cellRenderer: numberRenderer,
    };
};

function percentageCellRendererFunction(numberOfDecimals) {
    return (params) => {
        if (params.value != null) {
            let number = params.value;
            if (number >= 0) {
                return <span style={{ color: 'green' }}>{(number * 100).toFixed(numberOfDecimals)}%&nbsp;</span>;
            } else {
                return <span style={{ color: 'red' }}>({(Math.abs(number) * 100).toFixed(numberOfDecimals)}%)</span>;
            }
        } else {
            return <></>;
        }
    }
}

function nonNullComparator(a, b, nodeA, nodeB, isInverted) {
    if (a === b) {
        return 0;
    }
    // for null
    else if (a == null) {
        return isInverted ? -1 : 1;
    }
    else if (b == null) {
        return isInverted ? 1 : -1;
    }
    else {
        return (a > b) ? 1 : -1;
    }

};

const returnsColumnDef = (headerName, field, headerTooltip) => {
    return {
        headerName: headerName,
        field: field,
        cellRenderer: percentageCellRendererFunction(1),
        cellStyle: { verticalAlign: "middle", textAlign: 'right' },
        headerClass: "ag-right-aligned-header",
        width: 65,
        sortable: true,
        comparator: nonNullComparator,
        headerTooltip: headerTooltip,
    };
};

export const transformationMap = new Map([
    ['stockReturn',
        [
            ['stockReturn.1d', 'stockReturn.1d'],
            ['stockReturn.3d', 'stockReturn.3d'],
            ['stockReturn.5d', 'stockReturn.5d'],
            ['idioReturn.1d', 'idioReturn.1d'],
            ['idioReturn.3d', 'idioReturn.3d'],
            ['idioReturn.5d', 'idioReturn.5d'],
        ]
    ],
])

export const columnDefs = [
    {
        headerName: "Ticker",
        field: "ticker",
        filter: true,
        width: 140,
        sortable: true,
    },
    {
        headerName: 'Idio Return',
        headerClass: 'center-aligned-header',
        children: [
            returnsColumnDef('1d% ', 'idioReturn.1d'),
            returnsColumnDef('3d% ', 'idioReturn.3d'),
            returnsColumnDef('5d% ', 'idioReturn.5d'),
        ],
    },
    {
        headerName: 'Stock Return',
        headerClass: 'center-aligned-header',
        children: [
            // { headerName: '', field: '', width: 10, columnGroupShow: 'closed' },
            { ...returnsColumnDef('1d% ', 'stockReturn.1d'), columnGroupShow: 'open' },
            { ...returnsColumnDef('3d% ', 'stockReturn.3d'), columnGroupShow: 'open' },
            { ...returnsColumnDef('5d% ', 'stockReturn.5d'), columnGroupShow: 'open' },
        ],
    },
    {
        headerName: "Net Liq. (bps)",
        field: "netLiq",
        cellStyle: netLiqcolorCellStyle,
        cellRenderer: netLiqRenderer,
        filter: true,
        width: 150,
        sortable: true,
    },
    generateColumnDefinition("Growth", "growth"),
    generateColumnDefinition("Volatility", "volatility"),
    generateColumnDefinition("Value", "value"),
    generateColumnDefinition("Short Interest", "si"),
    generateColumnDefinition("Momentum", "momentum"),
    generateColumnDefinition("Size", "size"),
    generateColumnDefinition("Leverage", "leverage"),
    generateColumnDefinition("Earn Yield", "earnYield"),
    generateColumnDefinition("Div Yield", "divYield"),
    generateColumnDefinition("Profitability", "profitability"),
    generateColumnDefinition("Volume", "volume"),
];

function netLiqRenderer(cellParams) {
    const valFixed = (Math.abs(cellParams.value) * 100).toFixed(0);
    return cellParams.value < 0 ? `(${valFixed})` : valFixed;
}

function netLiqcolorCellStyle(cellParams) {
    const cellValue = cellParams.value;
    const color =
        cellValue >= 0 ? `#09801e` : `#da0a0a`;
    return { "vertical-align": "middle", "text-align": "center", color: color };
}

function numberRenderer(cellParams) {
    const valFixed = (Math.abs(cellParams.value) * 1000).toFixed(2);
    return cellParams.value < 0 ? `(${valFixed})` : valFixed;
}

function colorCellStyle(cellParams) {
    const cellValue = cellParams.value;
    const normalizedCellValue = (cellValue + 1) / 2;
    const intensity = Math.round(normalizedCellValue * 255);
    const color =
        cellValue >= 0 ? `rgb(0, ${intensity}, 0)` : `rgb(${intensity}, 0, 0)`;
    return { "vertical-align": "middle", "text-align": "center", color: color };
}