import {createContext, useContext, useEffect, useState} from "react";
import {getTickerById} from "../../../api/data/DataProvider";
import {RefreshContext} from "./RefreshContextProvider";

export const TickerContext = createContext();

export const TickerContextProvider = ({children, activeTick}) => {
    const {refresh} = useContext(RefreshContext);

    const [tickerInfo, setTickerInfo] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        if (activeTick.id !== null) {
            // First set ticker info to null, then fetch Ticker object
            setTickerInfo(null);
            getTickerById(activeTick.id, abortController)
                .then((res) => {
                    setTickerInfo(res.data);
                }).catch((err) => {
                if (err.code === "ERR_CANCELED") {
                    // do nothing for now
                } else {
                    console.error("Failed to fetch ticker info", err);
                    setTickerInfo(null);
                }
            })
        }

        return () => abortController.abort();
    }, [activeTick, refresh])

    return <TickerContext.Provider value={{
        tickerInfo
    }}>
        {children}
    </TickerContext.Provider>
}