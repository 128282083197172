import {CAlert, CTable} from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css'
import {forwardRef, useEffect, useState} from "react";
import {getStockGrades} from "../../api/data/DataProvider";
const StockGradesTable = forwardRef((props, ref)  => {
    const columns = [
        {
            key: 'date',
            label: 'Grading Date',
            _props: { scope: 'col' },
        },
        {
            key: 'grading_company',
            label: 'Company',
            _props: { scope: 'col' },
        },
        {
            key: 'previous_grade',
            label: 'Previous Grade',
            _props: { scope: 'col' },
        },
        {
            key: 'new_grade',
            label: 'New Grade',
            _props: { scope: 'col' },
        }
    ];

    const [stockGrades, setStockGrades] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getStockGrades(props.activeTicker)
            .then((res) => {
                let stockGradingInfo = res.data.map(grade => {
                    return {
                        date: grade.date,
                        grading_company: grade.gradingCompany,
                        previous_grade: grade.previousGrade,
                        new_grade: grade.newGrade
                    }
                });

                setStockGrades(stockGradingInfo);
                setIsLoading(false);
            }).catch((err) => {
                console.error("Failed to load stock grades", err);
        })
    }, [props.activeTicker])

    return(<center>
        <div style={{width: "90%", marginTop: 60}} ref={isLoading ? null: ref}>
            <h4>Company Stock Grades</h4>
            <CTable striped columns={columns} items={stockGrades} />
        </div></center>
    )
})

export default StockGradesTable;