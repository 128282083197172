import {useEffect, useState} from "react";
import {getExtraWeekIndicator, saveLastExtraWeek} from "../../../api/data/DataProvider";
import {Button, DatePicker} from "antd";
import dayjs from "dayjs";

let utc = require('dayjs/plugin/utc');
let timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

export const ExtraWeekIndicator = ({activeTick}) => {

    const [lastExtraWeek, setLastExtraWeek] = useState({});

    useEffect(() => {
        getExtraWeekIndicator(activeTick?.name)
            .then((res) => {
                setLastExtraWeek(res?.data);
            })
            .catch((err) => {
                console.error("Failed to get indicator data: {}", err);
                setLastExtraWeek({});
            })
    }, [activeTick]);

    const saveLastExtraWeekOnChange = (date) => {
        let saveDateString = '';
        if (date) {
            saveDateString = date.date(1).utc(true).toISOString().substring(0, 10);
        }
        console.log("saving extra week datepicker values=", saveDateString);
        saveLastExtraWeek(activeTick?.name, saveDateString)
            .then((res) => {
                setLastExtraWeek(res?.data);
            })
            .catch((err) => {
                console.error("Failed to get indicator data: {}", err);
                setLastExtraWeek({});
            })
    }

    const getLastWeekUpdateHover = () => {
        let lastUpdatedTimeStr = lastExtraWeek?.lastUpdatedTs;
        if (lastUpdatedTimeStr) {
            return `Info last updated on ${dayjs(lastExtraWeek?.lastUpdatedTs)}`;
        } else {
            return 'Unknown last extra week';
        }
    }

    return (<div style={{
            float: "left", height: "40px", display: "flex", width: "57px", alignItems: "center",
            justifyContent: "center", paddingTop: "5px"
        }} title={getLastWeekUpdateHover()}>
            {lastExtraWeek &&
                <DatePicker picker={"month"} onChange={saveLastExtraWeekOnChange} size={"small"} format="MM/YY"
                            needConfirm allowClear={false} suffixIcon={() => (<></>)}
                            value={lastExtraWeek?.lastExtraWeek ? dayjs(lastExtraWeek?.lastExtraWeek) : undefined}
                            placeholder={lastExtraWeek?.lastUpdatedTs && !lastExtraWeek?.lastExtraWeek ? "None" : ""}
                            renderExtraFooter={() => {
                                let disabledStatus = lastExtraWeek?.lastUpdatedTs && !lastExtraWeek?.lastExtraWeek;
                                return (
                                    <Button type={"primary"} danger
                                            onClick={() => saveLastExtraWeekOnChange(null)}
                                            disabled={disabledStatus}
                                    >Set no Extra Week</Button>
                                );
                            }}
                />}
        </div>
    );
}