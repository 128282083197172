import {DashboardQueryTypes} from "./DashboardQueryTypes";
import WeeklyCharts from "../../components/line_chart/WeeklyCharts";
import QuarterCharts from "../../components/line_chart/QuarterCharts";
import IdioChart from "../../components/line_chart/IdioChart";
import StackCharts from "../../components/line_chart/StackCharts";
import DailyCharts from "../../components/line_chart/DailyCharts";

export const QueryTypeToChartMapping = {
    [DashboardQueryTypes.WEEKLY_YOY]: <WeeklyCharts/>,
    [DashboardQueryTypes.WEEKLY_YOY2]: <WeeklyCharts/>,
    [DashboardQueryTypes.WEEKLY_YOY3]: <WeeklyCharts/>,
    [DashboardQueryTypes.WEEKLY_YOY4]: <WeeklyCharts/>,
    [DashboardQueryTypes.WEEKLY_YOY5]: <WeeklyCharts/>,
    [DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_INDEX]: <WeeklyCharts/>,
    [DashboardQueryTypes.WEEKLY_PROFIT_YOY_LAG0]: <WeeklyCharts/>,
    [DashboardQueryTypes.WEEKLY_PRICING_YOY_LAG0]: <WeeklyCharts/>,
    [DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY_LAG0]: <WeeklyCharts/>,
    [DashboardQueryTypes.WEEKLY_PROFIT_YOY2_LAG0]: <WeeklyCharts/>,
    [DashboardQueryTypes.WEEKLY_PRICING_YOY2_LAG0]: <WeeklyCharts/>,
    [DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY2_LAG0]: <WeeklyCharts/>,
    [DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_GP_INDEX]: <WeeklyCharts/>,
    [DashboardQueryTypes.WEEKLY_LEVELS]: <WeeklyCharts/>,
    [DashboardQueryTypes.DAILY_YOY]: <DailyCharts />,
    [DashboardQueryTypes.QTR_YOY_LAG0]: <QuarterCharts/>,
    [DashboardQueryTypes.PROFIT_YOY_LAG0]: <QuarterCharts/>,
    [DashboardQueryTypes.PROFIT_YOY_LAG1]: <QuarterCharts/>,
    [DashboardQueryTypes.PROFIT_YOY_LAG2]: <QuarterCharts/>,
    [DashboardQueryTypes.PRICING_YOY_LAG0]: <QuarterCharts/>,
    [DashboardQueryTypes.PRICING_YOY_LAG1]: <QuarterCharts/>,
    [DashboardQueryTypes.PRICING_YOY_LAG2]: <QuarterCharts/>,
    [DashboardQueryTypes.PROFIT_YOY2_LAG0]: <QuarterCharts/>,
    [DashboardQueryTypes.PROFIT_YOY2_LAG1]: <QuarterCharts/>,
    [DashboardQueryTypes.PROFIT_YOY2_LAG2]: <QuarterCharts/>,
    [DashboardQueryTypes.PRICING_YOY2_LAG0]: <QuarterCharts/>,
    [DashboardQueryTypes.PRICING_YOY2_LAG1]: <QuarterCharts/>,
    [DashboardQueryTypes.PRICING_YOY2_LAG2]: <QuarterCharts/>,
    [DashboardQueryTypes.QTR_YOY_LAG1]: <QuarterCharts/>,
    [DashboardQueryTypes.QTR_YOY_LAG2]: <QuarterCharts/>,
    [DashboardQueryTypes.QTR_YOY2_LAG0]: <QuarterCharts/>,
    [DashboardQueryTypes.QTR_YOY2_LAG1]: <QuarterCharts/>,
    [DashboardQueryTypes.QTR_YOY2_LAG2]: <QuarterCharts/>,
    [DashboardQueryTypes.QTR_YOY3_LAG0]: <QuarterCharts/>,
    [DashboardQueryTypes.QTR_YOY3_LAG1]: <QuarterCharts/>,
    [DashboardQueryTypes.QTR_YOY3_LAG2]: <QuarterCharts/>,
    [DashboardQueryTypes.QTR_LEVELS_LAG0]: <QuarterCharts/>,
    [DashboardQueryTypes.QTR_LEVELS_LAG1]: <QuarterCharts/>,
    [DashboardQueryTypes.QTR_LEVELS_LAG2]: <QuarterCharts/>,
    [DashboardQueryTypes.QTR_TRANSACTIONS_YOY_LAG0]: <QuarterCharts/>,
    [DashboardQueryTypes.IDIO]: <IdioChart />,
    [DashboardQueryTypes.WEEKLY_STACK_GT_WW]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_GT_US]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_SW_WW]: <StackCharts />,
    [DashboardQueryTypes.DAILY_STACK_SW_WW]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_SW_US]: <StackCharts />,
    [DashboardQueryTypes.DAILY_STACK_SW_US]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_ER_ORION_SALES]: <StackCharts />,
    [DashboardQueryTypes.DAILY_STACK_ER_ORION_SALES]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_ER_ORION_TRANSACTIONS]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_SALES]: <StackCharts />,
    [DashboardQueryTypes.DAILY_STACK_ER_VELA_VELORUM_SALES]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_TRANSACTIONS]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_SALES]: <StackCharts />,
    [DashboardQueryTypes.DAILY_STACK_ER_VELA_GAMMA_SALES]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_TRANSACTIONS]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_SM_SALES]: <StackCharts />,
    [DashboardQueryTypes.DAILY_STACK_SM_SALES]: <StackCharts />,
    [DashboardQueryTypes.DAILY_STACK_CC_BASELINE]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_TOTAL_BASELINE]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_US]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_US]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_US]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_WW]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_WW]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_WW]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_US]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_US]: <StackCharts />,
    [DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_US]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_WW]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_WW]: <StackCharts />,
    [DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_WW]: <StackCharts />,
    [DashboardQueryTypes.WEEKLY_STACK_GT_TOP_REGIONS_GRID]: <StackCharts />,
}

const QUERY_LIST_TO_RANGE_SELECCTOR_MAP = {
    [DashboardQueryTypes.WEEKLY_YOY]: null,
    [DashboardQueryTypes.WEEKLY_YOY2]: null,
    [DashboardQueryTypes.WEEKLY_YOY3]: null,
    [DashboardQueryTypes.WEEKLY_YOY4]: null,
    [DashboardQueryTypes.WEEKLY_YOY5]: null,
    [DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_INDEX]: null,
    [DashboardQueryTypes.WEEKLY_PROFIT_YOY_LAG0]: null,
    [DashboardQueryTypes.WEEKLY_PRICING_YOY_LAG0]: null,
    [DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY_LAG0]: null,
    [DashboardQueryTypes.WEEKLY_PROFIT_YOY2_LAG0]: null,
    [DashboardQueryTypes.WEEKLY_PRICING_YOY2_LAG0]: null,
    [DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY2_LAG0]: null,
    [DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_GP_INDEX]: null,
    [DashboardQueryTypes.WEEKLY_LEVELS]: null,
    [DashboardQueryTypes.DAILY_YOY]: 1,
    [DashboardQueryTypes.QTR_YOY_LAG0]: 5,
    [DashboardQueryTypes.PROFIT_YOY_LAG0]: 5,
    [DashboardQueryTypes.PROFIT_YOY_LAG1]: 5,
    [DashboardQueryTypes.PROFIT_YOY_LAG2]: 5,
    [DashboardQueryTypes.PRICING_YOY_LAG0]: 5,
    [DashboardQueryTypes.PRICING_YOY_LAG1]: 5,
    [DashboardQueryTypes.PRICING_YOY_LAG2]: 5,
    [DashboardQueryTypes.PROFIT_YOY2_LAG0]: 5,
    [DashboardQueryTypes.PROFIT_YOY2_LAG1]: 5,
    [DashboardQueryTypes.PROFIT_YOY2_LAG2]: 5,
    [DashboardQueryTypes.PRICING_YOY2_LAG0]: 5,
    [DashboardQueryTypes.PRICING_YOY2_LAG1]: 5,
    [DashboardQueryTypes.PRICING_YOY2_LAG2]: 5,
    [DashboardQueryTypes.QTR_YOY_LAG1]: 5,
    [DashboardQueryTypes.QTR_YOY_LAG2]: 5,
    [DashboardQueryTypes.QTR_YOY2_LAG0]: 5,
    [DashboardQueryTypes.QTR_YOY2_LAG1]: 5,
    [DashboardQueryTypes.QTR_YOY2_LAG2]: 5,
    [DashboardQueryTypes.QTR_YOY3_LAG0]: 5,
    [DashboardQueryTypes.QTR_YOY3_LAG1]: 5,
    [DashboardQueryTypes.QTR_YOY3_LAG2]: 5,
    [DashboardQueryTypes.QTR_LEVELS_LAG0]: 7,
    [DashboardQueryTypes.QTR_LEVELS_LAG1]: 7,
    [DashboardQueryTypes.QTR_LEVELS_LAG2]: 7,
    [DashboardQueryTypes.QTR_TRANSACTIONS_YOY_LAG0]: 5,
    [DashboardQueryTypes.IDIO]: null,
    [DashboardQueryTypes.WEEKLY_STACK_GT_WW]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_GT_US]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_SW_WW]: 1,
    [DashboardQueryTypes.DAILY_STACK_SW_WW]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_SW_US]: 1,
    [DashboardQueryTypes.DAILY_STACK_SW_US]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_ER_ORION_SALES]: 1,
    [DashboardQueryTypes.DAILY_STACK_ER_ORION_SALES]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_ER_ORION_TRANSACTIONS]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_SALES]: 1,
    [DashboardQueryTypes.DAILY_STACK_ER_VELA_VELORUM_SALES]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_TRANSACTIONS]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_SALES]: 1,
    [DashboardQueryTypes.DAILY_STACK_ER_VELA_GAMMA_SALES]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_TRANSACTIONS]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_SM_SALES]: 1,
    [DashboardQueryTypes.DAILY_STACK_SM_SALES]: 1,
    [DashboardQueryTypes.DAILY_STACK_CC_BASELINE]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_TOTAL_BASELINE]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_US]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_US]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_US]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_WW]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_WW]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_WW]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_US]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_US]: 1,
    [DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_US]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_WW]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_WW]: 1,
    [DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_WW]: 1,
    [DashboardQueryTypes.WEEKLY_STACK_GT_TOP_REGIONS_GRID]: 1,
}


export const getChartForQueryType = (queryType) => {
    return QueryTypeToChartMapping[queryType] ?? <QuarterCharts />;
}

export const getYearsToShowForQueryType = (queryType) => {
    return QUERY_LIST_TO_RANGE_SELECCTOR_MAP[queryType] ?? 5;
}