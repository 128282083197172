import {useCallback, useEffect, useRef} from "react";
import {useGridFilter} from "@ag-grid-community/react";
import {monitor} from "../../../event_handler/KeyPressMonitor";

export const CustomFilter = ({model, onModelChange, getValue}) => {
    const refInput = useRef(null);

    useEffect(() => {
        const handleFocus = () => {
            monitor.enableHotKeys(false);
        };

        const handleBlur = () => {
            monitor.enableHotKeys(true);
        };

        const filterElement = refInput.current;

        filterElement.addEventListener('focus', handleFocus);
        filterElement.addEventListener('blur', handleBlur);

        return () => {
            filterElement.removeEventListener('focus', handleFocus);
            filterElement.removeEventListener('blur', handleBlur);
        };
    }, [])

    const doesFilterPass = useCallback((params) => {
        const { node } = params;
        const filterText = model;
        const value = getValue(node).toString().toLowerCase();

        return value.includes(filterText.toLowerCase());

    }, [model] );

    // register filter handlers with the grid
    useGridFilter({
        doesFilterPass
    });

    return (
        <input
            type="text"
            ref={refInput}
            onChange={({ target: { value } }) => onModelChange(value === '' ? null : value)}
            value={model || ''}
        />
    )

}