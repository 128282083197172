import {useEffect, useRef, useState} from "react";
import {Select} from 'antd';
import {BRAND_NAME} from "../DashboardReport/DashboardConstants";
import {monitor} from "../../../event_handler/KeyPressMonitor";
import {getBrands} from "../../../api/data/DataProvider";

// Brand Dropdown
export function BrandDropdown(props) {
    const defaultBrandOption = {
        name: "Select Brand",
        id: null
    }

    let [brandOptions, setBrandOptions] = useState([defaultBrandOption]);
    let[brandLoading, setBrandLoading] = useState(false);
    const brandSelectRef = useRef(null);

    const popoutNewTab = (name, id) => {
        const params = new URLSearchParams(window.location.search);
        params.set(BRAND_NAME, name);
        const newUrl = window.location.pathname + '?' + params.toString();
        window.open(newUrl, '_blank');
    }

    const _onBrandSelect = (value, element) => {
        if(element.value !== null && (props.popout != null && props.popout !== false)  ) {
            popoutNewTab(element.label, element.value);
            return;
        }
        monitor.enableHotKeys(true);
        setBrandLoading(true);
        props.setActiveBrand({
            name: element.label,
            id: element.value
        })

        if (brandSelectRef.current) {
            brandSelectRef.current.blur();
        }
        setTimeout(() => {
            setBrandLoading(false);
        }, 3000)
    }

    useEffect(() => {
       /* props.setActiveBrand({
            name: "Select Brand",
            id: null
        });*/
        if (props.activeTick.id!=null) {
            getBrands(props.activeTick.id).then((res) => {
                let selectedTickBrands = res.data.map(brandResponse => {
                    return {
                        value: brandResponse.brandId,
                        label: brandResponse.brandName
                    }
                });
                selectedTickBrands.push({
                    label: defaultBrandOption.name,
                    value: defaultBrandOption.id
                })
                setBrandOptions(selectedTickBrands)
            }).catch((err) => {
                // Ignore error
            })
        }
    }, [props.activeTick])

    const _filter = (inputValue, option) => {
        return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
    }

    const _sort = (optionA, optionB) => {
        if(optionA.label === "Select Brand"){
            return 1;
        } else if (optionB.label === "Select Brand"){
            return -1;
        }

        return optionA.label.length - optionB.label.length;
    }

    return (
        <center>
            <Select
                ref={brandSelectRef}
                style={{width: 150, marginLeft: 10}}
                showSearch
                onFocus={() => monitor.enableHotKeys(false)}
                onBlur={() => monitor.enableHotKeys(true)}
                loading={brandLoading}
                defaultValue={{
                    label: defaultBrandOption.name,
                    value: defaultBrandOption.id
                }}
                value={{
                    label: props.activeBrand.name,
                    value: props.activeBrand.id
                }}
                filterOption = {_filter}
                filterSort = {_sort}
                onChange={_onBrandSelect}
                optionFilterProp={"label"}
                options={brandOptions} />
        </center>
    );
}