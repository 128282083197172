import React, {useContext} from 'react';
import FinMatrix from './FinMatrix';
import "./findata.css";
import {FinancialMatricesContext} from "../oracle/findata/FinancialMatricesDataProvider";

const FinMatrixGrid = () => {

    const {fmDS1,
        fmDS2,
        fmDS3,
        fmDS4,
        lineItems,
        finMatrixSeriesType1, setFinMatrixSeriesType1,
        finMatrixSeriesType2, setFinMatrixSeriesType2,
        finMatrixSeriesType3, setFinMatrixSeriesType3,
        finMatrixSeriesType4, setFinMatrixSeriesType4 } = useContext(FinancialMatricesContext);

    return (
        <div className="fin-matrix-grid">
            <FinMatrix lineItems={lineItems} dataState={fmDS1} seriesOption={finMatrixSeriesType1} onSeriesOptionChange={(value) => {
                setFinMatrixSeriesType1(value);
            }} />
            <FinMatrix lineItems={lineItems} dataState={fmDS2} seriesOption={finMatrixSeriesType2} onSeriesOptionChange={(value) => {
                setFinMatrixSeriesType2(value);
            }} />
            <FinMatrix lineItems={lineItems} dataState={fmDS3} seriesOption={finMatrixSeriesType3} onSeriesOptionChange={(value) => {
                setFinMatrixSeriesType3(value);
            }} />
            <FinMatrix lineItems={lineItems} dataState={fmDS4} seriesOption={finMatrixSeriesType4} onSeriesOptionChange={(value) => {
                setFinMatrixSeriesType4(value);
            }} />
        </div>
    );
};

export default FinMatrixGrid;