//Todo: Move this to a dedicated file with descriptive comments:/**/
import {cloneElement, memo, useContext, useEffect, useMemo, useRef, useState} from "react";
import {DashboardApiContextStore} from "../DashboardReportContextProvider";
import {
    COMPACT_VIEW_QUERY_LIST,
    DailyStackAlignmentDependentCharts,
    DashboardQueryTypes
} from "../../../../api/data/DashboardQueryTypes";
import _ from "lodash";
import {GridModalContext} from "../../../studio_high_chart/contextsForCharts/ChartGridModalContextProvider";
import {ViewContextStore} from "./ViewContext";


const shouldSkipForModalState = (prevProps, newProps) => {
    const compactViewQueryIndex = _.indexOf(COMPACT_VIEW_QUERY_LIST, newProps.queryType);
    // query un-impacted by this restaurant. No point in caring.
    if(compactViewQueryIndex === -1) return true;
    return !(compactViewQueryIndex === newProps?.modal3x3State?.index); // State changes here are only relevant if they directly hit our component
}

export const useCompactViewModalState = ({ queryType, modal3x3State, toggle3x3Modal }) => {

    const compactViewQueryIndex = useMemo(() => _.indexOf(COMPACT_VIEW_QUERY_LIST, queryType), [queryType]);

    const [state, setState] = useState({
        compactViewModalState: compactViewQueryIndex !== -1
            ? (compactViewQueryIndex === modal3x3State.index) && modal3x3State.open
            : false,
        compactViewToggleModal: compactViewQueryIndex !== -1 ? () => toggle3x3Modal(compactViewQueryIndex) : () => {}
    });

    const prevStateRef = useRef({ queryType, modal3x3State });

    useEffect(() => {
        const prevState = prevStateRef.current;
        const newState = { queryType, modal3x3State };

        if (!shouldSkipForModalState(prevState, newState)) {


            const compactViewModalState = compactViewQueryIndex !== -1
                ? (compactViewQueryIndex === modal3x3State.index) && modal3x3State.open
                : false;

            const compactViewToggleModal = compactViewQueryIndex !== -1 ? () => toggle3x3Modal(compactViewQueryIndex) : () => {};

            setState({
                compactViewModalState,
                compactViewToggleModal
            });

            // Update the previous state reference
            prevStateRef.current = newState;
        }
    }, [queryType, modal3x3State, toggle3x3Modal]);

    return state;
};

const shouldSkipForViewStateData = (prevProps, newProps) => {
    const queryType = newProps.queryType;

    // Check for functions being equal:
    if (prevProps?.modifyActiveSeries !== newProps?.modifyActiveSeries) return false;
    if (prevProps?.updateChartRange !== newProps?.updateChartRange) return false;
    if (prevProps?.setCombineAxes !== newProps?.setCombineAxes) return false;
    if (prevProps?.setDynamicLag !== newProps?.setDynamicLag) return false;
    if (prevProps?.addToMapOfChartRefs !== newProps?.addToMapOfChartRefs) return false;
    if (prevProps?.removeFromMapOfChartRefs !== newProps?.removeFromMapOfChartRefs) return false;

    // Variable where shallow compare works:
    if (prevProps?.dropLastPoint !== newProps?.dropLastPoint) return false;

    // Deep comparison for a map of object refs:
    if (newProps?.mapOfChartRefs[queryType] !== prevProps?.mapOfChartRefs[queryType]) return false;

    // Full viewState comparisons:
    const oldViewState = prevProps?.viewState?.[queryType];
    const newViewState = newProps?.viewState?.[queryType];

    return oldViewState === newViewState;


}


export const useChartViewState = ({
                                      modifyActiveSeries,
                                      updateChartRange,
                                      setCombineAxes,
                                      setDynamicLag,
                                      addToMapOfChartRefs,
                                      removeFromMapOfChartRefs,
                                      dropLastPoint,
                                      mapOfChartRefs,
                                      viewState,
                                      queryType
                                  }) => {
    const [state, setState] = useState({
        viewData: viewState?.[queryType],
        modifyActiveSeries: (seriesName) => modifyActiveSeries(queryType, seriesName),
        updateChartRange: (extremities) => updateChartRange(queryType, extremities),
        setCombineAxes: (combineAxes) => setCombineAxes(queryType, combineAxes),
        setDynamicLag: (dynLag) => setDynamicLag(queryType, dynLag),
        dropLastPoint,
        addToMapOfChartRefs: (chartRef) => addToMapOfChartRefs(chartRef, queryType),
        removeFromMapOfChartRefs: (chartRef) => removeFromMapOfChartRefs(chartRef, queryType),
        mapOfChartRefs
    });

    const prevPropsRef = useRef({
        modifyActiveSeries,
        updateChartRange,
        setCombineAxes,
        setDynamicLag,
        addToMapOfChartRefs,
        removeFromMapOfChartRefs,
        dropLastPoint,
        mapOfChartRefs,
        viewState,
        queryType
    });

    useEffect(() => {
        const prevProps = prevPropsRef.current;
        const newProps = {
            modifyActiveSeries,
            updateChartRange,
            setCombineAxes,
            setDynamicLag,
            addToMapOfChartRefs,
            removeFromMapOfChartRefs,
            dropLastPoint,
            mapOfChartRefs,
            viewState,
            queryType
        };

        if (!shouldSkipForViewStateData(prevProps, newProps)) {
            setState({
                viewData: viewState?.[queryType],
                modifyActiveSeries: (seriesName) => modifyActiveSeries(queryType, seriesName),
                updateChartRange: (extremities) => updateChartRange(queryType, extremities),
                setCombineAxes: (combineAxes) => setCombineAxes(queryType, combineAxes),
                setDynamicLag: (dynLag) => setDynamicLag(queryType, dynLag),
                dropLastPoint,
                addToMapOfChartRefs: (chartRef) => addToMapOfChartRefs(chartRef, queryType),
                removeFromMapOfChartRefs: (chartRef) => removeFromMapOfChartRefs(chartRef, queryType),
                mapOfChartRefs
            });

            // Update the previous props reference
            prevPropsRef.current = newProps;
        }
    }, [
        modifyActiveSeries,
        updateChartRange,
        setCombineAxes,
        setDynamicLag,
        addToMapOfChartRefs,
        removeFromMapOfChartRefs,
        dropLastPoint,
        mapOfChartRefs,
        viewState,
        queryType
    ]);

    return state;
};

function arePropsEqual(obj1, obj2) {
    for (const key of Object.keys(obj2)) {
            if (obj1[key] !== obj2[key]) {

                return false;
            }

    }
    return true;
}


const MemoizablePassthroughComponent = memo((props) => {
    const chartToggleConfig = {
        ...props.chartToggleConfigInternal,
        compactViewModalState: props.compactViewModalState,
        compactViewToggleModal: props.compactViewToggleModal,
    }

    return (cloneElement(props.RawComponent, {
        ...props,
        chartToggleConfig,
        ...props.RawComponent.props //Todo: Understand the flow without this.
    }));
}, arePropsEqual);


export const SplitContextsConsumerWrapper = (props) => {
    const completeViewContextData = useContext(ViewContextStore);
    const { dataState } = props.dataStoreContext == null ? useContext(DashboardApiContextStore) : useContext(props.dataStoreContext);

    // Compute queryType based on context and props
    const queryType = useMemo(() => {
        return completeViewContextData.isDailyStackAlignment ?
            (DailyStackAlignmentDependentCharts.includes(props.queryType) ? `${props.queryType}_ALIGNED` : props.queryType) :
            props.queryType;
    }, [completeViewContextData.isDailyStackAlignment, props.queryType]);

    const splitData = dataState[queryType];
    const chartData = splitData?.chartData;
    const generalDataStore = useContext(DashboardApiContextStore);
    const idioData = generalDataStore?.dataState[DashboardQueryTypes.IDIO]?.chartData?.data;

    const { modal3x3State, toggle3x3Modal } = useContext(GridModalContext);

    // Use custom hook for managing compact view modal state
    const { compactViewModalState, compactViewToggleModal } = useCompactViewModalState({ queryType, modal3x3State, toggle3x3Modal });

    // Use custom hook for managing chart view state
    const chartViewState = useChartViewState({
        modifyActiveSeries: completeViewContextData?.modifyActiveSeries,
        updateChartRange: completeViewContextData?.updateChartRange,
        setCombineAxes: completeViewContextData?.setCombineAxes,
        setDynamicLag: completeViewContextData?.setDynamicLag,
        addToMapOfChartRefs: props.addToMapOfChartRefs == null ? completeViewContextData?.addToMapOfChartRefs : props.addToMapOfChartRefs,
        removeFromMapOfChartRefs: props.removeFromMapOfChartRefs == null ? completeViewContextData?.removeFromMapOfChartRefs : props.removeFromMapOfChartRefs,
        dropLastPoint: completeViewContextData?.dropLastPoint,
        mapOfChartRefs: props.mapOfChartRefs == null ?  completeViewContextData?.mapOfChartRefs : props.mapOfChartRefs,
        viewState: completeViewContextData?.viewState,
        queryType
    });

    const propsToBePassed = {
        chartData,
        loading: splitData?.loading,
        isError: splitData?.isError,
        chartToggleConfigInternal: chartViewState,
        compactViewModalState,
        compactViewToggleModal,
        idioData,
        pairedChart: props.pairedChart,
        ...props
    }

    return <MemoizablePassthroughComponent {...propsToBePassed} />;
};