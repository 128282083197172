import { Alert } from 'antd';
import {useContext} from "react";
import {DashboardApiContextStore} from "./DashboardReport/DashboardReportContextProvider";

function IndexDetails({ tickerName}) {
    const competitorList = useContext(DashboardApiContextStore)?.competitorList;
    let competitorMsg = competitorList?.peerIds?.filter(t => t.tick.toLowerCase() !== tickerName.toLowerCase())
        .map((t, i, arr) =>
                <span>
            <a href={`/?tickerName=${t.tick}`} target={"_blank"}>{t.tick}</a>
                    {/* Add comma after each ticker name except the last one*/}
                    {i !== arr.length - 1 && ', '}
        </span>
        );
    let message = `Competitors : ${competitorMsg}`
    return (
        <>
            {message && <Alert message={<span>Competitors : {competitorMsg}</span>} type="info"/>}
            <Alert message={`CC Index : Orion 37.5%, Vela Gamma 20%, Vela Velorum 22.5%, Second Measure 20%`} type="info" />
            <Alert message={`Oracle Index : Gtrends 30%, SimilarWeb 10%, Orion 22.5%, Vela Gamma 12%, Vela Velorum 13.5%, Second Measure 12%`} type="info" />
        </>
    );

};

export default IndexDetails;