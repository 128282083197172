import { Layout, Menu, Space, Switch, Tooltip } from "antd";
import { Link } from "react-router-dom";
const { Header, Content } = Layout;

function PortfolioNavBar(props) {
    return (
        <>
            <Layout>
                <Header style={{ height: 45, position: "fixed", padding: '0 10px', width: "100%", zIndex: 1000 }}>
                    <div className="logo" style={{ float: "left", marginTop: -10, color: "#ffffff" }}>
                        <img style={{ height: 25, marginRight: 20 }} src={"/portfolio.png"} />
                    </div>
                    <Menu selectable={false} mode={"horizontal"} theme={"dark"} style={{ height: 45, alignItems: "center", overflow: "hidden" }}>
                        <Menu.Item key="1" onClick={() => props.setSelectedScreen('NAV')}>NAV</Menu.Item>
                        <Menu.Item key="2" onClick={() => props.setSelectedScreen('POSITIONS')}>Positions</Menu.Item>
                        <Menu.Item key="4" onClick={() => props.setSelectedScreen('DASHBOARD')}>Dashboard</Menu.Item>
                        <Menu.Item key="5" onClick={() => props.setSelectedScreen('FACTORS')}>Factors</Menu.Item>
                        <Menu.Item key="8" onClick={() => props.setSelectedScreen('INTRADAY')}>Intraday</Menu.Item>
                        <Menu.Item key="3"><Link target={"_blank"} style={{ textDecoration: "none" }} to={"/"}>Oracle</Link></Menu.Item>
                       <Menu.Item key="6"><Link target={"_blank"} style={{ textDecoration: "none" }} to={"/linkedin"}>Linkedin</Link></Menu.Item>
                    </Menu>
                </Header>
            </Layout>
        </>
    )
}

export default PortfolioNavBar;