import {
    dollarCellRendererFunction,
    numberValueFormatter,
    percentageCellRendererFunction
} from "../../../../utils/StudioAgGridUtils";

export const columnDefs = [
    {
        headerName: "Asset Type",
        field: "assetType",
        type: 'rightAligned',
        valueFormatter: (params) => {
            return params.value === "COMBINED" ? "" : params.value
        },
        width: 40,
        filter: true,
        sortable: true,
    },
    {
        headerName: '% of Net Liq',
        field: 'ratioOfNav',
        type: 'rightAligned',
        cellRenderer: percentageCellRendererFunction(1),
        width: 100,
        filter: true,
        sortable: true,
        sort: 'desc'
    },
    {
        headerName: 'Quantity',
        field: 'position',
        type: 'rightAligned',
        valueFormatter: numberValueFormatter(0),
        width: 100,
        filter: true,
        sortable: true
    },
    {
        headerName: 'Mkt Value',
        field: 'currentMarketValue',
        type: 'rightAligned',
        width: 140,
        cellRenderer: dollarCellRendererFunction(),
        filter: true,
        sortable: true,
        aggFunc: "sum",
    },
    {
        headerName: 'Unrealized PnL',
        field: 'unrealizedPnl',
        type: 'rightAligned',
        cellRenderer: dollarCellRendererFunction(),
        width: 140,
        sortable: true,
        aggFunc: "sum",
    },
    {
        headerName: 'Beta',
        field: 'beta',
        type: 'rightAligned',
        valueFormatter: numberValueFormatter(2),
        width: 60,
        filter: false,
        sortable: true,
    },
    {
        headerName: 'Beta Adj $',
        field: 'betaAdjustedDeltaDollars',
        type: 'rightAligned',
        width: 140,
        cellRenderer: dollarCellRendererFunction(),
        filter: false,
        sortable: true,
        aggFunc: "sum",
    },
    {
        headerName: 'Daily PnL',
        field: 'dailyPnl',
        type: 'rightAligned',
        cellRenderer: dollarCellRendererFunction(),
        width: 140,
        sortable: true,
        aggFunc: "sum",
    },
];

export const intradayColumnDefs = [
    ...columnDefs.filter(col => !col.headerName.includes("PnL")),
];
