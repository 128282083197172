import {useContext, useEffect, useMemo, useState} from "react";
import {getSwVisitsStack} from "../../../api/data/DataProvider";
import LoadingOverlay from 'react-loading-overlay';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {CAlert} from "@coreui/react";
import {DynSWStackContext} from "./DynSWStackContext";

//Todo: Make this and AltGTStackChart to be a single common component
function DynSwStackChart(props) {

    const {dataState} = useContext(DynSWStackContext);

    const chartData = dataState[props.location]?.chartData?.data;
    const loading = dataState[props.location].loading;
    const isError = dataState[props.location].isError;

    const memoizedChartData = useMemo(() => {
        if(chartData == null) return null;
        return {
            ...chartData,
            title: {
                text: props.activeTicker.name + ' - ' + 'Visits Stack (Weekly)' + ' - ' + props.selectedUrl?.label + ' - ' + props.location
            },
            chart: {
                ...chartData.chart,
                zooming: {
                    mouseWheel: {
                        enabled: false
                    }
                },
                zoomType: 'x',
                height: 450,
                plotBackgroundColor: '#E5ECF6',
                panning: {
                    enabled: true,
                    type: 'x'
                },
                panKey: 'shift'
            },
            legend: {
                ...chartData.legend,
                itemStyle: {
                    color: "#000000"
                },
                itemHiddenStyle: {
                    textDecoration: 'none'  // Disable strikethrough effect
                }
            }
        }
    }, [chartData, props.activeTicker?.name, props.selectedUrl?.label, props.location])


    return (
        <>
            <LoadingOverlay
                active={loading && !isError}
                spinner={!isError}
                text={"Loading..."}
            >
                {isError && <div>
                    <CAlert color="danger">
                        Failed to load - {props.queryType}.
                        Data Not Configured
                    </CAlert>
                </div>}
                {chartData == null && loading && !isError && <p style={{height: "400px", width: "90%"}}> ...</p>}
                {chartData != null && !isError && chartData.series.length === 0 && <div>
                    <CAlert color="warning">
                        Data Not Available - {props.queryType}
                    </CAlert>
                </div>}
                {chartData != null && !isError && chartData.series.length !== 0 && <center><div style={{marginTop: 10, paddingLeft: 10}}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={memoizedChartData}
                    />
                </div>
                </center>}
            </LoadingOverlay>

        </>
    )
}

export default DynSwStackChart;