import _ from "lodash";
import {memo, useEffect, useMemo, useRef, useState} from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts/highstock';
import {CustomLegend} from "./CustomLegend";
import { toolTipNumberFormatter, HighChartsFormatters } from "../../utils/FinDataUtils";


const StudioHighChart = ({chartData, chartViewConfig, isMiniView, chartHeightOverride, legendWidthOverride, overrideOptions}) => {

    const {viewData = null, modifyActiveSeries = null, updateChartRange = null, reversePopulateChartConfig = null} = chartViewConfig || {};
    const {disableCombineAxis = false, useOriginalYAxis = false, seriesWithoutIdio = false } = overrideOptions || {};

    //Use chartConfig to setup the active series:
    const [activeSeries, setActiveSeries] = useState([]);
    const [chartOptions, setChartOptions] = useState(chartData);

    //Modify the activeSeries array to always have the latest list from the context.
    useEffect(() => {
        if(viewData != null && Object.keys(viewData.seriesConfig).length > 0){
            const newActiveSeries = chartData.series
                .filter(item => viewData?.seriesConfig[item.name]?.visible ?? true);
            const newActiveSeriesNames = newActiveSeries.map(item => item.name);
            const newActiveSeriesYAxis = newActiveSeries.map(item => item.yAxis);
            setActiveSeries(newActiveSeriesNames);
            setChartOptions(prevOptions => {
                const newOptions =  {
                    ...chartData,
                    series: chartData.series.map((seriesItem, index) => ({
                        ...seriesItem,
                        visible: newActiveSeriesNames.includes(seriesItem.name)
                    })),
                    yAxis: chartData.yAxis.map((axis) => ({
                        ...axis,
                        labels: {
                            formatter: function(){
                                return HighChartsFormatters[axis.labelFormatter || "DefaultFormatter"](this.value);
                            }
                        },
                        visible: newActiveSeriesYAxis.includes(axis.id)
                    }))
                };
                return newOptions;
                // return modifyOpacityOfSeries(0, newOptions, false);
             })
        } else if(chartData != null) {
            // When there is no chartConfig, we reverse populate it:
            const newSeriesConfigObject = {};
            
            chartData.series.forEach((seriesItem) => {
                newSeriesConfigObject[seriesItem.name] = {
                    visible: true,
                };
            });

            if(reversePopulateChartConfig != null) reversePopulateChartConfig(newSeriesConfigObject);
        }
    }, [viewData, JSON.stringify(viewData?.seriesConfig), JSON.stringify(chartData)]);



    const toggleSeries = (index) => {
        const seriesName = chartData.series[index].name;
        modifyActiveSeries(seriesName);
    }

    const setHoverForSeries = (index, isHovering) => {
        if(!activeSeries.includes(index)) return;
        setChartOptions((prev) => modifyOpacityOfSeries(index, prev, isHovering));
    }


    const modifyOpacityOfSeries = (index, chartOptions, highlightOneSeries) => {
        let newOptions = {
            ...chartOptions
        }
        newOptions.series = newOptions.series.map((seriesItem) => ({
            ...seriesItem,
            opacity: highlightOneSeries ? 0.1: 1
        }));
        newOptions.series[index].opacity = 1;
        // newOptions.chart.animation = false;
        return newOptions;
    }
    // Calculate the height of the charts and the widths of the legends:
    let chartHeight = isMiniView ? '450px' : '600px';
    if (chartHeightOverride !== null && chartHeightOverride !== undefined) chartHeight = chartHeightOverride;
    let legendWidth = isMiniView ? '200px' : '300px';
    if (legendWidthOverride !== null && legendWidthOverride !== undefined) legendWidth = legendWidthOverride;

    // Manage maintaining the extremities, since these will be modified within the chart:
    const extreminitiesRef = useRef({xMin: viewData?.extremities?.xMin, xMax: viewData?.extremities?.xMax});

    const disableRangeSelectorRef = useRef(false);

    const chartRef = useRef(null);
    // Adding a state to combine or uncombine axis of series on any studio highchart instance
    const rightSideTooltipPositioner = (labelWidth, labelHeight, point) => {
        const chart = chartRef.current?.chart;
        let toolTipX = point.isHeader === true ? point.plotX : point.plotX + 30;
        let toolTipY = point.plotY;
        // Ideally, we would want the tooltips to go as right as they need to go.
        // However, doing that by enabling tooltip.outside leads to floating tooltips, outside the chart area, and hence the compromise.
        if (chart) {
            // Check if tooltip goes beyond the right of the chart
            // If it does, reposition tooltip to the left of the point
            if (toolTipX + labelWidth > chart.plotWidth) {
                toolTipX = point.plotX - labelWidth;
            }
        }

        return {
            x: toolTipX,
            y: toolTipY
        };
    }

    // useEffect(() => {
    //     return () => {
    //         if(updateChartRange != null && extreminitiesRef.current != null)
    //             updateChartRange(extreminitiesRef.current);
    //     }
    // },[])

    const memoizedChartOptions = useMemo(() => {
        let newOptions = {
            ...chartOptions,
            plotOptions: {
                area: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                arearange: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                areaspline: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                areasplinerange: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                bar: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                boxplot: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                bubble: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                column: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                columnrange: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                errorbar: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                funnel: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                gauge: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                heatmap: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                line: { animation: false, enableMouseTracking: true, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                pie: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                polygon: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                pyramid: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                scatter: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                series: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                solidgauge: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                spline: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                treemap: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                waterfall: { animation: false, enableMouseTracking: false, stickyTracking: true, shadow: false, dataLabels: { style: { textShadow: false } } },
                candlestick: {
                    animation:false,
                    pointWidth: 40, // Sets the width of each candle to 10px
                    pointPadding: 0.1 // Minimal padding between points
                }
            },
            chart: {
                ...chartOptions.chart,
                height: Number(chartHeight.replace('px', '')),
                reflow: false,
                animation: false,
            },
            legend: {
                ...chartOptions.legend,
                enabled: false
            },
            xAxis: {
                ...chartOptions.xAxis,
                events: {
                    setExtremes: (e) => {
                        extreminitiesRef.current = {
                            xMin: e.min,
                            xMax: e.max,
                        };
                        disableRangeSelectorRef.current = true;
                    }
                },
                crosshair: {
                    color: 'black',
                    dashStyle: 'shortdot'
                }
            },
            navigator: {
                series: {
                    boostThreshold: 1,
                    type: 'line',
                    dataGrouping: {
                        enabled: true,
                        smoothed: true
                    }
                }
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            tooltip: {
                ...chartOptions.tooltip,
                positioner: rightSideTooltipPositioner,
                shared: true,
                shadow: false,
                animation: false,
                outside: false,
                backgroundColor: 'rgba(255,255,255,0.7)',
                hideDelay: 0,
                pointFormatter: toolTipNumberFormatter
            },
            rangeSelector: {
                ...chartOptions.rangeSelector,
                selected: (disableRangeSelectorRef.current) ? undefined: (chartOptions.rangeSelector?.selected)
            }
        };
        
        if(extreminitiesRef.current?.xMin && extreminitiesRef.current?.xMax) {
            newOptions.xAxis.min = extreminitiesRef.current.xMin;
            newOptions.xAxis.max = extreminitiesRef.current.xMax;
            newOptions.rangeSelector.selected = undefined
        }

        newOptions.series.forEach(series => {
            //this was for combine Axis
            // if (series.hasOwnProperty('originalYAxis')) {
            //     series.yAxis = series.originalYAxis; // restore original yAxis if it was saved
            // }
            if (series.name === 'idio') {
                series.visible = true; // toggle idio back to normal
            }
        });


        return newOptions;
    }, [chartOptions, chartHeight]);
    // Note: for the above, we intentionally do not add dependency on extremities, since we only want to use that information on next re-render, and we don't want change of extremities to cause a re-render.

    return (
        <div className="studioChartContainer" style={{height: chartHeight}} >
            <div className="chartContainer">
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={memoizedChartOptions}
                    ref={chartRef}
                />
            </div>
            <CustomLegend series={memoizedChartOptions.series}
                          toggleSeries={toggleSeries}
                          width={legendWidth}
                          chartHeight={chartHeight}
                          setHoverForSeries={setHoverForSeries}
            />

        </div>
    );
};

export default StudioHighChart;
