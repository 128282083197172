import {useCallback, useEffect, useState} from "react";

const DEFAULT_HEIGHT = 500;
const DEFAULT_WIDTH = 500;

const useElementMeasurements = (initialHeight = DEFAULT_HEIGHT, initialWidth = DEFAULT_WIDTH) => {

    const [dimensions, setDimensions] = useState({
        height: initialHeight,
        width: initialWidth
    });

    const [dimensionsMeasured, setDimensionsMeasured] = useState(false);

    // Allow for updating the measurements on change to the window size
    useEffect(() => {
        const unsetDimensionsMeasured = () => setDimensionsMeasured(false);
        window.addEventListener("resize", unsetDimensionsMeasured);

        return () => window.removeEventListener("resize", unsetDimensionsMeasured);
    }, []);

    const handleResize = useCallback((contentRect) => {
        if (dimensionsMeasured) return;
        setDimensionsMeasured(true);
        const { height, width } = contentRect.bounds;

        if (Math.abs(height - dimensions.height) > 1 || Math.abs(width - dimensions.width) > 1) {

            setDimensions({
                height: height || initialHeight,
                width: width || initialWidth,
            });
        }
    }, [dimensionsMeasured, dimensions.height, dimensions.width]);

    return [ dimensions, handleResize];
};

export default useElementMeasurements;