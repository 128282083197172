import {createContext, memo, useCallback, useEffect, useState} from "react";

import _ from "lodash";
import {APP, CC, GT, IDIO, IDIO_SI, INDICES, PLACER, SEGMENT, SI, SW, YIPIT} from "../../components/ShortcutsDropDown";
import {listOfListOfDashboardQueriesWithPriorities} from "../../../../api/data/DashboardQueryTypes";
import {
    appSeriesPredicate,
    ccSeriesPredicate,
    combinePredicates,
    combinePredicatesOr,
    gtSeriesPredicate,
    idioPredicate,
    idioSiPredicate,
    indexesPredicate,
    inversePredicate,
    isHurricaneSeriesWithActiveCounterpart,
    isNormalSeriesWithActiveHurricaneCounterpart,
    kpiPredicate,
    oracleIndexPredicate,
    placerPredicate,
    segmentSeriesPredicate,
    similarWebPredicate,
    siPredicate,
    weekliesButtonPredicate,
    weeklyPredicate,
    yipitPredicate
} from "./ViewContextPredicatesCollection";
import {getBoundChart, isABoundPairChart} from "./pairedChartsUtility";
import useChartRefs from "../../commonHooks/UseChartRefs";


const viewRerender = (obj1, obj2) => {
    for (const key of Object.keys(obj2)) {
        if (obj1[key] !== obj2[key]) {
            console.log(`Rendering the ViewContext for the key ${key}`);
            if(key === 'chartToggleConfigInternal'){
                const changedKeys = Object.keys(obj2[key]).filter(k => !_.isEqual(obj1[key][k], obj2[key][k]));
                console.log(`Changed keys in chartToggleConfigInternal: ${changedKeys.join(', ')}`);

            }
            return false;
        }

    }
}

/*
* chartsSetup: {
*   WEEKLY_YOY: {
*       extremities:{
*       x.min: null,
*       x.max: null
* }
*
*       seriesConfig: {
*           SeriesName: {
*           visible:
*           yAxisVisibile:
* color:
*           }
*       }
* },
* toggleOptions: {
*   isDailyAxisCombined,
*   showWeeklySeries,
* }
*
*
*modals3x3: {
            open: false,
            index: null
        }
* }
* */
//functionalTags, vendorTags

export const MODES = {
    STICKY: 'STICKY',
    DEFAULT: 'DEFAULT'
};

const predicateToAllTransformer = (predicateDescriptor) => predicateDescriptor + '_ALL';

const BUTTON_TO_PREDICATE_MAP = {
    [CC]: ccSeriesPredicate,
    [GT]: gtSeriesPredicate,
    [SW]: similarWebPredicate,
    [APP]: appSeriesPredicate,
    [SEGMENT]: segmentSeriesPredicate,
    [PLACER]: placerPredicate,
    [YIPIT]: yipitPredicate,
    [INDICES]: indexesPredicate
}

const createToggleFunction = (stateSetterFunction, initalState = false) => {
    return () => {
        stateSetterFunction(prev => {
            if (prev == null) return initalState;
            return !prev;
        })
    }
}

// Create empty objects against each query type:
const viewContextStoreBuilder = (combineAxes) => {
    const flatListOfDashboardQueries = listOfListOfDashboardQueriesWithPriorities.flat();
    const contextsStore = {};
    const combineAxesValue = combineAxes == null ? false : combineAxes;
    flatListOfDashboardQueries.forEach((query) => {
        contextsStore[query] = {
            seriesConfig: {
                // Keeping it empty for now
            },
            extremities: {
                'xMin': null,
                'xMax': null
            },
            dynamicLag: null,
            combineAxes: combineAxesValue,
            seriesMetadata: null, // For series metadata sent from the service.
        };
    });
    return contextsStore;
}

// How to calculate the initial state?
export const ViewContextStore = createContext(null);


export function toggleSeriesForChartUsingMetadata(chartConfig, predicate, enableSeries, showWeeklySeries) {
    toggleSeriesForChartUsingMetadataExplicit(chartConfig.seriesConfig, chartConfig.seriesMetadata, predicate, enableSeries, showWeeklySeries);
}

export const toggleSeriesForChartUsingMetadataExplicit = (seriesConfig, metadata, predicate, enableSeries, showWeeklySeries) => {
    if (enableSeries == null) return;
    metadata?.forEach(seriesMetadata => {

        //Show weekly needs to be factored in when enabling series(case: weekly off, and turn off a set), but not when a series set is being disabled:
        const predicateResult = enableSeries ? (showWeeklySeries ? predicate(seriesMetadata, seriesConfig) : predicate(seriesMetadata, seriesConfig) && weeklyPredicate(seriesMetadata, showWeeklySeries)) : predicate(seriesMetadata, seriesConfig);

        if (predicateResult) {
            // Series is a candidate for toggle:
            seriesConfig[seriesMetadata.seriesName] = {
                visible: enableSeries,
                yAxisVisible: enableSeries && seriesConfig[seriesMetadata.seriesName]?.yAxisVisible
            };
        }
    })
}

export const bulkFlipSeriesUsingMetadata = (enableSeries, predicate, state, showWeeklySeries) => {
    if (enableSeries === null) return;
    const newState = _.cloneDeep(state);
    Object.keys(newState).forEach(chartName => {
        const chartConfig = newState[chartName];
        toggleSeriesForChartUsingMetadata(chartConfig, predicate, enableSeries, showWeeklySeries);
    });
    return newState;
}


function toggleYAxisVisibility(viewState, queryType, seriesName) {
    const newObjectForSeries = {
        ...viewState[queryType].seriesConfig[seriesName]
    };
    newObjectForSeries.visible = true;
    newObjectForSeries.yAxisVisible = !newObjectForSeries.yAxisVisible;
    return newObjectForSeries;
}

export const ViewContextProvider = memo(({tick, user, children, brand, combineAxes = false}) => {
    const [viewState, setViewState] = useState(viewContextStoreBuilder(combineAxes));


    const [wtdStackModalOpen, setWtdStackModalOpen] = useState(false);
    const [chatModalOpen, setChatModalOpen] = useState(false);

    const [mode, setMode] = useState(MODES.STICKY);

    const [metadataMap, setMetadataMap] = useState({});

    // APP, cc, GT, SW toggles:
    // Store if the next toggle will be disabling(toggle down) or enabling:
    const [appSeriesBulkToggle, setAppSeriesBulkToggle] = useState(null);
    const [ccSeriesBulkToggle, setCcSeriesBulkToggle] = useState(null);
    const [gtSeriesBulkToggle, setGtSeriesBulkToggle] = useState(null);
    const [swSeriesBulkToggle, setSwSeriesBulkToggle] = useState(null);
    const [segmentsSeriesBulkToggle, setSegmentsSeriesBulkToggle] = useState(null);
    const [yipitSeriesBulkToggle, setYipitSeriesBulkToggle] = useState(null);
    const [placerSeriesBulkToggle, setPlacerSeriesBulkToggle] = useState(null);
    const [indexSeriesBulkToggle, setIndexSeriesBulkToggle] = useState(null);
    const [combineAxesBulkToggle, setCombineAxesBulkToggle] = useState(false);
    const [idioBulkToggle, setIdioBulkToggle] = useState(null);
    const [siBulkToggle, setSiBulkToggle] = useState(null);

    const [idioSiBulkToggle, setIdioSiBulkToggle] = useState(null);

    const [isDailyStackAlignment, setDailyStackAlignment] = useState(false);

    const [showWeeklySeries, setShowWeeklySeries] = useState(false);
    const [showPeerIndices, setShowPeerIndices] = useState(false);

    const [versionedViewState, setVersionedViewState] = useState({});
    const [predicateExclusiveMode, setPredicateExclusiveMode] = useState(false);
    const [predicateModeActivePredicate, setPredicateModeActivePredicate] = useState(null);

    const [swapHurricaneSeries, setSwapHurricaneSeries] = useState(false);

    // When active binds 1y and 2y charts. Any change to one should reflect to another:
    const [chartPairingActive, setChartPairingActive] = useState(true);

    useEffect(() => {
        // Reset the view on a tick change.
        setMetadataMap({});// Empty out the metadata map, and have it repopulate with the reversePopulate logic.
        if (mode === MODES.DEFAULT) setViewState(viewContextStoreBuilder());
        // Todo: add logic for fetching the initial view state from the backend + populate it if default is empty.
    }, [tick, brand]);

    const reversePopulateChartConfig = useCallback((chartName, seriesConfig, metadata, ucChart = false) => {


        setMetadataMap(prev => ({
            ...prev,
            [chartName]: metadata
        }));


        // If mode is sticky and there was a ticker active before this and this is not a UC chart:
        if (mode === MODES.STICKY && !ucChart) {
            const currentChartState = predicateExclusiveMode ? versionedViewState[chartName] : viewState[chartName];
            Object.keys(seriesConfig).forEach(seriesName => {
                if (currentChartState != null) {
                    // Copy the current setting if the series matches
                    const currentSeriesConfig = currentChartState.seriesConfig[seriesName];
                    if (currentSeriesConfig != null) {
                        seriesConfig[seriesName] = currentSeriesConfig;
                    }
                }
            });
            // Apply all the shortcuts, using a mutating function, align what the function consumes:
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, appSeriesPredicate, appSeriesBulkToggle, showWeeklySeries);
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, ccSeriesPredicate, ccSeriesBulkToggle, showWeeklySeries);
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, gtSeriesPredicate, gtSeriesBulkToggle, showWeeklySeries);
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, similarWebPredicate, swSeriesBulkToggle, showWeeklySeries);
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, segmentSeriesPredicate, segmentsSeriesBulkToggle, showWeeklySeries);
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, yipitPredicate, yipitSeriesBulkToggle, showWeeklySeries);
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, placerPredicate, placerSeriesBulkToggle, showWeeklySeries);
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, indexesPredicate, indexSeriesBulkToggle, showWeeklySeries);

            // Logic to swap active series to hurricane ones, if the toggle is active:
            if (swapHurricaneSeries) {
                toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, isHurricaneSeriesWithActiveCounterpart, true, showWeeklySeries);
                toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, isNormalSeriesWithActiveHurricaneCounterpart, false, showWeeklySeries);
            }

            // Weekly should always be applied last, since it is dependent on the state mutations that were done above:
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, oracleIndexPredicate, !showWeeklySeries, showWeeklySeries);
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, weekliesButtonPredicate, showWeeklySeries, showWeeklySeries);

            if (predicateExclusiveMode) {
                // Save the currently created config to the versioned store:
                const newSeriesConfig = {...seriesConfig};
                setVersionedViewState(prev => ({
                    ...prev,
                    [chartName]: {
                        ...prev[chartName],
                        seriesConfig: newSeriesConfig,
                        seriesMetadata: metadata != null ? metadata : prev[chartName]?.seriesMetadata,
                    }
                }));

                // Do the changes based on the exclusive modes:
                toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, inversePredicate(BUTTON_TO_PREDICATE_MAP[predicateModeActivePredicate]), false, showWeeklySeries);
                toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, BUTTON_TO_PREDICATE_MAP[predicateModeActivePredicate], true, showWeeklySeries);
            }
        }

        setViewState(prev => ({
            ...prev,
            [chartName]: {
                ...prev[chartName],
                seriesConfig,
                seriesMetadata: metadata != null ? metadata : prev[chartName]?.seriesMetadata,
                combineAxes: combineAxesBulkToggle
            }
        }));
    }, [mode, predicateExclusiveMode, versionedViewState, viewState, appSeriesBulkToggle, showWeeklySeries,
        ccSeriesBulkToggle, gtSeriesBulkToggle, swSeriesBulkToggle, segmentsSeriesBulkToggle,
        yipitSeriesBulkToggle, placerSeriesBulkToggle, indexSeriesBulkToggle, swapHurricaneSeries,
        predicateModeActivePredicate, combineAxesBulkToggle]);

    const modifyViewStateWithObjectForSeriesAndChart = useCallback((chartName, seriesName, modifiedObject) => {
        setViewState(prevState => {
            const chart = prevState[chartName];

            if (!chart || !chart.seriesConfig || !chart.seriesConfig[seriesName]) {
                return prevState;
            }

            return {
                ...prevState,
                [chartName]: {
                    ...chart,
                    seriesConfig: {
                        ...chart.seriesConfig,
                        [seriesName]: modifiedObject,
                    },
                },
            };
        });
    }, []);

    const updateChartRange = useCallback((chartName, extremities) => {
        let boundChart = null;
        if (chartPairingActive && isABoundPairChart(chartName)) boundChart = getBoundChart(chartName);
        setViewState(prev => {
            const newState = {
                ...prev,
                [chartName]: {
                    ...prev?.[chartName],
                    extremities: extremities,
                }
            };

            if (boundChart) {
                newState[boundChart] = {
                    ...prev?.[boundChart],
                    extremities: extremities,
                };
            }

            return newState;
        });
    }, [chartPairingActive]);

    const toggleCombineAxisBulk = useCallback(createToggleFunction(setCombineAxesBulkToggle), []);

    const toggleAppSeriesBulk = useCallback(createToggleFunction(setAppSeriesBulkToggle), []);

    const toggleCCSeriesBulk = useCallback(createToggleFunction(setCcSeriesBulkToggle), []);

    const toggleGTSeriesBulk = useCallback(createToggleFunction(setGtSeriesBulkToggle), []);

    const toggleSwSeriesBulk = useCallback(createToggleFunction(setSwSeriesBulkToggle), []);

    const toggleSegmentSeriesBulk = useCallback(createToggleFunction(setSegmentsSeriesBulkToggle), []);

    const toggleYipitSeriesBulk = useCallback(createToggleFunction(setYipitSeriesBulkToggle), []);

    const togglePlacerSeriesBulk = useCallback(createToggleFunction(setPlacerSeriesBulkToggle), []);

    const toggleIndexSeriesBulk = useCallback(createToggleFunction(setIndexSeriesBulkToggle), []);

    const toggleIdioBulk = useCallback(createToggleFunction(setIdioBulkToggle), []);

    const toggleSiBulk = useCallback(createToggleFunction(setSiBulkToggle), []);

    const toggleIdioSiBulk = useCallback(createToggleFunction(setIdioSiBulkToggle), []);

    function bulkToggleSeriesUsingMetadata(enableSeries, predicate) {
        if (enableSeries == null) return; // Effect should not run, unless triggered by user.
        // Set to the correct value for each chart:
        setViewState(prev => bulkFlipSeriesUsingMetadata(enableSeries, predicate, prev, showWeeklySeries));
    }

    /**
     * Applies multiple enableSeries and predicate changes in a single setViewState.
     * @param {...{enableSeries: boolean, predicate: function}} actions - Multiple sets of enableSeries and predicate arguments.
     */
    function chainedBulkToggleSeriesUsingMetadata(...actions) {
        // Filter actions that don't have valid enableSeries or predicate to avoid unnecessary updates
        const validActions = actions.filter(({
                                                 enableSeries,
                                                 predicate
                                             }) => enableSeries != null && typeof predicate === 'function');

        if (validActions.length === 0) return; // If no valid actions, do nothing

        // Apply all actions to the view state in one setViewState call
        setViewState(prev => {
            let newState = {...prev};

            validActions.forEach(({enableSeries, predicate}) => {
                newState = bulkFlipSeriesUsingMetadata(enableSeries, predicate, newState, showWeeklySeries);
            });

            return newState;
        });
    }

    useEffect(() => {
        const enableCombineAxes = combineAxesBulkToggle;
        setViewState(prev => {
            const newObject = _.cloneDeep(prev);
            Object.keys(newObject).forEach((chartName) => {
                // Levels charts should not have combine axis enabled.
                if (!chartName.toLowerCase().includes('level')) {
                    newObject[chartName].combineAxes = enableCombineAxes;
                    if (combineAxesBulkToggle) {
                        toggleSeriesForChartUsingMetadata(newObject[chartName], siPredicate, true);
                        if (newObject[chartName].seriesConfig.idio != null)
                            newObject[chartName].seriesConfig.idio.visible = true;

                    }
                }
            });
            return newObject;
        });
    }, [combineAxesBulkToggle]);

    useEffect(() => {
        // If next toggle will be disabling, current action should enable it:
        bulkToggleSeriesUsingMetadata(appSeriesBulkToggle, appSeriesPredicate);
    }, [appSeriesBulkToggle]);

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(ccSeriesBulkToggle, ccSeriesPredicate);
    }, [ccSeriesBulkToggle]);

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(gtSeriesBulkToggle, gtSeriesPredicate);
    }, [gtSeriesBulkToggle]);

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(swSeriesBulkToggle, similarWebPredicate)
    }, [swSeriesBulkToggle]);

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(segmentsSeriesBulkToggle, segmentSeriesPredicate)
    }, [segmentsSeriesBulkToggle]);

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(idioBulkToggle, idioPredicate)
    }, [idioBulkToggle]);

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(siBulkToggle, siPredicate)
    }, [siBulkToggle]);

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(idioSiBulkToggle, combinePredicatesOr(idioPredicate, siPredicate));
    }, [idioSiBulkToggle]);

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(yipitSeriesBulkToggle, yipitPredicate);
    }, [yipitSeriesBulkToggle]);

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(placerSeriesBulkToggle, placerPredicate);
    }, [placerSeriesBulkToggle])

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(indexSeriesBulkToggle, indexesPredicate);
    }, [indexSeriesBulkToggle])

    useEffect(() => {
        if (swapHurricaneSeries) {
            chainedBulkToggleSeriesUsingMetadata(
                {enableSeries: true, predicate: isHurricaneSeriesWithActiveCounterpart},
                {enableSeries: false, predicate: isNormalSeriesWithActiveHurricaneCounterpart}
            );
        } else {
            chainedBulkToggleSeriesUsingMetadata(
                {enableSeries: true, predicate: isNormalSeriesWithActiveHurricaneCounterpart},
                {enableSeries: false, predicate: isHurricaneSeriesWithActiveCounterpart}
            );
        }
    }, [swapHurricaneSeries])

    useEffect(() => {
        if (showWeeklySeries) {
            // disable app and placer:
            setAppSeriesBulkToggle(false);
            setPlacerSeriesBulkToggle(false);
            setSwSeriesBulkToggle(false);
        }
        bulkToggleSeriesUsingMetadata(!showWeeklySeries, oracleIndexPredicate);
        bulkToggleSeriesUsingMetadata(showWeeklySeries, weekliesButtonPredicate);
    }, [showWeeklySeries])

    //Code for the functionality to disable all series apart from those matching a predicate, with a versioning system
    // on the view state to preserve the old state and revert to it on disabling of the feature.


    const switchToPredicateExclusiveModeWithPredicate = (predicate, predicateDescriptor, all) => {
        //Save the old version:
        if (!predicateExclusiveMode) setVersionedViewState(viewState);
        //Switch on the predicate exclusive mode
        setPredicateExclusiveMode(true);
        const finalPredicateDescriptor = all ? predicateToAllTransformer(predicateDescriptor) : predicateDescriptor;
        setPredicateModeActivePredicate(finalPredicateDescriptor);

        setViewState(prev => {
            const disabledSeriesState = all ? bulkFlipSeriesUsingMetadata(false, inversePredicate(predicate), prev, showWeeklySeries)
                : bulkFlipSeriesUsingMetadata(false, combinePredicates(inversePredicate(predicate), inversePredicate(kpiPredicate)), prev, showWeeklySeries);
            return bulkFlipSeriesUsingMetadata(true, predicate, disabledSeriesState, showWeeklySeries)
        });

    }

    const disablePredicateExclusiveMode = () => {
        setViewState(prev => {
            const newObject = Object.assign({}, prev);
            Object.keys(newObject).forEach((chartName) => {
                //Todo: Integrate with a more comprehensive merging logic instead of overwrites:
                newObject[chartName].seriesConfig = versionedViewState[chartName].seriesConfig;
            });
            return newObject;
        });
        setVersionedViewState({});
        setPredicateExclusiveMode(false);
        setPredicateModeActivePredicate(null);
    }

    const getFlipExclusiveModeForSeriesFunction = (predicate, predicateDescriptor, all) => () => {
        const finalPredicateDescriptor = all ? predicateToAllTransformer(predicateDescriptor) : predicateDescriptor;
        if (predicateExclusiveMode && predicateModeActivePredicate === finalPredicateDescriptor) {
            disablePredicateExclusiveMode();
        } else {
            switchToPredicateExclusiveModeWithPredicate(predicate, predicateDescriptor, all);
        }
    }

    const [keysPressed, setKeysPressed] = useState({zKey: false, xKey: false, cKey: false, vKey: false});

    const [dropLastPoint, setDropLastPoint] = useState(false);


    useEffect(() => {
        const handleKeyUp = (event) => {
            const newKeysStatus = {...keysPressed};
            if (['z', 'x', 'c', 'v'].includes(event.key)) {
                newKeysStatus[`${event.key}Key`] = false;
                setKeysPressed(newKeysStatus);
            }
        };
        document.addEventListener("keyup", handleKeyUp);
        return () => document.removeEventListener("keyup", handleKeyUp);
    }, []);

    const [viewSettingsModalIsOpen, setViewSettingsModalIsOpen]
        = useState(false);
    const toggleViewSettingsModal = () => setViewSettingsModalIsOpen(prev => !prev);
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.ctrlKey && e.key === 'd') {
                e.preventDefault();
                setDailyStackAlignment(prev => !prev);
            }

            if (e.ctrlKey && e.key === 'a') {
                e.preventDefault();
                setCombineAxesWithSeriesSetup(prev => !prev);
            }

            if (e.ctrlKey && e.key === 'i') {
                e.preventDefault();
                setShowPeerIndices(prev => !prev);
            }

            if (e.ctrlKey && e.key === 'g') {
                e.preventDefault();
                toggleGTSeriesBulk()
            }

            if (e.ctrlKey && e.key === 'k') {
                e.preventDefault();
                toggleAppSeriesBulk()
            }

            if (e.ctrlKey && e.key === 'r') {
                e.preventDefault();
                toggleCCSeriesBulk();
            }

            if (e.ctrlKey && e.key === 'w') {
                e.preventDefault();
                toggleSwSeriesBulk();
            }

            if (e.ctrlKey && e.key === 'e') {
                e.preventDefault();
                toggleSegmentSeriesBulk();
            }

            if (e.ctrlKey && e.key === 'o') {
                e.preventDefault();
                toggleCombineAxisBulk();
            }

            if (e.ctrlKey && e.key === 'p') {
                e.preventDefault();
                togglePlacerSeriesBulk();
            }

            if (e.ctrlKey && e.key === 'y') {
                e.preventDefault();
                toggleYipitSeriesBulk();
            }

            if (e.ctrlKey && e.key === 'x') {
                e.preventDefault();
                toggleIndexSeriesBulk();
            }

            if (e.ctrlKey && e.key === '/') {
                e.preventDefault();
                if (mode === MODES.STICKY) setMode(MODES.DEFAULT);
                else if (mode === MODES.DEFAULT) setMode(MODES.STICKY);
            }

            if (e.ctrlKey && e.key === '\\') {
                e.preventDefault();
                toggleViewSettingsModal();
            }

            if (e.ctrlKey && e.key === ';') {
                e.preventDefault();
                console.log('Changing drop last point')
                setDropLastPoint(prev => !prev);
            }

            if (e.ctrlKey && e.key === 't') {
                e.preventDefault();
                setWtdStackModalOpen(prev => !prev);
            }

            if (e.ctrlKey && e.key === 'e') {
                e.preventDefault();
                setChartPairingActive(prev => !prev);
            }

            if (e.key === 'F1') {
                e.preventDefault();
                setChatModalOpen(prev => !prev);
            }

            const newKeysStatus = {...keysPressed};
            if (['z', 'x', 'c', 'v'].includes(e.key)) {
                newKeysStatus[`${e.key}Key`] = true;
                setKeysPressed(newKeysStatus);
            }

        };

        document.addEventListener("keydown", handleKeyPress);
        return () => document.removeEventListener("keydown", handleKeyPress);
    }, []);

    // Wrap this in a hoc and pass that to the individual charts:
    const modifyActiveSeries = useCallback((queryType, seriesName) => {
        let boundChart = null; // Will be null if pairing needs triggering, else will hold the paired chart.
        if (chartPairingActive && isABoundPairChart(queryType)) boundChart = getBoundChart(queryType);

        if (keysPressed.vKey) {
            // Toggle the particular series across all charts which have those series visible:
            setViewState(prev => {
                // Find the current state of the series in the specific chart:
                const currentVisibility = prev[queryType].seriesConfig[seriesName]?.visible;
                if (currentVisibility == null) return prev; // Unreachable code, but safe.
                const newState = _.cloneDeep(prev);
                const targetVisibility = !currentVisibility;
                Object.keys(newState).filter(key => newState[key].seriesConfig[seriesName] != null).forEach(chart => {
                    newState[chart].seriesConfig[seriesName].visible = targetVisibility;
                    // Y axis should be visible only if the axis was previous enabled and the series is visible:
                    newState[chart].seriesConfig[seriesName].yAxisVisible = targetVisibility && newState[chart].seriesConfig[seriesName].yAxisVisible;
                });
                return newState;
            });
            return;
        }

        if (keysPressed.xKey) {
            // toggle y-axis visibility of the series, and make series itself visible:
            setViewState(prev => {
                const newState = {
                    ...prev,
                    [queryType]: {
                        ...prev[queryType],
                        seriesConfig: {
                            ...prev[queryType].seriesConfig
                        }
                    }
                };
                const newObjectForSeries = toggleYAxisVisibility(newState, queryType, seriesName);
                newState[queryType].seriesConfig[seriesName] = newObjectForSeries;
                if (boundChart != null) {
                    newState[boundChart] = {
                        ...prev[boundChart],
                        seriesConfig: {
                            ...prev[boundChart].seriesConfig,
                        }
                    };
                    newState[boundChart].seriesConfig[seriesName] = _.clone(newObjectForSeries);
                }
                return newState;
            })
            return;
        }

        if (keysPressed.cKey) {

            // Disable all y axis visible for all the series:
            setViewState(prev => {
                const newOptions = {
                    ...prev,
                    [queryType]: {
                        ...prev[queryType],
                        seriesConfig: {
                            ...prev[queryType].seriesConfig
                        }
                    }
                }
                const newStateObjectForSeries = newOptions[queryType].seriesConfig;
                Object.keys(newStateObjectForSeries).forEach((seriesName) => {
                    newStateObjectForSeries[seriesName].yAxisVisible = false
                });

                if (boundChart != null) {
                    newOptions[boundChart] = {
                        ...prev[boundChart],
                        seriesConfig: {
                            ...prev[boundChart].seriesConfig,
                        }
                    };
                    const newStateObjectForSeriesBound = newOptions[boundChart].seriesConfig;
                    Object.keys(newStateObjectForSeriesBound).forEach((seriesName) => {
                        newStateObjectForSeriesBound[seriesName].yAxisVisible = false
                    });
                }
                return newOptions;

            })
            return;
        }
        if (keysPressed.zKey) {
            // Disable all series apart from current series and the following:
            // 'ghost' and 'ghost_daily' -> Required for preserving scaling and other things
            // 'idio' should always be enabled, unless user explicitly closes it:
            const seriesToKeepVisible = seriesName === 'idio' ? ['ghost', 'ghost_daily', 'idio'] : ['ghost', 'ghost_daily', 'idio', seriesName];

            setViewState(prev => {

                const newState = {
                    ...prev,
                    [queryType]: {
                        ...prev[queryType],
                        seriesConfig: {
                            ...prev[queryType].seriesConfig
                        }
                    }
                };

                const newStateObjectForSeries = newState[queryType].seriesConfig;
                Object.keys(newStateObjectForSeries).forEach((seriesName) => {
                    newStateObjectForSeries[seriesName] = {
                        yAxisVisible: newStateObjectForSeries[seriesName].yAxisVisible && _.findIndex(seriesToKeepVisible, name => name === seriesName) > 0,
                        visible: _.findIndex(seriesToKeepVisible, name => name === seriesName) > 0
                    }
                });

                if (boundChart != null) {
                    newState[boundChart] = {
                        ...prev[boundChart],
                        seriesConfig: {
                            ...prev[boundChart].seriesConfig
                        }
                    };
                    const newStateObjectForSeriesBound = newState[boundChart].seriesConfig;
                    Object.keys(newStateObjectForSeriesBound).forEach((seriesName) => {
                        newStateObjectForSeriesBound[seriesName] = {
                            yAxisVisible: newStateObjectForSeriesBound[seriesName].yAxisVisible && _.findIndex(seriesToKeepVisible, name => name === seriesName) > 0,
                            visible: _.findIndex(seriesToKeepVisible, name => name === seriesName) > 0
                        }
                    });
                }
                return newState;
            });
            return;
        }
        // No key is pressed, simply toggle the values:
        // Todo: Replicate this creation of new object for chart across the Context:
        setViewState(prev => {
            const newState = {...prev};

            // Ensure we copy queryType state to a new object
            newState[queryType] = {
                ...prev[queryType],
                seriesConfig: {
                    ...prev[queryType].seriesConfig
                }
            };

            // Update the seriesConfig for the given seriesName
            const modifiedSeriesObject = {
                yAxisVisible: newState[queryType].seriesConfig[seriesName]?.yAxisVisible,
                visible: newState[queryType].seriesConfig[seriesName]?.visible
            };

            if (modifiedSeriesObject.visible) {
                // Disable yAxis as well
                modifiedSeriesObject.yAxisVisible = false;
                modifiedSeriesObject.visible = false;
            } else {
                modifiedSeriesObject.visible = true;
            }

            newState[queryType].seriesConfig[seriesName] = modifiedSeriesObject;

            // If boundChart is not null, update its seriesConfig as well
            if (boundChart != null) {
                newState[boundChart] = {
                    ...prev[boundChart],
                    seriesConfig: {
                        ...prev[boundChart].seriesConfig,
                        [seriesName]: _.clone(modifiedSeriesObject)
                    }
                };
            }

            return newState;
        });
    }, [chartPairingActive, keysPressed.vKey, keysPressed.xKey, keysPressed.cKey, keysPressed.zKey]);

    const setCombineAxes = useCallback((chartName, combineAxes) => {
        let boundChart = null;
        if (chartPairingActive && isABoundPairChart(chartName)) boundChart = getBoundChart(chartName);
        setViewState(prev => {
            const newState = {
                ...prev,
                [chartName]: {
                    ...prev[chartName],
                    combineAxes
                }
            };

            if (boundChart) {
                newState[boundChart] = {
                    ...prev[boundChart],
                    combineAxes
                };
            }

            return newState;
        });
    }, [chartPairingActive]);

    const setDynamicLag = useCallback((chartName, dynLag) => {
        let boundChart = null;
        if (chartPairingActive && isABoundPairChart(chartName)) boundChart = getBoundChart(chartName);
        setViewState(prev => {
            const newState = {
                ...prev,
                [chartName]: {
                    ...prev[chartName],
                    dynamicLag: dynLag
                }
            };

            if (boundChart) {
                newState[boundChart] = {
                    ...prev[boundChart],
                    dynamicLag: dynLag
                };
            }

            return newState;
        });
    }, [chartPairingActive]);

    const setSeriesMetaData = useCallback((chartName, seriesMetaData) => {
        setViewState(prev => ({
            ...prev,
            [chartName]: {
                ...prev[chartName],
                seriesMetadata: seriesMetaData
            }
        }));
    }, []);

    const shortcutTogglesAndState = {
        [CC]: {
            state: ccSeriesBulkToggle,
            trigger: toggleCCSeriesBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(ccSeriesPredicate, CC, false),
            excModeDataActive: predicateModeActivePredicate === CC,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(ccSeriesPredicate, CC, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(CC)
        },
        [GT]: {
            state: gtSeriesBulkToggle,
            trigger: toggleGTSeriesBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(gtSeriesPredicate, GT, false),
            excModeDataActive: predicateModeActivePredicate === GT,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(gtSeriesPredicate, GT, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(GT)
        },
        [SW]: {
            state: swSeriesBulkToggle,
            trigger: toggleSwSeriesBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(similarWebPredicate, SW, false),
            excModeDataActive: predicateModeActivePredicate === SW,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(similarWebPredicate, SW, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(SW)
        },
        [APP]: {
            state: appSeriesBulkToggle,
            trigger: toggleAppSeriesBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(appSeriesPredicate, APP, false),
            excModeDataActive: predicateModeActivePredicate === APP,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(appSeriesPredicate, APP, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(APP)
        },
        [SEGMENT]: {
            state: segmentsSeriesBulkToggle,
            trigger: toggleSegmentSeriesBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(segmentSeriesPredicate, SEGMENT, false),
            excModeDataActive: predicateModeActivePredicate === SEGMENT,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(segmentSeriesPredicate, SEGMENT, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(SEGMENT)
        },
        [PLACER]: {
            state: placerSeriesBulkToggle,
            trigger: togglePlacerSeriesBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(placerPredicate, PLACER, false),
            excModeDataActive: predicateModeActivePredicate === PLACER,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(placerPredicate, PLACER, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(PLACER)
        },
        [YIPIT]: {
            state: yipitSeriesBulkToggle,
            trigger: toggleYipitSeriesBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(yipitPredicate, YIPIT, false),
            excModeDataActive: predicateModeActivePredicate === YIPIT,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(yipitPredicate, YIPIT, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(YIPIT)
        },
        [INDICES]: {
            state: indexSeriesBulkToggle,
            trigger: toggleIndexSeriesBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(indexesPredicate, INDICES, false),
            excModeDataActive: predicateModeActivePredicate === INDICES,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(indexesPredicate, INDICES, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(INDICES)
        },
        [IDIO]: {
            state: idioBulkToggle,
            trigger: toggleIdioBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(idioPredicate, IDIO, false),
            excDataActive: predicateModeActivePredicate === IDIO,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(idioPredicate, IDIO, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(IDIO),
        },
        [SI]: {
            state: siBulkToggle,
            trigger: toggleSiBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(siPredicate, SI, false),
            excModeDataActive: predicateModeActivePredicate === SI,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(siPredicate, SI, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(SI),
        },
        [IDIO_SI]: {
            state: idioSiBulkToggle,
            trigger: toggleIdioSiBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(idioSiPredicate, IDIO_SI, false),
            excModeDataActive: predicateModeActivePredicate === IDIO_SI,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(idioSiPredicate, IDIO_SI, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(IDIO_SI),
        }
    }


    /*const completeMetadata = Object.fromEntries(
        Object.entries(viewState).map(([key, value]) => [key, value.seriesMetadata])
    );*/

    const bulkToggle = useCallback((seriesList, chartsList, isEnableSeries, isEnableAxis) => {
        setViewState(prev => {
            const newObj = {...prev}
            chartsList.forEach(chart => {
                const chartConfig = newObj[chart].seriesConfig;
                seriesList.forEach(series => {
                    if (chartConfig[series])
                        chartConfig[series] = {
                            visible: isEnableSeries,
                            yAxisVisible: isEnableAxis,
                        }
                })
            });
            return newObj;
        })
    }, []);

    const bulkToggleChartSettings = useCallback((chartsList, isEnableCombineAxis) => {
            setViewState(prev => {
                const newObj = _.cloneDeep(prev);
                chartsList.forEach(chart => {
                    newObj[chart].combineAxes = isEnableCombineAxis;
                });
                return newObj;
            })
        }
        , []);

    const [modalViewModePaired, setModalViewModePaired] = useState(true);

    const {mapOfChartRefs, addToMapOfChartRefs, removeFromMapOfChartRefs} = useChartRefs();


    return (
        <ViewContextStore.Provider value={{
            viewState,
            modifyActiveSeries,
            reversePopulateChartConfig,
            updateChartRange,
            modifyViewStateWithObjectForSeriesAndChart,
            isDailyStackAlignment,
            setDailyStackAlignment,
            combineAxesBulkToggle,
            toggleCombineAxisBulk,
            showWeeklySeries,
            setShowWeeklySeries,
            showPeerIndices,
            setShowPeerIndices,
            setCombineAxes,
            setDynamicLag,
            setSeriesMetaData,
            shortcutTogglesAndState,
            mode,
            setMode,
            viewSettingsModalIsOpen,
            toggleViewSettingsModal,
            completeMetadata: {},
            bulkToggle,
            bulkToggleChartSettings,
            dropLastPoint,
            setDropLastPoint,
            wtdStackModalOpen,
            setWtdStackModalOpen,
            chatModalOpen,
            setChatModalOpen,
            metadataMap,
            tick,
            brand,
            chartPairingActive,
            setChartPairingActive,
            modalViewModePaired,
            setModalViewModePaired,
            mapOfChartRefs,
            addToMapOfChartRefs,
            removeFromMapOfChartRefs,
            swapHurricaneSeries,
            setSwapHurricaneSeries
        }}>
            {children}
        </ViewContextStore.Provider>
    )
})

