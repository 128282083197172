// Build a system which gets passed: current full config, current metadata, and a predicate, which factors this, and applies it to all the series.
//Then when the ticker changes, all of these are then applied sequentially:

export const inversePredicate = (predicate) => {
    return (...args) => !predicate(...args);
};

export const combinePredicates = (...predicates) => {
    return (item) => predicates.every((predicate) => predicate(item));
};

export const combinePredicatesOr = (...predicates) => {
    return (item) => predicates.some((predicate) => predicate(item));
};

export const weeklyPredicate = (seriesMetadata, showWeeklySeries) =>
        showWeeklySeries ? (seriesMetadata.granularityTags.includes('WEEKLY') || seriesMetadata.seriesName.includes('wkly'))
            :
            !(seriesMetadata.granularityTags.includes('WEEKLY') || seriesMetadata.seriesName.includes('wkly')) ;


export const yipitPredicate = (seriesMetadata) => (seriesMetadata?.vendorTags?.includes("YIPIT") ||
    seriesMetadata?.seriesName?.toLowerCase().includes("yipit"));

export const placerPredicate = (seriesMetadata) => (seriesMetadata?.vendorTags?.includes("PLACER") ||
    seriesMetadata?.seriesName?.toLowerCase().includes("placer"))

export const appSeriesPredicate = (seriesMetadata) =>
    (seriesMetadata?.functionalTags?.includes("APP_DOWNLOAD") ||
        seriesMetadata?.functionalTags?.includes("APP_DAU")) &&
    !seriesMetadata?.functionalTags?.includes("FINANCIAL");

export const southEastPredicate = (seriesMetadata) => seriesMetadata.seriesName.includes("_ex_se");

export const ccSeriesPredicate = (seriesMetadata) =>
    ((seriesMetadata?.functionalTags?.includes("CC") && seriesMetadata?.functionalTags?.includes("SALES") && seriesMetadata?.functionalTags?.includes("TOTAL")
        && !seriesMetadata?.functionalTags?.includes("FINANCIAL")) || seriesMetadata?.functionalTags?.includes("STUDIO_CC_INDEX"))/* && !seriesMetadata.seriesName.includes("_ex_se")*/ ;

export const gtSeriesPredicate = (seriesMetadata) => (seriesMetadata?.functionalTags?.includes("WEB") && !seriesMetadata?.functionalTags?.includes("ALT_GT") &&
    seriesMetadata?.vendorTags?.includes("SERP_API") && !seriesMetadata?.functionalTags?.includes("FINANCIAL"));

export const similarWebPredicate = (seriesMetadata) => (seriesMetadata?.vendorTags?.includes("SIMILARWEB") &&
    !seriesMetadata?.functionalTags?.includes("FINANCIAL"))

export const segmentSeriesPredicate = (seriesMetadata) =>
    (seriesMetadata?.functionalTags?.includes("SEGMENT") && !seriesMetadata?.functionalTags?.includes("FINANCIAL"));

export const altGtPredicate = (seriesMetadata) => seriesMetadata?.functionalTags?.includes("WEB") && seriesMetadata?.functionalTags?.includes("ALT_GT") &&
    seriesMetadata?.vendorTags?.includes("SERP_API") && !seriesMetadata?.functionalTags?.includes("FINANCIAL");

export const oracleIndexPredicate = (seriesMetadata) => (seriesMetadata?.functionalTags?.includes("STUDIO_INDEX") && !seriesMetadata?.functionalTags?.includes("STUDIO_CC_INDEX"));

export const indexesPredicate = (seriesMetadata) => (seriesMetadata?.functionalTags?.includes("STUDIO_INDEX"));

// Weeklies for series which are not placer, app, gt && have the parent series active for it:
export const weekliesButtonPredicate = (seriesMetadata, currentChartConfig) => !appSeriesPredicate(seriesMetadata) && !gtSeriesPredicate(seriesMetadata) &&
    !altGtPredicate(seriesMetadata) && !placerPredicate(seriesMetadata) &&
    ((seriesMetadata.granularityTags.includes('WEEKLY') || seriesMetadata.seriesName.includes('wkly'))&&
        currentChartConfig[Object.keys(currentChartConfig).find(activeConfigSeries => activeConfigSeries !== seriesMetadata.seriesName && isValidParentSeriesForWeekly(activeConfigSeries, seriesMetadata.seriesName))]?.visible);

export const isHurricaneSeriesWithActiveCounterpart = (seriesMetadata, currentChartConfig) => seriesMetadata.seriesName.includes('ex_se') &&
    currentChartConfig[Object.keys(currentChartConfig).find(series => isHurricaneSeriesPair(seriesMetadata.seriesName, series))]?.visible;

export const isNormalSeriesWithActiveHurricaneCounterpart = (seriesMetadata, currentChartConfig) => !seriesMetadata.seriesName.includes('ex_se') &&
    currentChartConfig[Object.keys(currentChartConfig).find(series => isHurricaneSeriesPair(series, seriesMetadata.seriesName))]?.visible;

export const siPredicate = (seriesMetadata) => seriesMetadata?.functionalTags?.includes("SHORT_INTEREST");

export const idioPredicate = (seriesMetadata) => seriesMetadata?.seriesName?.toLowerCase().includes("idio");

export const kpiPredicate = (seriesMetadata) => seriesMetadata?.functionalTags?.includes("KPI") || seriesMetadata?.functionalTags?.includes("REVENUE");

export const idioSiPredicate = combinePredicatesOr(siPredicate, idioPredicate);

export const findMatchingPredicate = (item, predicates) => {
    const matchingPredicate = predicates.find(predicate => predicate(item));
    return matchingPredicate ? matchingPredicate : null;
}

//Given a hurricane series name, and a normal series, determines if the hurricane series is derieved from normal series:
export const isHurricaneSeriesPair = (candidateHurricaneSeries, candidateOGSeries) => {
    return removeSubstring(candidateHurricaneSeries, candidateOGSeries) === '_ex_se' ;
}

export function isValidParentSeriesForWeekly(candidateParentSeries, weeklySeries) {
    const weeklySeriesParent = removeSubstring(weeklySeries, "wkly:");
    // Do a string comparison after
    return weeklySeriesParent.replace(/\s+/g, '') === candidateParentSeries.replace(/\s+/g, '');
}

/**
 * [AI Written, verified] Removes all occurrences of a specified substring from a given string.
 * @param {string} str - The original string.
 * @param {string} subStr - The substring to be removed.
 * @returns {string} - The string with the specified substring removed.
 */
function removeSubstring(str, subStr) {
    // Replace all occurrences of subStr with an empty string
    return str.split(subStr).join('');
}

