import {createContext, useContext, useEffect, useState} from "react";
import {getAltGtTerms, getDynAltGtData} from "../../../api/data/DataProvider";
import {RefreshContext} from "../../oracle/commonContexts/RefreshContextProvider";

export const AltGtStackContext = createContext(null);

export const AltGtStackContextProvider = ({ children, activeTicker, activeBrand, queryType }) => {

    const {refresh} = useContext(RefreshContext);

    const [reload, setReload] = useState(false);
    const [clear, setClear] = useState(true);
    const [loading, setLoading] = useState(false);

    const [altTerms, setAltTerms] = useState([])
    const [selectedTerm, setSelectedTerm] = useState(null);

    const [dataState, setDataState] = useState({
        'US': {
            chartData: null,
            isError: false,
            loading: false
        },
        'WW': {
            chartData: null,
            isError: false,
            loading: false
        }
    });

    const _onClick = () => {
        setClear(false);
        setLoading(true);
        setReload((prev) => !prev);
    }

    useEffect(() => {
        const abortController = new AbortController();
        setSelectedTerm(null);
        setClear(true);
        getAltGtTerms(activeTicker)
            .then((res) => {
                setAltTerms(res.data);
            }).catch((err) => {
            if (err.code === "ERR_CANCELED") {
                // do nothing for now
            } else {
                console.error("Failed to fetch alt terms.", err);
            }
        });

        return () => abortController.abort();
    }, [activeTicker, activeBrand, refresh]);

    useEffect(() => {
        if(selectedTerm == null) return;
            //Load the data for the stack charts once a term has been selected:
        setDataState({
            'US': {
                chartData: null,
                isError: false,
                loading: true
            },
            'WW': {
                chartData: null,
                isError: false,
                loading: true
            }
        });
            const abortController = new AbortController();

            getDynAltGtData(activeTicker, queryType, selectedTerm.value, 'US', abortController)
                .then(res => setDataState( prev => ({
                    ...prev,
                        'US': {
                        chartData: res,
                         isError: false,
                         loading: false
                    }
                })))
                .catch(err => {
                    console.err(err);
                    if (err.code === "ERR_CANCELED") {
                        // do nothing for now
                    } else {
                        setDataState( prev => ({
                            ...prev,
                            'US': {
                                chartData: null,
                                isError: true,
                                loading: false
                            }
                        }))
                    }
                });
        getDynAltGtData(activeTicker, queryType, selectedTerm.value, 'WW', abortController)
            .then(res => setDataState( prev => ({
                ...prev,
                'WW': {
                    chartData: res,
                    isError: false,
                    loading: false
                }
            })))
            .catch(err => {
                console.err(err);
                if (err.code === "ERR_CANCELED") {
                    // do nothing for now
                } else {
                    setDataState( prev => ({
                        ...prev,
                        'WW': {
                            chartData: null,
                            isError: true,
                            loading: false
                        }
                    }))
                }
            });
    },
        [selectedTerm, reload, refresh]);

    useEffect(() => {
        setDataState({
            'US': {
                chartData: null,
                isError: false,
                loading: false
            },
            'WW': {
                chartData: null,
                isError: false,
                loading: false
            }
        })
    }, [clear])

    return(
        <AltGtStackContext.Provider value={{selectedTerm, setSelectedTerm, altTerms, dataState, _onClick}}>
            {children}
        </AltGtStackContext.Provider>
    )

}