import {CAlert} from "@coreui/react";
import {AgGridReact} from "@ag-grid-community/react";
import {Button} from "antd";
import {useEffect, useState} from "react";
import LoadingOverlay from "react-loading-overlay";
import {getAllFactorLoadings, getStockAndIdioReturn} from "../../../api/data/PortfolioDataProvider";
import MarketFactorChart from "../../line_chart/macro/MarketFactorChart";
import {columnDefs, transformationMap} from "./configuration/FactorLoadingsConfiguration";

const _ = require('lodash');

const applyTransformation = (rowData, type, source) => {
    let updatedRows = [];
    for (let transformKey of transformationMap.get(type)) {
        let value = null;
        let transformationLogic = transformKey[1];
        if (transformationLogic instanceof Function) {
            value = transformationLogic(source);
        } else if (typeof transformationLogic == 'string') {
            value = _.get(source, transformationLogic, null);
        }

        if (value !== null) {
            _.set(rowData, transformKey[0], value);
            if (updatedRows.length === 0) {
                updatedRows.push(rowData);
            }
        }
    }

    return updatedRows;
}

function FactorLoadingsScreen(props) {
    const [gridApi, setGridApi] = useState(null);
    const [factorLoadings, setFactorLoadings] = useState([]);
    const [loading, setLoading] = useState(false);

    // Filter state
    const [filterPositions, setFilterPositions] = useState(true);

    useEffect(() => {
        setLoading(true);
        getAllFactorLoadings(filterPositions).then((res) => {
            setFactorLoadings(res.data);
            setLoading(false);
        }).catch((err) => {
            console.error("Failed to fetch all factor loadings", err);
            setLoading(false);
        })
    }, [filterPositions]);

    useEffect(() => {
        const abortController = new AbortController();
        let tickerIds = factorLoadings.map(t => t.tickerId);
        if (null != tickerIds && tickerIds.length) {
            setLoading(true);
            getStockAndIdioReturn('', tickerIds, abortController)
                .then((res) => {
                    const data = res.data;
                    for (const returnObj of data) {
                        const tickerNode = gridApi.getRowNode(returnObj['tickerId']);
                        if (tickerNode) {
                            let updatedData = applyTransformation(tickerNode.data, 'stockReturn', returnObj);
                            gridApi.applyTransaction({ update: [updatedData] });
                            gridApi.refreshCells({ rowNodes: [tickerNode] })
                        }
                    }
                    setLoading(false);
                }).catch((err) => {
                    console.error("Failed to fetch stock and idio returns", err);
                    setLoading(false);
                });
        }

        return () => abortController.abort();
    }, [factorLoadings]);

    const _toggleFilterPositions = () => {
        setFilterPositions((prevState) => !prevState);
    }

    return (
        <div style={{ marginTop: 50 }}>
            <CAlert color={"warning"}>The Cumulative Returns are calculated as the sum of daily returns from the start date to the plot date.</CAlert>
            <MarketFactorChart queryType={"FACTOR_LOADINGS"} />
            <div style={{ margin: 10 }}>
                <Button type={filterPositions ? "primary" : undefined} onClick={_toggleFilterPositions}>Positions</Button>
                <Button type={!filterPositions ? "primary" : undefined} onClick={_toggleFilterPositions} style={{ marginLeft: 5 }}>Full Coverage</Button>
            </div>
            <LoadingOverlay active={loading} text={"Loading..."}>
                <div className="ag-theme-alpine nav-grid" style={{ overflow: 'scroll' }}>
                    <AgGridReact
                        onGridReady={(params) => {
                            setGridApi(params.api);
                        }}
                        rowData={factorLoadings}
                        columnDefs={columnDefs}
                        getRowId={(params) => params.data.tickerId}
                        animateRows={true}
                        showLoadingOverlay={true}
                        rowSelection='multiple'
                        alwaysShowVerticalScroll={true}
                        debounceVerticalScrollbar={true}
                        suppressColumnVirtualisation={true}
                        pagination={true}
                        paginationPageSize={30}
                        domLayout="print"
                    />
                </div>
            </LoadingOverlay>
        </div>
    )
}

export default FactorLoadingsScreen;