import {Table} from "antd";
import LoadingOverlay from 'react-loading-overlay';
import {useFactorWeightsProviderHook} from "../oracle/commonHooks/FactorWeightsProviderHook";
import {useContext} from "react";
import {RefreshContext} from "../oracle/commonContexts/RefreshContextProvider";

const limitDecimalsRenderer = (num) => {
    return <>{Math.round((num + Number.EPSILON) * 10000).toFixed(0)}</>
}

const generateColumns = (factorWeights) => {
    return factorWeights
        .filter(w => w.category.toLowerCase().includes("style")) // Implicitly filter for style factors only
        .sort((a, b) => b?.rawExposure - a?.rawExposure)
        .map((w) => {
            return {
                dataIndex: w?.id,
                title: w?.name,
                render: limitDecimalsRenderer,
                align: "center",
            }
        });
}

const generateDataSource = (factorWeights) => {
    let result = {key: 1};

    factorWeights.forEach(w => {
        result[w.id] = w.rawExposure;
    })

    return [result];
}

export function generateFactorWeightsTableFromData(loading, factorWeights) {
    const isEmpty = !factorWeights || Object.keys(factorWeights).length === 0 || factorWeights.length === 0;

    return (
        <div>
            <div id="factor-weight-table" style={{height: '0px'}}></div>
            <LoadingOverlay
                active={loading}
                spinner={loading}
                text={"Loading..."}
            >
                <div style={{width: '100%', marginTop: 0, paddingLeft: 0}}>
                    {isEmpty ? <span>No Factor Data available</span>
                        :
                        <Table
                            dataSource={generateDataSource(factorWeights)}
                            columns={generateColumns(factorWeights)}
                            size="small"
                            pagination={{hideOnSinglePage: true}}
                        />
                    }
                </div>
            </LoadingOverlay>

        </div>
    )
}

function FactorWeightTable(props) {
    const {refresh} = useContext(RefreshContext);
    const {factorWeights, loading} = useFactorWeightsProviderHook(props.activeTickerId, refresh);

    return generateFactorWeightsTableFromData(loading, factorWeights);
}

export default FactorWeightTable;