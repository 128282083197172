import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FinDataPage from './FinDataPage';
import { getTickers } from '../../api/data/DataProvider';
import { getAllPositions } from '../../api/data/PortfolioDataProvider';

const FinDataTabber = () => {
  const [tickerIds, setTickerIds] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [tickerOptions, setTickerOptions] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const searchParams = new URLSearchParams(location.search);
      const tickerIdsParam = searchParams.get('ticker_ids');
      const tickersParam = searchParams.get('tickers');

      // Fetch ticker options first
      const options = await getTickers();
      setTickerOptions(options.data.map(t => ({ value: t.tickerId, label: t.tick })));

      if (tickerIdsParam) {
        setTickerIds(tickerIdsParam.split(',').map(Number));
      } else if (tickersParam) {
        const tickerSymbols = tickersParam.split(',');
        const filteredOptions = options.data.filter(option => tickerSymbols.includes(option.tick));
        setTickerIds(filteredOptions.map(option => option.tickerId));
      } else {
        const positions = await getAllPositions('', [], '', null).then(positions => 
          positions.data.filter(position => position.tickerId !== null).sort((a, b) => Math.abs(b.currentMarketValue) - Math.abs(a.currentMarketValue))
        );
        setTickerIds(positions.map(position => position.tickerId));
      }
    };

    fetchData();
  }, [location]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
      } else if (event.key === 'ArrowRight') {
        setCurrentIndex((prevIndex) => (prevIndex < tickerIds.length - 1 ? prevIndex + 1 : prevIndex));
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [tickerIds]);

  const currentTickerId = tickerIds[currentIndex];
  const currentTicker = tickerOptions.find(ticker => ticker.value === currentTickerId);

  return (
    <div>
      {currentTicker && (
        <FinDataPage
          initialTicker={{ 
            name: currentTicker.label, 
            id: currentTicker.value
          }}
          showFinDataGrid={false}
        />
      )}
    </div>
  );
};

export default FinDataTabber;
