import {createContext, useContext, useEffect, useState} from "react";
import {fetchVptData} from "../experimental/FinDataPage";
import {
    FinancialSummaryMetric,
    LeaseSummaryMetrics,
    ValuationMetricsOrder,
    FinancialSummarySecondaryMetricMap,
    MainVPTMetrics,
    
    VPT_TIME_PERIODS
} from "./VptConfig";

import {formatCurrency} from "../../utils/FinDataUtils";
import {RefreshContext} from "../oracle/commonContexts/RefreshContextProvider";


const addMetrics = (metricName, metadata, quarters, years, halfYears, metricsArray, customProps) => {
    const metricObject = {
        ...metadata[metricName],
        ...customProps
    };

    quarters.forEach(quarter => {
        metricObject[quarter.period] = quarter[metricName];
    });

    //Populate values for all the half years:
    halfYears.forEach(halfYear => {
        metricObject[halfYear.period] = halfYear[metricName];
    });

    // populate values for all the years:
    years.forEach(year => {
        metricObject[year.period] = year[metricName];
    });

    metricsArray.push(metricObject);

}

const doDfsWithHierarchAddition = (metricName, metadata, quarters, years, halfYears, metricsArray, pathArray) => {
    if (!metadata[metricName]) return;
    const hierarchyArray = [...pathArray, metricName];
    addMetrics(metricName, metadata, quarters, years, halfYears, metricsArray, {hierarchy: hierarchyArray});
    pathArray.push(metricName);

    const node = metadata[metricName];
    // Loop over all the available children nodes:
    if (node.children != null && node.children.length > 0) {
        for (const child of node.children) {
            doDfsWithHierarchAddition(child, metadata, quarters, years, halfYears, metricsArray, pathArray);
        }
    }
    pathArray.pop();
}



export const VPTGridContext = createContext(null);

export const VPTGridProvider = ({ children, activeTick }) => {

    const {refresh} = useContext(RefreshContext);

    // VPT API logic:
    const [vptData, setVptData] = useState({
        data: null,
        loading: true,
        isError: false,
    });

    useEffect(() => {
        if(activeTick?.id == null) return;
        setVptData({
            data: null,
            loading: true,
            isError: false,
        });
        fetchVptData(activeTick.id)
            .then(res => setVptData({
                data: res,
                loading: false,
                isError: false,
            }))
            .catch(err => {
                console.error("Error while fetching the VPT data", err);
                setVptData({
                    data: null,
                    loading: false,
                    isError: true,
                });
            });
    }, [activeTick, refresh]);


    const [transformedData, setTransformedData] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [currencyCode, setCurrencyCode] = useState(null);
    const [dataSource, setDataSource] = useState(null);
    useEffect(() => {
        const data = vptData.data;
        if (data == null) {
            setTransformedData(null);
            return;
        }


        const metadata = data.metadata;
        const quarters = data.quarterly;
        const years = data.annually;
        const halfYears = data.halfyearly;


        // Period metadata:
        const periodsMetadata = {
            [VPT_TIME_PERIODS.QUARTER]: quarters.reduce((acc, item) => {
                acc[item.period] = {
                    period_end_date: item.period_end_date,
                    timePeriodType: item.data_type
                };
                return acc;
            }, {}),
            [VPT_TIME_PERIODS.HALFYEAR]: halfYears.reduce((acc, item) => {
                acc[item.period] = {
                    period_end_date: item.period_end_date,
                    timePeriodType: item.data_type
                };
                return acc;
            }, {}),
            [VPT_TIME_PERIODS.YEAR]: years.reduce((acc, item) => {
                acc[item.period] = {
                    period_end_date: item.period_end_date,
                    timePeriodType: item.data_type
                };
                return acc;
            }, {})
        }

        // console.log("Periods Metadata: ", periodsMetadata);

        //Manage the main metrics:
        const mainMetrics = [];

        Object.keys(MainVPTMetrics).forEach(key => {
            const metric = MainVPTMetrics[key];


            // Start a dfs, with the hierarch being preserved:
            doDfsWithHierarchAddition(metric, metadata, quarters, years, halfYears, mainMetrics, []);


        });


        // Array holding the metadata for each of the metric and all of the corresponding values:
        const valuationMetrics = [];


        ValuationMetricsOrder.forEach(item => {
            // Add the metadata in the object:
            const metricObject = {...metadata[item]};

            //Populate value for all the quarters:
            quarters.forEach(quarter => {
                metricObject[quarter.period] = quarter[item];
            });

            //Populate values for all the half years:
            halfYears.forEach(halfYear => {
                metricObject[halfYear.period] = halfYear[item];
            });

            // populate values for all the years:
            years.forEach(year => {
                metricObject[year.period] = year[item];
            })
            valuationMetrics.push(metricObject);
        });

        // Financial Summary items:
        const financialSummaryMetrics = [];
        Object.keys(FinancialSummaryMetric).forEach(metricKey => {
            const metric = FinancialSummaryMetric[metricKey];
            const hasSecondary = FinancialSummarySecondaryMetricMap.hasOwnProperty(metric);
            const metricObject = {
                ...metadata[metric],
                hasSecondary,
                secondary:
                    hasSecondary
                        ? metadata[FinancialSummarySecondaryMetricMap[metric]]
                        : null
            };
            quarters.forEach(quarter => {
                if (hasSecondary) {
                    metricObject[quarter.period] = {
                        primary: quarter[metric],
                        secondary: quarter[FinancialSummarySecondaryMetricMap[metric]]
                    };
                } else
                    metricObject[quarter.period] = quarter[metric]

            });

            //Populate values for all the half years:
            halfYears.forEach(halfYear => {
                if (hasSecondary) {
                    metricObject[halfYear.period] = {
                        primary: halfYear[metric],
                        secondary: halfYear[FinancialSummarySecondaryMetricMap[metric]]
                    };
                } else
                    metricObject[halfYear.period] = halfYear[metric]

            });

            // populate values for all the years:
            years.forEach(year => {
                if (hasSecondary) {
                    metricObject[year.period] = {
                        primary: year[metric],
                        secondary: year[FinancialSummarySecondaryMetricMap[metric]]
                    };
                } else
                    metricObject[year.period] = year[metric]

            });
            financialSummaryMetrics.push(metricObject);
        });

        // Add Lease Summary metrics processing
        const leaseSummaryMetrics = [];
        Object.keys(LeaseSummaryMetrics).forEach(metricKey => {
            const metric = LeaseSummaryMetrics[metricKey];
            const metricObject = {
                ...metadata[metric]
            };

            quarters.forEach(quarter => {
                metricObject[quarter.period] = quarter[metric];
            });

            halfYears.forEach(halfYear => {
                metricObject[halfYear.period] = halfYear[metric];
            });

            years.forEach(year => {
                metricObject[year.period] = year[metric];
            });

            leaseSummaryMetrics.push(metricObject);
        });

        setTransformedData({
            periodsMetadata,
            mainMetrics,
            valuationMetrics,
            financialSummaryMetrics,
            leaseSummaryMetrics  // Add to transformed data
        });

        const tradingCurrency = vptData.data?.company_info?.currency;
        const reportingCurrency = vptData.data?.company_info?.eqy_fund_crncy;
        const dataSource = vptData.data?.company_info?.source;
        setCurrencyCode({
            trading: tradingCurrency,
            reporting: reportingCurrency
        });
        setDataSource(dataSource);

    }, [vptData]);

    const {loading, isError} = vptData;

    return (
        <VPTGridContext.Provider value={{
            transformedData,
            loading,
            isError,
            currencyCode,
            dataSource
        }}>
            { children }
        </VPTGridContext.Provider>
    );

}
