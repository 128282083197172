import './LoginPage.css';
import {Form, Input, Button, Checkbox, message} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {loginUser} from "../../api/data/DataProvider";
import {useNavigate} from "react-router-dom";

function LoginPage(props) {
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    const onFinish = values => {
        console.log('Received values of form: ', values);
        loginUser(values.username, values.password).then((res) => {
            messageApi.open({
                type: 'success',
                content: 'Login Successful!',
            });

            localStorage.setItem('studio_internal_authenticated_user', values.username);
            navigate('/'); // Move to Home Page
        }).catch((err) => {
            console.error("Failed to login: {}", err);
            messageApi.open({
                type: 'error',
                content: 'Login Failed!',
            });
        })
    };

    return (
        <>
            {contextHolder}
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', fontSize: '130%'}}>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: false,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item>
                        Oracle Login
                    </Form.Item>
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Username!',
                            },
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>

                        <a className="login-form-forgot" href="">
                            Forgot password
                        </a>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

export default LoginPage;