import {CAlert} from "@coreui/react";
import {Switch} from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {useEffect, useState} from "react";
import LoadingOverlay from 'react-loading-overlay';
import {getSpreadData} from "../../../api/data/PortfolioDataProvider";
import {debounce} from "../../../utils/StudioChartUtils";
import {getCommonEarningsEvents} from "../../../api/data/DataProvider";
import dayjs from "dayjs";

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

function getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

function getColor(seriesName) {
    seriesName = seriesName.toLowerCase();
    if (seriesName.startsWith('equal') || seriesName.includes('net ')) {
        return '#ff00e4';
    } else if (seriesName.startsWith('contribution') || seriesName.includes('gross ')) {
        return '#636efa';
    } else if (seriesName.startsWith('long')) {
        if (seriesName.includes('equal')) {
            return '#03f84f';
        } else {
            return '#008a27';
        }
    } else if (seriesName.startsWith('short')) {
        if (seriesName.includes('equal')) {
            return '#fa3cb9';
        } else {
            return '#f85134';
        }
    } else if (seriesName.startsWith('nav')) {
        return '#007560';
    } else {
        return getRandomColor();
    }
}

const indicesToInclude = ['tlt', 'spy', 'qqq', 'xrt', 'igv', 'xly', 'iwm'];

const spreadSeriesToShow = [
    'Contribution Weighted Idio Spread',
    'Long Stock Idio (Cont Weighted)',
    'Short Stock Idio (Cont Weighted)',
];

const spreadSeriesOrder = [
    ...[
        'Contribution Weighted Spread',
        'Equal Weighted Spread',
        'Contribution Weighted Idio Spread',
        'Equal Weighted Idio Spread',
        'Long Stock (Cont Weighted)',
        'Long Stock (Equal Weighted)',
        'Long Stock Idio (Cont Weighted)',
        'Long Stock Idio (Equal Weighted)',
        'Short Stock (Cont Weighted)',
        'Short Stock (Equal Weighted)',
        'Short Stock Idio (Cont Weighted)',
        'Short Stock Idio (Equal Weighted)',
        'Hedges (Cont Weighted)',
        'Hedges (Equal Weighted)',
        'Total Short (Cont Weighted)',
        'Total Short (Equal Weighted)',
    ].map(s => s.toLowerCase()),
    ...indicesToInclude.map(s => s.toLowerCase()),
]

const grossSeriesToShow = [
    'Gross %',
    'Gross Value',
    'Beta Adj Net %',
    'Beta Adj Net Value',
]

const grossPercentageSeries = [
    'Gross %',
    'Beta Adj Gross %',
    'Net %',
    'Beta Adj Net %',
];

const grossDollarSeries = [
    'Gross Value',
    'Beta Adj Gross Value',
    'Net Value',
    'Beta Adj Net Value',
    'NAV',
];

function SpreadChart(props) {
    const [originalData, setOriginalData] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [grossChartData, setGrossChartData] = useState(null);
    const [loading, setLoading] = useState(chartData == null);
    const [isError, setIsError] = useState(false);
    const [rebaseToZero, setRebaseToZero] = useState(true);
    const [grossPercentMode, setGrossPercentMode] = useState(true);
    const [earningEvents, setEarningEvents] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();

        setIsError(false)
        setLoading(true)
        getSpreadData(props.accountGroup?.accountIds, indicesToInclude, abortController)
            .then((res) => {
                let data = res.data;
                for (const series of Object.values(data)) {
                    series.sort((a, b) => {
                        return a.dataDateMillis - b.dataDateMillis;
                    });
                }

                setOriginalData(data);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                if (err.code === "ERR_CANCELED") {
                    // do nothing for now
                } else {
                    setIsError(true);
                }
            })


        return () => abortController.abort();
    }, []);

    useEffect(() => {
        if (originalData) {
            setChartData((prev) => {
                return {
                    series: [...calculateSeriesData(originalData)],
                    title: {
                        text: 'Performance & Spread'
                    },
                    tooltip: {
                        shared: true,
                        split: true,
                        inactiveOtherSeries: false,
                        xDateFormat: "%A, %e %B, %Y",
                        pointFormatter: function () {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' +
                                Highcharts.numberFormat(this.y * 100, 2) + '%</b><br/>';
                        },
                    },
                    chart: {
                        zoomType: "x",
                        zooming: {
                            mouseWheel: {
                                enabled: false
                            }
                        },
                        height: 600,
                        plotBackgroundColor: '#E5ECF6',
                        panning: {
                            enabled: true,
                            type: 'x'
                        },
                        panKey: 'shift',
                        events: {
                            load: function () {
                                const chart = this;

                                // Wait for the chart to be fully rendered before selecting range
                                setTimeout(function () {
                                    chart.rangeSelector.clickButton(2, true);
                                }, 100);
                            }
                        }
                    },
                    legend: {
                        enabled: true,
                        align: "right",
                        verticalAlign: "middle",
                        layout: "vertical",
                        width: 200,
                        itemWidth: 200,
                        itemStyle: {
                            color: "#000000",
                            fontSize: '12px',
                            textOverflow: 'ellipsis'
                        },
                        itemHiddenStyle: {
                            textDecoration: 'none'  // Disable strikethrough effect
                        }
                    },
                    navigation: {
                        buttonOptions: {
                            align: "right",
                            verticalAlign: "top",
                            y: 10
                        }
                    },
                    plotOptions: {
                        marker: {
                            enabled: false
                        }
                    },
                    xAxis: {
                        type: "datetime",
                        labels: {
                            enabled: true
                        },
                        dateTimeLabelFormats: {
                            day: "%Y-%m-%d"
                        },
                        gridLineWidth: 1,
                        gridLineColor: "#fff",
                        events: {
                            afterSetExtremes: debounce((e) => {
                                // Recalculate series here based on available range
                                let chart = e?.target?.chart;
                                if (chart && originalData) {
                                    let startTimestamp = e.min;
                                    let spreadData = rebaseToZero ? calculateSeriesData(originalData, startTimestamp)
                                        : calculateSeriesData(originalData);
                                    if (spreadData) {
                                        for (let series of spreadData) {
                                            let seriesName = series.name;
                                            let seriesData = series.data;
                                            let seriesObject = chart?.series.find(s => s.name === seriesName);
                                            if (seriesObject) {
                                                seriesObject.update({data: seriesData}, true);
                                            }
                                        }
                                    }
                                }
                            }, 100)
                        },
                        plotBands: calculatePlotBands(),
                    },
                    yAxis: [
                        {
                            title: {
                                text: "Return Percent %"
                            },
                            visible: true,
                            opposite: false,
                            gridLineWidth: 1,
                            gridLineColor: "#fff",
                            labels: {
                                formatter: function () {
                                    return (this.value * 100) + '%';
                                }
                            }
                        }
                    ]
                }
            });
            setGrossChartData((prev) => {
                return {
                    series: calculateGrossChartSeriesData(originalData, grossPercentMode),
                    title: {
                        text: 'Gross Value'
                    },
                    tooltip: {
                        shared: true,
                        split: true,
                        inactiveOtherSeries: false,
                        xDateFormat: "%A, %e %B, %Y",
                        pointFormatter:
                            grossPercentMode ?
                                function () {
                                    return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' +
                                        Highcharts.numberFormat(this.y * 100, 2) + '%</b><br/>';
                                }
                                :
                                function () {
                                    let formattedNumber = Highcharts.numberFormat(Math.abs(this.y), 0, '.', ',');
                                    let signCheck = this.y < 0 ? '(' : '';
                                    let signCheckClose = this.y < 0 ? ')' : '';

                                    return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' +
                                        signCheck + '$' + formattedNumber + signCheckClose + '</b><br/>';
                                }
                    },
                    rangeSelector: {
                        selected: 2
                    },
                    chart: {
                        zoomType: "x",
                        zooming: {
                            mouseWheel: {
                                enabled: false
                            }
                        },
                        height: 600,
                        plotBackgroundColor: '#E5ECF6',
                        panning: {
                            enabled: true,
                            type: 'x'
                        },
                        panKey: 'shift'
                    },
                    legend: {
                        enabled: true,
                        align: "right",
                        verticalAlign: "middle",
                        layout: "vertical",
                        width: 200,
                        itemWidth: 200,
                        itemStyle: {
                            color: "#000000",
                            fontSize: '12px',
                            textOverflow: 'ellipsis'
                        },
                        itemHiddenStyle: {
                            textDecoration: 'none'  // Disable strikethrough effect
                        }
                    },
                    navigation: {
                        buttonOptions: {
                            align: "right",
                            verticalAlign: "top",
                            y: 10
                        }
                    },
                    plotOptions: {
                        marker: {
                            enabled: false
                        }
                    },
                    xAxis: {
                        type: "datetime",
                        labels: {
                            enabled: true
                        },
                        dateTimeLabelFormats: {
                            day: "%Y-%m-%d"
                        },
                        gridLineWidth: 1,
                        gridLineColor: "#fff",
                        plotBands: calculatePlotBands(),
                    },
                    yAxis: [
                        grossPercentMode ?
                            {
                                title: {
                                    text: "Percent %"
                                },
                                visible: true,
                                opposite: false,
                                gridLineWidth: 1,
                                gridLineColor: "#fff",
                                labels: {
                                    formatter: function () {
                                        return (this.value * 100) + '%';
                                    }
                                }
                            }
                            :
                            {
                                title: {
                                    text: "USD"
                                },
                                visible: true,
                                opposite: false,
                                gridLineWidth: 1,
                                gridLineColor: "#fff",
                                labels: {
                                    formatter: function () {
                                        let value = this.value;
                                        let absValue = Math.abs(value);
                                        let formattedNumber = Highcharts.numberFormat(absValue > 1000000 ?
                                            absValue / 1000000 :
                                            absValue / 1000, 0);
                                        let prefix = absValue > 1000000 ? 'M' : 'K';
                                        let sign = value < 0 ? '-' : '';
                                        return sign === '-'
                                            ? '(' + '$' + formattedNumber + prefix + ')'
                                            : '$' + formattedNumber + prefix;
                                    }
                                }
                            }
                    ]
                }
            });
        }

    }, [originalData, rebaseToZero, earningEvents, grossPercentMode]);

    useEffect(() => {
        getCommonEarningsEvents()
            .then((res) => {
                let data = res.data;
                data = data.filter(e => e?.eventType === 'EARNINGS_SEASON')
                setEarningEvents(data);
            })
            .catch((err) => {
                console.error("Failed to get earning events!", err);
            })
    }, []);

    const calculateSeriesData = (rawData, startTimestamp) => {
        let startDateStr = startTimestamp ? dayjs(startTimestamp).format('YYYY-MM-DD') : "1900-01-01";
        let seriesArr = [];

        for (const series of Object.values(rawData)) {
            if (series.length !== 0 && spreadSeriesOrder.includes(series[0].source.toLowerCase())) {
                let prevValue = null, currValue = null;
                let newSeriesData = [];
                for (let point of series) {
                    if (point?.dataDate < startDateStr) {
                        newSeriesData.push([point?.dataDateMillis, 0.0]);
                    } else {
                        if (null === currValue) {
                            currValue = 0.0;
                        } else {
                            currValue = (1 + prevValue) * point?.value + prevValue;
                        }
                        newSeriesData.push([point?.dataDateMillis, currValue]);
                        prevValue = currValue;
                    }
                }

                let newSeries = {
                    data: newSeriesData,
                    name: series[0].source,
                    visible: spreadSeriesToShow.includes(series[0].source),
                    showInLegend: true,
                    color: getColor(series[0].source),
                    lineWidth: 2,
                    dashStyle: series[0].source.includes('Idio') ? 'dash' : 'solid',
                    yAxis: 0
                };

                seriesArr.push(newSeries);
            }
        }

        seriesArr.sort((a, b) => spreadSeriesOrder.indexOf(a.name.toLowerCase()) - spreadSeriesOrder.indexOf(b.name.toLowerCase()));
        return seriesArr;
    }

    const calculateGrossChartSeriesData = (rawData, isPercentMode) => {
        let seriesArr = [];
        let currentModeSeries = isPercentMode ? grossPercentageSeries : grossDollarSeries;

        for (const series of Object.values(rawData)) {
            if (series.length !== 0 && currentModeSeries.includes(series[0].source)) {
                let newSeriesData = [];
                for (let point of series) {
                    let newPoint = [point?.dataDateMillis, point?.value];
                    newSeriesData.push(newPoint);
                }

                let newSeries = {
                    data: newSeriesData,
                    name: series[0].source,
                    visible: grossSeriesToShow.includes(series[0].source),
                    showInLegend: true,
                    color: getColor(series[0].source),
                    lineWidth: 2,
                    dashStyle: series[0].source.includes('Beta Adj') ? 'dash' : 'solid',
                    yAxis: 0
                };

                seriesArr.push(newSeries);
            }
        }

        seriesArr.sort((a, b) => currentModeSeries.indexOf(a.name) - currentModeSeries.indexOf(b.name));
        return seriesArr;
    }

    const onCalcMethodChange = (checked) => {
        setRebaseToZero(checked);
    };

    const onGrossCalcMethodChange = (checked) => {
        setGrossPercentMode(checked);
    };

    const calculatePlotBands = () => {
        let plotBands = [];

        for (const event of earningEvents) {
            let startTime = dayjs.utc(event?.startDate).startOf('day').valueOf();
            let endTime = dayjs.utc(event?.endDate).startOf('day').valueOf();
            let eventName = event?.eventName;
            plotBands.push({
                from: startTime,
                to: endTime,
                color: 'rgba(144, 238, 144, 0.2)',
                label: {
                    // text: eventName,
                    verticalAlign: 'bottom',
                    y: -2,
                }
            });
        }
        return plotBands;
    }

    return (
        <>
            <a id='spread-chart' href={""}> </a>
            <LoadingOverlay
                active={loading && !isError}
                spinner={!isError}
                text={"Loading..."}
            >
                {isError && <div>
                    <CAlert color="danger">
                        Failed to load chart.
                        Data Not Configured
                    </CAlert>
                </div>}
                {chartData == null && !isError && <p style={{height: "400px", width: "90%"}}> ...</p>}
                {chartData != null && !isError && chartData.series.length === 0 && <div>
                    <CAlert color="warning">
                        Data Not Available
                    </CAlert>
                </div>}
                {chartData != null && !isError && chartData.series.length !== 0 &&
                    <center>
                        <Switch onChange={onCalcMethodChange} checkedChildren='Recenter to 0'
                                unCheckedChildren='Static calculation' defaultChecked/>
                        <div style={{marginTop: 10}}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'stockChart'}
                                options={chartData}
                            />
                        </div>
                    </center>}
                {grossChartData != null && !isError && grossChartData.series.length !== 0 &&
                    <center>
                        <Switch onChange={onGrossCalcMethodChange} checkedChildren='Percent'
                                unCheckedChildren='Dollars' defaultChecked/>
                        <div style={{marginTop: 10}}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'stockChart'}
                                options={grossChartData}
                            />
                        </div>
                    </center>}
            </LoadingOverlay>

        </>
    )
}

export default SpreadChart;