import Measure from "react-measure";
import {VPTGrid} from "../vpt/VPTGrid";
import {StudioHistoricalPriceChart} from "../studio_high_chart/StudioHistoricalPriceChart";
import {StudioIntradayPriceChart} from "../studio_high_chart/StudioIntradayPriceChart";
import useElementMeasurements from "../oracle/commonHooks/ElementMeasurementsHook";

const ESTIMATED_INITIAL_HEIGHT = 500;
const ESTIMATED_INITIAL_WIDTH = 500;

export const FinDataGrid = () => {
    const [ dimensions, handleResize]
        = useElementMeasurements(ESTIMATED_INITIAL_HEIGHT, ESTIMATED_INITIAL_WIDTH);

    return (
        <>
            <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridTemplateRows: "1fr 1fr",
                gap: "10px",
                minHeight: dimensions.height,
                minWidth: dimensions.width,
            }}>
                <div style={{ gridRow: "1", gridColumn: "1" }}>
                    <VPTGrid onlyGridView={true} gridHeight={dimensions.height} gridWidth={dimensions.width} />
                </div>
                <div style={{ gridRow: "1", gridColumn: "2" }}>
                    <Measure
                        bounds
                        onResize={handleResize}>
                        {({ measureRef }) => (
                            <div ref={measureRef} key={`findata-grid-component-intradayPrice`}>
                                <StudioIntradayPriceChart chartHeight={dimensions.height} chartWidth={dimensions.width} />
                            </div>
                        )}
                    </Measure>
                </div>
                <div style={{ gridRow: "2", gridColumn: "1 / span 2" }}>
                    <StudioHistoricalPriceChart chartHeight={dimensions.height} chartWidth={dimensions.width * 2} />
                </div>
            </div>
        </>
    );
};