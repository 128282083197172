import {createContext, useContext, useEffect, useState} from "react";
import {getPositionInfo, getShortInterestInfo} from "../../../api/data/DataProvider";
import {getCompanyProfileForTicker, getWashsaleInfoForTicker} from "../../../api/data/PortfolioDataProvider";
import {RefreshContext} from "./RefreshContextProvider";

export const TickerWatchListContext = createContext();
export const TickerWatchListProvider = ({children, activeTick}) => {

    const {refresh} = useContext(RefreshContext);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [positionInfo, setPositionInfo] = useState(null);
    const [shortInterestInfo, setShortInterestInfo] = useState(null);
    const [companyProfileInfo, setCompanyProfileInfo] = useState(null);

    const [washSaleInfo, setWashSaleInfo] = useState([]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const abortController = new AbortController();
        if (activeTick.id !== null) {
            setPositionInfo(undefined);
            getPositionInfo(activeTick.id, abortController)
                .then((res) => {
                    setPositionInfo(res.data);
                })
                .catch((err) => {
                    if (err.code === "ERR_CANCELED") {
                        // do nothing for now
                    } else {
                        if (err.response.status === 404) {
                            setPositionInfo("NO_POSITION");
                        } else {
                            setPositionInfo(null);
                        }
                    }
                });

            getShortInterestInfo(activeTick.id, abortController)
                .then((res) => {
                    setShortInterestInfo(res.data);
                })
                .catch((err) => {
                    if (err.code === "ERR_CANCELED") {
                        // do nothing for now
                    }
                });

            // Fetching Washsale Info
            const currentDate = new Date().toISOString().slice(0,10);
            setWashSaleInfo(undefined);
            getWashsaleInfoForTicker(currentDate, activeTick.id, abortController)
                .then((res) => {
                    setWashSaleInfo(res.data.stocks);
                })
                .catch((err) => {
                    if (err.code === "ERR_CANCELED") {
                        // do nothing for now
                    } else {
                        console.error("Failed to fetch washsale info", err);
                        setWashSaleInfo(null);
                    }
                })

            // Fetching Company Profile Info
            getCompanyProfileForTicker(activeTick.id, abortController)
                .then((res) => {
                    setCompanyProfileInfo(res.data);
                }).catch((err) => {
                if (err.code === "ERR_CANCELED") {
                    // do nothing for now
                } else {
                    console.error("Failed to fetch company profile info", err);
                    setCompanyProfileInfo(null);
                }
            })
        }

        return () => abortController.abort();
    }, [activeTick, refresh])

    return <TickerWatchListContext.Provider value={{
        isModalOpen,
        setIsModalOpen,
        positionInfo,
        setPositionInfo,
        shortInterestInfo,
        setShortInterestInfo,
        handleOk,
        handleCancel,
        washSaleInfo,
        setWashSaleInfo,
        companyProfileInfo,
        setCompanyProfileInfo
    }}>
        {children}
    </TickerWatchListContext.Provider>
}