import {Alert, Button, InputNumber, Layout, Menu, Table} from "antd";
import {useEffect, useState} from "react";
import {SALES_COMPOSITE_SCREENER_TABLE_COLUMNS} from "./Utils";
import {getSalesCompositeIndexScreenerData} from "../../api/data/ScreenerDataProvider";
import {monitor} from "../../event_handler/KeyPressMonitor";

const { Header, Content } = Layout;


function CompositeScreener(props) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [isEditWt, setEditWt] = useState(false);

    const [reload, setReload] = useState(false);

    const [weightsPayload, setWeightsPayload] = useState({
        "orionWeight" : 0.3,
        "velorumWeight" : 0.15,
        "gammaWeight" : 0.25,
        "smWeight" : 0.3
    });

    useEffect(() => {
        setLoading(true);
        getSalesCompositeIndexScreenerData(weightsPayload)
            .then((res) => {
                setData(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.error("Failed to load sales composite index screener data.", err);
                setLoading(false);
                alert("Failed to load data. Please check logs.")
            });
    }, [reload]);

    return (
        <>
            <Layout>
                <Header style={{ height: 45, position: "fixed", padding: '0 10px', width: "100%", zIndex: 1000 }}>
                    <div className="logo" style={{ float: "left", marginTop: -10, color: "#ffffff" }}>
                        <img style={{ height: 25, marginRight: 15 }} src={"/dashboard_logo.png"} />
                    </div>
                    <Menu mode={"horizontal"} theme={"dark"} style={{ height: 45, alignItems: "center", overflow: "hidden" }}>
                        <Menu.Item key="0">
                            <a style={{textDecoration: "none"}} href={"/"} target={"_blank"}> Oracle Dashboard </a>
                        </Menu.Item>
                    </Menu>
                </Header>
                <Content style={{marginTop: 50, height: "100vh"}}>
                    <div>
                        <h5>Weights to use in the composite index calculation</h5>
                        <InputNumber
                            prefix={<b>Orion</b>}
                            style={{
                                width: 200,
                                margin: 10
                            }}
                            onFocus={() => monitor.enableHotKeys(false)}
                            onBlur={() => monitor.enableHotKeys(true)}
                            onChange={(v) => {
                                setWeightsPayload((prev) => {
                                    return {
                                        ...prev,
                                        orionWeight: v
                                    }
                                });
                            }}
                            disabled={!isEditWt}
                            defaultValue={weightsPayload.orionWeight}
                            min="0"
                            max="1"
                            step="0.01"
                            stringMode />

                        <InputNumber
                            prefix={<b>Gamma</b>}
                            style={{
                                width: 200,
                                margin: 10
                            }}
                            onChange={(v) => {
                                setWeightsPayload((prev) => {
                                    return {
                                        ...prev,
                                        gammaWeight: v
                                    }
                                });
                            }}
                            disabled={!isEditWt}
                            defaultValue={weightsPayload.gammaWeight}
                            min="0"
                            max="1"
                            step="0.01"
                            onFocus={() => monitor.enableHotKeys(false)}
                            onBlur={() => monitor.enableHotKeys(true)}
                            stringMode />

                        <InputNumber
                            prefix={<b>Velorum</b>}
                            style={{
                                width: 200,
                                margin: 10
                            }}
                            onChange={(v) => {
                                setWeightsPayload((prev) => {
                                    return {
                                        ...prev,
                                        velorumWeight: v
                                    }
                                });
                            }}
                            onFocus={() => monitor.enableHotKeys(false)}
                            onBlur={() => monitor.enableHotKeys(true)}
                            disabled={!isEditWt}
                            defaultValue={weightsPayload.velorumWeight}
                            min="0"
                            max="1"
                            step="0.01"
                            stringMode />

                        <InputNumber
                            prefix={<b>Sm</b>}
                            style={{
                                width: 200,
                                margin: 10
                            }}
                            onChange={(v) => {
                                setWeightsPayload((prev) => {
                                    return {
                                        ...prev,
                                        smWeight: v
                                    }
                                });
                            }}
                            disabled={!isEditWt}
                            defaultValue={weightsPayload.smWeight}
                            onFocus={() => monitor.enableHotKeys(false)}
                            onBlur={() => monitor.enableHotKeys(true)}
                            min="0"
                            max="1"
                            step="0.01"
                            stringMode />

                        <br/>
                        <Button style={{margin: 10, width: 80, marginTop: 12}} type={"primary"} disabled={isEditWt} onClick={() => setEditWt((prev) => !prev)}>Edit</Button>
                        <Button style={{margin: 10, width: 80, marginTop: 12}} type={"primary"} disabled={!isEditWt}
                        onClick={() => {
                            setEditWt((prev) => !prev);
                            setReload((prev) => !prev);
                        }}
                        >Apply</Button>
                    </div>
                    <center>
                    <Alert message={
                        <span><b>Studio Index Guidance</b> - The column is essentially difference between studio sales composite index YoY value and the estimated YoY value.
                            <br/>It may take up to 3-4 minutes (first time) to recompute indexes if above weights are changed.
                        </span>}
                           style={{width: 1000}}
                           type="info" />
                    </center>
                    <Table
                        loading={loading}
                        style={{margin: 15}}
                        bordered
                        dataSource={data}
                        columns={SALES_COMPOSITE_SCREENER_TABLE_COLUMNS}
                        size={"large"}
                        title={() => <b>Studio Sales Composite Index Screen</b>}
                    >

                    </Table>
                </Content>
            </Layout>
        </>
    )
}

export default CompositeScreener;