import React, {memo, useCallback, useContext, useEffect} from 'react';
import {areEqual, FixedSizeList as List} from 'react-window';
import {ProjectionsChartGrid} from './ProjectionsChartGrid';
import {ProjectionsInfoContext} from './ProjectionsInfoProvider';
import {ViewContextStore} from '../views/ViewContext';
import useWindowDimensions from '../../commonHooks/WindowDimensionsHook';
import {ProjectionsAdderGrid} from "./PositionsAdderGrid";


const shallowCompare = (prevProps, nextProps) => {
    for (let key in prevProps) {
        if (prevProps.hasOwnProperty(key) && nextProps.hasOwnProperty(key)) {
            if (prevProps[key] !== nextProps[key]) {
                console.log(`Prop '${key}' changed:`, prevProps[key], '->', nextProps[key]);
                return false;
            }
        }
    }
    return true;
};

const Row =memo(({ index, style, data }) => {
    const { chartLayout, removeProjections,  addableProjections, addProjections  } = data;
    let outerDivKey = 'ProjectionsChartLayout' + 'parentDiv';
    if(index < chartLayout.length) outerDivKey  +=  chartLayout[index].rhs?.seriesName + chartLayout[index].lhs?.seriesName;
    return (
        <div
            style={style}
            key={outerDivKey}
        >
            { index < chartLayout.length && (chartLayout[index].rhs != null || chartLayout[index].lhs != null) ? (
                <ProjectionsChartGrid
                    chartLayout={chartLayout[index]}
                    key={'ProjectionsChartLayout' + chartLayout[index].rhs?.seriesName + chartLayout[index].lhs?.seriesName}
                    removeProjections={removeProjections}
                />
            ) : null}
            {
                index === chartLayout.length && (
                    <ProjectionsAdderGrid addableProjections={addableProjections} addProjection={addProjections} />
                )
            }
        </div>
    );
}, areEqual);

const ProjectionsScreenInternal = React.memo(({ chartLayout, removeProjections, availableProjectionsUpdated, addableProjections, addProjections, height, width }) => {
    // Memoize row renderer function
    const renderRow = useCallback(({ index, style }) => (
        <Row
            index={index}
            style={style}
            data={{ chartLayout, removeProjections, addableProjections, addProjections }}
        />
    ), [chartLayout, removeProjections]);

    return (
        <>
            {availableProjectionsUpdated ? (
                <>
                    <div style={{ height: height, width: width }}>
                        <List
                            height={height + 60}
                            itemCount={chartLayout.length + 1}
                            itemSize={height+ 60}
                            width={'100%'}

                        >
                            {renderRow}
                        </List>
                    </div>

                </>
            ) : (
                <h1>Please wait. Available projections have not yet been determined.</h1>
            )}
        </>
    );
});

export const ProjectionsScreen = () => {
    const {
        availableProjectionsUpdated,
        chartLayout,
        addProjections,
        removeProjections,
        addableProjections,
        setProjectionsScreenActive
    } = useContext(ProjectionsInfoContext);

    const { toggleCombineAxisBulk, setShowWeeklySeries } = useContext(ViewContextStore);

    const { height, width } = useWindowDimensions();

    useEffect(() => {
        setProjectionsScreenActive(true);
        return () => {
            setProjectionsScreenActive(false);
        };
    }, [setProjectionsScreenActive]);

    useEffect(() => {
        toggleCombineAxisBulk();
        setShowWeeklySeries(true);
    }, [toggleCombineAxisBulk, setShowWeeklySeries]);

    return (
        <ProjectionsScreenInternal
            chartLayout={chartLayout}
            removeProjections={removeProjections}
            availableProjectionsUpdated={availableProjectionsUpdated}
            addableProjections={addableProjections}
            addProjections={addProjections}
            height={height}
            width={width}
        />
    );
};
