import {useContext} from "react";
import {Button, Select} from "antd";
import DynSwStackChart from "./DynSwStackChart";
import {DynSWStackContext} from "./DynSWStackContext";


function DynSwStackChartsWrapper(props) {

    const {selectedUrl, setSelectedUrl, mode, setMode, avlUrls, _onClick} = useContext(DynSWStackContext);

    const defTerm = {
        label: "Select SW Url",
        value: null
    }

    const parseOptions = () => {
        return avlUrls.map(entry => {
            return {
                value: entry.swSearchId,
                label: entry.url
            };
        });
    }

    return (
        <>
            <div>
                <a id={props.queryType} href={""} />
                    <Select
                        value={selectedUrl == null ? defTerm: {label: selectedUrl.label, value: selectedUrl.value}}
                        showSearch
                        onSelect={(value, element) => setSelectedUrl(element)}
                        style={{width: 500}}
                        options={parseOptions()} />
                    <Button type="primary"
                            style={{backgroundColor: "#1677ff", marginLeft: 15}}
                            disabled={selectedUrl==null}
                            onClick={_onClick}>
                        Load SW Visits
                    </Button>
                    <Button style={{marginLeft: 15}}
                            onClick={() => {
                                setMode((prev) => {
                                    if (prev === "SW_METRICS") return "SW_SEG_METRICS"
                                    else return "SW_METRICS"
                                })
                            }}>
                        {mode}
                    </Button>
                    <DynSwStackChart
                        mode={mode}
                        activeTicker={props.activeTicker}
                        queryType={props.queryType}
                        selectedUrl={selectedUrl}
                        location={"world"} />
                    <DynSwStackChart
                        mode={mode}
                        activeTicker={props.activeTicker}
                        queryType={props.queryType}
                        selectedUrl={selectedUrl}
                        location={"us"}/>
                </div>
        </>
    )
}

export default DynSwStackChartsWrapper;