import {useContext, useEffect, useState} from "react";
import {generateLinkForOracleClickout} from "../../studio_high_chart/utilityFunctions";
import {Link} from "react-router-dom";
import {TickerWatchListContext} from "../commonContexts/TickerWatchListProvider";
import {
    getCompanyProfileInfoComponent,
    getShortInterestComponent,
    getTextsLength,
    getWashSaleTexts,
    washSaleRenderingComponent
} from "../commonUtils/WatchListBarUtilFunctions";
import WaitListMarker from "../../stock_grades/WLMarker";
import {NextEarningsDateContext} from "../commonContexts/NextEarningsDateProvider";
import {BrandDropdown} from "../components/BrandDropdown";
import useClickoutProviderHook from "../commonHooks/ClickoutProviderHook";
import {useFactorWeightsProviderHook} from "../commonHooks/FactorWeightsProviderHook";
import {Tooltip} from 'react-tooltip';
import {generateFactorWeightsTableFromData} from "../../line_chart/FactorWeightTable";
import {ListSelectionBar} from "./ListSelectionBar";
import {RefreshContext} from "../commonContexts/RefreshContextProvider";
import useWindowDimensions from "../commonHooks/WindowDimensionsHook";

const enabledLinkStyleGT = {};
const disabledLinkStyleGT = {pointerEvents: "none", opacity: 0.6, color: "black"};
const fixedLinkWidthLink = 150; // Fixed width in pixels

const calculateFontSize = (text, fixedWidth, minSize, maxSize, lineCount = 1) => {
    const charCount = text.length;
    return calculateFontSizeGivenLength(charCount, fixedWidth, minSize, maxSize, lineCount);
};

const calculateFontSizeGivenLength = (textLength, fixedWidth, minSize, maxSize, lineCount = 1) => {
    const size = fixedWidth / (textLength * 0.6 * lineCount); // Assuming average character width is 0.6em

    return `${Math.min(Math.max(size, minSize), maxSize)}px`;
}

const fixedWidthStyle = { width: '150px', display: 'inline-block', overflow: 'hidden', textOverflow: 'ellipsis' };

export const TickerBar = ({activeTick, brand, setBrand, showDataLag, showViewControls, showPositionsTooltips}) => {

    const {refresh} = useContext(RefreshContext);

    const {washSaleInfo, shortInterestInfo, positionInfo, companyProfileInfo} = useContext(TickerWatchListContext);
    const {earningsInfo, daysToEarning} = useContext(NextEarningsDateContext);

    const {
        clickOutUrl: clickoutUrl_us,
        loading: loading_us,
        make3Y: make3Y_us,
        make4Y: make4Y_us,
        make5Y: make5Y_us
    } = useClickoutProviderHook(activeTick.id, brand.id, "GT_US", refresh);

    const {
        clickOutUrl: clickoutUrl_ww,
        loading: loading_ww,
        make3Y: make3Y_ww,
        make4Y: make4Y_ww,
        make5Y: make5Y_ww
    } = useClickoutProviderHook(activeTick.id, brand.id, "GT_WW", refresh);

    const {
        clickOutUrl: clickoutUrl_AS_overview,
        loading: loading_AS_overview,
    } = useClickoutProviderHook(activeTick.id, brand.id, "ALPHASENSE_OVERVIEW", refresh);

    const {
        clickOutUrl: clickoutUrl_AS_vpt,
        loading: loading_AS_vpt,
    } = useClickoutProviderHook(activeTick.id, brand.id, "ALPHASENSE_VPT", refresh);

    const {
        clickOutUrl: clickoutUrl_ER_cohort,
        loading: loading_ER_cohort,
    } = useClickoutProviderHook(activeTick.id, brand.id, "ER_COHORT", refresh);

    const {
        clickOutUrl: clickoutUrl_ER_trends,
        loading: loading_ER_trends,
    } = useClickoutProviderHook(activeTick.id, brand.id, "ER_TRENDS", refresh);

    const {
        clickOutUrl: clickoutUrl_SM_cohort,
        loading: loading_SM_cohort,
    } = useClickoutProviderHook(activeTick.id, brand.id, "SM_COHORT", refresh);

    // Data fetching and tooltip state management for factorWeights table.
    const {factorWeights, loading: loading_factors} = useFactorWeightsProviderHook(activeTick.id, refresh);

    const colorGetter = num => num >= 0 ? "green" : "red";
    const numberFormatter = num => {
        if (num >= 0) {
            return num.toFixed(0);
        } else {
            return '(' + Math.abs(num).toFixed(0) + ')';
        }
    }
    let positionTextComponent;

    if (positionInfo === undefined) {
        positionTextComponent = <span>Loading...</span>;
    } else if (positionInfo === null || positionInfo === "NO_POSITION" || positionInfo.position === 0) {
        positionTextComponent =
            <span style={{color: "#000000"}}>NO_POSITION</span>
    } else {
        let nav = positionInfo.currentMarketValue / positionInfo?.ratioOfNav;
        let bpsStock = positionInfo.stockPosition?.ratioOfNav * 10000;
        let bpsOptionsMax = (positionInfo?.callOptions.map(p => p.currentDeltaDollars / Math.abs(p?.delta)).reduce((a, b) => a + b, 0)
            + positionInfo?.putOptions.map(p => p.currentDeltaDollars / Math.abs(p?.delta)).reduce((a, b) => a + b, 0)) * 10000 / nav;
        let bpsOptionsCurrent = (positionInfo.callOptions.map(p => p.currentDeltaDollars).reduce((a, b) => a + b, 0)
            + positionInfo.putOptions.map(p => p.currentDeltaDollars).reduce((a, b) => a + b, 0)) * 10000 / nav;
        let bpsOptionsMarketValue = (positionInfo.callOptions.map(p => p?.ratioOfNav * Math.sign(p?.delta)).reduce((a, b) => a + b, 0)
            + positionInfo.putOptions.map(p => p?.ratioOfNav * Math.sign(p?.delta)).reduce((a, b) => a + b, 0)) * 10000;
        let bpsIncludingDelta = bpsStock + bpsOptionsCurrent;
        let profitValue = positionInfo.unrealizedPnl * 100 / Math.abs(positionInfo.currentMarketValue - positionInfo.unrealizedPnl);
        let profitText;
        if (profitValue > 0) {
            profitText = profitValue.toFixed(2) + '% ';
        } else {
            profitText = '(' + Math.abs(profitValue).toFixed(2) + '%) ';
        }
        let positionInDaysOfVol, positionInDaysOfVolTitle;
        if (companyProfileInfo !== null && companyProfileInfo !== undefined) {
            positionInDaysOfVol = (Math.abs(positionInfo.stockPosition?.position) / companyProfileInfo?.volAvg).toFixed(1);
            positionInDaysOfVolTitle = `Position of ${Math.abs(positionInfo.stockPosition?.position)} shares with ${companyProfileInfo?.volAvg} traded per day`;
        }

        positionTextComponent =
            <span>
                <span title="Bps of stock and options delta over NAV" style={{
                    color: colorGetter(bpsIncludingDelta)
                }}>{numberFormatter(bpsIncludingDelta)}</span> = <span
                title="Bps of stock market value over NAV"
                style={{color: colorGetter(bpsStock)}}>{numberFormatter(bpsStock)}</span> + <span
                title="Bps of current options delta over NAV" style={{
                color: colorGetter(bpsOptionsCurrent)
            }}>{numberFormatter(bpsOptionsCurrent)}</span> | <span>
                    <span title="Bps of options with maximum delta over NAV" style={{
                        color: colorGetter(bpsOptionsMax)
                    }}>{numberFormatter(bpsOptionsMax)}</span> <span
                title="Bps of options premium over NAV" style={{
                color: colorGetter(bpsOptionsMarketValue)
            }}>{numberFormatter(bpsOptionsMarketValue)}</span>
                </span> | <span
                title="PnL percentage" style={{
                color: colorGetter(profitValue)
            }}>{profitText}</span> | <span
                title={positionInDaysOfVolTitle}>{positionInDaysOfVol}</span></span>;
    }

    const handleClick = (e, us, ww, func_us, func_ww, country) => {
        e.preventDefault();
        window.open(func_us(us, country).replaceAll("+", "%2B"), '_blank');
        window.open(func_ww(ww, "").replaceAll("+", "%2B"), '_blank');
    }

    const [linkFontSize, setLinkFontSize] = useState('25px');

    useEffect(() => {
        // Function to calculate the font size based on the number of characters


        if (activeTick && activeTick.name) {
            const newFontSize = calculateFontSize(activeTick.name);
            setLinkFontSize(newFontSize);
        }
    }, [activeTick]);

    const nextEarningsDateText = earningsInfo.nextEarningsDate+' | ' + daysToEarning;

    const washSaleTexts = getWashSaleTexts(washSaleInfo);

    const {width} = useWindowDimensions();

    return (
        <>
            <div style={{
                display: 'flex',
                backgroundColor: '#CCD3D9',
                alignItems: 'center',
                padding: '0px',
                height: '40px'
            }}>
                {activeTick.id != null && <>
                    <Link
                        to={generateLinkForOracleClickout(activeTick.name)}
                        target={"_blank"}
                        style={{
                            width: width*0.1,
                            textDecoration: "underline",
                            fontSize: calculateFontSize(activeTick.name, width*0.1, 10, 25), // Responsive font size
                            display: 'inline-block', // To ensure text-overflow works correctly
                            overflow: 'hidden', // Hide overflow text
                            textOverflow: 'ellipsis' // Add ellipsis for overflow text
                        }}
                    >
                        {activeTick.name}
                    </Link>
                    <span className={'gt-display-bar'}
                          style={{
                              width: width * 0.15,
                              fontSize: calculateFontSize(positionTextComponent, width * 0.15, 5, 15, 2)
                        }}
                    >
                        {positionTextComponent}
                    </span>
                </>}
                <span className={'gt-display-bar'} style={{
                    width: width*0.06,
                    fontSize: calculateFontSizeGivenLength(getTextsLength(washSaleTexts), width*0.06, 10, 15, 3)
                }}>{washSaleRenderingComponent(washSaleInfo, washSaleTexts)}</span>
                <span className={'gt-display-bar'}
                      style={{
                          width: width*0.08,
                          fontSize: calculateFontSize(nextEarningsDateText, width*0.08, 10, 25, 3),
                    }}
                      title={'As of ' + earningsInfo.asOf}>{nextEarningsDateText}</span>
                <div>
                    <BrandDropdown activeTick={activeTick} activeBrand={brand} setActiveBrand={setBrand}
                                   popout={false}/>
                </div>
                <span className={'gt-display-bar'}>
                    GT: (<a
                    style={(loading_us || loading_ww) ? disabledLinkStyleGT : enabledLinkStyleGT}
                    href="#"
                    onClick={(e) => handleClick(e, clickoutUrl_us, clickoutUrl_ww, make3Y_us, make3Y_ww, "US")}
                    target={"_blank"}>
                                        3Y
                        </a>, <a
                    style={(loading_us || loading_ww) ? disabledLinkStyleGT : enabledLinkStyleGT}
                    href="#"
                    onClick={(e) => handleClick(e, clickoutUrl_us, clickoutUrl_ww, make4Y_us, make4Y_ww, "US")}
                    target={"_blank"}>
                                        4Y
                        </a>)
                    AS: (
                    <a style={loading_AS_overview ? disabledLinkStyleGT : enabledLinkStyleGT}
                       href={clickoutUrl_AS_overview} target={"_blank"}>O</a>
                    , <a style={loading_AS_vpt ? disabledLinkStyleGT : enabledLinkStyleGT}
                         href={clickoutUrl_AS_vpt} target={"_blank"}>V</a>), <a
                    style={loading_ER_trends ? disabledLinkStyleGT : enabledLinkStyleGT}
                    href={clickoutUrl_ER_trends} target={"_blank"}>ER</a>, <a
                    style={loading_ER_cohort ? disabledLinkStyleGT : enabledLinkStyleGT}
                    href={clickoutUrl_ER_cohort} target={"_blank"}>ER(R)</a>, <a
                    style={loading_SM_cohort ? disabledLinkStyleGT : enabledLinkStyleGT}
                    href={clickoutUrl_SM_cohort} target={"_blank"}>SM</a>
                </span>
                <a id="anchor-element" className="button-factors">Factors</a>
                {!loading_factors && <Tooltip
                    anchorSelect="#anchor-element"
                    place='bottom'
                    variant='light'
                    style={{zIndex: 1000}}
                    opacity={1}
                >
                    {generateFactorWeightsTableFromData(loading_factors, factorWeights)}
                </Tooltip>}

                <a id="anchor-element-si" className="button-factors">SI</a>
                {<Tooltip
                    anchorSelect="#anchor-element-si"
                    place='bottom'
                    variant='light'
                    style={{zIndex: 1000}}
                    opacity={1}
                >
                    {getShortInterestComponent(shortInterestInfo)}
                </Tooltip>}
                <a id="anchor-element-profile-info" className="button-factors">Info</a>
                {<Tooltip
                    anchorSelect="#anchor-element-profile-info"
                    place='bottom'
                    variant='light'
                    style={{zIndex: 1000}}
                    opacity={1}
                >
                    {getCompanyProfileInfoComponent(companyProfileInfo)}
                </Tooltip>}
                {showPositionsTooltips && <ListSelectionBar
                    activeTick={activeTick}
                    showDataLag={showDataLag}
                    showViewControls={showViewControls}
                    tooltipMode={true}
                    brand={brand}
                />}
                <WaitListMarker activeTick={activeTick}/>
            </div>
        </>
    )
}
