import {AgGridReact} from '@ag-grid-community/react';
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import LoadingOverlay from 'react-loading-overlay';
import {getAccountSummaryData} from "../../../api/data/PortfolioDataProvider";
import {dollarCellRendererFunction} from "../../../utils/StudioAgGridUtils";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import {ModuleRegistry} from "@ag-grid-community/core";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {RowGroupingModule} from "@ag-grid-enterprise/row-grouping";

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    RowGroupingModule
]);

function getColumnDefs() {
    return [
        {
            headerName: "Total",
            field: "total",
            cellRenderer: dollarCellRendererFunction(),
            type: 'rightAligned',
        },
        {
            headerName: "US Securities",
            field: "usSecurities",
            cellRenderer: dollarCellRendererFunction(),
            type: 'rightAligned',
        },
        {
            headerName: "US Commodities",
            field: "usCommodities",
            cellRenderer: dollarCellRendererFunction(),
            type: 'rightAligned',
        },
    ];
}

const calculateData = (data) => {
    let newRows = [];
    if (data) {
        newRows.push(
            {
                path: ['Balances', 'Net Liquidation Value'],
                parameterName: "Net Liquidation Value",
                total: data?.netLiquidation?.total,
                usSecurities: data?.netLiquidation?.usSecurities,
                usCommodities: data?.netLiquidation?.usCommodities,
            },
            {
                path: ['Balances', 'Equity with Loan Value'],
                parameterName: 'Equity with Loan Value',
                total: data?.equityWithLoanValue?.total,
                usSecurities: data?.equityWithLoanValue?.usSecurities,
                usCommodities: data?.equityWithLoanValue?.usCommodities,
            },
            {
                path: ['Balances', 'Cash'],
                parameterName: 'Cash',
                total: data?.totalCashValue?.total,
                usSecurities: data?.totalCashValue?.usSecurities,
                usCommodities: data?.totalCashValue?.usCommodities,
            },
            {
                path: ['Margin Requirements', 'Current Initial Margin'],
                parameterName: 'Current Initial Margin',
                total: data?.initialMarginReq?.total,
                usSecurities: data?.initialMarginReq?.usSecurities,
                usCommodities: data?.initialMarginReq?.usCommodities,
            },
            {
                path: ['Margin Requirements', 'Current Maintenance Margin'],
                parameterName: 'Current Maintenance Margin',
                total: data?.maintenanceMarginReq?.total,
                usSecurities: data?.maintenanceMarginReq?.usSecurities,
                usCommodities: data?.maintenanceMarginReq?.usCommodities,
            },
            {
                path: ['Available for Trading', 'Current Available Funds'],
                parameterName: 'Current Available Funds',
                total: data?.availableFunds?.total,
                usSecurities: data?.availableFunds?.usSecurities,
                usCommodities: data?.availableFunds?.usCommodities,
            },
            {
                path: ['Available for Trading', 'Current Excess Liquidity'],
                parameterName: 'Current Excess Liquidity',
                total: data?.excessLiquidity?.total,
                usSecurities: data?.excessLiquidity?.usSecurities,
                usCommodities: data?.excessLiquidity?.usCommodities,
            },
            {
                path: ['Available for Trading', 'Buying Power at 15% Margin'],
                parameterName: 'Buying Power at 15% Margin',
                total: data?.buyingPower?.total,
                usSecurities: data?.buyingPower?.usSecurities,
                usCommodities: data?.buyingPower?.usCommodities,
            }
        );
    }

    console.log("Calculated data:", newRows);
    return newRows;
}

function AccountSummaryTable(props) {
    const gridRef = useRef();
    const [loading, setLoading] = useState(false);
    const [columnDefs] = useState(getColumnDefs());
    const [rowData, setRowData] = useState([]);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        marryChildren: true,
        resizable: true,
        sizeColumnsToFit: true,
        skipHeaderOnAutoSize: true,
        suppressMovable: true,
        suppressDragLeaveHidesColumns: true,
        menuTabs: [],
        flex: 1,
    }), []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerName: "Account Summary",
            minWidth: 300,
            cellRendererParams: {
                suppressCount: true,
            },
        };
    }, []);

    const getDataPath = useCallback((data) => data.path, []);

    useEffect(() => {
        const abortController = new AbortController();
        setLoading(true);

        getAccountSummaryData(props.accountGroup?.accountIds, abortController)
            .then((res) => {
                console.log("Response received for account summary data");
                setRowData(calculateData(res.data));
                setLoading(false);
            })
            .catch((err) => {
                console.error('Error faced while getting account summary data from backend', err);
                setLoading(false);
            })

        return () => abortController.abort();
    }, [props.reportTime, props.accountGroup]);

    return (
        <div id="account-summary-table" style={{marginTop: 10}}>
            <LoadingOverlay active={loading} text={"Loading..."}>
                <div className="nav-grid ag-theme-alpine" style={{height: '270px', width: '700px', margin: '0px auto'}}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        treeData={true}
                        getDataPath={getDataPath}
                        groupDefaultExpanded={-1}
                    />
                </div>
            </LoadingOverlay>
        </div>
    )
}

export default AccountSummaryTable;