import PortfolioNavBar from "./PorfolioNavBar";
import {useEffect, useState} from "react";
import NavScreen from "../overview/NavScreen";
import HC_exporting from "highcharts/modules/exporting";
import Highcharts from "highcharts";
import HighchartsStock from "highcharts/modules/stock";
import PortfolioDashboard from "../dashboard/PortfolioDashboard";
import FactorLoadingsScreen from "../factor/FactorLoadingsScreen";
import IntradayFactors from "../intraday/IntradayFactorsScreen";
import {auditUser, getUserPortfolioAuthStatus} from "../../../api/data/DataProvider";
import {CompactView} from "../../oracle/compactView/CompactView";
import {useNavigate} from "react-router-dom";
import {DASHBOARD, FACTORS, INTRADAY, NAV, POSITIONS} from "../../oracle/PathConstants";
import {Button, Result} from "antd";

function PortfolioScreenWrapper(props) {
    const [selectedScreen, setSelectedScreen] = useState(NAV);
    const [reportDate, setReportDate] = useState();
    const [isUserAuthorised, setIsUserAuthorised] = useState(false);



    const _resolveSelectedScreen = () => {
        if (selectedScreen === NAV) {
            return <NavScreen reportDate={reportDate} setReportDate={setReportDate} />
        } else if (selectedScreen === POSITIONS) {
            return <CompactView />
        } else if (selectedScreen === DASHBOARD) {
            return <PortfolioDashboard reportDate={reportDate} setReportDate={setReportDate} />
        } else if (selectedScreen === FACTORS) {
            return <FactorLoadingsScreen />;
        } else if (selectedScreen === INTRADAY) {
            return <IntradayFactors />;
        }
    }

    let navigate = useNavigate();

    useEffect(() => {
        let user = localStorage.getItem('studio_internal_authenticated_user');
        auditUser(user, "PORTFOLIO_DASHBOARD", null).then((res) => {}).catch((e) => {});
        getUserPortfolioAuthStatus(btoa(user), "PORTFOLIO_DASHBOARD", null).then((res) => {
            setIsUserAuthorised(true);
        }).catch((e) => {
            setIsUserAuthorised(false);
        });
    }, []);

    useEffect(() => {
        const path = window.location.pathname;
        const pathParams = path.split('/');
        const screen = pathParams[2];
        if(screen == null) return;
        setSelectedScreen(screen.toUpperCase());
    }, []);

    useEffect(() => {
        const path = window.location.pathname;
        const pathParams = path.split('/');
        if(pathParams[2] !== null) {
            pathParams[2] = selectedScreen;
        } else {
            pathParams.push(selectedScreen);
        }
        navigate(pathParams.join('/'));
    }, [selectedScreen])

    useEffect(() => {
        const popstateHandler = () => {
            const path = window.location.pathname;
            if (path === '/portfolio') {
                setSelectedScreen('NAV') // Or whatever is your default state
            }
            else {
                const pathParams = path.split("/");
                const screen = pathParams[2]?.toUpperCase();
                if (screen) {
                    setSelectedScreen(screen);
                }
            }
        }

        window.addEventListener("popstate", popstateHandler);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("popstate", popstateHandler);
        };
    }, []);

    return (
        <>
            {HC_exporting(Highcharts)}
            {require("highcharts/modules/export-data")(Highcharts)}
            {HighchartsStock(Highcharts)}
            {selectedScreen !== POSITIONS && <PortfolioNavBar setSelectedScreen={setSelectedScreen} />}
            {!isUserAuthorised && <div style={{display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: 50}}>
                <Result
                    status="403"
                    title="403"
                    subTitle="Sorry, you are not authorized to access this page."
                    extra={<a href={"/"}><Button type="primary">Back Home</Button></a>}
                /></div>}
            {isUserAuthorised && _resolveSelectedScreen()}
        </>
    )
}

export default PortfolioScreenWrapper;