import {createContext, memo, useContext, useEffect, useState} from "react";
import {getNextEarningsDate} from "../../../api/data/DataProvider";

import dayjs from "dayjs";
import {RefreshContext} from "./RefreshContextProvider";

let utc = require('dayjs/plugin/utc');
let timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

const defEarningsInfo = {
    next_annc_date: "N/A",
    avg_price_reaction: null,
    avg_earnings_surprise: "N/A",
    as_of: "N/A"
};

const formatEarningsDate = (value) => {
    if (value.next_annc_date === null) {
        return "N/A";
    }

    const date = dayjs(value.next_annc_date);
    const formattedDate = `${date.format("DD MMMM")}`;

    return formattedDate + ' (* Exp.)';
}

const calcDaysToEarnings = (earningsInfo) => {
    if (earningsInfo.nextEarningsDate !== "N/A") {
        const currentDate = dayjs().startOf('date');
        const earningsDate = dayjs(earningsInfo.nextEarningsDateOriginal);
        // Calculate the time difference in milliseconds
        let days = earningsDate.diff(currentDate, 'day');

        // Convert the time difference into days
        return days + " days left" + ' (' + (earningsInfo.avgPriceReaction != null ? (earningsInfo.avgPriceReaction.toFixed(0) + '%') : '') + ')';
    }

    return "N/A";
}

const parseNextEarnings = (earningsInfo) => {
    let defaultEarningsInfo = {...defEarningsInfo};
    defaultEarningsInfo.next_annc_date = earningsInfo.expectedEarningsDate;
    defaultEarningsInfo.avg_price_reaction = earningsInfo.expectedEarningsImpliedMove;
    defaultEarningsInfo.as_of = earningsInfo.asOf;
    const nextEarningsInfo = {
        nextEarningsDateOriginal: defaultEarningsInfo.next_annc_date,
        nextEarningsDate: formatEarningsDate(defaultEarningsInfo),
        avgPriceReaction: defaultEarningsInfo.avg_price_reaction,
        avgEarningsSurprise: defaultEarningsInfo.avg_earnings_surprise,
        asOf: defaultEarningsInfo.as_of
    };
    return [nextEarningsInfo, calcDaysToEarnings(nextEarningsInfo)];
}

export const NextEarningsDateContext = createContext(null);
export const NextEarningsDateProvider = memo(({activeTick, children}) => {

    const {refresh} = useContext(RefreshContext);

    const [earningsInfo, setEarningsInfo] = useState(defEarningsInfo);
    const [daysToEarning, setDaysToEarning] = useState();

    useEffect(() => {
        const abortController = new AbortController();
        if (activeTick.id != null) {
            console.log('Fetching the next earning date data');
            getNextEarningsDate(activeTick.id)
                .then((res) => {
                    const nextEarningInfo = parseNextEarnings(res.data);
                    setEarningsInfo(nextEarningInfo[0]);
                    setDaysToEarning(nextEarningInfo[1]);
                })
                .catch((err) => {
                    console.error("Failed to fetch Earnings Dates for: {}", activeTick.name, err);
                    const errorEarningsInfo = {
                        nextEarningsDate: defEarningsInfo.next_annc_date,
                        avgPriceReaction: defEarningsInfo.avg_price_reaction,
                        avgEarningsSurprise: defEarningsInfo.avg_earnings_surprise,
                        asOf: defEarningsInfo.as_of
                    }
                    setEarningsInfo(errorEarningsInfo);
                    setDaysToEarning(calcDaysToEarnings(errorEarningsInfo));
                });
        }

        return () => abortController.abort();
    }, [activeTick, refresh])

    return <NextEarningsDateContext.Provider value={{earningsInfo, daysToEarning}}>
        {children}
    </NextEarningsDateContext.Provider>

});