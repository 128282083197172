import {useMemo} from "react";
import LoadingOverlay from 'react-loading-overlay';
import {CAlert} from "@coreui/react";
import StudioHighChart from "../studio_high_chart/StudioHighChart";

import {SplitContextsConsumerWrapper} from "../oracle/DashboardReport/views/SplitContextsConsumerWrapper";


const QuarterCharts = ({chartData, isError, loading, activeTicker, activeBrand, activeBrandName, queryType, showMiniView, yearsToShow, chartToggleConfig, chartHeightOverride, chartWidth, isMiniMode, idioData, enableModal = true, pairedChart = null, dataStoreContext=null}) =>  {

    const constructChartConfig = (chartData, ticker, brand, activeBrandName, showMiniView, yearsToShow) => {
        if(chartData == null  || chartData.data == null) return null;
        const titleText = (brand == null ? ticker : ticker + ' - ' + activeBrandName) + ' - ' + chartData.data.title.text;
        return {
            ...chartData.data,
            rangeSelector: {
                buttons: [
                    {
                        type: 'month',
                        count: 15,
                        text: '1m'
                    }, {
                        type: 'month',
                        count: 16,
                        text: '2m'
                    },
                    {
                        type: 'month',
                        count: 17,
                        text: '3m'
                    },
                    {
                        type: 'month',
                        count: 20,
                        text: '6m'
                    },
                    {
                        type: 'year',
                        count: 2,
                        text: '1y'
                    },
                    {
                        type: 'year',
                        count: 3,
                        text: '2y'
                    },
                    {
                        type: 'ytd',
                        text: 'YTD'
                    },
                    {
                        type: 'all',
                        text: 'All'
                    }],
                selected: yearsToShow
            },
            title: {
                text: titleText
            },
            chart: {
                ...chartData.data.chart,
                zooming: {
                    mouseWheel: {
                        enabled: false
                    }
                },
                zoomType: 'x',
                height: showMiniView ? 450: 850,
                plotBackgroundColor: '#E5ECF6',
                panning: {
                    enabled: true,
                    type: 'x'
                },
                panKey: 'shift'
            }
        }

    }


    const memoizedChartData =  useMemo(() => {
        return constructChartConfig(chartData, activeTicker, activeBrand, activeBrandName, showMiniView, yearsToShow);
    }, [chartData, activeTicker, activeBrand, showMiniView, yearsToShow] )

    let chartHeightOverrideLocal = showMiniView ? '450px' : '850px';
    if(chartHeightOverride != null) chartHeightOverrideLocal = chartHeightOverride;
    return (
        <div>
            <a id={queryType} href={""}> </a>
            <LoadingOverlay
                active={loading && !isError}
                spinner={!isError}
                text={"Loading..."}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        height: chartHeightOverrideLocal,
                        width: '100%'
                    })
                }}
            >
                {isError && <div>
                    <CAlert color="danger">
                        Failed to load - {queryType}.
                        Data Not Configured
                    </CAlert>
                </div>}
                {memoizedChartData == null && !isError && <div style={{height: chartHeightOverrideLocal, width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}><h1 style={{color: 'white'}}>Chart is loading. Please wait.</h1></div>}
                {memoizedChartData != null && !isError && memoizedChartData.series.length === 0 && <div>
                    <CAlert color="warning">
                        Data Not Available - {queryType}
                    </CAlert>
                </div>}
                {memoizedChartData != null && !isError && memoizedChartData.series.length !== 0 && <center><div style={{marginTop: 10, paddingLeft: 10}}>
                    <StudioHighChart
                        chartData={memoizedChartData}
                        isMiniView={showMiniView}
                        chartHeightOverride={chartHeightOverrideLocal}
                        queryType={queryType}
                        chartToggleConfig={chartToggleConfig}
                        chartWidth={chartWidth}
                        isMiniMode={isMiniMode}
                        idioData={idioData}
                        enableModal={enableModal}
                        pairedChart={pairedChart}
                        dataStoreContext={dataStoreContext}
                    />
                </div>
                </center>}
            </LoadingOverlay>

        </div>
    )
};

//Created for use in the ExpandModal:
export const ApiContextConnectedQuarterChart =  (props) => {
    return <SplitContextsConsumerWrapper {...props} RawComponent={<QuarterCharts />} />
}

export default QuarterCharts;