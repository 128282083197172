import {memo, useContext, useEffect, useState} from "react";
import LoadingOverlay from 'react-loading-overlay';
import {Radio, Select, Switch} from "antd";
import {CAlert} from "@coreui/react";
import StudioHighChart from "../studio_high_chart/StudioFinHighChart";
import {DashboardQueryTypes} from "../../api/data/DashboardQueryTypes";
import {ViewContextStore} from "../oracle/DashboardReport/views/ViewContext";
import {SeriesTypes} from "../../api/data/SeriesTypes";
import {AggregationType} from "../../api/data/FinDataTypes";
import {TradingMultiplesContext} from "../oracle/findata/TradingMultiplesDataProvider";


const allSeriesTypes = [SeriesTypes.PRICE, SeriesTypes.EV, SeriesTypes.EPSDILUTED, SeriesTypes.P_EPS, SeriesTypes.P_SALES, SeriesTypes.EBITDA_xLEASE, SeriesTypes.SALES];

const RatioToSeriesList = {
    [SeriesTypes.P_EPS]: {
        "label": "P/EPS",
        "seriesTypes": [SeriesTypes.PRICE, SeriesTypes.EPSDILUTED, SeriesTypes.P_EPS],
    },
    // [SeriesTypes.P_SALES]: {
    //     "label": "P/Sales",
    //     "seriesTypes": [SeriesTypes.PRICE, SeriesTypes.P_SALES, SeriesTypes.SALES],
    // },
    [SeriesTypes.EV_EBITDA_xLEASE]: {
        "label": "EV/EBITDA ex-Lease",
        "seriesTypes": [SeriesTypes.PRICE, SeriesTypes.EBITDA_xLEASE, SeriesTypes.EV_EBITDA_xLEASE],
    },
    [SeriesTypes.EV_SALES]: {
        "label": "EV/SALES ex-Lease",
        "seriesTypes": [SeriesTypes.PRICE, SeriesTypes.SALES, SeriesTypes.EV_SALES_xLEASE],
    }
}
const aggregateTypesToShow = [
    {
        "label": "NTM",
        "value": AggregationType.NTM
    },
    {
        "label": "LTM",
        "value": AggregationType.LTM
    },
    {
        "label": "Next FY",
        "value": AggregationType.NEXTYEAR
    },
    {
        "label": "Current FY",
        "value": AggregationType.CURRENT
    },
]

const HorizontalDivider = () => (
    <div style={{
        width: 1,
        height: 32,
        backgroundColor: '#d9d9d9',
        margin: '0 10px',
    }} />
);

const mergeChartData = (mainChartData, additionalMetaData, additionalSeriesList) => {
    return {
        ...mainChartData,
        seriesMetadata: [
            ...mainChartData.seriesMetadata,
            ...additionalMetaData
        ],
        series: [
            ...mainChartData.series,
            ...additionalSeriesList
        ]
    };
}

const createFlagData = (eventsData) => {
    return {
        type: 'flags',
        color: 'rgba(251, 146, 44, 0.5)',
        shape: 'squarepin',
        id: "ticker_events",
        showInNavigator: true,
        accessibility: {
            exposeAsGroupOnly: true,
            description: 'Events'
        },
        data: eventsData.map(event => {
            return {
                x: event[0],
                title: event[1],
                text: event[1]
            }
        })
    };
}

const mergeChartDataWithEvents = (chartData, estimatesChartData, events) => {
    if (!chartData) return chartData;
    const additionalSeriesMetadata = [];
    const additionalSeriesData = [];
    if (events) {
        const flagData = createFlagData(events);
        additionalSeriesData.push(flagData);
    }
    if (estimatesChartData) {
        additionalSeriesMetadata.push(...estimatesChartData.seriesMetadata);
        additionalSeriesData.push(...estimatesChartData.series);
    }
    return mergeChartData(chartData, additionalSeriesMetadata, additionalSeriesData);
};

const getAggregatesToSeriesIdMap = (chartData) => {
    let aggMap = {};
    if (chartData && chartData.seriesMetadata) {
        for (let i = 0; i < chartData.seriesMetadata.length; i++) {
            let smdata = chartData.seriesMetadata[i];
            let seriesId = smdata.seriesId;
            if ('aggregateType' in smdata && smdata.aggregateType) {
                if (smdata.aggregateType in aggMap) {
                    aggMap[smdata.aggregateType].push(seriesId);
                } else {
                    aggMap[smdata.aggregateType] = [seriesId];
                }
            }
        }
    }
    return aggMap;
}

const getSeriesTypeToSeriesIdMap = (chartData) => {
    let seriesTypeMap = {};
    if (chartData && chartData.seriesMetadata) {
        for (let i = 0; i < chartData.seriesMetadata.length; i++) {
            let smdata = chartData.seriesMetadata[i];
            let seriesId = smdata.seriesId;
            if ('seriesType' in smdata && smdata.seriesType) {
                if (smdata.seriesType in seriesTypeMap) {
                    seriesTypeMap[smdata.seriesType].push(seriesId);
                } else {
                    seriesTypeMap[smdata.seriesType] = [seriesId];
                }
            }
        }
    }
    return seriesTypeMap;

}

//estimate report type is estimate, surprise is surprise
const getEstimatePeriodToSeriesIdMap = (chartData) => {
    let reportTypeMap = {};
    if (chartData && chartData.seriesMetadata) {
        for (let i = 0; i < chartData.seriesMetadata.length; i++) {
            let smdata = chartData.seriesMetadata[i];
            let seriesId = smdata.seriesId;
            if ('extraTypes' in smdata && 'estimatePeriod' in smdata.extraTypes) {
                let period = smdata.extraTypes.estimatePeriod.split("FY-")[0];
                if (period == "") {
                    period = "FY";
                }
                if (period in reportTypeMap) {
                    reportTypeMap[period].push(seriesId);
                } else {
                    reportTypeMap[period] = [seriesId];
                }
            }
        }
    }
    return reportTypeMap;
}


const TradingMultiplesCharts = memo(({ activeTicker, chartToggleConfig }) => {

    const {multipleChartData, estimatesChartData, eventsData} = useContext(TradingMultiplesContext);

    const chartData = mergeChartDataWithEvents(multipleChartData?.data, estimatesChartData?.data, eventsData?.data);
    const isError = multipleChartData.error;
    const loading = multipleChartData.loading;

    const [activeRatio, setActiveRatio] = useState(SeriesTypes.P_EPS);
    const [activeAgg, setActiveAgg] = useState(AggregationType.NTM);
    const [estimatePeriod, setActiveEstimatePeriod] = useState("FY");

    const aggToSeriesIdMap = getAggregatesToSeriesIdMap(chartData);
    const seriesTypeMap = getSeriesTypeToSeriesIdMap(chartData);
    const estimatePeriodToSeriesIdMap = getEstimatePeriodToSeriesIdMap(chartData);

    const [activeSeriesTypes,   setActiveSeriesTypes] = useState([]);

    // const [showSurprisesSeries, setShowSurprisesSeries] = useState(false);
    const [showEstimatesSeries, setShowEstimatesSeries] = useState(false);

    useEffect(() => {
        setActiveRatio(SeriesTypes.P_EPS);
        setActiveSeriesTypes([SeriesTypes.PRICE, SeriesTypes.EPSDILUTED, SeriesTypes.P_EPS]);
        setActiveAgg(AggregationType.NTM);
        setActiveEstimatePeriod("FY");
    }, [activeTicker]);



    const onRatioChange = ({ target: { value } }) => {
        console.log(`onRatioChange ${value}`);
        setActiveRatio(value);
        setActiveSeriesTypes(RatioToSeriesList[value].seriesTypes);
    }

    const activeSeriesChange = (value) => {
        console.log(`activeSeriesChange ${value}`);
        setActiveSeriesTypes(value);
    }

    const handleAggChange = (value) => {
        console.log(`handleAggChange ${value}`);
        setActiveAgg(value);
    }

    const onEstimatePeriodChange = (e) => {
        console.log(`onEstimatePeriodChange ${e.target}`);
        setActiveEstimatePeriod(e.target.value);
    }


    //filtering logic is
    //first we get the activeSeriesTypes
    //then we get the active Agg
    //then we get the surprises and estimates
    //for each series type there should be different agg, surprises and estimates
    //we get the right series names from the intersection of these
    const filterSeriesIdsByChartOptions = () => {
        if (activeRatio == null || activeAgg == null || chartData == null) return [];
        if (aggToSeriesIdMap[activeAgg] == null) return [];
        let activeSeriesIds = [];
        activeSeriesTypes.forEach(seriesType => {
            if (seriesTypeMap[seriesType]) {
                activeSeriesIds = activeSeriesIds.concat(seriesTypeMap[seriesType]);
            }
        });
        let activeSeriesSet = new Set(activeSeriesIds);
        let aggSet = new Set(aggToSeriesIdMap[activeAgg]);
        let fixedSet = new Set(seriesTypeMap[SeriesTypes.PRICE]); //Price, Idio & Price Impact
        if(SeriesTypes.PRICE in seriesTypeMap && seriesTypeMap[SeriesTypes.PRICE].length > 0) {
            fixedSet.add(seriesTypeMap[SeriesTypes.PRICE][0]);
        }
        if(SeriesTypes.EV in seriesTypeMap && seriesTypeMap[SeriesTypes.EV].length > 0) {
            fixedSet.add(seriesTypeMap[SeriesTypes.EV][0]);
        }
        let finalSeriesIds = activeSeriesSet.intersection(aggSet.union(fixedSet));

        if (showEstimatesSeries) {
            let estimateSet = new Set(estimatePeriodToSeriesIdMap[estimatePeriod]);
            estimateSet = activeSeriesSet.intersection(estimateSet);
            finalSeriesIds = finalSeriesIds.union(estimateSet);
        }

        // let reportSeriesNames = activeSeriesSet.intersection(reportTypeSet);
        // let finalSeriesNames = aggSeriesNames.union(reportSeriesNames);
        //For multiple take fixed and agg - fixed are series like Price who don't have Agg  
        finalSeriesIds.add("ticker_events")
        return Array.from(finalSeriesIds);
    }

    const filterChartDataByChartOptions = (chartData) => {
        
        if (chartData == null) return null;
        let filteredSeries;
        let seriesIds = filterSeriesIdsByChartOptions();
        if (seriesIds && seriesIds.length > 0) {
            filteredSeries = chartData.series.filter(series => seriesIds.indexOf(series.id) > -1);
        } else {
            filteredSeries = chartData.series;
        }

        return {
            ...chartData,
            series: filteredSeries, 
            title: {
                text: ''
            },
            rangeSelector: {
                buttons: [
                    { type: 'year', count: 1, text: '1y' },
                    { type: 'year', count: 2, text: '2y' },
                    { type: 'year', count: 3, text: '3y' },
                    { type: 'year', count: 5, text: '5y' },
                    { type: 'year', count: 10, text: '10y' },
                    { type: 'all', text: 'All' }
                ],
                selected: 1
            },
            xAxis: {
                ...chartData.xAxis,
                overscroll: 60 * 1000 * 60 * 24 * 30
            },
            chart: {
                ...chartData.chart,
                spacingRight: 10,
                scrollablePlotArea: { minWidth: 600 },
                zooming: { mouseWheel: { enabled: false } },
                zoomType: 'x',
                height: 600,
                backgroundColor: "#FFFFFF",
                type: 'line',
                plotBackgroundColor: '#FFFFFF',
                panning: { enabled: true, type: 'x' },
                panKey: 'shift'
            }
        };
    }


    const chartDataFiltered = filterChartDataByChartOptions(chartData);

    return (
        <div style={{ height: '800px' }}>
            <LoadingOverlay
                active={loading && !isError}
                spinner={!isError}
                text={"Loading..."}
            >
                {isError && <div>
                    <CAlert color="danger">
                        Failed to load - {"Financial Ratios"}.
                        Data Not Configured
                    </CAlert>
                </div>}
                {chartDataFiltered == null && !isError && <p style={{ height: "400px", width: "90%" }}> ...</p>}
                {chartDataFiltered != null && !isError && chartDataFiltered.series?.length === 0 && <div>
                    <CAlert color="warning">
                        Data Not Available - {"Financial Ratios"}
                    </CAlert>
                </div>}

                {!loading && !isError && <center className={"finratio-header"} style={{ lineHeight: '30px', display: 'flex', justifyContent: 'space-between', width: '90%', paddingLeft: '50px', paddingTop: 20, marginBottom: '40px', paddingRight: '50px', flexWrap: 'nowrap' }}>
                    <div style={{ textAlign: 'left', paddingLeft: '20px' }}>
                        <h4 style={{ fontSize: '24px' }}>{"Trading Multiples "}</h4>
                    </div>
                    {/* <HorizontalDivider /> */}
                    {/* <div>
                        <Switch checked={showSurprisesSeries} onChange={setShowSurprisesSeries} />
                        <span style={{ marginLeft: '10px' }}>Surprises</span>
                    </div> */}
                    <HorizontalDivider />
                    <div>
                        <Switch checked={showEstimatesSeries} onChange={setShowEstimatesSeries} />
                        <span style={{ marginLeft: '10px' }}>Estimates</span>
                    </div>
                    <Radio.Group size="medium" onChange={onEstimatePeriodChange} value={estimatePeriod} >
                        {['1Q', '2Q', '3Q', '4Q', 'FY'].map(period => (
                            <Radio.Button value={period}>{period}</Radio.Button>
                        ))}
                    </Radio.Group>
                    <HorizontalDivider />
                    <Radio.Group size="large" onChange={onRatioChange} value={activeRatio}>
                        {Object.keys(RatioToSeriesList).map(multiple => (
                            <Radio.Button value={multiple}>{RatioToSeriesList[multiple].label}</Radio.Button>
                        ))}
                    </Radio.Group>
                   
                    <Select
                        value={activeSeriesTypes}
                        size="large"
                        mode="multiple"
                        autoClearSearchValue={true}
                        style={{ minWidth: 300, minHeight: 40 }}
                        dropdownStyle={{ fontSize: '20px' }}
                        onChange={activeSeriesChange}
                        // onSelect={handleSeriesTypeSelect}
                        // onDeselect={handleSeriesTypeDeselect}
                        options={allSeriesTypes.map(st => ({
                            value: st,
                            label: st
                        }))}
                    />
                    <HorizontalDivider />
                    <Select
                        size="large"
                        value={activeAgg}
                        style={{ minWidth: 170, minHeight: 40, marginLeft: '30px', height: 40 }}
                        dropdownStyle={{ fontSize: '20px' }}
                        onChange={handleAggChange}
                        options={aggregateTypesToShow.map(agg => ({
                            value: agg.value,
                            label: agg.label
                        }))}
                    />

                    
                </center>}
                {chartData != null && !isError && chartDataFiltered.series?.length !== 0 &&
                    <center>
                        <div style={{ marginTop: 10, paddingLeft: 10 }}>
                            <StudioHighChart
                                chartData={chartDataFiltered}
                                chartViewConfig={chartToggleConfig}
                                // legendWidthOverride
                                // chartHeightOverride
                                overrideOptions={{
                                    seriesWithoutIdio: true
                                }}
                                isMiniView={false}
                            />
                        </div>
                    </center>}

            </LoadingOverlay>
        </div>
    )
})

export const ViewContextConnectedTradingMultiple = props => {

    const completeViewContextData = useContext(ViewContextStore);
    const toggleOptionsConfig = {
        // isDailyStackAlignment: completeViewContextData.isDailyStackAlignment,
        // setDailyStackAlignment: completeViewContextData.setDailyStackAlignment,
    }
    const viewConfig = {
        viewData: completeViewContextData?.viewState[DashboardQueryTypes.FIN_RATIOS],
        modifyActiveSeries: (seriesName) => completeViewContextData?.modifyActiveSeries(DashboardQueryTypes.FIN_RATIOS, seriesName),
        reversePopulateChartConfig: (seriesConfig, metadata) => completeViewContextData?.reversePopulateChartConfig(DashboardQueryTypes.FIN_RATIOS, seriesConfig, metadata),
        toggleOptionsConfig
    }

    return <TradingMultiplesCharts {...props} chartToggleConfig={viewConfig} />
}



export default ViewContextConnectedTradingMultiple;
