import {createContext, useContext, useEffect, useRef, useState} from "react";
import {getHistoricalStockData} from "../../../api/data/DataProvider";
import {RefreshContext} from "../../oracle/commonContexts/RefreshContextProvider";

export const HistoricalPriceChartContext = createContext(null);

export const HistoricalPriceDataProvider = ({activeTicker, children}) => {

    const {refresh} = useContext(RefreshContext);

    const [historicalPriceData, setHistoricalPriceData] = useState({
        data: null,
        loading: true,
        isError: false
    });

    // ToDo: Move all the API calls to a separate shared context:
    useEffect(() => {
        if(activeTicker?.id == null) return;
        const abortController = new AbortController();
        setHistoricalPriceData({
            data: null,
            loading: true,
            isError: false
        });
        getHistoricalStockData(activeTicker.id, abortController, null)
            .then(res =>
            {
                setHistoricalPriceData({
                    data: res.data,
                    loading: false,
                    isError: false
                });
            })
            .catch(err => {
                if(err.code === 'ERR_CANCELED') {
                    //Do nothing
                } else {
                    setHistoricalPriceData({
                        data: null,
                        loading: false,
                        isError: true
                    });
                }
            });
        return () => {
            abortController.abort();
        }
    }, [activeTicker, refresh]);

    const [transformedData, setTransformedData] = useState({
        data: null,
        loading: true,
        isError: false
    });

    const workerRef = useRef(null);

    useEffect(() => {
        workerRef.current = new Worker(new URL('../workers/historical-stock-price-worker.js', import.meta.url));

        workerRef.current.onmessage = (event) => {
            setTransformedData({
                data: {...event.data, xAxis: {...event.data.xAxis, overscroll: 60 * 1000 * 60 * 24 * 10}},
                loading: false,
                isError: false
            });
        }

        return () => workerRef.current?.terminate();

    }, []);

    useEffect(() => {
        if(historicalPriceData.data == null) return;
        setTransformedData({
            data: null,
            loading: true,
            isError: false
        });
        workerRef.current?.postMessage(historicalPriceData.data);
    }, [historicalPriceData]);

    const loading = historicalPriceData.loading || transformedData.loading;
    const isError = historicalPriceData.isError || transformedData.isError;

    return (
        <HistoricalPriceChartContext.Provider value={{data: transformedData.data, loading, isError}}>
            {children}
        </HistoricalPriceChartContext.Provider>
    )
}