import {memo, useMemo} from "react";
import LoadingOverlay from 'react-loading-overlay';
import {CAlert} from "@coreui/react";
import StudioHighChart from "../studio_high_chart/StudioHighChart";

import {SplitContextsConsumerWrapper} from "../oracle/DashboardReport/views/SplitContextsConsumerWrapper";


const WeeklyCharts = memo(({
                               chartData,
                               isError,
                               loading,
                               activeTicker,
                               activeBrand,
                               activeBrandName,
                               queryType,
                               showMiniView,
                               chartToggleConfig,
                               chartHeightOverride,
                               chartWidth,
                               isMiniMode,
                               idioData,
                                enableModal = true
                           }) => {
    let chartHeight = showMiniView ? '450px' : '600px';
    if (chartHeightOverride != null) chartHeight = chartHeightOverride;


    // Enable IDIO on mini view
    const enableIdioSeries = (chartConfig) => {
        if (chartConfig === null) return chartConfig;
        if (showMiniView) {
            chartConfig.series.forEach(currentSeries => {
                if (currentSeries.name === "idio") {
                    currentSeries.visible = true;
                }
            });
        }

        return chartConfig;
    }

    const constructChartConfig = (chartData, ticker, brand, activeBrandName, showMiniView) => {
        if (chartData === null || chartData === undefined || chartData.data === null || chartData.data === undefined) return null;
        const titleText = (brand == null ? ticker : ticker + ' - ' + activeBrandName) + ' - ' + chartData.data.title.text;
        return {
            ...chartData.data,
            title: {
                text: titleText
            },
            rangeSelector: {
                buttons: [
                    {
                        type: 'day',
                        count: 1,
                        text: '1d'
                    },
                    {
                        type: 'week',
                        count: 1,
                        text: '1w'
                    },
                    {
                        type: 'week',
                        count: 2,
                        text: '2w'
                    },
                    {
                        type: 'month',
                        count: 1,
                        text: '1m'
                    },
                    {
                        type: 'month',
                        count: 2,
                        text: '2m'
                    },
                    {
                        type: 'month',
                        count: 3,
                        text: '3m'
                    },
                    {
                        type: 'month',
                        count: 6,
                        text: '6m'
                    },
                    {
                        type: 'year',
                        count: 1,
                        text: '1y'
                    },
                    {
                        type: 'month',
                        count: 16,
                        text: '15m'
                    },
                    {
                        type: 'year',
                        count: 2,
                        text: '2y'
                    },
                    {
                        type: 'ytd',
                        text: 'YTD'
                    },
                    {
                        type: 'all',
                        text: 'All'
                    }
                ],
                selected: 8
            },
            chart: {
                ...chartData.data.chart,
                zooming: {
                    mouseWheel: {
                        enabled: false
                    }
                },
                zoomType: 'x',
                height: typeof chartHeight === 'string' ? Number(chartHeight.replace('px', '')) : chartHeight,
                plotBackgroundColor: '#E5ECF6',
                panning: {
                    enabled: true,
                    type: 'x'
                },
                panKey: 'shift'
            },
            xAxis: {
                ...chartData.data.xAxis,
                events: {
                    setExtremes: function (e) {
                        if (e.trigger === "navigator") {
                            const max = e.max + 60 * 1000 * 60 * 24 * 10;
                            const x = this;
                            setTimeout(function () {
                                x.setExtremes(e.min, max);
                            }, 4);
                        }
                    }
                }
            }
        };
    };

    const memoizedChartData = useMemo(() => {
        const updatedDataOptions = constructChartConfig(chartData, activeTicker, activeBrand, activeBrandName, showMiniView);
        return activeBrandName === null ? enableIdioSeries(updatedDataOptions) : updatedDataOptions;
    }, [chartData, activeTicker, activeBrand, showMiniView]);


    return (
        <div>
            <a id={queryType} href={""}> </a>
            <LoadingOverlay
                active={loading && !isError}
                spinner={!isError}
                text={"Loading..."}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        height: chartHeight,
                        width: '100%'
                    })
                }}
            >
                {isError && <div>
                    <CAlert color="danger">
                        Failed to load - {queryType}.
                        Data Not Configured
                    </CAlert>
                </div>}
                {memoizedChartData == null && !isError && <div style={{
                    height: chartHeight,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}><h1 style={{color: 'white'}}>Chart is loading. Please wait.</h1></div>}
                {memoizedChartData != null && !isError && memoizedChartData.series.length === 0 && <div>
                    <CAlert color="warning">
                        Data Not Available - {queryType}
                    </CAlert>
                </div>}
                {memoizedChartData != null && !isError && memoizedChartData.series.length !== 0 && <center>
                    <div style={{marginTop: 10, paddingLeft: 10}}>
                        <StudioHighChart chartData={memoizedChartData} isMiniView={showMiniView} queryType={queryType}
                                         chartToggleConfig={chartToggleConfig} chartWidth={chartWidth}
                                         isMiniMode={isMiniMode}
                                         chartHeightOverride={chartHeightOverride} idioData={idioData}
                                         enableModal={enableModal}
                        />
                    </div>
                </center>}
            </LoadingOverlay>

        </div>
    )
})

export const ApiContextConnectedWeeklyChart = (props) => {
    return <SplitContextsConsumerWrapper {...props} RawComponent={<WeeklyCharts/>}/>
}

export default WeeklyCharts;