import {
    apiStateDefaultObject,
    initialDataLoadState,
    triggerDataFetchAndDoAssociatedStateChanges,
    triggerPeerIndicesDependentDataFetches
} from "./DashboardDataLoadsAndStateManagement";
import {createContext, useContext, useEffect, useRef, useState} from "react";
import {
    COMPACT_VIEW_QUERY_LIST,
    listOfListOfDashboardQueriesWithPriorities
} from "../../../api/data/DashboardQueryTypes";
import {getCompetitorList} from "../../../api/data/DataProvider";
import {ViewContextStore} from "./views/ViewContext";
import {DRReportAbortControllers} from "./utils/DRAbortControllers";
import {RefreshContext} from "../commonContexts/RefreshContextProvider";
import {ListsDataContext} from "../commonContexts/ListsProvider";
import _ from "lodash";

export const dashboardReportContextsStoreBuilder = () => {
    const flatListOfDashboardQueries = listOfListOfDashboardQueriesWithPriorities.flat();
    const contextsStore = {};
    flatListOfDashboardQueries.forEach((query) => {
        contextsStore[query] = apiStateDefaultObject;
    });
    return contextsStore;
}

export const DashboardApiContextStore = createContext(null);

export function addIdioSeriesIfChartHasIdio(apiResult) {
    if (Boolean(apiResult?.data?.idioSettings?.hasIdio)
        && apiResult?.data?.series.filter(s => s.name === 'idio').length === 0) {
        const newIdioSeries = {
            "yAxis": apiResult?.data?.idioSettings?.yAxis,
            "data": [],
            "name": "idio",
            "color": apiResult?.data?.idioSettings?.color,
            "marker": {
                "enabled": false
            },
            "lineWidth": apiResult?.data?.idioSettings?.lineWidth,
            "enableMouseTracking": apiResult?.data?.idioSettings?.enableMouseTracking,
            "showInLegend": true,
            "visible": apiResult?.data?.idioSettings?.visible ?? true,
        };

        const newIdioMetadata = {

            functionalTags: ["IDIO", "INTERNAL_REFERENCE"],
            geoTags: [],
            granularityTags: [],
            matchingTaxonomyRegex: ["idio"],
            seriesName: "idio",
            vendorTags: ["INTERNAL"]
        }

        apiResult?.data?.series?.splice(1, 0, newIdioSeries);
        apiResult?.data?.seriesMetadata?.splice(1, 0, newIdioMetadata);
    }
}

//IMP: To maintain complete sanity, we use a key to guarantee that the Context provider is cleared and is new, which is done by having an unique key for:
const FIXED_NON_CRITICAL_API_DELAY = 1500;
export const DashboardReportContextProvider = ({
                                                   children,
                                                   tick,
                                                   brand,
                                                   showCompetitors,
                                                   customQueryList = null
                                               }) => {
    const {refresh, apiScheduler} = useContext(RefreshContext);

    const {someListActive} = useContext(ListsDataContext);

    const [dataState, setDataState] = useState(initialDataLoadState());

    const abortControllers = useRef(new DRReportAbortControllers());


    const {showPeerIndices, setSeriesMetaData, reversePopulateChartConfig} = useContext(ViewContextStore);

    const [competitorList, setCompetitorList] = useState(null);

    const setDataAndMetadata = (apiResult, queryType, isLoading, isError) => {
        if (isLoading) {
            setDataState((prev) => ({
                ...prev,
                [queryType]: {
                    chartData: null,
                    isError: false,
                    loading: true
                }
            }));
            setSeriesMetaData(queryType, null);
            return;
        }
        if (!isError) {
            // Add Idio series for all enabled charts
            addIdioSeriesIfChartHasIdio(apiResult);

            setDataState((prev) => ({
                ...prev,
                [queryType]: {
                    chartData: apiResult,
                    isError: false,
                    loading: false
                }
            }));
            // Set the initial view state to be configured from the backend.
            const newSeriesConfigObject = {};
            apiResult?.data?.series?.forEach(seriesItem => {
                newSeriesConfigObject[seriesItem.name] = {
                    visible: seriesItem.visible,
                    yAxisVisible: false
                }
            });

            if (reversePopulateChartConfig != null) reversePopulateChartConfig(queryType, newSeriesConfigObject, apiResult?.data?.seriesMetadata);
            return;
        }
        //Api call has errored out:
        setDataState((prev) => ({
            ...prev,
            [queryType]: {
                chartData: null,
                isError: true,
                loading: false
            }
        }));
        setSeriesMetaData(queryType, null);

    };

    //Todo: Bring this under the umbrella of the data fetch below, to sequence it
    useEffect(() => {
        if (!showCompetitors) return;
        getCompetitorList(tick.id)
            .then((res) => {
                setCompetitorList(res.data);
            })
            .catch((err) => {
                console.log("Got error while fetching competitor list: " + err);
                setCompetitorList(null);
            });

    }, [tick]);

    const delayedApiCallTimeoutRef = useRef(null);


    const triggerFullDataFetch = () => {
        // abort all the calls presently happening:
        abortControllers.current.abortThemAll();
        // Reset all the values to be unset so that chart reflects correctly:
        setDataState(initialDataLoadState());
        if (tick.id == null) return;

        //Clear any active timeouts:
        if (delayedApiCallTimeoutRef.current) clearTimeout(delayedApiCallTimeoutRef.current);

        const allCallsToBeMade = customQueryList == null ? listOfListOfDashboardQueriesWithPriorities.flat() : customQueryList;

        if (!someListActive) {
            // No list is active, no fast cycling, go the old school route of all API calls:
            triggerDataFetchAndDoAssociatedStateChanges(setDataAndMetadata, tick.name, tick.id, brand.id, apiScheduler,
                abortControllers.current, showPeerIndices, customQueryList);
        } else {
            // Some list is active. Load on the backend needs to be managed. Make the 3x3 grid calls, and defer the rest:
            const subsetOfApiCalls = allCallsToBeMade
                .filter((query) => COMPACT_VIEW_QUERY_LIST.includes(query));
            const remainingApiCalls = _.difference(allCallsToBeMade, subsetOfApiCalls);

            triggerDataFetchAndDoAssociatedStateChanges(
                setDataAndMetadata,
                tick.name,
                tick.id,
                brand.id,
                apiScheduler,
                abortControllers.current,
                showPeerIndices,
                subsetOfApiCalls
            );

            // Set a new timeout for the remaining API calls and store timeout id for cancellation:
            delayedApiCallTimeoutRef.current = setTimeout(() => {
                triggerDataFetchAndDoAssociatedStateChanges(
                    setDataAndMetadata,
                    tick.name,
                    tick.id,
                    brand.id,
                    apiScheduler,
                    abortControllers.current,
                    showPeerIndices,
                    remainingApiCalls
                );
            }, FIXED_NON_CRITICAL_API_DELAY);
        }


    }

    useEffect(() => {
        triggerFullDataFetch();
        return () => {
            abortControllers.current.abortThemAllNoReset();
        }
    }, [tick, brand, refresh, customQueryList]);

    const isFirstRenderSkippedPeers = useRef(true);

    useEffect(() => {
        if (tick.id == null) return;
        if (isFirstRenderSkippedPeers.current && !showPeerIndices) {
            // Do not trigger this api on the first render, if show peer indices is false, since the full data fetch in the useEffect above will trigger it:
            isFirstRenderSkippedPeers.current = false;
            return;
        }
        //  Abort ongoing calls if any:
        abortControllers.current.abortPeerIndicesCalls()
        triggerPeerIndicesDependentDataFetches(setDataAndMetadata, tick.name, tick.id, brand.id, apiScheduler, abortControllers.current, showPeerIndices, customQueryList);
    }, [showPeerIndices]);


    return (
        <DashboardApiContextStore.Provider value={{dataState, competitorList}}>
            {children}
        </DashboardApiContextStore.Provider>
    )
}

