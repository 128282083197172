import { useState } from "react";
import { ExpandOutlined } from "@ant-design/icons";
import { Modal } from "antd";

function ExpandModal(props) {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <ExpandOutlined onClick={() => setShowModal(true)} />
            {props.outerContent}

            <Modal width={2500} open={showModal} onOk={() => setShowModal(false)} onCancel={() => setShowModal(false)}>
                {props.modalContent}
            </Modal>
        </>
    )
}

export default ExpandModal;