import {useEffect, useState} from 'react';
import {debounce} from "../../../utils/StudioChartUtils";

const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            if (Math.abs(windowDimensions.height - window.innerHeight) > 1
                || Math.abs(windowDimensions.width - window.innerWidth) > 1){
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }

        };

        window.addEventListener('resize', debounce(handleResize, 200));
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};

export default useWindowDimensions;