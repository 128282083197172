import {useEffect, useState} from "react";
import LoadingOverlay from 'react-loading-overlay';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {CAlert} from "@coreui/react";
import {getMacroResults} from "../../../api/data/DataProvider";


function MacroStackCharts(props) {
    const [chartData, setChartData] = useState(null)
    const [loading, setLoading] = useState(chartData == null)
    const [statusMsg, setStatusMsg] = useState('Loading your content...')
    const [isError, setIsError] = useState(false)

    const setPref = (data) => {
        data.series.forEach(series => {
            if (!series.name.includes(" v ")) {
                // series.visible = false;
            } else {
                series.color = "#000000"
            }
        })

        return data;
    }

    useEffect(() => {
        const abortController = new AbortController();
        setIsError(false);
        setLoading(true)
            getMacroResults(props.queryType, abortController)
                .then((res) => {
                    let data = setPref(res.data);
                    setChartData((prev) => {
                        return {
                            ...data,
                            title: {
                                text: res.data.title.text
                            },
                            // title: props.activeTicker + ' ' + res.data.title,
                            chart: {
                                ...res.data.chart,
                                zooming: {
                                    mouseWheel: {
                                        enabled: false
                                    }
                                },
                                zoomType: 'x',
                                height: 350,
                                plotBackgroundColor: '#E5ECF6',
                                panning: {
                                    enabled: true,
                                    type: 'x'
                                },
                                panKey: 'shift'
                            },
                            legend: {
                                ...res.data.legend,
                                itemStyle: {
                                    color: "#000000"
                                },
                                itemHiddenStyle: {
                                    textDecoration: 'none'  // Disable strikethrough effect
                                }
                            }
                        }
                    });
                    setLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    if (err.code === "ERR_CANCELED") {
                        // do nothing for now
                    } else {
                        setStatusMsg("Failed to load data for the chart. Looks like this data is not available.")
                        setIsError(true);
                    }
                })

        return () => abortController.abort();
    }, [props.refresh]);

    return (
        <>
            <a id={props.queryType} href={""}> </a>
            <LoadingOverlay
                active={loading && !isError}
                spinner={!isError}
                text={"Loading..."}
            >
                {isError && <div>
                    <CAlert color="danger">
                        Failed to load - {props.queryType}.
                        Data Not Configured
                    </CAlert>
                </div>}
                {chartData == null && !isError && <p style={{ height: "400px", width: "90%" }}> ...</p>}
                {chartData != null && !isError && chartData.series.length === 0 && <div>
                    <CAlert color="warning">
                        Data Not Available - {props.queryType}
                    </CAlert>
                </div>}
                {chartData != null && chartData != undefined && !isError && chartData.series.length !== 0 && <center><div style={{ marginTop: 10, paddingLeft: 10 }}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartData}
                    /></div></center>}
            </LoadingOverlay>

        </>
    )
}

export default MacroStackCharts;