const highchartsToSvg = {
    'solid': 'none',
    'shortdash': '6,2',
    'shortdot': '2,2',
    'shortdashdot': '6,2,2,2',
    'shortdashdotdot': '6,2,2,2,2,2',
    'dot': '2,6',
    'dash': '8,6',
    'longdash': '16,6',
    'dashdot': '8,6,2,6',
    'longdashdot': '16,6,2,6',
    'longdashdotdot': '16,6,2,6,2,6'
};

export default function highChartToSvgDashStyleConvertor (strokeDashStyle) {
    return highchartsToSvg[makeLowerCase(strokeDashStyle)] || 'none';
}

const makeLowerCase = (input) => {
    if(input !== undefined && input !== null) return input.toLowerCase();
    return input;
}