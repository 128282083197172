import useClickoutProviderHook from "../oracle/commonHooks/ClickoutProviderHook";
import {useContext} from "react";
import {RefreshContext} from "../oracle/commonContexts/RefreshContextProvider";

function ClickOut(props) {

    const {refresh} = useContext(RefreshContext);


    const {
        clickOutUrl,
        loading,
        enabledLinkStyle,
        disabledLinkStyle,
        make3Y,
        make4Y,
        make5Y
    } = useClickoutProviderHook(props.activeTickerId, props.activeBrandId, props.clickOutType, refresh);

    return (
        <div id={props.displayName} align={"left"} style={{marginBottom: 10}}>
            <br/>
            {props.positionView && <>
                {props.displayName === "GT US Trends Click" && <a style={loading ? disabledLinkStyle : enabledLinkStyle} href={make3Y(clickOutUrl, "US").replaceAll("+", "%2B")} target={"_blank"}>
                    {props.displayName + ' (3Y)'}
                </a>}<br/><br/>
                {props.displayName === "GT US Trends Click" && <a style={loading ? disabledLinkStyle : enabledLinkStyle} href={make4Y(clickOutUrl, "US").replaceAll("+", "%2B")} target={"_blank"}>
                    {props.displayName + ' (4Y)'}
                </a>}
                {props.displayName === "GT WW Trends Click" && <a style={loading ? disabledLinkStyle : enabledLinkStyle} href={make3Y(clickOutUrl, "").replaceAll("+", "%2B")} target={"_blank"}>
                    {props.displayName + ' (3Y)'}
                </a>}<br/><br/>
                {props.displayName === "GT WW Trends Click" && <a style={loading ? disabledLinkStyle : enabledLinkStyle} href={make4Y(clickOutUrl, "").replaceAll("+", "%2B")} target={"_blank"}>
                    {props.displayName + ' (4Y)'}
                </a>}
            </>}
            {!props.positionView && <>
                {props.clickOutType === "GT_TERM_USAGE" && <a style={loading ? disabledLinkStyle : enabledLinkStyle} href={clickOutUrl.replaceAll("+", "%2B")} target={"_blank"}>{props.displayName}</a>}
                {props.displayName === "GT US Trends Click" && <a style={loading ? disabledLinkStyle : enabledLinkStyle} href={make3Y(clickOutUrl, "US").replaceAll("+", "%2B")} target={"_blank"}>
                    {props.displayName + ' (3Y)'}
                </a>}

                {props.displayName === "GT US Trends Click" && <a style={loading ? disabledLinkStyle : enabledLinkStyle} href={make4Y(clickOutUrl, "US").replaceAll("+", "%2B")} target={"_blank"}>
                    {props.displayName + ' (4Y)'}
                </a>}

                {props.displayName === "GT US Trends Click" && <a style={loading ? disabledLinkStyle : enabledLinkStyle} href={make5Y(clickOutUrl, "US").replaceAll("+", "%2B")} target={"_blank"}>
                    {props.displayName + ' (5Y)'}
                </a>}

                {props.displayName === "GT WW Trends Click" && <a style={loading ? disabledLinkStyle : enabledLinkStyle} href={make3Y(clickOutUrl, "").replaceAll("+", "%2B")} target={"_blank"}>
                    {props.displayName + ' (3Y)'}
                </a>}

                {props.displayName === "GT WW Trends Click" && <a style={loading ? disabledLinkStyle : enabledLinkStyle} href={make4Y(clickOutUrl, "").replaceAll("+", "%2B")} target={"_blank"}>
                    {props.displayName + ' (4Y)'}
                </a>}

                {props.displayName === "GT WW Trends Click" && <a style={loading ? disabledLinkStyle : enabledLinkStyle} href={make5Y(clickOutUrl, "").replaceAll("+", "%2B")} target={"_blank"}>
                    {props.displayName + ' (5Y)'}
                </a>}
                {props.displayName === "GT Terms Used" && <span style={{marginLeft: 5}}>(<b>{clickOutUrl.substring(clickOutUrl.indexOf("q=") + 2).replaceAll("%2B", "+")}</b>)</span>}
            </>}
        </div>
    )
}

export default ClickOut;