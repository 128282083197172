import {useContext, useState} from "react";
import {ViewContextStore} from "../DashboardReport/views/ViewContext";
import {Modal} from "antd";
import {Box, Tab, Tabs, Typography} from "@mui/material";
import {CommonControlsPane} from "./CommonControlsPane";
import {SeriesControlPane} from "./SeriesControlPane";
import {ChartControlsPane} from "./ChartControlsPane";

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

//Todo: disable the shortcuts hook when the modal is open:

export const ViewsSettingsModal = () => {

    const {
        viewSettingsModalIsOpen,
        toggleViewSettingsModal,
    }
        = useContext(ViewContextStore);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Modal width={950} open={viewSettingsModalIsOpen}
            onOk={toggleViewSettingsModal} onCancel={toggleViewSettingsModal}
        >
            <Tabs
                variant="scrollable"
                value={value}
                onChange={handleChange}
                >
                <Tab label={'Common Controls'} {...a11yProps(0)} />
                <Tab label={'Series Controls'} {...a11yProps(1)} />
                <Tab label={'Chart Controls'} {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <CommonControlsPane />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <SeriesControlPane />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ChartControlsPane />
            </TabPanel>
        </Modal>
    )
}