import {Layout, Menu} from "antd";
import LinkedinCharts from "../line_chart/linkedin/LinkedinEmployeeYoY";
import HC_exporting from "highcharts/modules/exporting";
import Highcharts from "highcharts";
import HighchartsStock from "highcharts/modules/stock";
import {CAlert} from "@coreui/react";

const {Header, Content} = Layout;


function LinkedinReport(props) {

    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'instant'});
        }
    };

    return (
        <div>
            {HC_exporting(Highcharts)}
            {require("highcharts/modules/export-data")(Highcharts)}
            {HighchartsStock(Highcharts)}
            <Layout>
                <Header style={{height: 45, position: "fixed", padding: '0 10px', width: "100%", zIndex: 1000}}>
                    <div className="logo" style={{float: "left", marginTop: -10, color: "#ffffff"}}>
                        <img style={{height: 25, marginRight: 15}} src={"/linkedin_logo.png"}/>
                    </div>
                    <Menu mode={"horizontal"} theme={"dark"}
                          style={{height: 45, alignItems: "center", overflow: "hidden"}}>
                        <Menu.Item key="9" onClick={() => scrollToElement('LN_EMPLOYEE_COUNT_YOY')}>
                            EMPLOYEE COUNT YoY
                        </Menu.Item>
                    </Menu>
                </Header>
                <Content style={{marginTop: 50}}>
                    <CAlert color="info">
                        <img style={{height: 50, marginRight: 15}} src={"/linkedin_logo.png"}/>
                    </CAlert>
                    <br/>
                    <LinkedinCharts queryType={"LN_EMPLOYEE_COUNT_YOY"}/>
                </Content>
            </Layout>
        </div>
    )
}

export default LinkedinReport;