import {useContext} from "react";
import {MODES, ViewContextStore} from "../DashboardReport/views/ViewContext";
import {Grid} from "@mui/material";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

export const CommonControlsPane = () => {

    const {
        mode,
        setMode,
        isDailyStackAlignment,
        setDailyStackAlignment,
        showWeeklySeries,
        setShowWeeklySeries,
        showPeerIndices,
        setShowPeerIndices,
        dropLastPoint,
        setDropLastPoint,
        combineAxesBulkToggle,
        toggleCombineAxisBulk,
    } = useContext(ViewContextStore);

    const handleModeChange = e => setMode(e.target.value);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>View Mode:</Grid>
                <Grid item xs={6}>
                    <Select
                        value={mode}
                        onChange={handleModeChange}
                        >
                        {Object.keys(MODES).map((key) => (
                            <MenuItem key={key} value={MODES[key]}>{MODES[key]}</MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={6}>Daily Stack Alignment:</Grid>
                <Grid item xs={6}>
                    <Checkbox
                        checked={isDailyStackAlignment}
                        onChange={e => setDailyStackAlignment(e.target.checked)}
                    />
                </Grid>
                <Grid item xs={6}>Show Weekly Series:</Grid>
                <Grid item xs={6}>
                    <Checkbox
                        checked={showWeeklySeries}
                        onChange={e => setShowWeeklySeries(e.target.checked)}
                    />
                </Grid>
                <Grid item xs={6}>Show Peer Indices:</Grid>
                <Grid item xs={6}>
                    <Checkbox
                        checked={showPeerIndices}
                        onChange={e => setShowPeerIndices(e.target.checked)}
                    />
                </Grid>
                <Grid item xs={6}>Drop Last Point:</Grid>
                <Grid item xs={6}>
                    <Checkbox
                        checked={dropLastPoint}
                        onChange={e => setDropLastPoint(e.target.checked)}
                    />
                </Grid>
                <Grid item xs={6}>Combine axis across charts:</Grid>
                <Grid item xs={6}>
                    <Checkbox
                        checked={combineAxesBulkToggle}
                        onChange={e => toggleCombineAxisBulk()}
                    />
                </Grid>
            </Grid>
        </>
    )
}

