import {
    ContainsPeerIndices,
    DashboardQueryTypes,
    getPriorityForQueryType,
    IsCCAxisCombinedDependentCharts,
    listOfListOfDashboardQueriesWithPriorities
} from "../../../api/data/DashboardQueryTypes";
import {getData, getDataForBrand} from "../../../api/data/DataProvider";
import {getIdioData} from "../../../api/data/PortfolioDataProvider";

export const apiStateDefaultObject = {
    chartData: null,
    isError: false,
    loading: true
};

let initialDataLoadStateCache;
export const initialDataLoadState = () => {
    if(initialDataLoadStateCache !== undefined) return initialDataLoadStateCache;
    const flatListOfDashboardQueries = listOfListOfDashboardQueriesWithPriorities.flat();
    initialDataLoadStateCache = {};
    flatListOfDashboardQueries.forEach((query) => {
        initialDataLoadStateCache[query] = {...apiStateDefaultObject}
    });
    return initialDataLoadStateCache;
}

function makeApiCallAndAttachCallbacks(functionToCall, setDataStateFunction, queryType) {
    // Set the state to be loading
    setDataStateFunction(null, queryType, true, false);
    // API call and associated handling:
    functionToCall().then((res) =>
        setDataStateFunction(res, queryType, false, false)
    )
        .catch((err) => {
            if (err.code === 'ERR_CANCELED') {
                // Do nothing, since this was due to the abort being triggered on the abort controller.
            } else {
                console.log(err);
                console.log(err.code);
                    setDataStateFunction(null, queryType, false, true);
            }

        })
}


const fetchDataForWeeklyCharts = (setDataStateFunction, activeTicker, activeBrand,apiScheduler, queryType, abortController, peerIndices = false) => {
    const priority = getPriorityForQueryType(queryType)
    const isEligibleForPeerIndices = ContainsPeerIndices.includes(queryType);
    const functionToCall = activeBrand == null ? () => getData(activeTicker, queryType, abortController, apiScheduler, priority, peerIndices && isEligibleForPeerIndices) : () => getDataForBrand(activeTicker, activeBrand, queryType,abortController, apiScheduler, priority, peerIndices && isEligibleForPeerIndices);
    makeApiCallAndAttachCallbacks(functionToCall, setDataStateFunction, queryType);
}

const fetchDataForQuarterCharts = (setDataStateFunction, activeTicker, activeBrand, queryType, apiScheduler, abortController, peerIndices = false) => {
    const priority = getPriorityForQueryType(queryType);
    const isEligibleForPeerIndices = ContainsPeerIndices.includes(queryType);
    let functionToCall = null;
    if(activeBrand == null){
        functionToCall = () => getData(activeTicker, queryType, abortController, apiScheduler, priority, peerIndices && isEligibleForPeerIndices);
    } else{
        functionToCall =  () => getDataForBrand(activeTicker, activeBrand, queryType, abortController, apiScheduler, priority, peerIndices && isEligibleForPeerIndices);
    }
    makeApiCallAndAttachCallbacks(functionToCall, setDataStateFunction, queryType);
}

const fetchDataForStackCharts = (setDataStateFunction, activeTicker, activeBrand, apiScheduler, queryType, abortController) => {
    const priority = getPriorityForQueryType(queryType);
    let queryString = queryType;
    const functionToCall = activeBrand == null ? () => getData(activeTicker, queryString, abortController, apiScheduler, priority) : () => getDataForBrand(activeTicker, activeBrand, queryType, abortController, apiScheduler, priority);
    // Query Type should remain  consistent here:
    makeApiCallAndAttachCallbacks(functionToCall, setDataStateFunction, queryType);
}

const fetchDataForIdio = (setDataStateFunction, activeTickerId, apiScheduler, abortController) => {
    const queryType = DashboardQueryTypes.IDIO;
    const priority = getPriorityForQueryType(queryType);
    const functionToCall = () => getIdioData(activeTickerId, abortController, apiScheduler, priority);
    makeApiCallAndAttachCallbacks(functionToCall, setDataStateFunction, queryType);
}

const fetchDataForGtRegionGrid = (setDataStateFunction, activeTicker, activeBrand, apiScheduler, abortController) => {
    const queryType = DashboardQueryTypes.WEEKLY_STACK_GT_TOP_REGIONS_GRID;
    const priority = getPriorityForQueryType(queryType);
    const functionsToCall = activeBrand == null ?
        [
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_1", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_2", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_3", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_4", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_5", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_6", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_7", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_8", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_9", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_10", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_11", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_12", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_13", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_14", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_15", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_16", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_17", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_REGIONS_18", abortController, apiScheduler, priority),
            () => getData(activeTicker, "WEEKLY_STACK_GT_TOP_CONTINENTS_1", abortController, apiScheduler, priority),
        ] :
        [
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_1", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_2", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_3", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_4", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_5", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_6", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_7", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_8", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_9", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_10", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_11", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_12", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_13", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_14", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_15", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_16", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_17", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_REGIONS_18", abortController, apiScheduler, priority),
            () => getDataForBrand(activeTicker, activeBrand, "WEEKLY_STACK_GT_TOP_CONTINENTS_1", abortController, apiScheduler, priority),
        ];

    let promises = functionsToCall.map((f, index) =>
        f().then(res => res)
            .catch(err => null));

    Promise.all(promises).then(res => {
        setDataStateFunction(res, queryType);
    })
}


// Todo: Bind the query type to the chart type in some way, so that this massive function reduces to a simpler and less buggy implementation:
const triggerQueryOfQueryType = (queryType, setDataStateFunction, activeTicker, activeTickerId, activeBrand, apiScheduler, abortController, peerIndices = false) => {
    const queryTypeToFunctionMapping = {
        [DashboardQueryTypes.WEEKLY_YOY]: () => fetchDataForWeeklyCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_YOY, abortController, peerIndices),
        [DashboardQueryTypes.QTR_YOY_LAG0]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.QTR_YOY_LAG0, apiScheduler, abortController, peerIndices),
        [DashboardQueryTypes.WEEKLY_YOY2]: () => fetchDataForWeeklyCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_YOY2, abortController, peerIndices),
        [DashboardQueryTypes.QTR_YOY2_LAG0]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.QTR_YOY2_LAG0, apiScheduler, abortController),
        [DashboardQueryTypes.IDIO]: () => fetchDataForIdio(setDataStateFunction, activeTickerId, apiScheduler, abortController),
        [DashboardQueryTypes.WEEKLY_YOY3]: () => fetchDataForWeeklyCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_YOY3, abortController),
        [DashboardQueryTypes.WEEKLY_YOY4]: () => fetchDataForWeeklyCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_YOY4, abortController),
        [DashboardQueryTypes.WEEKLY_YOY5]: () => fetchDataForWeeklyCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_YOY5, abortController),
        [DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_INDEX]: () => fetchDataForWeeklyCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_INDEX, abortController),
        [DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_GP_INDEX]: () => fetchDataForWeeklyCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_GP_INDEX, abortController),
        [DashboardQueryTypes.WEEKLY_PROFIT_YOY_LAG0]: () => fetchDataForWeeklyCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_PROFIT_YOY_LAG0, abortController),
        [DashboardQueryTypes.WEEKLY_PRICING_YOY_LAG0]: () => fetchDataForWeeklyCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_PRICING_YOY_LAG0, abortController),
        [DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY_LAG0]: () => fetchDataForWeeklyCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY_LAG0, abortController),
        [DashboardQueryTypes.WEEKLY_PROFIT_YOY2_LAG0]: () => fetchDataForWeeklyCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_PROFIT_YOY2_LAG0, abortController),
        [DashboardQueryTypes.WEEKLY_PRICING_YOY2_LAG0]: () => fetchDataForWeeklyCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_PRICING_YOY2_LAG0, abortController),
        [DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY2_LAG0]: () => fetchDataForWeeklyCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY2_LAG0, abortController),
        [DashboardQueryTypes.WEEKLY_LEVELS]: () => fetchDataForWeeklyCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_LEVELS, abortController),
        [DashboardQueryTypes.WEEKLY_GP_LEVELS]: () => fetchDataForWeeklyCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_GP_LEVELS, abortController),
        [DashboardQueryTypes.PROFIT_YOY_LAG0]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.PROFIT_YOY_LAG0, apiScheduler, abortController),
        [DashboardQueryTypes.PROFIT_YOY_LAG1]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.PROFIT_YOY_LAG1, apiScheduler, abortController),
        [DashboardQueryTypes.PROFIT_YOY_LAG2]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.PROFIT_YOY_LAG2, apiScheduler, abortController),
        [DashboardQueryTypes.PRICING_YOY_LAG0]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.PRICING_YOY_LAG0, apiScheduler, abortController),
        [DashboardQueryTypes.PRICING_YOY_LAG1]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.PRICING_YOY_LAG1, apiScheduler, abortController),
        [DashboardQueryTypes.PRICING_YOY_LAG2]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.PRICING_YOY_LAG2, apiScheduler, abortController),
        [DashboardQueryTypes.QTR_TRANSACTIONS_YOY_LAG0]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.QTR_TRANSACTIONS_YOY_LAG0, apiScheduler, abortController),
        [DashboardQueryTypes.PROFIT_YOY2_LAG0]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.PROFIT_YOY2_LAG0, apiScheduler, abortController),
        [DashboardQueryTypes.PROFIT_YOY2_LAG1]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.PROFIT_YOY2_LAG1, apiScheduler, abortController),
        [DashboardQueryTypes.PROFIT_YOY2_LAG2]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.PROFIT_YOY2_LAG2, apiScheduler, abortController),
        [DashboardQueryTypes.PRICING_YOY2_LAG0]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.PRICING_YOY2_LAG0, apiScheduler, abortController),
        [DashboardQueryTypes.PRICING_YOY2_LAG1]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.PRICING_YOY2_LAG1, apiScheduler, abortController),
        [DashboardQueryTypes.PRICING_YOY2_LAG2]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.PRICING_YOY2_LAG2, apiScheduler, abortController),
        [DashboardQueryTypes.QTR_TRANSACTIONS_YOY2_LAG0]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.QTR_TRANSACTIONS_YOY2_LAG0, apiScheduler, abortController),
        [DashboardQueryTypes.QTR_YOY_LAG1]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.QTR_YOY_LAG1, apiScheduler, abortController),
        [DashboardQueryTypes.QTR_YOY_LAG2]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.QTR_YOY_LAG2, apiScheduler, abortController),
        [DashboardQueryTypes.QTR_YOY2_LAG0]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.QTR_YOY2_LAG0, apiScheduler, abortController),
        [DashboardQueryTypes.QTR_YOY2_LAG1]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.QTR_YOY2_LAG1, apiScheduler, abortController),
        [DashboardQueryTypes.QTR_YOY2_LAG2]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.QTR_YOY2_LAG2, apiScheduler, abortController),
        [DashboardQueryTypes.QTR_YOY3_LAG0]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.QTR_YOY3_LAG0, apiScheduler, abortController),
        [DashboardQueryTypes.QTR_YOY3_LAG1]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.QTR_YOY3_LAG1, apiScheduler, abortController),
        [DashboardQueryTypes.QTR_YOY3_LAG2]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.QTR_YOY3_LAG2, apiScheduler, abortController),
        [DashboardQueryTypes.QTR_LEVELS_LAG0]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.QTR_LEVELS_LAG0, apiScheduler, abortController),
        [DashboardQueryTypes.QTR_LEVELS_LAG1]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.QTR_LEVELS_LAG1, apiScheduler, abortController),
        [DashboardQueryTypes.QTR_LEVELS_LAG2]: () => fetchDataForQuarterCharts(setDataStateFunction, activeTicker, activeBrand, DashboardQueryTypes.QTR_LEVELS_LAG2, apiScheduler, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_GT_WW]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_GT_WW, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_GT_US]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_GT_US, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_SW_WW]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_SW_WW, abortController),
        [DashboardQueryTypes.DAILY_STACK_SW_WW]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.DAILY_STACK_SW_WW, abortController),
        [DashboardQueryTypes.DAILY_STACK_SW_WW_ALIGNED]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.DAILY_STACK_SW_WW_ALIGNED, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_SW_US]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_SW_US, abortController),
        [DashboardQueryTypes.DAILY_STACK_SW_US]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.DAILY_STACK_SW_US, abortController),
        [DashboardQueryTypes.DAILY_STACK_SW_US_ALIGNED]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.DAILY_STACK_SW_US_ALIGNED, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_ER_ORION_SALES]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_ER_ORION_SALES, abortController),
        [DashboardQueryTypes.DAILY_STACK_ER_ORION_SALES]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.DAILY_STACK_ER_ORION_SALES, abortController),
        [DashboardQueryTypes.DAILY_STACK_ER_ORION_SALES_ALIGNED]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.DAILY_STACK_ER_ORION_SALES_ALIGNED, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_ER_ORION_TRANSACTIONS]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_ER_ORION_TRANSACTIONS, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_SALES]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_SALES, abortController),
        [DashboardQueryTypes.DAILY_STACK_ER_VELA_VELORUM_SALES]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.DAILY_STACK_ER_VELA_VELORUM_SALES, abortController),
        [DashboardQueryTypes.DAILY_STACK_ER_VELA_VELORUM_SALES_ALIGNED]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.DAILY_STACK_ER_VELA_VELORUM_SALES_ALIGNED, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_TRANSACTIONS]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_TRANSACTIONS, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_SALES]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_SALES, abortController),
        [DashboardQueryTypes.DAILY_STACK_ER_VELA_GAMMA_SALES]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.DAILY_STACK_ER_VELA_GAMMA_SALES, abortController),
        [DashboardQueryTypes.DAILY_STACK_ER_VELA_GAMMA_SALES_ALIGNED]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.DAILY_STACK_ER_VELA_GAMMA_SALES_ALIGNED, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_TRANSACTIONS]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_TRANSACTIONS, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_SM_SALES]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_SM_SALES, abortController),
        [DashboardQueryTypes.DAILY_STACK_SM_SALES]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.DAILY_STACK_SM_SALES, abortController),
        [DashboardQueryTypes.DAILY_STACK_SM_SALES_ALIGNED]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.DAILY_STACK_SM_SALES_ALIGNED, abortController),
        [DashboardQueryTypes.DAILY_STACK_CC_BASELINE]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.DAILY_STACK_CC_BASELINE, abortController),
        [DashboardQueryTypes.DAILY_STACK_CC_BASELINE_ALIGNED]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.DAILY_STACK_CC_BASELINE_ALIGNED, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_TOTAL_BASELINE]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_TOTAL_BASELINE, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_US]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_US, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_US]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_US, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_US]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_US, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_WW]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_WW, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_WW]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_WW, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_WW]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_WW, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_US]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_US, abortController),
        [DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_US]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_US, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_US]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_US, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_WW]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_WW, abortController),
        [DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_WW]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_WW, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_WW]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_WW, abortController),
        [DashboardQueryTypes.DAILY_YOY]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.DAILY_YOY, abortController),
        [DashboardQueryTypes.DAILY_YOY_ALIGNED]: () => fetchDataForStackCharts(setDataStateFunction, activeTicker, activeBrand, apiScheduler, DashboardQueryTypes.DAILY_YOY_ALIGNED, abortController),
        [DashboardQueryTypes.WEEKLY_STACK_GT_TOP_REGIONS_GRID]: () => fetchDataForGtRegionGrid(setDataStateFunction, activeTicker, activeBrand, apiScheduler, abortController),
    }
    // Get the corresponding function from the map above, and trigger the function:
    const functionToTrigger = queryTypeToFunctionMapping[queryType];
    if(functionToTrigger == null) {
    } else functionToTrigger();
}



export const triggerDataFetchAndDoAssociatedStateChanges = (setDataStateFunction, activeTicker, activeTickerId, activeBrand, apiScheduler, drAbortControllers, peerIndices, customQueryList) => {
    const flatListOfDashboardQueries = customQueryList == null  ? listOfListOfDashboardQueriesWithPriorities.flat() : customQueryList;
    triggerDataFetchesForQueryList(setDataStateFunction, activeTicker, activeTickerId, activeBrand, apiScheduler, drAbortControllers, peerIndices, flatListOfDashboardQueries);
}

export const triggerDataFetchesForQueryList = (setDataStateFunction, activeTicker, activeTickerId, activeBrand, apiScheduler, abortControllers, peerIndices, queryList) => {
    queryList.forEach(queryType => triggerQueryOfQueryType(queryType, setDataStateFunction, activeTicker, activeTickerId, activeBrand, apiScheduler, abortControllers.getControllerToUseForQuery(queryType), peerIndices));
}

export const triggerPeerIndicesDependentDataFetches = (setDataStateFunction, activeTicker, activeTickerId, activeBrand, apiScheduler, abortControllers, peerIndices, customQueryList) => {
    // The following filter and set is redundant, but makes code consistent in case of future changes:
    const queryList = customQueryList == null  ? ContainsPeerIndices : ContainsPeerIndices.filter(query => customQueryList.includes(query)) ;
    triggerDataFetchesForQueryList(setDataStateFunction, activeTicker, activeTickerId, activeBrand, apiScheduler, abortControllers, peerIndices, queryList);
}

export const setStateAsLoadingForAxisDependentSeries = (setDataStateFunction) => {
    IsCCAxisCombinedDependentCharts.forEach(queryType => setDataStateFunction((prev) => ({
        ...prev,
        [queryType]: {
           ...apiStateDefaultObject
        }
    })));
}