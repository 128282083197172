import {memo, useMemo, useState} from "react";
import LoadingOverlay from 'react-loading-overlay';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {CAlert} from "@coreui/react";
import {transportChartOptionsForSmallViewWithCustomSize} from "../studio_high_chart/StudioHighChart";
import {Modal} from "antd";
import {calculateIdioData} from "./IdioChart";


const StackCharts = memo(({
                              chartData,
                              chartToggleConfig,
                              preferences,
                              activeTicker,
                              activeBrand,
                              activeBrandName,
                              queryType,
                              isError,
                              loading,
                              chartHeightOverride,
                              chartWidth,
                              isMiniMode,
                              idioData,
                          }) => {

    const getDailyTimeShiftedChart = (chartData) => {

        if (chartData == null) return null;
        if (!preferences) { // If no preferences, return as-is.
            return chartData;
        }

        const newChartData = {...chartData};
        for (let series of newChartData?.series) {
            let seriesName = series?.name;
            let timeShift = new Map(Object.entries(preferences?.timeShift?.stack?.daily));

            if (timeShift.has(seriesName)) {
                for (let dataPoint of series?.data) {
                    let dataDate = new Date(dataPoint[0]);
                    dataDate.setDate(dataDate.getDate() + timeShift.get(seriesName));
                    dataPoint[0] = dataDate.getTime();
                }
            }
        }

        return newChartData;
    }

    const hasBrand = activeBrand != null;
    let chartHeight = hasBrand ? 500 : 350;
    if (chartHeightOverride != null) chartHeight = chartHeightOverride;

    const {
        compactViewModalState,
        compactViewToggleModal
    } = chartToggleConfig;

    const [localModalState, setLocalModalState] = useState(false);
    const modalState = isMiniMode ? compactViewModalState : localModalState;
    const setModalState = isMiniMode ? compactViewToggleModal : () => setLocalModalState(prev => !prev);

    const buildChartData = (chartData, activeTicker, activeBrand, activeBrandName) => {
        if (chartData == null) return null;
        const entityTitle = hasBrand ? `${activeTicker} - ${activeBrandName}` : activeTicker;

        return {
            ...chartData.data,
            title: {
                text: `${entityTitle} - ${chartData?.data?.title?.text}`,
            },
            chart: {
                ...chartData.data.chart,
                zooming: {
                    mouseWheel: {enabled: false},
                },
                zoomType: 'x',
                height: chartHeight,
                plotBackgroundColor: '#E5ECF6',
                panning: {enabled: true, type: 'x'},
                panKey: 'shift',
                marginLeft: 150,
                events:{
                    click: e => {
                        const xAxisValue = e.xAxis[0].value;
                        const yAxisValue = e.yAxis[0].value;
                        // Only trigger if the click is in the active chart area:
                        if(!Number.isNaN(xAxisValue) && !Number.isNaN(yAxisValue))
                            setModalState()
                    }
                }
            },
            legend: {
                ...chartData.data.legend,
                itemStyle: {color: "#000000"},
                itemHiddenStyle: {textDecoration: 'none'},  // Disable strikethrough effect
            },
        };
    }

    const addIdioSeries = (chartData) => {
        let startTimestamp = new Date(new Date().getFullYear(), 0, 1).getTime();
        let allIdioData = calculateIdioData(idioData, startTimestamp);
        let newIdioSeries = allIdioData ? allIdioData.idioSeries : undefined;
        if (newIdioSeries) {
            // For stacks, we need to trim the Idio series as well
            newIdioSeries = newIdioSeries.filter(p => p[0] >= startTimestamp);

            let idioSeriesObject = chartData?.data?.series.find(s => s.name === "idio")
            if (idioSeriesObject) {
                idioSeriesObject.data = newIdioSeries;
            }
        }

        return chartData;
    }

    const memoizedChartData = useMemo(() => {
        let chartDataWithIdio = addIdioSeries(chartData);
        const newChartOptions = buildChartData(chartDataWithIdio, activeTicker, activeBrand, activeBrandName, compactViewToggleModal);
        if (queryType.includes("DAILY")) return getDailyTimeShiftedChart(newChartOptions);
        return newChartOptions;
    }, [chartData, activeTicker, activeBrand, queryType])

    return (
        <>
            <a id={queryType} href={""}> </a>
            <LoadingOverlay
                active={loading && !isError}
                spinner={!isError}
                text={"Loading..."}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        height: chartHeight,
                        width: '100%'
                    })
                }}
            >
                {isError && <div>
                    <CAlert color="danger">
                        Failed to load - {queryType}.
                        Data Not Configured
                    </CAlert>
                </div>}
                {memoizedChartData == null && !isError && <div style={{
                    height: chartHeight,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}><h1 style={{color: 'white'}}>Chart is loading. Please wait.</h1></div>}
                {memoizedChartData != null && !isError && memoizedChartData.series.length === 0 && <div>
                    <CAlert color="warning">
                        Data Not Available - {queryType}
                    </CAlert>
                </div>}
                {memoizedChartData != null && !isError && memoizedChartData.series.length !== 0 &&
                    <>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartHeightOverride != null && chartWidth != null && !compactViewModalState ? transportChartOptionsForSmallViewWithCustomSize(memoizedChartData, chartHeightOverride, chartWidth) : memoizedChartData}
                        />
                        <Modal width={2500} open={modalState} onOk={setModalState}
                               onCancel={setModalState}>
                            <center>
                                <div style={{marginTop: 10, paddingLeft: 10}}>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartHeightOverride != null && chartWidth != null && !compactViewModalState ? transportChartOptionsForSmallViewWithCustomSize(memoizedChartData, chartHeightOverride, chartWidth) : memoizedChartData}
                                    />
                                </div>
                            </center>
                        </Modal>
                    </>
                }
            </LoadingOverlay>

        </>
    )
})

export default StackCharts;

