import {List, Popover} from "antd";
import dayjs from "dayjs";

export const getIndex = (scrollTicks, activeTick) => {
    if (scrollTicks && scrollTicks.length > 0) {
        const isObjectArray = typeof scrollTicks[0] === 'object';
        const targetValue = activeTick?.id;

        // Using findIndex to get the index
        const index = scrollTicks.findIndex(item =>
            isObjectArray ? item.tickerId === targetValue : item === targetValue
        );

        // Return -1 if not in list, else fix to a 1-based index:
        return index >= 0 ? index + 1 : -1;
    }

    // The array is not defined or is empty
    return -1;
}

const shortDateFormat = "MMM DD";

export const getTextsLength = washsaleTexts => {
    if(washsaleTexts.length === 1) return washsaleTexts[0].length;
    else {
        return washsaleTexts.slice(1).reduce((acc, text) => acc + text.length, 0);
    }

}

export const getWashSaleTexts = (washsaleInfo) => {
    if(washsaleInfo === undefined) {
        return ['Loading...'];
    }

    if (washsaleInfo === null) {
        return ['WASH ERROR'];
    }

    if(washsaleInfo.length === 0){
        return ['NO WASH'];
    }

    let allDates = washsaleInfo[0].washsaleTrades.map(t => dayjs(t.tradeDate));
    let earliestDate = dayjs(Math.min(...allDates));
    let latestDate = dayjs(Math.max(...allDates));
    let washEndDate;
    if (!earliestDate.isSame(latestDate, 'day')) {
        washEndDate = `${earliestDate.add(31, 'day').format(shortDateFormat)} - ${latestDate.add(31, 'day').format(shortDateFormat)}`
    } else {
        washEndDate = earliestDate.add(31, 'day').format(shortDateFormat);
    }
    let washLoss = washsaleInfo[0].washsaleLoss;

    let stocksLoss = washsaleInfo[0].washsaleTrades.filter(t => t.assetType === "STK").reduce((sum, t) => sum + t.loss, 0);
    let optionsLoss = washsaleInfo[0].washsaleTrades.filter(t => t.assetType === "OPT").reduce((sum, t) => sum + t.loss, 0);

    let spanTitle = `Stock Loss: ${Math.abs(stocksLoss/1000).toFixed(0)}K, Option Loss: ${Math.abs(optionsLoss/1000).toFixed(0)}K`;
    const span1Text = '$' + (washLoss*-1/1000).toFixed(0) + 'K';
    return [spanTitle, span1Text, `(${washEndDate})`];
}


export const washSaleRenderingComponent = (washsaleInfo, washsaleTexts) => {
    if (washsaleInfo === undefined) {
        return <span>Loading...</span>;
    }

    if (washsaleInfo === null) {
        return <span>WASH ERROR</span>;
    }

    if (washsaleInfo.length === 0) {
        return <span style={{color: "green"}}>NO WASH</span>;
    }

    let washLoss = washsaleInfo[0].washsaleLoss;


    let tradeDetailPopover = (
        <List style={{width: '200px', height: '100%'}}
              dataSource={washsaleInfo[0].washsaleTrades.sort((a, b) => dayjs(a.tradeDate) - dayjs(b.tradeDate))}
              renderItem={(item) => {
                  let assetType = item.assetType === 'STK' ? 'Stock' : 'Option';
                  let loss = new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      maximumFractionDigits: 0,
                      currency: 'USD'
                  }).format(item.loss);
                  let description = `${loss} (${assetType}: ${item.symbol})`;
                  return (
                      <List.Item>
                          <List.Item.Meta title={dayjs(item.tradeDate).format(shortDateFormat)} description={description}/>
                      </List.Item>
                  )
              }
              }
        />);

    return (washsaleInfo[0].washsaleTrades.length > 0)  && washLoss && <Popover placement={"bottom"} content={tradeDetailPopover}>
        <span><span style={{"color": "red"}}>{washsaleTexts[1]}</span><span
            style={{marginLeft: 5}}>{washsaleTexts[2]}</span></span>
    </Popover>;
}

export const getSiPctFloat = (shortInterestInfo) => {
    if (shortInterestInfo != null) {
        let siPctFloat = shortInterestInfo.filter(info => info.shortInterestType === "PCT_FLOAT")
        if (siPctFloat.length > 0) {
            return {
                value: siPctFloat[0].shortInterestPct,
                oneMonthChg: siPctFloat[0].oneMonthChange,
                asOf: siPctFloat[0].asOfDate
            }
        }
    }

    return {
        value: "N/A",
        oneMonthChg: "N/A",
        asOf: "N/A"
    };
}

export const resolveColor = (value) => {
    if (value === "N/A") {
        return "black";
    }

    if (value < 0) {
        return "green";
    }

    if (value > 0) {
        return "red";
    }
}

export const getShortInterestComponent = (shortInterestInfo) => {
    let siPctFloat = getSiPctFloat(shortInterestInfo);
    let siPctShout = getSiPctShout(shortInterestInfo);

    return <span style={{color: "#000000", fontWeight: 500}}>
                                <span
                                    style={{fontWeight: 300}}>SI Float:</span> <span>{siPctFloat.value === "N/A" ? "N/A" : (siPctFloat.value * 100).toFixed(1)}%</span>
                                ,
                                <span
                                    style={{color: resolveColor(siPctFloat.oneMonthChg)}}>{siPctFloat.oneMonthChg === "N/A" ? "N/A" : (siPctFloat.oneMonthChg * 100).toFixed(1)}%</span> 30D Chg
                                <span style={{marginLeft: 5}}>({siPctFloat.asOf})</span>
                                <span style={{
                                    marginLeft: 30,
                                    fontWeight: 300
                                }}> SI S/O:</span> <span>{siPctShout.value === "N/A" ? "N/A" : (siPctShout.value * 100).toFixed(1)}%</span>
                                ,
                                <span
                                    style={{color: resolveColor(siPctShout.oneMonthChg)}}>{siPctShout.oneMonthChg === "N/A" ? "N/A" : (siPctShout.oneMonthChg * 100).toFixed(1)}%</span> 30D Chg
                                <span style={{marginLeft: 5}}>({siPctShout.asOf})</span>
                            </span>
}

export const getCompanyProfileInfoComponent = (profileInfo) => {
    if (profileInfo == null) {
        return <span><b>NOT AVAILABLE</b></span>
    }

    const mktCapInBillion = profileInfo.mktCap == null ? "NA" : (profileInfo.mktCap / 1_000_000_000).toFixed(2);

    return <span style={{color: "#000000", fontWeight: 500}}>
        <span>Avg. Vol: <b>{abbreviateNumber(profileInfo.volAvg)}</b></span>
        <span style={{marginLeft: 10}}>Mkt. Cap: <b>{mktCapInBillion} B</b></span>
    </span>
}

const abbreviateNumber = (num) => {
    if (num == null) return "NA";
    if (num >= 1_000_000) {
        return (num / 1_000_000).toFixed(1) + 'M';
    } else if (num >= 1_000) {
        return (num / 1_000).toFixed(1) + 'K';
    } else {
        return num.toString();
    }
};

export const getSiPctShout = (shortInterestInfo) => {
    if (shortInterestInfo != null) {
        let siPctShout = shortInterestInfo.filter(info => info.shortInterestType === "PCT_SHARES_OUTSTANDING")
        if (siPctShout.length > 0) {
            return {
                value: siPctShout[0].shortInterestPct,
                oneMonthChg: siPctShout[0].oneMonthChange,
                asOf: siPctShout[0].asOfDate
            }
        }
    }

    return {
        value: "N/A",
        oneMonthChg: "N/A",
        asOf: "N/A"
    };
};