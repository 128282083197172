import {useEffect, useState} from "react";
import {getFactorWeightsForTicker} from "../../../api/data/PortfolioDataProvider";

export function useFactorWeightsProviderHook(activeTickerId, refresh) {
    const [factorWeights, setFactorWeights] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const abortController = new AbortController();
        setLoading(true);
        getFactorWeightsForTicker(activeTickerId, abortController)
            .then((res) => {
                setFactorWeights(res.data);
            })
            .catch((err) => {
                setFactorWeights([]);
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })

        return () => abortController.abort();
    }, [activeTickerId, refresh]);

    return {factorWeights, loading};
}