import StockGradesTable from "../../stock_grades/StockGradesTable";
import InsiderTransTable from "../../insider_transactions/InsiderTransTable";
import React, {memo, useEffect, useRef, useState} from "react";
import {DashboardReportCompactView} from "../compactView/DashboardReportCompactView";
import SentieoBar from "../../stock_grades/SentieoBar";
import CompositeIndexDetails from "../CompositeIndexDetails";
import WeeklyCharts from "../../line_chart/WeeklyCharts";
import {DashboardQueryTypes} from "../../../api/data/DashboardQueryTypes";
import IdioChart from "../../line_chart/IdioChart";
import DailyCharts from "../../line_chart/DailyCharts";
import IndexDetails from "../IndexDetails";
import QuarterCharts, {ApiContextConnectedQuarterChart} from "../../line_chart/QuarterCharts";
import ExpandModal from "../../ExpandModal";
import {APIContextConnectedMutiChartSwitcher} from "../../line_chart/MultiChartSwitcher";
import ClickOut from "../../clickout/ClickOut";
import DynAltGtStackChartsWrapper from "../../line_chart/alt_gt_stack/DynAltGtStackChartsWrapper";
import StackCharts from "../../line_chart/StackCharts";
import GtTopRegionsGrid from "../../line_chart/GtTopRegionsGrid";
import DynSwStackChartsWrapper from "../../line_chart/sw_dynamic_stack/DynSwStackChartsWrapper";
import {DASHBOARD_GRID} from "./DashboardReport";
import Measure from "react-measure";
import {message} from "antd";
import {MiniView2x2} from "./MiniView2x2";
import {areEqual, VariableSizeList} from "react-window";
import {ProjectionsScreen} from "./projectionChartsManagement/ProjectionsScreen";
import {ContextConnectedCorrelationReport} from "../correlationReort/CorrelationReport";
import Banner from "react-js-banner";

import _ from "lodash";
import NavBar from "../NavBar";
import {WTDStacksComponent} from "./wtdStacks/WTDStacksComponent";
import {OracleAIPopupComponent} from "./chat/OracleAIPopupComponent";
import {SplitContextsConsumerWrapper} from "./views/SplitContextsConsumerWrapper";
import useWindowDimensions from "../commonHooks/WindowDimensionsHook";

export function addUcToUrlAndOpenInNewTab() {
    const currentUrl = window.location.href
    const urlObj = new URL(currentUrl);
    urlObj.searchParams.set('UC', 'true');
    window.open(urlObj.toString(), '_blank');
}

const getItemsListForReport1 = (tick, brand, setTick, setBrand, preferences, indexWeights, ccIndexWeights) => {
    const weeklyChartsComponentHeight = 600;
    const idioChartsComponentHeight = 600;
    const dailyChartsComponentHeight = 600;
    const quarterChartsComponentHeight = 850;
    const modalChartsComponentHeight = 950;
    const stackChartHeight = brand.id == null ? 350 : 500;

    return [
        {
            height: idioChartsComponentHeight,
            component: <IdioChart
                activeTicker={tick.name}
                queryType={DashboardQueryTypes.IDIO}
                activeTickerId={tick.id}/>,
            queryType: DashboardQueryTypes.IDIO
        }
    ]
};
const getItemsListForReport = (tick, brand, setTick, setBrand, preferences, indexWeights, ccIndexWeights) => {
    const weeklyChartsComponentHeight = 600;
    const idioChartsComponentHeight = 600;
    const dailyChartsComponentHeight = 600;
    const quarterChartsComponentHeight = 850;
    const modalChartsComponentHeight = 950;
    const stackChartHeight = brand.id == null ? 350 : 500;

    const bannerElement = brand.id == null ? {height: 0, component: <></>} :
        {
            height: 50,
            component: <Banner
                id={'KPI-Mosiacs-info-banner'}
                title={"Additional KPI's Available for QTR mosaics below (not mapped to any specific brand), can be found in end of legend with prefix 'bbg_kpi: '"}/>
        };

    const stockGradesTableComponent = brand.id == null ? {
        height: 600,
        refForwardingOverride: true,
        component: <StockGradesTable activeTicker={tick.name}/>
    } : {height: 0, component: <></>};

    const insiderTransactionsTableComponent = brand.id == null ? {
        height: 600,
        refForwardingOverride: true,
        component: <InsiderTransTable activeTicker={tick.name}/>
    } : {height: 0, component: <></>};

    return [
        {
            height: 1500,
            component: <DashboardReportCompactView tick={tick} brand={brand} modifyTick={setTick} setBrand={setBrand}/>,
            queryType: DASHBOARD_GRID
        },
        {
            height: 185,
            component:<SentieoBar activeTick={tick}/>
        },
        {
            height: 75,
            component: <CompositeIndexDetails tickerName={tick.name}/>
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_YOY}

            />,
            queryType: DashboardQueryTypes.WEEKLY_YOY
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_YOY2}
            />,
            queryType: DashboardQueryTypes.WEEKLY_YOY2
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_YOY3}
            />,
            queryType: DashboardQueryTypes.WEEKLY_YOY3
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_YOY4}
            />,
            queryType: DashboardQueryTypes.WEEKLY_YOY4
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_YOY5}
            />,
            queryType: DashboardQueryTypes.WEEKLY_YOY5
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_INDEX}
            />,
            queryType: DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_INDEX
        },
        {
            height: idioChartsComponentHeight,
            component: <IdioChart
                activeTicker={tick.name}
                queryType={DashboardQueryTypes.IDIO}
                activeTickerId={tick.id}/>,
            queryType: DashboardQueryTypes.IDIO
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_PROFIT_YOY_LAG0}
            />,
            queryType: DashboardQueryTypes.WEEKLY_PROFIT_YOY_LAG0
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_PRICING_YOY_LAG0}
            />,
            queryType: DashboardQueryTypes.WEEKLY_PRICING_YOY_LAG0
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY_LAG0}
            />,
            queryType: DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY_LAG0
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_PROFIT_YOY2_LAG0}
            />,
            queryType: DashboardQueryTypes.WEEKLY_PROFIT_YOY2_LAG0
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_PRICING_YOY2_LAG0}
            />,
            queryType: DashboardQueryTypes.WEEKLY_PRICING_YOY2_LAG0
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY2_LAG0}
            />,
            queryType: DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY2_LAG0
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_GP_INDEX}
            />,
            queryType: DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_GP_INDEX
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_LEVELS}
            />,
            queryType: DashboardQueryTypes.WEEKLY_LEVELS
        },
        {
            height: dailyChartsComponentHeight,
            component: <DailyCharts activeTicker={tick.name} activeBrand={brand.id} activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.DAILY_YOY}
                                    yearsToShow={1}
            />,
            queryType: DashboardQueryTypes.DAILY_YOY,
            style: {marginBottom: 10}
        },
        bannerElement,
        {
            height: 50,
            component: <IndexDetails name='Total Index' weights={indexWeights?.weightDetails?.weights}/>
        },
        {
            height: 50,
            component: <IndexDetails name='CC Index' weights={ccIndexWeights?.weightDetails?.weights}/>
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY_LAG0}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY_LAG0
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY_LAG1}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY_LAG1
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY_LAG2}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY_LAG2
        },
        {
            height: modalChartsComponentHeight,
            component: <ExpandModal
                outerContent={<ApiContextConnectedQuarterChart
                    activeTicker={tick.name}
                    activeBrand={brand.id}
                    activeBrandName={brand.name}
                    queryType={DashboardQueryTypes.PROFIT_YOY_LAG0}
                    yearsToShow={5}/>}
                modalContent={
                    <APIContextConnectedMutiChartSwitcher ChartType={QuarterCharts}
                                                          queryTypes={[DashboardQueryTypes.PROFIT_YOY_LAG0, DashboardQueryTypes.PROFIT_YOY_LAG1, DashboardQueryTypes.PROFIT_YOY_LAG2]}
                                                          activeTicker={tick.name}
                                                          activeBrand={brand.id} activeBrandName={brand.name}
                                                          yearsToShow={5}/>
                }/>,
            queryType: DashboardQueryTypes.PROFIT_YOY_LAG0
        },
        {
            height: modalChartsComponentHeight,
            component: <ExpandModal
                outerContent={<ApiContextConnectedQuarterChart
                    activeTicker={tick.name}
                    activeBrand={brand.id}
                    activeBrandName={brand.name}
                    queryType={DashboardQueryTypes.PRICING_YOY_LAG0}
                    yearsToShow={5}/>}
                modalContent={
                    <APIContextConnectedMutiChartSwitcher ChartType={QuarterCharts}
                                                          queryTypes={[DashboardQueryTypes.PRICING_YOY_LAG0, DashboardQueryTypes.PRICING_YOY_LAG1, DashboardQueryTypes.PRICING_YOY_LAG2]}
                                                          activeTicker={tick.name}
                                                          activeBrand={brand.id} activeBrandName={brand.name}
                                                          yearsToShow={5}/>
                }/>,
            queryType: DashboardQueryTypes.PRICING_YOY_LAG0
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts activeTicker={tick.name}
                                      activeBrand={brand.id}
                                      activeBrandName={brand.name}
                                      queryType={DashboardQueryTypes.QTR_TRANSACTIONS_YOY_LAG0}
                                      yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_TRANSACTIONS_YOY_LAG0
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY2_LAG0}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY2_LAG0
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY2_LAG1}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY2_LAG1
        }, {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY2_LAG2}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY2_LAG2
        },
        {
            height: modalChartsComponentHeight,
            component: <ExpandModal
                outerContent={<ApiContextConnectedQuarterChart
                    activeTicker={tick.name}
                    activeBrand={brand.id}
                    activeBrandName={brand.name}
                    queryType={DashboardQueryTypes.PROFIT_YOY2_LAG0}
                    yearsToShow={5}/>}
                modalContent={
                    <APIContextConnectedMutiChartSwitcher ChartType={QuarterCharts}
                                                          queryTypes={[DashboardQueryTypes.PROFIT_YOY2_LAG0, DashboardQueryTypes.PROFIT_YOY2_LAG1, DashboardQueryTypes.PROFIT_YOY2_LAG2]}
                                                          activeTicker={tick.name}
                                                          activeBrand={brand.id} activeBrandName={brand.name}
                                                          yearsToShow={5}/>
                }/>
        },
        {
            height: modalChartsComponentHeight,
            component: <ExpandModal
                outerContent={<ApiContextConnectedQuarterChart
                    activeTicker={tick.name}
                    activeBrand={brand.id}
                    activeBrandName={brand.name}
                    queryType={DashboardQueryTypes.PRICING_YOY2_LAG0}
                    yearsToShow={5}/>}
                modalContent={
                    <APIContextConnectedMutiChartSwitcher ChartType={QuarterCharts}
                                                          queryTypes={[DashboardQueryTypes.PRICING_YOY2_LAG0, DashboardQueryTypes.PRICING_YOY2_LAG1, DashboardQueryTypes.PRICING_YOY2_LAG2]}
                                                          activeTicker={tick.name}
                                                          activeBrand={brand.id} activeBrandName={brand.name}
                                                          yearsToShow={5}/>
                }/>
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_TRANSACTIONS_YOY2_LAG0}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_TRANSACTIONS_YOY2_LAG0
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY3_LAG0}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY3_LAG0
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY3_LAG1}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY3_LAG1
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY3_LAG2}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY3_LAG2
        }, {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_LEVELS_LAG0}
                yearsToShow={7}/>,
            queryType: DashboardQueryTypes.QTR_LEVELS_LAG0
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_LEVELS_LAG1}
                yearsToShow={7}/>,
            queryType: DashboardQueryTypes.QTR_LEVELS_LAG1
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_LEVELS_LAG2}
                yearsToShow={7}/>,
            queryType: DashboardQueryTypes.QTR_LEVELS_LAG2
        },
        {
            height: 50,
            component: <ClickOut activeTickerId={tick.id} activeBrandId={brand.id} clickOutType={"GT_TERM_USAGE"}
                                 displayName={"GT Terms Used"}/>
        },
        {
            height: 75,
            component: <ClickOut activeTickerId={tick.id} activeBrandId={brand.id} clickOutType={"GT_WW"}
                                 displayName={"GT WW Trends Click"}/>,
            queryType: "GT WW Trends Click"
        },
        {
            height: 1000,
            component: <DynAltGtStackChartsWrapper activeTicker={tick.name} activeBrand={brand.id}
                                                   activeBrandName={brand.name}
                                                   queryType={DashboardQueryTypes.WEEKLY_DYN_ALT_GT_STACK}
                                                   yearsToShow={1}
                                                   location={"WW"}/>,
            queryType: DashboardQueryTypes.WEEKLY_DYN_ALT_GT_STACK
        },
        // Todo: state for this needs to be lifted out, when it unmounts, closes the opened/selected charts
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_GT_WW}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_GT_WW
        },
        {
            height: 50,
            component: <ClickOut activeTickerId={tick.id} activeBrandId={brand.id} clickOutType={"GT_US"}
                                 displayName={"GT US Trends Click"}/>
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_GT_US}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_GT_US,
            style: {marginBottom: 10}
        }, {
            height: 1700,
            perpetualHeightOverride: true,
            component: <GtTopRegionsGrid activeTicker={tick.name}
                                         activeBrand={brand.id}
                                         activeBrandName={brand.name}
                                         queryType={DashboardQueryTypes.WEEKLY_STACK_GT_TOP_REGIONS_GRID}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_GT_TOP_REGIONS_GRID
        },
        {
            height: 70,
            component: <DynSwStackChartsWrapper activeTicker={tick}
                                                queryType={DashboardQueryTypes.WEEKLY_DYN_SW_STACK}
                                                yearsToShow={1}
                                                location={"WW"}/>,
            queryType: DashboardQueryTypes.WEEKLY_DYN_SW_STACK
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_SW_WW}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_SW_WW
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.DAILY_STACK_SW_WW}
                                    yearsToShow={1}
                                    preferences={preferences}/>,
            queryType: DashboardQueryTypes.DAILY_STACK_SW_WW
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_SW_US}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_SW_US
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.DAILY_STACK_SW_US}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.DAILY_STACK_SW_US
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ER_ORION_SALES}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ER_ORION_SALES
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.DAILY_STACK_ER_ORION_SALES}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.DAILY_STACK_ER_ORION_SALES
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ER_ORION_TRANSACTIONS}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ER_ORION_TRANSACTIONS
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_SALES}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_SALES
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.DAILY_STACK_ER_VELA_VELORUM_SALES}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.DAILY_STACK_ER_VELA_VELORUM_SALES
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_TRANSACTIONS}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_TRANSACTIONS
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_SALES}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_SALES
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.DAILY_STACK_ER_VELA_GAMMA_SALES}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.DAILY_STACK_ER_VELA_GAMMA_SALES
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_TRANSACTIONS}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_TRANSACTIONS
        },
        {
            height: 75,
            component: <ClickOut activeTickerId={tick.id}
                                 activeBrandId={brand.id}
                                 clickOutType={"SM"}
                                 displayName={"SM Click"}/>
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_SM_SALES}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_SM_SALES
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.DAILY_STACK_SM_SALES}
                                    yearsToShow={1}
                                    preferences={preferences}/>,
            queryType: DashboardQueryTypes.DAILY_STACK_SM_SALES
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.DAILY_STACK_CC_BASELINE}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.DAILY_STACK_CC_BASELINE
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_TOTAL_BASELINE}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_TOTAL_BASELINE
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_US}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_US
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_US}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_US
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_US}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_US
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_WW}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_WW
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_WW}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_WW
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_WW}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_WW
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_US}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_US
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_US}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_US
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_US}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_US
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_WW}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_WW
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_WW}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_WW
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_WW}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_WW
        },
        stockGradesTableComponent,
        insiderTransactionsTableComponent
    ]
}

const queryTypeToIndexCacheForScrolling = {};

const onRenderCallback = (
    id, // the "id" prop of the Profiler tree that has just committed
    phase, // either "mount" or "update"
    actualDuration, // time spent rendering the committed update
    baseDuration, // estimated time to render the entire subtree without memoization
    startTime, // when React began rendering this update
    commitTime, // when React committed this update
    interactions // the Set of interactions belonging to this update
) => {
    console.log({
        id,
        phase,
        actualDuration,
        baseDuration,
        startTime,
        commitTime,
        interactions
    });
};


export const DashboardReportViewComponent = ({
                                                 tick,
                                                 brand,
                                                 showMiniView,
                                                 miniViewMode3x3,
                                                 setTick,
                                                 setShowMiniView,
                                                 setBrand,
                                                 indexWeights,
                                                 ccIndexWeights,
                                                 preferences,
                                                 showMacroView,
                                                 setShowMacroView,
                                                 toggleMiniView,
                                                 toggleMiniViewMode,
                                                 tickerOptions,
                                                 defaultUcMode = false,
                                                 setDefaultUcMode
                                             }
) => {

    const [messageApi, contextHolder] = message.useMessage();

    const SCROLL_POSITION = "start";

    const fullDashBoardItemsList = getItemsListForReport(tick, brand, setTick, setBrand, preferences, indexWeights, ccIndexWeights);

    // This ref will store the ResizeObserver / Measure observed height for each of the LazyLoaded components:
    const rowHeights = useRef({});
    const dimensionsMeasured = useRef({});
    const listRef = useRef(null);

    function getItemSize(index) {
        // TradingView needs the size to be hardcoded:
        if (fullDashBoardItemsList[index].perpetualHeightOverride != null && fullDashBoardItemsList[index].perpetualHeightOverride === true) {
            return fullDashBoardItemsList[index].height;
        }
        let possibleHeight = rowHeights.current[index];
        // For initial render, use provided heuristic values:
        if (possibleHeight == null) {
            return fullDashBoardItemsList[index].height;
        }
        return possibleHeight;
    }

    // Allow for updating the measurements on change to the window size
    useEffect(() => {
        const unsetDimensionsMeasured = () => dimensionsMeasured.current = {};
        window.addEventListener("resize", unsetDimensionsMeasured);

        return () => window.removeEventListener("resize", unsetDimensionsMeasured);
    }, []);

    const [correlReportVisibility, setCorrelReportVisibility] = useState(false);
    const [projectionsVisibility, setProjectionsVisibility] = useState(defaultUcMode);

    useEffect(() => {
        setDefaultUcMode(projectionsVisibility);
    }, [projectionsVisibility]);

    const scrollIndex = useRef(-1);

    const scrollToElement = (queryType) => {
        messageApi.open({
            type: 'loading',
            content: 'Scrolling',
            duration: 0.1
        }).then(() => {
            let index;
            // Try to lookup the item in the cache:
            if (queryTypeToIndexCacheForScrolling[queryType] != null) {
                // Cache hit:
                index = queryTypeToIndexCacheForScrolling[queryType];
            } else {
                index = _.findIndex(fullDashBoardItemsList, item => item.queryType === queryType);
                queryTypeToIndexCacheForScrolling[queryType] = index;
            }

            if (correlReportVisibility || projectionsVisibility) {
                setCorrelReportVisibility(false);
                setProjectionsVisibility(false);
                scrollIndex.current = index;
                return;
            }

            if (showMiniView) {
                setShowMiniView(false);
                scrollIndex.current = index;
                return;
            }

            // Todo: Figure the placement of item in hte viewport:
            listRef.current?.scrollToItem(index, SCROLL_POSITION);
        });

    }

    const scrollOffset = useRef(-1);
    const toggleCorrelReportVisibility = () => {

        messageApi.open({
            type: 'loading',
            content: 'Toggling Correlations',
            duration: 0.2
        }).then(() => {
            //get current state:
            if (!correlReportVisibility) {
                //Store the current scroll position:
                scrollOffset.current = listRef.current?.state.scrollOffset;
                setCorrelReportVisibility(true);
            } else {
                setCorrelReportVisibility(false)
            }
            setProjectionsVisibility(false)
        })
    }

    const toggleProjectionsVisibility = () => {

        messageApi.open({
            type: 'loading',
            content: 'Toggling Projections',
            duration: 0.2
        }).then(() => {
            //get current state:
            if (!projectionsVisibility) {
                //Store the current scroll position:
                scrollOffset.current = listRef.current?.state.scrollOffset;
                setProjectionsVisibility(true);
            } else {
                setProjectionsVisibility(false)
            }
            setCorrelReportVisibility(false);
        })
    }


    useEffect(() => {
            if (!listRef.current) return;
            if (scrollIndex.current >= 0) {
                listRef.current?.scrollToItem(scrollIndex.current, SCROLL_POSITION);
                scrollIndex.current = -1;
            } else if (scrollOffset.current >= 0) {
                listRef.current?.scrollTo(scrollOffset.current)
                scrollOffset.current = -1;
            }
        },
        [listRef.current]);


    useEffect(() => {
        const handleKeyPress = (event) => {
            if (window.isEnableHotKeys && !event.ctrlKey) {
                if (event.key === 'q') {
                    scrollToElement(DASHBOARD_GRID);
                } else if (event.key === 'w') {
                    toggleCorrelReportVisibility();
                } else if (event.key === '1') {
                    toggleProjectionsVisibility();
                } else if (event.key === 'e') {
                    scrollToElement(DashboardQueryTypes.QTR_YOY_LAG0);
                } else if (event.key === 'r') {
                    scrollToElement(DashboardQueryTypes.PROFIT_YOY_LAG0);
                } else if (event.key === 't') {
                    scrollToElement(DashboardQueryTypes.QTR_YOY2_LAG0);
                } else if (event.key === 'y') {
                    scrollToElement(DashboardQueryTypes.QTR_YOY3_LAG0);
                } else if (event.key === 'u') {
                    scrollToElement(DashboardQueryTypes.QTR_LEVELS_LAG0);
                } else if (event.key === 'i') {
                    scrollToElement(DashboardQueryTypes.WEEKLY_DYN_ALT_GT_STACK);
                } else if (event.key === 'o') {
                    scrollToElement(DashboardQueryTypes.WEEKLY_DYN_SW_STACK);
                } else if (event.key === 'p') {
                    scrollToElement(DashboardQueryTypes.WEEKLY_STACK_ER_ORION_SALES);
                } else if (event.key === '[') {
                    scrollToElement(DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_SALES)
                } else if (event.key === ']') {
                    scrollToElement(DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_SALES);
                } else if (event.key === '\\') {
                    scrollToElement(DashboardQueryTypes.WEEKLY_STACK_SM_SALES);
                } else if (event.key === 's') {
                    scrollToElement(DashboardQueryTypes.DAILY_STACK_CC_BASELINE);
                } else if (event.key === 'a') {
                    scrollToElement(DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_US);
                }
            }

        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [correlReportVisibility, projectionsVisibility]);

    function setRowHeight(index, size) {
        if (dimensionsMeasured.current[index]) return;
        listRef.current?.resetAfterIndex(0);
        if (rowHeights.current[index] === Math.round(size)) return;
        rowHeights.current = {...rowHeights.current, [index]: Math.round(size)};
        dimensionsMeasured.current = {...dimensionsMeasured.current, [index]: Math.round(size)};
    }

    const HeightObserverWrappedComponent = memo(({index, style}) => {
        // Todo: There is scope for optimizing the behaviour here, using single resizeObserver shared across all the components.
        const item = fullDashBoardItemsList[index];
        const currentMeasuredHeight = getItemSize(index);
        return <div id={item.queryType} style={style}>
            <Measure
                bounds
                onResize={(contentRect) => setRowHeight(index, contentRect.bounds.height)}>
                {({measureRef}) =>
                    <div ref={measureRef}>
                        <SplitContextsConsumerWrapper
                            queryType={item.queryType}
                            RawComponent={item.component}
                            chartHeightOverride={currentMeasuredHeight}
                        />
                    </div>
                }
            </Measure>
        </div>
    }, areEqual);

    const {height, width} = useWindowDimensions();
    return (
        <>
            {contextHolder}
            <NavBar activeTick={tick}
                    setActiveTick={setTick}
                    activeBrand={brand}
                    setActiveBrand={setBrand}
                    showMacroView={showMacroView}
                    setShowMacroView={setShowMacroView}
                    toggleMiniView={toggleMiniView}
                    showMiniView={showMiniView}
                    tickerOptions={tickerOptions}
                    scrollToItem={scrollToElement}
                    toggleCorrelReportVisibility={toggleCorrelReportVisibility}
                    miniViewMode3x3={miniViewMode3x3}
                    toggleMiniViewMode={toggleMiniViewMode}
                    toggleProjectionsVisibility={toggleProjectionsVisibility}
            />
            <WTDStacksComponent
                brand={brand}
                tick={tick}
            />
            <OracleAIPopupComponent
                brand={brand}
                tick={tick}
            />
            <br/>
            <br/>
            {showMiniView && miniViewMode3x3 && <>
                <DashboardReportCompactView tick={tick}
                                            brand={brand}
                                            modifyTick={setTick}
                                            setBrand={setBrand}/>
            </>}
            {showMiniView && !miniViewMode3x3 &&
                <MiniView2x2 tick={tick} brand={brand}
                             showMiniView={showMiniView}
                             setBrand={setBrand}/>
            }

            {!showMiniView && !correlReportVisibility && !projectionsVisibility && <>
                <VariableSizeList
                    height={height}
                    itemCount={fullDashBoardItemsList.length}
                    itemSize={getItemSize}
                    width={'100%'}
                    ref={listRef}
                    overscanCount={1}
                >
                    {HeightObserverWrappedComponent}
                </VariableSizeList>
            </>
            }
            {
                !showMiniView && !correlReportVisibility && projectionsVisibility && <>
                    <ProjectionsScreen/>
                </>
            }
            {!showMiniView && correlReportVisibility && !projectionsVisibility &&
                <ContextConnectedCorrelationReport
                    activeTicker={tick.name}
                    activeBrand={brand.id}
                    indexWeights={indexWeights}
                    ccIndexWeights={ccIndexWeights}
                />
            }
        </>
    )
}