import {Autocomplete, TextField} from "@mui/material";
import {useState} from "react";
import {monitor} from "../../../../event_handler/KeyPressMonitor";

const cleanString = (str) => str.replace(/['"]/g, '');

const areStringsEqual = (str1, str2) => {

    return cleanString(str1) === cleanString(str2);
};

export const ProjectionsAdderGrid = ({addableProjections, addProjection}) => {

    // addableProjections = availableProjections - activeProjections
    // addProjections -> function -> adds the projection to active projections + adds it to the layout

    const seriesOptions = Object.keys(addableProjections);

    const [lhsSeries, setLhsSeries] = useState(seriesOptions[0] || null);
    const [rhsSeries, setRhsSeries] = useState(seriesOptions[1] || null);

    const handleAddProjection = () => {
        if(lhsSeries != null || rhsSeries != null) addProjection(lhsSeries, rhsSeries);
    };

    return (
        <div className="uc-grid-container">
            <Autocomplete
                className="uc-select-component"
                disablePortal={true}
                options={seriesOptions}
                value={lhsSeries}
                onChange={(e, newValue) => setLhsSeries(newValue)}
                renderInput={(params) => <TextField {...params} label={"Series Name"} />}
                onOpen={() => monitor.enableHotKeys(false)}
                onClose={() => monitor.enableHotKeys(true)}
                isOptionEqualToValue={(option, value) => areStringsEqual(option, value)}
            />
            <Autocomplete
                className="uc-select-component"
                disablePortal={true}
                options={seriesOptions}
                value={rhsSeries}
                onChange={(e, newValue) => setRhsSeries(newValue)}
                renderInput={(params) => <TextField {...params} label={"Series Name"} />}
                onOpen={() => monitor.enableHotKeys(false)}
                onClose={() => monitor.enableHotKeys(true)}
                isOptionEqualToValue={(option, value) => areStringsEqual(option, value)}
            />
            <button className="uc-button-component" onClick={handleAddProjection}>Add Projection</button>
        </div>
    );
}