import {AsyncTaskPriorityBasedScheduler} from "./AsyncTaskPriorityBasedScheduler.js";

export class TickerApiScheduler {

    constructor(tickerName, parallelTaskCount) {
        this.currentTicker = tickerName;
        this.asyncTaskScheduler = new AsyncTaskPriorityBasedScheduler(parallelTaskCount);
    }

    async scheduleApiCall(apiCall, ticker, priority, isTickerIdBased = false){
        if(ticker !== null && ticker !== this.currentTicker && isTickerIdBased === false){ // If ticker is null => General call, not specific to the company
            // Ticker has been changed, remove all the old pending APIs:
            this.currentTicker = ticker;
            this.asyncTaskScheduler.resetPendingTaskList();
        }
        return this.asyncTaskScheduler.makeRequest(apiCall, priority);
    }



}