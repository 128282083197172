

export const GranularityType = {
    Weekly: "Weekly",
    Monthly: "Monthly",
    Quarterly: "Quarterly",
    Annual: "Annual",
    Daily: "Daily"
};

export const AggregationType = {
    NTM: "NTM",
    LTM: "LTM",
    NTM_TWA: "NTM_TWA",
    CURRENT: "F0",
    NEXTYEAR: "F1",
    LEVELS: "nope",
    YOY: "YoY"
};

export const ReportType = {
    Reported: "reported",
    Estimated: "estimated",
    Computed: "computed"
};
