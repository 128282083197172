import React, {useContext} from "react";
import {RefreshContext} from "../../commonContexts/RefreshContextProvider";
import {Modal} from "antd";
import {ViewContextStore} from "../views/ViewContext";
import ChatComponent from "./ChatComponent";

export const OracleAIPopupComponent = ({tick, brand}) => {

    const {refresh} = useContext(RefreshContext);

    const {chatModalOpen, setChatModalOpen} = useContext(ViewContextStore);

    const close = () => setChatModalOpen(false);

    return (
        <Modal width={1800} open={chatModalOpen} onOk={() => close()} onCancel={() => close()}>
            <ChatComponent
                brand={brand}
                tick={tick}
            />
        </Modal>
    );


}
