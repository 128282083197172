import {createContext, memo, useEffect, useState} from "react";

const matrixWidth = 3; // Defines the Compact view height and width in terms of chart count in each direction.

/*
Below is the modified code handling navigation around the 3x3 matrix excluding 9th element:
When the direction would normally take you to the 9th element, it skips it and goes to the next in line.
This applies to all directions Up, Down, Left, Right.

The Idea is just to add an extra step away from the Excluded Index when we encounter indexes leading to it.
*/
let totalCells = matrixWidth ** 2;
let ExcludedIndex = 8; // this is that 9th cell which we want to exclude
const getBelowIndex = (currentIndex) => {
    let newIndex = (currentIndex + matrixWidth) % totalCells;
    return newIndex !== ExcludedIndex ? newIndex : getBelowIndex(newIndex); // if index is excluded one, calculate for the next one
}
const getAboveIndex = (currentIndex) => {
    let newIndex = (currentIndex - matrixWidth + totalCells) % totalCells;
    return newIndex !== ExcludedIndex ? newIndex : getAboveIndex(newIndex); // if index is excluded one, calculate for the next one
}
const getNextIndex = (currentIndex) => {
    let newIndex = (currentIndex + 1) % totalCells;
    return newIndex !== ExcludedIndex ? newIndex : getNextIndex(newIndex); // if index is excluded one, calculate for the next one
}
const getPreviousIndex = (currentIndex) => {
    let newIndex = (currentIndex - 1 + totalCells) % totalCells;
    return newIndex !== ExcludedIndex ? newIndex : getPreviousIndex(newIndex); // if index is excluded one, calculate for the next one
}


export const GridModalContext = createContext(null);
 const ChartGridModalContextProviderInternal = ({children}) => {

    const [modal3x3State, setModal3x3State] = useState({
        open: false,
        index: 0
    });

    const toggle3x3Modal = (index) => {
        setModal3x3State(prev => ({
            index: index,
            open: !prev.open
        }));
    };

    // Logic for handling arrow keys based toggling of the modal open chart in the mini view:
    const goAbove = (e) => {
        if (modal3x3State.open) e.preventDefault();
        setModal3x3State(prev => {
            if (!prev.open) return prev;
            return {
                ...prev,
                index: getAboveIndex(prev.index)
            }
        });
    }

    const goBelow = (e) => {
        if (modal3x3State.open) e.preventDefault();
        setModal3x3State(prev => {
            if (!prev.open) return prev;
            return {
                ...prev,
                index: getBelowIndex(prev.index)
            }
        });
    }

    const goNext = (e) => {
        if (modal3x3State.open) e.preventDefault();
        setModal3x3State(prev => {
            if (!prev.open) return prev;
            return {
                ...prev,
                index: getNextIndex(prev.index)
            }
        });
    }

    const goPrev = (e) => {
        if (modal3x3State.open) e.preventDefault();
        setModal3x3State(prev => {
            if (!prev.open) return prev;
            return {
                ...prev,
                index: getPreviousIndex(prev.index)
            }
        });
    }

    const toggleOffModal = (e) => {
        if (modal3x3State.open) e.preventDefault();
        setModal3x3State(prev => {
            if(prev.open) e.preventDefault();
            return {
                ...prev,
                open: false
            }
        });
    }

    useEffect(() => {
        const handleKeyPress = e => {
            if ( e.key === 'ArrowUp') {
                goAbove(e);
            }

            if ( e.key === 'ArrowDown') {
                goBelow(e);
            }

            if ( e.key === 'ArrowRight') {
                goNext(e);
            }

            if ( e.key === 'ArrowLeft') {
                goPrev(e);
            }

            if (e.keyCode === 27) {
                toggleOffModal(e);
            }
        }

        document.addEventListener("keydown", handleKeyPress);
        return () => document.removeEventListener("keydown", handleKeyPress);

    }, [modal3x3State]);

    return (
        <GridModalContext.Provider value={{
            modal3x3State,
            toggle3x3Modal
            }}>
            {children}
        </GridModalContext.Provider>
    );
}

export const ChartGridModalContextProvider = memo(ChartGridModalContextProviderInternal);