//manages the list of default projections and logic for checking and adding these to the projections lists if required:

import {DashboardQueryTypes} from "../../../../api/data/DashboardQueryTypes";
import _ from "lodash";

export const DEFAULT_UC_ALGORITHM = 'TWO_THREE_Y_FLAT';

// Mosaic based queries:
const ORACLE_INDEX_CC_YOY = 'oracle_index_cc_yoy';
const VELA_VELORUM_TOTAL_SALES = 'vela-velorum_total_sales';
const VELA_GAMMA_TOTAL_SALES = 'vela-gamma_total_sales';
const ORION_TOTAL_SALES = 'orion_total_sales';
const GTRENDS_US = 'gtrends_us';
const GTRENDS_WW = 'gtrends_ww';
const PLACER_SALES_INDEX = 'placer_sales_index';
const SM_OBSERVED_SALES = 'sm_observed_sales';
const oracleIndexCcYoyExSe = 'oracle_index_cc_yoy_ex_se';
const velaVelorumTotalSalesExSe = 'vela-velorum_total_sales_ex_se';
const velaGammaTotalSalesExSe = 'vela-gamma_total_sales_ex_se';
const orionTotalSalesExSe = 'orion_total_sales_ex_se';
const VISITS_US = 'visits_us';
const VISITS_WW = 'visits_ww';
const UNIQUE_VISITS_DESKTOP_US = 'unique_visits_desktop_us';
const UNIQUE_VISITS_DESKTOP_WW = 'unique_visits_desktop_ww';
const ORION_DEBIT_TOTAL_SALES = 'orion_debit_total_sales';

// Gross Profit based queries:
const oracleProfitProxyIndex = 'oracle_profit_proxy_index';
const velaVelorumProfitProxy = 'vela-velorum_profit_proxy';
const velaGammaProfitProxy = 'vela-gamma_profit_proxy';
const orionProfitProxy = 'orion_profit_proxy';
const placerProfitProxyIndex = 'placer_profit_proxy_index';
const smProfitProxy = 'sm_profit_proxy';
const oracleProfitProxyIndexExSe = 'oracle_profit_proxy_index_ex_se';
const velaVelorumProfitProxyExSe = 'vela-velorum_profit_proxy_ex_se';
const velaGammaProfitProxyExSe = 'vela-gamma_profit_proxy_ex_se';
const orionProfitProxyExSe = 'orion_profit_proxy_ex_se';
const ORION_DEBIT_PROFIT_PROXY = 'orion_debit_profit_proxy';

// Used for making the api calls:
const SeriesForMosaics = [
    ORACLE_INDEX_CC_YOY,
    VELA_VELORUM_TOTAL_SALES,
    VELA_GAMMA_TOTAL_SALES,
    ORION_TOTAL_SALES,
    GTRENDS_US,
    GTRENDS_WW,
    PLACER_SALES_INDEX,
    SM_OBSERVED_SALES,
    oracleIndexCcYoyExSe,
    velaVelorumTotalSalesExSe,
    velaGammaTotalSalesExSe,
    orionTotalSalesExSe,
    VISITS_US,
    VISITS_WW,
    UNIQUE_VISITS_DESKTOP_US,
    UNIQUE_VISITS_DESKTOP_WW,
    ORION_DEBIT_TOTAL_SALES
];


const SeriesForGPs = [
    oracleProfitProxyIndex,
    velaVelorumProfitProxy,
    velaGammaProfitProxy,
    orionProfitProxy,
    placerProfitProxyIndex,
    smProfitProxy,
    oracleProfitProxyIndexExSe,
    velaVelorumProfitProxyExSe,
    velaGammaProfitProxyExSe,
    orionProfitProxyExSe,
    ORION_DEBIT_PROFIT_PROXY
];

// Defines the grouping of charts / visual pairs:
export const DefaultSeriesPairs = [
    [ORACLE_INDEX_CC_YOY, oracleProfitProxyIndex],
    [VELA_VELORUM_TOTAL_SALES, velaVelorumProfitProxy],
    [VELA_GAMMA_TOTAL_SALES, velaGammaProfitProxy],
    [ORION_TOTAL_SALES, orionProfitProxy],
    [ORION_DEBIT_TOTAL_SALES, ORION_DEBIT_PROFIT_PROXY],
    [SM_OBSERVED_SALES, smProfitProxy],
    [GTRENDS_US, GTRENDS_WW],
    [PLACER_SALES_INDEX, placerProfitProxyIndex],
    [oracleIndexCcYoyExSe, oracleProfitProxyIndexExSe],
    [velaVelorumTotalSalesExSe, velaVelorumProfitProxyExSe],
    [velaGammaTotalSalesExSe, velaGammaProfitProxyExSe],
    [orionTotalSalesExSe, orionProfitProxyExSe],
    [VISITS_US, VISITS_WW],
    [UNIQUE_VISITS_DESKTOP_US, UNIQUE_VISITS_DESKTOP_WW]
];


const MOSAICS = [DashboardQueryTypes.QTR_YOY_LAG0, DashboardQueryTypes.QTR_YOY2_LAG0];

const GP_CHARTS = [DashboardQueryTypes.PROFIT_YOY_LAG0, DashboardQueryTypes.PROFIT_YOY2_LAG0];

let CACHED_DEFAULT_MAP = null;

function addSeriesToProjectionsMap(projectionsMap, seriesList, targetChartsList) {
    seriesList.forEach(series => {
        const item = [];
        targetChartsList.forEach(chart => item.push({queryType: chart, algorithm: DEFAULT_UC_ALGORITHM}));
        projectionsMap[series] = item;
    });
}

 const buildDefaultsMap = () => {
    const map = {};
    addSeriesToProjectionsMap(map, SeriesForMosaics, MOSAICS);
    addSeriesToProjectionsMap(map, SeriesForGPs, GP_CHARTS);
    CACHED_DEFAULT_MAP = map;

};

export const getProjectionsDefaults = () => {
    if(CACHED_DEFAULT_MAP == null) buildDefaultsMap();
    return CACHED_DEFAULT_MAP;
}

export const transformChartMapToProjectionsMap = (chartsProjectionMap) => {
    const transformedAvailableProjections = {};
    for(const chartName in chartsProjectionMap) {
        for(const seriesName in chartsProjectionMap[chartName]){
            if(!transformedAvailableProjections[seriesName]){
                transformedAvailableProjections[seriesName] = [{queryType: chartName, algorithm: DEFAULT_UC_ALGORITHM}];
            } else {
                transformedAvailableProjections[seriesName].push({queryType: chartName, algorithm: DEFAULT_UC_ALGORITHM});
            }
        }
    }
    return transformedAvailableProjections;
}

//Mutates newActiveProjections:
const upsertDefaultProjectionsIfAvailable = (availableProjectionsMap, newActiveProjections) => {
    if(CACHED_DEFAULT_MAP == null) buildDefaultsMap();

    Object.keys(CACHED_DEFAULT_MAP).forEach(seriesName => {
        // Check if in available projections:
        if(availableProjectionsMap[seriesName] != null && availableProjectionsMap[seriesName].length > 0){
            // check if not present in activeProjections:
            if(newActiveProjections[seriesName]  == null || newActiveProjections[seriesName].length === 0){
                newActiveProjections[seriesName] = [];
            }
            // Iterate over each default projection, match if available and add to active projections:
            CACHED_DEFAULT_MAP[seriesName].forEach((proj) => {
                const availableProjection = availableProjectionsMap[seriesName].find(p => p.queryType === proj.queryType && p.algorithm === proj.algorithm);
                const configuredProjection = newActiveProjections[seriesName].find(p => p.queryType === proj.queryType && p.algorithm === proj.algorithm);
                if(availableProjection != null && configuredProjection == null){
                    newActiveProjections[seriesName].push(proj);
                }
            })
        }
    });

}

export const generateNewActiveProjectionsBasedOnAvailableProjections = (availableProjectionsChartMap, oldActiveProjectionsMap) => {
    const transformedAvailableProjections = transformChartMapToProjectionsMap(availableProjectionsChartMap);

    const updatedActiveProjections = {};
    //Compare active projections and remove those which are not available:
    Object.keys(oldActiveProjectionsMap).forEach(seriesName => {
        if(transformedAvailableProjections[seriesName] != null){
            updatedActiveProjections[seriesName] = []; // Initialize to empty array.
            oldActiveProjectionsMap[seriesName].forEach((proj) => {
                //Check if projection exists in the available projections and add:
                if(_.some(transformedAvailableProjections[seriesName], availProj => availProj.queryType === proj.queryType && availProj.algorithm === proj.algorithm)){
                    updatedActiveProjections[seriesName].push(proj);
                }
            })
        }
    });
    upsertDefaultProjectionsIfAvailable(transformedAvailableProjections, updatedActiveProjections);
    return updatedActiveProjections;

}

export const updateProjectionsForQueryType = metadata => {
    const updatedProjections = {};
    //Todo: Remove this monstrosity:
    const predicate = (item) => true;
    metadata.forEach(metadataItem => {
        if(predicate(metadataItem)){
            updatedProjections[metadataItem.seriesName] = [{algorithm: DEFAULT_UC_ALGORITHM}];
        }
    });
    return updatedProjections;
}

export const projectionKeyGenerator = (seriesName, chartName, algo) => seriesName + '_' + chartName + '_' + algo;

export const convertActiveProjectionsToMapOfProjectionsWithKey = (activeProjections) => {
    const projectionMap = {};
    Object.keys(activeProjections).forEach(seriesName => {
        activeProjections[seriesName].forEach(proj => {
            projectionMap[projectionKeyGenerator(seriesName, proj.queryType, proj.algorithm)] = {
                seriesName,
                queryType: proj.queryType,
                algorithm: proj.algorithm,
            };
        })
    });
    return projectionMap;
}
