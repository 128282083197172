// Defines the chart bound to a particular chart, if it has any binding. A bound chart is defined as a pair of
// charts, where we want the changes to one chart reflect into the other:
import {DashboardQueryTypes} from "../../../../api/data/DashboardQueryTypes";

const BoundChartsMap = {
    [DashboardQueryTypes.WEEKLY_YOY]: DashboardQueryTypes.WEEKLY_YOY2,
    [DashboardQueryTypes.WEEKLY_YOY2]: DashboardQueryTypes.WEEKLY_YOY,
    [DashboardQueryTypes.QTR_YOY_LAG0]: DashboardQueryTypes.QTR_YOY2_LAG0,
    [DashboardQueryTypes.QTR_YOY2_LAG0]: DashboardQueryTypes.QTR_YOY_LAG0,
    [DashboardQueryTypes.PROFIT_YOY_LAG0]: DashboardQueryTypes.PROFIT_YOY2_LAG0,
    [DashboardQueryTypes.PROFIT_YOY2_LAG0]: DashboardQueryTypes.PROFIT_YOY_LAG0,

    [DashboardQueryTypes.WEEKLY_PROFIT_YOY_LAG0]: DashboardQueryTypes.WEEKLY_PROFIT_YOY2_LAG0,
    [DashboardQueryTypes.WEEKLY_PROFIT_YOY2_LAG0]: DashboardQueryTypes.WEEKLY_PROFIT_YOY_LAG0,
    [DashboardQueryTypes.WEEKLY_PRICING_YOY_LAG0]: DashboardQueryTypes.WEEKLY_PRICING_YOY2_LAG0,
    [DashboardQueryTypes.WEEKLY_PRICING_YOY2_LAG0]: DashboardQueryTypes.WEEKLY_PRICING_YOY_LAG0,
    [DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY_LAG0]: DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY2_LAG0,
    [DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY2_LAG0]: DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY_LAG0,
    [DashboardQueryTypes.QTR_TRANSACTIONS_YOY_LAG0]: DashboardQueryTypes.QTR_TRANSACTIONS_YOY2_LAG0,
    [DashboardQueryTypes.QTR_TRANSACTIONS_YOY2_LAG0]: DashboardQueryTypes.QTR_TRANSACTIONS_YOY_LAG0
}

export const isABoundPairChart = (chartName) => {
    return BoundChartsMap[chartName] != null;
}

// Assumed that will only be called after a check using isABoundPairChart:
export const getBoundChart = (chartName) => {
    return BoundChartsMap[chartName];
}