import {ContainsPeerIndices} from "../../../../api/data/DashboardQueryTypes";

// Manages the abort controllers used for DashboardReport. Encapsulates the logic for aborting, and creating new controllers, such that the fetch functions simply need to query with query type to get the current correct corresponding controller to be attached to the query
export class DRReportAbortControllers{

    commonAbortController;
    peerIndicesDependentAbortController;

    //Abort controllers for axis and nonAxisDependent with peerIndices
    linkedController;

    constructor() {
        this.commonAbortController = new AbortController();
        this.peerIndicesDependentAbortController = new AbortController();
        this.createAndSetLinkedController();
    }

    reset(){
        this.commonAbortController = new AbortController();
        this.peerIndicesDependentAbortController = new AbortController();
        this.createAndSetLinkedController();
    }

    // Creates a new AbortController from given array of controllers. The new linked controller gets aborted whenever any of the input controllers are aborted.
    linkAbortControllers(controllers){
        const linked = new AbortController();

        controllers.forEach(controller =>
            controller.signal.addEventListener("abort", () => {
                linked.abort();
            })
        );

        return linked;
    }

    createAndSetLinkedController(){
        this.linkedController = this.linkAbortControllers([this.commonAbortController, this.peerIndicesDependentAbortController]);
    }

    abortPeerIndicesCalls(){
        this.peerIndicesDependentAbortController.abort();
        this.peerIndicesDependentAbortController = new AbortController();
        this.createAndSetLinkedController();
    }

    abortThemAllNoReset(){
        this.commonAbortController.abort();
    }

    abortThemAll(){
        this.abortThemAllNoReset()
        this.reset();
    }

    getControllerToUseForQuery(queryType) {
        const isPeerIndicesRelevant = ContainsPeerIndices.includes(queryType);

        if(isPeerIndicesRelevant){
            return this.linkedController;
        }

        return this.commonAbortController;
    }

}