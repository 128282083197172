import { useEffect, useState } from "react";
import LoadingOverlay from 'react-loading-overlay';
import { CAlert } from "@coreui/react";
import {getMacroResults} from "../../../api/data/DataProvider";
import StudioStackChart from "../../studio_high_chart/StudioStackChart";
import { generateColorGradient } from "../../studio_high_chart/utilityFunctions";


function LinkedinCharts(props) {
    const [chartData, setChartData] = useState(null)
    const [loading, setLoading] = useState(chartData == null)
    const [statusMsg, setStatusMsg] = useState('Loading your content...')
    const [isError, setIsError] = useState(false)

    const setPref = (data) => {
        const colorGradient = generateColorGradient("#333399", "#d30000", data.series.length);
        data.series.forEach((series, index) => {
            series.name = series.name.toUpperCase();
            series.color = colorGradient[index]; // Assigning color from the gradient to each series
        });
        data.series[0].visible = true;

        const seriesData = data.series.map(series => {
            const [ticker, yoyGrowth] = series.name.split(' ');
            return {
                ticker,
                yoyGrowth: parseFloat(yoyGrowth),
                ...series
            };
        });

        seriesData.sort((a, b) => b.yoyGrowth - a.yoyGrowth);

        const top10 = seriesData.slice(0, 10).map(series => series.name);
        const bottom10 = seriesData.slice(-10).map(series => series.name);

        data.filters = [
            {
                filterId: 'top10',
                filterText: 'Top 10',
                seriesNames: top10,
                selected: false
            },
            {
                filterId: 'bottom10',
                filterText: 'Bottom 10',
                seriesNames: bottom10,
                selected: false
            }
        ];

        return data;
    }

    useEffect(() => {
        const abortController = new AbortController();
        setIsError(false);
        setLoading(true)
        getMacroResults(props.queryType, abortController)
                .then((res) => {
                    let data = setPref(res.data);
                    setChartData((prev) => {
                        return {
                            ...data,
                            title: {
                                text: res.data.title.text + " : (by Quarter Revenue YoY high to low)"
                            },
                            chart: {
                                ...res.data.chart,
                                zooming: {
                                    mouseWheel: {
                                        enabled: false
                                    }
                                },
                                zoomType: 'x',
                                height: 350,
                                plotBackgroundColor: '#E5ECF6',
                                panning: {
                                    enabled: true,
                                    type: 'x'
                                },
                                panKey: 'shift'
                            },
                            legend: {
                                ...res.data.legend,
                                itemStyle: {
                                    color: "#000000"
                                },
                                itemHiddenStyle: {
                                    textDecoration: 'none'  // Disable strikethrough effect
                                }
                            }
                        }
                    });
                    setLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    if (err.code === "ERR_CANCELED") {
                        // do nothing for now
                    } else {
                        setStatusMsg("Failed to load data for the chart. Looks like this data is not available.")
                        setIsError(true);
                    }
                })

        return () => abortController.abort();
    }, []);
    

    return (
        <>
            <a id={props.queryType} href={""}> </a>
            <LoadingOverlay
                active={loading && !isError}
                spinner={!isError}
                text={"Loading..."}
            >
                {isError && <div>
                    <CAlert color="danger">
                        Failed to load - {props.queryType}.
                        Data Not Configured
                    </CAlert>
                </div>}
                {chartData == null && !isError && <p style={{ height: "400px", width: "90%" }}> ...</p>}
                {chartData != null && !isError && chartData.series.length === 0 && <div>
                    <CAlert color="warning">
                        Data Not Available - {props.queryType}
                    </CAlert>
                </div>}
                {chartData != null && chartData != undefined && !isError && chartData.series.length !== 0 && <center>
                    <div style={{ marginTop: 10, paddingLeft: 10 }}>
                        <StudioStackChart
                            chartHeightOverride={'800px'}
                            chartData={chartData}
                            isMiniView={false}
                            />
                    </div>
                </center>}
            </LoadingOverlay>

        </>
    )
}



export default LinkedinCharts;