import {Button} from "antd";
import {forwardRef, useContext, useState} from "react";
import {DashboardApiContextStore} from "../oracle/DashboardReport/DashboardReportContextProvider";
import {ViewContextStore} from "../oracle/DashboardReport/views/ViewContext";

const MultiChartSwitcher =  forwardRef((props, ref) =>  {
    const [queryType, setQueryType] = useState(null != props.queryTypes ? props.queryTypes[0] : null);
    const ChartType = props.ChartType;

    return (
        <div ref={props.dataState[queryType].loading ? null : ref}>
            <div>
                {props.queryTypes.map((qt) => {
                    return <Button key={qt} type={queryType === qt ? "primary" : undefined} style={{ margin: 5 }} onClick={() => setQueryType(qt)}>
                        {qt.replaceAll('_', ' ')}
                    </Button>
                })}
            </div>
            <ChartType key={queryType} queryType={queryType} chartData={props.dataState[queryType].chartData} isError={props.dataState[queryType].isError} loading={props.dataState[queryType].loading} chartToggleConfig={props.chartConfigs[queryType]} toggleOptionsConfig={props.toggleOptionsConfig} {...props} />
        </div>
    )
})

export const APIContextConnectedMutiChartSwitcher = props => {
    const comprehensiveApiData = useContext(DashboardApiContextStore)?.dataState;
    let dataObject = {};
    props.queryTypes.forEach((query) => {dataObject = {...dataObject, [query]: comprehensiveApiData[query]};})
    const completeViewContextData = useContext(ViewContextStore);
    let configData = {};
    props.queryTypes.forEach( query => configData = {...configData, [query]: {
        viewData: completeViewContextData?.viewState[query],
        modifyActiveSeries: (seriesName) => completeViewContextData?.modifyActiveSeries(query, seriesName),
            reversePopulateChartConfig: (seriesConfig, metadata) => completeViewContextData?.reversePopulateChartConfig(query, seriesConfig, metadata)
    }});
    const toggleOptionsConfig = {
        isDailyStackAlignment: completeViewContextData.isDailyStackAlignment,
        setDailyStackAlignment: completeViewContextData.setDailyStackAlignment,
        showWeeklySeries: completeViewContextData.showWeeklySeries,
        setShowWeeklySeries: completeViewContextData.setShowWeeklySeries,
    }
    return <MultiChartSwitcher {...props} dataState={dataObject} chartConfigs={configData} toggleOptionsConfig={toggleOptionsConfig}/>
}


export default MultiChartSwitcher;