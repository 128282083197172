import React, {useContext, useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import { formatCurrency } from '../../utils/FinDataUtils';
import PropTypes from 'prop-types';
import StudioCandleChart from "../studio_high_chart/StudioCandleChart";
import FinMatrixGrid from "../findata/FinMatrixGrid";
import {Layout, Menu, Space, Tooltip, Modal, Spin, Typography} from "antd";
import {Header} from "antd/es/layout/layout";
import {Link} from "react-router-dom";
import {TickerDropdown} from "../oracle/components/TickerDropdown";
import TradingMultiplesCharts from "../line_chart/TradingMultiples";
import {ViewContextProvider} from "../oracle/DashboardReport/views/ViewContext";
import {computationService} from "../../api/Clients";
import {getTickers} from "../../api/data/DataProvider";
import {TICKER_NAME} from "../oracle/DashboardReport/DashboardConstants";
import {SeriesTypes} from "../../api/data/SeriesTypes";
import ErrorBoundary from '../tradingview/ExternalErrorBoundary';
import {StudioHistoricalPriceChart} from "../studio_high_chart/StudioHistoricalPriceChart";
import {StudioIntradayPriceChart} from "../studio_high_chart/StudioIntradayPriceChart";
import {VPTGrid} from "../vpt/VPTGrid";
import {VPTGridProvider} from "../vpt/VPTGridProvider";
import {FinDataGrid} from "../findata/FinDataGrid";
import {VPT_TABLE_MODES} from "../vpt/VptConfig";
import {HistoricalPriceDataProvider} from "../studio_high_chart/contextsForCharts/HistoricalPriceDataProvider";
import {IntradayStockPriceDataProvider} from "../studio_high_chart/contextsForCharts/IntradayStockPriceWorker";
import {FinancialMatricesDataProvider} from "../oracle/findata/FinancialMatricesDataProvider";
import {TradingMultiplesDataProvider} from "../oracle/findata/TradingMultiplesDataProvider";
import {RefreshContext, RefreshContextProvider} from "../oracle/commonContexts/RefreshContextProvider";
import {MdRefresh, MdBlurCircular} from "react-icons/md";
import { CgDebug } from "react-icons/cg";
import {AiOutlineFundView} from "react-icons/ai";
import DataTrace from './DataTrace';
import { InfoCircleOutlined } from '@ant-design/icons';

import {
    DASHBOARDS_PATH,
    FACTORS_PATH,
    FINDATA_PATH,
    INTRADAY_PATH,
    LINKEDIN_PATH,
    MACRO_PATH,
    NAV_PATH,
    POSITIONS_PATH
} from "../oracle/PathConstants";

const { Text } = Typography;

const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
        element.scrollIntoView({behavior: 'instant', block: 'center'});
    }
}

export const fetchVptData = async (tickerId) => {
    const response = await computationService.get(`/v1/multiples/ticker/vpt?tickerId=${tickerId}`);
    return response.data;
}

export const fetchCompanyInfo = async (tickerId) => {
    const response = await computationService.get(`/v1/multiples/ticker/company_info?tickerId=${tickerId}`);
    return response.data;
}

function getSourceInfo(source) {
    switch (source.toLowerCase()) {
        case 'va':
            return { label: 'VisibleAlpha', color: 'blue' };
        case 'bbg':
            return { label: 'Bloomberg', color: 'green' };
        default:
            return { label: source, color: 'orange' };
    }
}

function CompanyInfoDisplay({ companyInfo, activeTicker }) {
    if (!companyInfo) return null;
    const headerFontSize = 20;

    const nonCommonSecurityInfo = companyInfo.security_info?.filter(info => info.security_description)
    const securityInfo = nonCommonSecurityInfo.map(info => 
        `${info.security_description} (${info.shares_outstanding}m)`
    ).join(', ');

    const { label: sourceLabel, color: sourceColor } = getSourceInfo(companyInfo.source);

    const exchangeMapping = {
        'UN': 'NYSE',
        'UQ': 'NASDAQ',
        'LN': 'London Stock Exchange',
        'HK': 'Hong Kong Stock Exchange',
        'JT': 'Tokyo Stock Exchange',
        'CN': 'Toronto Stock Exchange',
        'AT': 'Australian Stock Exchange',
        'FP': 'Euronext Paris',
        'GR': 'Frankfurt Stock Exchange (Xetra)',
        'CH': 'Shanghai Stock Exchange'
    };

    const fullExchangeName = exchangeMapping[companyInfo.exchange] || companyInfo.exchange;

    // Add this function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
    };

    return (
        <Space style={{fontSize: headerFontSize}}>
            <Text strong style={{fontSize: headerFontSize}} title={activeTicker.id}>{`${companyInfo.name} (${activeTicker.name})`}</Text>
            <Text style={{fontSize: headerFontSize}}>|</Text>
            <Text style={{fontSize: headerFontSize}}>{fullExchangeName}</Text>
            <Text style={{fontSize: headerFontSize}}>|</Text>
            { nonCommonSecurityInfo?.length > 0 && 
            <Text style={{fontSize: headerFontSize}}>{securityInfo}</Text> }
            {nonCommonSecurityInfo?.length > 0 && <Text style={{fontSize: headerFontSize}}>|</Text> }
            <Text style={{ color: sourceColor, fontSize: headerFontSize }}>{sourceLabel}</Text>
            {companyInfo.first_available_date && (
                <>
                    <Text style={{fontSize: headerFontSize}}>|</Text>
                    <Text style={{fontSize: headerFontSize - 4}}>{formatDate(companyInfo.first_available_date)}</Text>
                    
                </>
            )}
             {companyInfo.currency && companyInfo.eqy_fund_crncy && companyInfo.currency !== companyInfo.eqy_fund_crncy && (
                <>
                    <Text>|</Text>
                    <Text style={{fontSize: headerFontSize - 4}}>
                        Trading: {formatCurrency(companyInfo.currency)}, Reporting: {formatCurrency(companyInfo.eqy_fund_crncy)}
                    </Text>
                </>
            )}
        </Space>
    );
}

function FinDataHeader(props) {
    const [isDebugModalVisible, setIsDebugModalVisible] = useState(false);
    const {toggleRefresh} = useContext(RefreshContext);
    
    return <Header style={{
        height: 45,
        position: "fixed",
        padding: "0 10px",
        width: "100%",
        zIndex: 1000,
        marginBottom: 200
    }}>
        <Menu selectable={false} mode={"horizontal"} theme={"dark"}
              style={{
                  height: 45,
                  alignItems: "center",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center"
              }}>
            <Menu.Item key="1"
                       onClick={() => scrollToElement("findataGrid")}>Grid<sub>a</sub></Menu.Item>
            <Menu.Item key="2"
                       onClick={() => scrollToElement("vpt")}>VPT<sub>s</sub></Menu.Item>
            <Menu.Item key="3"
                       onClick={() => scrollToElement("multiplesCharts")}>Multiples <sub>d</sub></Menu.Item>
            <Menu.Item key="4"
                       onClick={() => scrollToElement("surprisesCharts")}>Surprises <sub>f</sub></Menu.Item>
            <Menu.Item key="5"
                       onClick={() => scrollToElement("financialMatrix")}>Matrices <sub>g</sub></Menu.Item>
            <Menu.Item key="6"
                       onClick={() => scrollToElement("historicalPriceChart")}>Historical <sub>h</sub></Menu.Item>
            <Menu.Item key="7"
                       onClick={() => scrollToElement("intradayPriceChart")}>Intraday <sub>j</sub></Menu.Item>
            <Menu.Item disabled>
                <div style={{display: "flex"}}>
                    {props.tickerOptions && props.tickerOptions.length > 0 ?
                        <TickerDropdown activeTick={props.activeTicker || {name: "", id: null}}
                                        setActiveTick={props.setActiveTick}
                                        tickerOptions={props.tickerOptions}
                                        setActiveBrand={props.activeBrand}
                                        setShowMacroView={props.activeBrand}
                        /> : "Loading.."}
                </div>
            </Menu.Item>
            <Menu.Item
                style={{background: "transparent"}}
                onClick={() => {
                    setIsDebugModalVisible(true);
                }}
            >
                <Space direction="horizontal" style={{color: "white"}}>
                    <Tooltip title="Click to open debug trace">
                        <CgDebug style={{color: "white", fontSize: 25}} />
                    </Tooltip>
                </Space>
            </Menu.Item>
            <Menu.Item
                style={{background: "transparent"}}
                onClick={toggleRefresh}
            >
                <Space direction="horizontal" style={{color: "white"}}>
                    <Tooltip title="Click to refresh all data.">
                        <MdRefresh style={{color: "white", fontSize: 25}}

                        />
                    </Tooltip>
                </Space>
            </Menu.Item>
            <Menu.SubMenu
                        key={'NAVIGATION_SUBMENU'}
                        title={
                            <Link to={NAV_PATH} target={"_blank"} style={{textDecoration: "none"}}><AiOutlineFundView
                                style={{fontSize: 25, color: "#ffffff"}}/></Link>
                        }
                    >
                        <Menu.Item>
                            <Link to={NAV_PATH} target={"_blank"} style={{textDecoration: "none"}}>NAV
                                Screen</Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={POSITIONS_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>Positions</Link>
                        </Menu.Item>

                        <Menu.Item>
                            <Link to={DASHBOARDS_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>Dashboard</Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={FACTORS_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>Factors</Link>
                        </Menu.Item>
                        
                        <Menu.Item>
                            <Link to={INTRADAY_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>Intraday</Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={MACRO_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>Macro</Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={LINKEDIN_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>LinkedIn</Link>
                        </Menu.Item>
                    </Menu.SubMenu>
            <Menu.Item
                style={{background: 'transparent'}}
                disabled={!props.activeTick}
            >
                <Space direction="horizontal" style={{color: 'white'}}>
                    <Tooltip title="Go to oracle for ticker">
                        <Link 
                            to={props.activeTicker ? `/?tickerName=${props.activeTicker.name}` : '#'}
                            target="_blank"
                            style={{textDecoration: "none", pointerEvents: props.activeTicker ? 'auto' : 'none'}}
                        >
                            <MdBlurCircular style={{fontSize: 25, color: props.activeTicker ? 'white' : 'gray'}} />
                        </Link>
                    </Tooltip>
                </Space>
            </Menu.Item>
        </Menu>

        { isDebugModalVisible && <Modal
            title="Data Trace"
            open={isDebugModalVisible}
            onCancel={() => setIsDebugModalVisible(false)}
            width={1600}
            footer={null}
        >
            <DataTrace tickerId={props.activeTicker?.id} />
        </Modal> }

    </Header>;
}

const FinDataPage = ({ initialTicker, showFinDataGrid = true }) => {
    const [activeTicker, setActiveTicker] = useState(null);
    let [tickerOptions, setTickerOptions] = useState([]);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [companyInfoLoading, setCompanyInfoLoading] = useState(false);
    const [companyInfoError, setCompanyInfoError] = useState(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const tickerNameFromUrl = searchParams.get(TICKER_NAME);

        if (initialTicker) {
            // Case 1: Use initialTicker if provided
            setActiveTicker(initialTicker);
        } else if (tickerNameFromUrl && tickerOptions.length > 0) {
            // Case 2: Use ticker from URL if available
            const tickerOption = tickerOptions.find(t => t.label === tickerNameFromUrl);
            if (tickerOption) {
                setActiveTicker({name: tickerNameFromUrl, id: tickerOption.value});
            }
        } else {
            // Case 3: Set to null if neither initialTicker nor URL ticker is available
            setActiveTicker(null);
        }
    }, [initialTicker, tickerOptions, location.search]);

    useEffect(() => {
        if (activeTicker && !initialTicker) {
            // Update URL when activeTicker changes and initialTicker is null
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set(TICKER_NAME, activeTicker.name);
            const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
            window.history.replaceState(null, '', newUrl);
        }
    }, [activeTicker, initialTicker]);

    useEffect(() => {
        getTickers().then((res) => {
            let activeTickers = res.data.map(tickResponse => {
                return {
                    value: tickResponse.tickerId,
                    label: tickResponse.tick
                }
            });
            setTickerOptions(activeTickers)
        }).catch((err) => {
            console.error("Error fetching Tickers", err)
        });
    }, []);

 

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (window.isEnableHotKeys && !event.ctrlKey) {
                if (event.key === 'a') {
                    scrollToElement("findataGrid");
                } else if (event.key === 's') {
                    scrollToElement("vpt");
                } else if (event.key === 'd') {
                    scrollToElement("multiplesCharts");
                } else if (event.key === 'f') {
                    scrollToElement("surprisesCharts");
                } else if (event.key === 'g') {
                    scrollToElement("financialMatrix");
                } else if (event.key === 'h') {
                    scrollToElement("historicalPriceChart");
                } else if (event.key === 'j') {
                    scrollToElement("intradayPriceChart");
                }
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    useEffect(() => {
        if (activeTicker) {
            setCompanyInfo(null);
            setCompanyInfoLoading(true);
            setCompanyInfoError(null);

            fetchCompanyInfo(activeTicker.id)
                .then((data) => {
                    setCompanyInfo(data);
                    setCompanyInfoLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching company info:", error);
                    setCompanyInfoError("Error loading company info");
                    setCompanyInfoLoading(false);
                });
        }
    }, [activeTicker]);

    return (
        <div>
            <RefreshContextProvider>
                <HistoricalPriceDataProvider activeTicker={activeTicker}>
                    <IntradayStockPriceDataProvider activeTicker={activeTicker}>
                        <Layout style={{height: 45, marginBottom: 20}}>
                            <FinDataHeader
                                           tickerOptions={tickerOptions}
                                           activeTicker={activeTicker}
                                           setActiveTick={(ticker) => {
                                               setActiveTicker(ticker);
                                           }} activeBrand={() => {
                            }} />
                        </Layout>
                        {activeTicker && (
                            <div>
                                <Space align="center" style={{minHeight: 40, height: 40, marginBottom: 25}}>
                                    {companyInfoLoading && <Spin size="small" />}
                                    {companyInfoError && (
                                        <Text type="danger" style={{ fontSize: '0.8em' }}>
                                            {companyInfoError}
                                        </Text>
                                    )}
                                    {companyInfo && <CompanyInfoDisplay companyInfo={companyInfo} activeTicker={activeTicker}/>}
                                </Space>
                                <ViewContextProvider tick={activeTicker.name}>
                                    <ErrorBoundary>
                                        <VPTGridProvider activeTick={activeTicker}>
                                            {showFinDataGrid && (
                                                <div id={'findataGrid'}>
                                                    <FinDataGrid/>
                                                </div>
                                            )}
                                            <div id={'vpt'}>
                                                <VPTGrid onlyGridView={false} gridWidth={'100%'}
                                                         defaultMode={VPT_TABLE_MODES.FULL}/>
                                            </div>
                                        </VPTGridProvider>
                                    </ErrorBoundary>
                                    <ErrorBoundary>
                                        <div id="financialMatrix" style={{marginTop: 40, marginBottom: 50}}>
                                            <h3>Financial Matrices</h3>
                                            <FinancialMatricesDataProvider activeTicker={activeTicker}>
                                                <FinMatrixGrid/>
                                            </FinancialMatricesDataProvider>
                                        </div>
                                    </ErrorBoundary>
                                    <ErrorBoundary>
                                        <div id="multiplesCharts">
                                            <TradingMultiplesDataProvider activeTicker={activeTicker}>
                                                <TradingMultiplesCharts
                                                    activeTicker={activeTicker.id}
                                                />
                                            </TradingMultiplesDataProvider>
                                        </div>
                                    </ErrorBoundary>
                                    <ErrorBoundary>
                                        <div id="surprisesCharts">
                                            {
                                                [SeriesTypes.P_EPS, SeriesTypes.EV_EBITDA_xLEASE, SeriesTypes.EV_SALES_xLEASE].map((seriesType) => {
                                                    return (
                                                        <div id={seriesType + "_surpriseChart"} style={{marginTop: 40}}
                                                             key={seriesType}>
                                                            <StudioCandleChart activeTicker={activeTicker.id}
                                                                               ratio={seriesType}/>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </ErrorBoundary>
                                </ViewContextProvider>
                                <div id={'historicalPriceChart'}>
                                    <StudioHistoricalPriceChart/>
                                </div>
                                <div id={'intradayPriceChart'}>
                                    <StudioIntradayPriceChart/>
                                </div>
                            </div>
                        )}
                    </IntradayStockPriceDataProvider>
                </HistoricalPriceDataProvider>
            </RefreshContextProvider>
        </div>
    );
}

FinDataPage.propTypes = {
    initialTicker: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
    }),
    showFinDataGrid: PropTypes.bool,
};

export default FinDataPage;