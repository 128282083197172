import {Button, List, Modal} from "antd";
import {useState} from "react";
import {dollarCellRendererFunction} from "../../../../utils/StudioAgGridUtils";
import dayjs from "dayjs";

export const columnDefs = [
    {
        headerName: 'Ticker',
        field: 'tickerName',
        cellStyle: { "vertical-align": "middle" },
        filter: true,
        width: 150,
        sortable: true,
    },
    {
        headerName: 'Wash End Date',
        field: 'washsaleEndDate',
        valueFormatter: (params) => {return dayjs(params.value).format("MMM DD, YYYY")},
        width: 150,
        filter: true,
        sortable: true,
        sort: 'asc'
    },
    {
        headerName: 'Wash Loss',
        field: 'washsaleLoss',
        width: 200,
        cellStyle: { textAlign: 'right' },
        cellRenderer: dollarCellRendererFunction(),
        filter: true,
        sortable: true
    },
    {
        headerName: 'Wash Trades',
        field: 'washsaleTrades',
        width: 200,
        autoHeight: true,
        cellRenderer: ({ value }) => {
            return <WashTradesModal trades={value}/>
        }
    }
];

const shortDateFormat = "MMM DD";
const WashTradesModal = (props) => {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Button type={"primary"} onClick={() => {setVisible(true)}} size='small'>
                View Trades
            </Button>
        <Modal
            title="Wash Trades"
            visible={visible}
            onCancel={() => {setVisible(false)}}
            footer={null} // Set to null to remove the default footer
        >
            <List
                dataSource={props.trades.sort((a, b) => dayjs(a.tradeDate) - dayjs(b.tradeDate))}
                renderItem={(item) => {
                    let assetType = item.assetType === 'STK' ? 'Stock' : 'Option';
                    let loss = new Intl.NumberFormat('en-US', { style: 'currency',maximumFractionDigits: 0, currency: 'USD' }).format(item.loss);
                    let description = `${loss} (${assetType}: ${item.symbol})`;
                    return (
                    <List.Item>
                        <List.Item.Meta title={dayjs(item.tradeDate).format(shortDateFormat)} description={description} />
                    </List.Item>
                )}
            }
            />
        </Modal>
            </>
    );
};
