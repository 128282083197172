import {useEffect, useState} from "react";
import {getClickoutUrl} from "../../../api/data/DataProvider";

export default function useClickoutProviderHook(activeTickerId, activeBrandId, clickOutType, refresh) {
    const [clickOutUrl, setClickOutUrl] = useState("");
    const [loading, setLoading] = useState(false);

    const enabledLinkStyle = {marginLeft: 50};
    const disabledLinkStyle = {marginLeft: 50, pointerEvents: "none", opacity: 0.6, color: "black"};

    const make3Y = (gtUrl, geo) => {
        let gt3YstackUrl = "https://trends.google.com/trends/explore?date=2024-01-01%202024-12-31,2023-01-01%202023-12-31,2022-01-01%202022-12-31";
        let queryStartIndex = gtUrl.indexOf("q=") + 2;
        // &geo=%s,,,,&q=%s"
        gt3YstackUrl = gt3YstackUrl + "&geo=" + geo;
        gt3YstackUrl = gt3YstackUrl + "&q=" + gtUrl.substring(queryStartIndex);

        return gt3YstackUrl;
    }

    const make4Y = (gtUrl, geo) => {
        let gt4YstackUrl = "https://trends.google.com/trends/explore?date=2024-01-01%202024-12-31,2023-01-01%202023-12-31,2022-01-01%202022-12-31,2021-01-01%202021-12-31";
        let queryStartIndex = gtUrl.indexOf("q=") + 2;
        // &geo=%s,,,,&q=%s"
        gt4YstackUrl = gt4YstackUrl + "&geo=" + geo;
        gt4YstackUrl = gt4YstackUrl + "&q=" + gtUrl.substring(queryStartIndex);

        return gt4YstackUrl;
    }

    const make5Y = (gtUrl, geo) => {
        let gt4YstackUrl = "https://trends.google.com/trends/explore?date=2024-01-01%202024-12-31,2023-01-01%202023-12-31,2022-01-01%202022-12-31,2021-01-01%202021-12-31";
        let queryStartIndex = gtUrl.indexOf("q=") + 2;
        // &geo=%s,,,,&q=%s"
        gt4YstackUrl = gt4YstackUrl + "&geo=" + geo;
        gt4YstackUrl = gt4YstackUrl + "&q=" + gtUrl.substring(queryStartIndex);

        return gt4YstackUrl;
    }

    useEffect(() => {
        setLoading(true);
        getClickoutUrl(activeTickerId, activeBrandId, clickOutType)
            .then((res) => {
                setClickOutUrl((prev) => {
                    return res.data;
                });
                setLoading(false);
            }).catch((err) => {
            console.error("Failed to fetch clickout url", err);
            setLoading(false);
        })
    }, [activeTickerId, activeBrandId, refresh]);

    return {
        clickOutUrl,
        loading,
        enabledLinkStyle,
        disabledLinkStyle,
        make3Y,
        make4Y,
        make5Y
    };

}