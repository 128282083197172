import {Button, Checkbox, message, Modal} from "antd";
import {
    addToAWL,
    addToLongWL, addToRWL,
    addToShortWL, addToWDDWL,
    getActiveFlag, removeFromAWL,
    removeFromLongWL, removeFromRWL,
    removeFromShortWL, removeFromWDDWL, saveNotes
} from "../../api/data/DataProvider";
import {useEffect, useState} from "react";
import TextArea from "antd/es/input/TextArea";
import {monitor} from "../../event_handler/KeyPressMonitor";

function WaitListMarker (props) {
    const [messageApi, contextHolder] = message.useMessage();

    const [longWlValue, setLongWlValue] = useState(false);
    const [shortWlValue, setShortWlValue] = useState(false);
    const [addWlValue, setAddWlValue] = useState(false);
    const [rWlValue, setRWlValue] = useState(false);
    const [wddWlValue, setWddWlValue] = useState(false);

    const [showNotePad, setShowNotePad] = useState(false);
    const [savingNote, setSavingNote] = useState(false);
    const [note, setNote] = useState(null);

    const _onLWL = (value) => {
        console.log("Clicked")
        if (value.target.checked) {
            addToLongWL(props.activeTick.id).then((res) => {
                messageApi.open({
                    type: 'success',
                    content: 'Added to Long Waitlist',
                });
                setLongWlValue(true);
                setShowNotePad(true);
                monitor.enableHotKeys(false);
            }).catch((err) => {
                messageApi.open({
                    type: 'warning',
                    content: 'Failed to add to Long Waitlist',
                });
            })
        } else {
            removeFromLongWL(props.activeTick.id).then((res) => {
                messageApi.open({
                    type: 'success',
                    content: 'Removed from Long Waitlist',
                });
                setLongWlValue(false);
            }).catch((err) => {
                messageApi.open({
                    type: 'warning',
                    content: 'Failed to remove from Long Waitlist',
                });
            })
        }
    }
    const _onSWL = (value) => {
        if (value.target.checked) {
            addToShortWL(props.activeTick.id).then((res) => {
                messageApi.open({
                    type: 'success',
                    content: 'Added to Short Waitlist',
                });
            }).catch((err) => {
                messageApi.open({
                    type: 'warning',
                    content: 'Failed to add to Short Waitlist',
                });
            })
            setShortWlValue(true);
            setShowNotePad(true);
            monitor.enableHotKeys(false);
        } else {
            removeFromShortWL(props.activeTick.id).then((res) => {
                messageApi.open({
                    type: 'success',
                    content: 'Removed from Short Waitlist',
                });
            }).catch((err) => {
                messageApi.open({
                    type: 'warning',
                    content: 'Failed to remove from Short Waitlist',
                });
            })
            setShortWlValue(false);
        }
    }

    const _onAWL = (value) => {
        console.log("Clicked")
        if (value.target.checked) {
            addToAWL(props.activeTick.id).then((res) => {
                messageApi.open({
                    type: 'success',
                    content: 'Added to Add More Waitlist',
                });
                setAddWlValue(true);
                setShowNotePad(true);
                monitor.enableHotKeys(false);
            }).catch((err) => {
                messageApi.open({
                    type: 'warning',
                    content: 'Failed to add to Add More Waitlist',
                });
            })
        } else {
            removeFromAWL(props.activeTick.id).then((res) => {
                messageApi.open({
                    type: 'success',
                    content: 'Removed from Add More Waitlist',
                });
                setAddWlValue(false);
            }).catch((err) => {
                messageApi.open({
                    type: 'warning',
                    content: 'Failed to remove from Add More Waitlist',
                });
            })
        }
    }

    const _onRWL = (value) => {
        console.log("Clicked")
        if (value.target.checked) {
            addToRWL(props.activeTick.id).then((res) => {
                messageApi.open({
                    type: 'success',
                    content: 'Added to Reduce Waitlist',
                });
                setRWlValue(true);
                setShowNotePad(true);
                monitor.enableHotKeys(false);
            }).catch((err) => {
                messageApi.open({
                    type: 'warning',
                    content: 'Failed to add to Reduce Waitlist',
                });
            })
        } else {
            removeFromRWL(props.activeTick.id).then((res) => {
                messageApi.open({
                    type: 'success',
                    content: 'Removed from Reduce Waitlist',
                });
                setRWlValue(false);
            }).catch((err) => {
                messageApi.open({
                    type: 'warning',
                    content: 'Failed to remove from Reduce Waitlist',
                });
            })
        }
    }

    const _onWDDWL = (value) => {
        console.log("Clicked")
        if (value.target.checked) {
            addToWDDWL(props.activeTick.id).then((res) => {
                messageApi.open({
                    type: 'success',
                    content: 'Added to WDD Waitlist',
                });
                setWddWlValue(true);
                setShowNotePad(true);
                monitor.enableHotKeys(false);
            }).catch((err) => {
                messageApi.open({
                    type: 'warning',
                    content: 'Failed to add to WDD Waitlist',
                });
            })
        } else {
            removeFromWDDWL(props.activeTick.id).then((res) => {
                messageApi.open({
                    type: 'success',
                    content: 'Removed from WDD Waitlist',
                });
                setWddWlValue(false);
            }).catch((err) => {
                messageApi.open({
                    type: 'warning',
                    content: 'Failed to remove from Long Waitlist',
                });
            })
        }
    }

    // Check if tick already part of a waitlist
    useEffect(() => {
        setLongWlValue(false);
        setShortWlValue(false);
        setRWlValue(false);
        setWddWlValue(false);
        setAddWlValue(false);
        getActiveFlag(props.activeTick.id)
            .then((res) => {
                let activeFlag = res.data;
                if (activeFlag === "LONG_WL") {
                    setLongWlValue(true);
                } else if (activeFlag === "SHORT_WL") {
                    setShortWlValue(true);
                } else if (activeFlag === "ADD_WL") {
                    setAddWlValue(true);
                } else if (activeFlag === "REDUCE_WL") {
                    setRWlValue(true);
                } else if (activeFlag === "WDD_WL") {
                    setWddWlValue(true);
                }
            })
            .catch((err) => {
                console.error("Failed to fetch active flag list", err);
            })
    }, [props.activeTick]);

    const saveNote = () => {
        setSavingNote(true);
        saveNotes(props.activeTick.id, note).then((res) => {
            messageApi.open({
                type: 'success',
                content: 'Saved Note Sucessfully!',
            });
            setSavingNote(false);
            setShowNotePad(false);
            monitor.enableHotKeys(true);
        }).catch((err) => {
            console.error("Failed to save note: ", err);
            setSavingNote(false);
            setShowNotePad(false);
            monitor.enableHotKeys(true);
        })
    }

    return (
        <>
            {contextHolder}
            <Checkbox checked={longWlValue} onChange={_onLWL}>Long WL</Checkbox>
            <Checkbox checked={shortWlValue} onChange={_onSWL}>Short WL</Checkbox>
            <Checkbox checked={addWlValue} onChange={_onAWL}>Add WL</Checkbox>
            <Checkbox checked={rWlValue} onChange={_onRWL}>Reduce WL</Checkbox>
            <Checkbox checked={wddWlValue} onChange={_onWDDWL}>WDD WL</Checkbox>

            <Modal title="Take Note" open={showNotePad}
                   footer={[
                       <Button key="back" onClick={() => setShowNotePad(false)}>
                           Return
                       </Button>,
                       <Button key="submit" type="primary" loading={savingNote} onClick={saveNote}>
                           Save
                       </Button>]}
            >
                <TextArea style={{marginBottom: 12}} showCount={true} onChange={(value) => setNote((prev) => value.target.value)} placeholder="Enter note here" rows={4} />
            </Modal>
        </>
    )
}

export default WaitListMarker;