import React, {useContext, useEffect, useRef, useState} from "react";
import {getWtdStacks} from "../../../../api/data/DataProvider";
import {RefreshContext} from "../../commonContexts/RefreshContextProvider";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {Modal} from "antd";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {ViewContextStore} from "../views/ViewContext";


export const SECONDMEASURE = 'SECONDMEASURE';
export const ORION = 'ORION';
export const VELA_GAMMA = 'VELA_GAMMA';
export const VELA_VELORUM = 'VELA_VELORUM';
export const ORION_DEBIT = 'ORION_DEBIT';
export const OICC = 'OICC';
const ALL_CC_SERIES = [ORION_DEBIT, VELA_GAMMA, ORION, VELA_VELORUM , SECONDMEASURE];


const fixSizeProps = (options, chartHeight, chartWidth) => ({
    ...options,
    chart: {
        ...options?.chart,
        zooming: {
            mouseWheel: {
                enabled: false
            }
        },
        plotBackgroundColor: '#E5ECF6',
        panning: {
            enabled: true,
            type: 'x'
        },
        panKey: 'shift',
        height: chartHeight,
        width: chartWidth,
    }
})

const getInitialState = () => ({
    data: null,
    loading: false,
    isError: false,
})

const getLoadingState = () => ({
    data: null,
    loading: true,
    isError: false,
})

const ONE_YEAR = '1';
const TWO_YEAR = '2';

const SALES = 'SALES';
const GP = 'GP';

export const WTDStacksComponent = ({tick, brand}) => {

    const {refresh} = useContext(RefreshContext);

    const {wtdStackModalOpen, setWtdStackModalOpen} = useContext(ViewContextStore);


    const [seriesType, setSeriesType] = useState(SALES);

    const [usedParams, setUsedParams] = useState(null);

    const [dataState, setDataState] = useState({
        [ONE_YEAR]: {
            [SECONDMEASURE]: getInitialState(),
            [ORION]: getInitialState(),
            [VELA_GAMMA]: getInitialState(),
            [VELA_VELORUM]: getInitialState(),
            [ORION_DEBIT]: getInitialState(),
            [OICC]: getInitialState(),
        },
        [TWO_YEAR]: {
            [SECONDMEASURE]: getInitialState(),
            [ORION]: getInitialState(),
            [VELA_GAMMA]: getInitialState(),
            [VELA_VELORUM]: getInitialState(),
            [ORION_DEBIT]: getInitialState(),
            [OICC]: getInitialState(),
        }
    });

    const abortControllerRef = useRef(null);

    useEffect(() => {
        // data is same as before, ignore:
        if (usedParams != null && usedParams.tick === tick.id && usedParams.brand === brand.id
            && usedParams.seriesType === seriesType) return;
        // Do the corresponding api calls, only when the modal is open:
        if(!wtdStackModalOpen) return;
        if (abortControllerRef.current) {
            abortControllerRef.current?.abort();
        }
        abortControllerRef.current = new AbortController();
        setUsedParams({
            tick: tick.id,
            brand: brand.id,
            seriesType: seriesType,
        })
        setDataState({
            [ONE_YEAR]: {
                [SECONDMEASURE]: getLoadingState(),
                [ORION]: getLoadingState(),
                [VELA_GAMMA]: getLoadingState(),
                [VELA_VELORUM]: getLoadingState(),
                [ORION_DEBIT]: getLoadingState(),
                [OICC]: getLoadingState(),
            },
            [TWO_YEAR]: {
                [SECONDMEASURE]: getLoadingState(),
                [ORION]: getLoadingState(),
                [VELA_GAMMA]: getLoadingState(),
                [VELA_VELORUM]: getLoadingState(),
                [ORION_DEBIT]: getLoadingState(),
                [OICC]: getLoadingState(),
            }
        })

        ALL_CC_SERIES.forEach((ccSeries) => {
            const ccSeriesKey = seriesType === GP ? ccSeries + '_GP' : ccSeries;
            [ONE_YEAR, TWO_YEAR].forEach(yearFactor => {
                getWtdStacks(tick.id, brand.id, ccSeriesKey, yearFactor, abortControllerRef.current)
                    .then((data) => {
                        setDataState(prev => ({
                            ...prev,
                            [yearFactor]: {
                                ...prev[yearFactor],
                                [ccSeries]: {
                                    data: data.data,
                                    loading: false,
                                    isError: false
                                }
                            }
                        }));
                    })
                    .catch((err) => {
                        console.error(err);
                        setDataState(prev => ({
                            ...prev,
                            [yearFactor]: {
                                ...prev[yearFactor],
                                [ccSeries]: {
                                    data: null,
                                    loading: false,
                                    isError: true
                                }
                            }
                        }));
                    })
            })


        });

    }, [ tick, brand, wtdStackModalOpen, seriesType]);

    useEffect(() => {
        // Unset the used params to null, so that the next time the modal is opened, api call is retriggered.
        setUsedParams(null);
    }, [refresh])

    //Todo: Change this and add error handling:
    const getChartForCCSeries = (ccSeries, yearFactor) => {
        if(dataState[yearFactor][ccSeries].data != null && !dataState[yearFactor][ccSeries].isError && !dataState[yearFactor][ccSeries].loading) return (
        <div key={`ChartGrid-Chart-wtdStack-${ccSeries}-${yearFactor}`} className="grid-item-wtd-stack">
            <HighchartsReact
                highcharts={Highcharts}
                options={fixSizeProps(dataState[yearFactor][ccSeries].data, 278, 820)}
            />
        </div>
    );
        if(dataState[yearFactor][ccSeries].isError) {
            return (<h3>{`Errored while loading the WTD chart for ${ccSeries}.`}</h3>);
        }
    }


    const close = () => setWtdStackModalOpen(false);

    return (
        <Modal width={1800} open={wtdStackModalOpen} onOk={close} onCancel={close}>
            <Stack spacing={2} direction="row" justifyContent="space-between">

                <Stack spacing={2} direction="row">
                    <Button variant={seriesType === SALES ? 'contained' : 'outlined'}
                            onClick={() => setSeriesType(SALES)}>Sales</Button>
                    <Button variant={seriesType === GP ? 'contained' : 'outlined'}
                            onClick={() => setSeriesType(GP)}>GP</Button>
                </Stack>
            </Stack>
            <div className="grid-container-wtd-stack">
                <div>1 Year</div>
                <div>2 Year</div>
                {ALL_CC_SERIES.map((ccSeries) => (
                    <React.Fragment key={`WTDMODALSTACKCHARTSRANGE-${ccSeries}`}>
                        {[ONE_YEAR, TWO_YEAR].map((yearFactor) => (
                            <React.Fragment key={`WTDMODALSTACKCHARTSRANGE-${ccSeries}-${yearFactor}`}>
                                {getChartForCCSeries(ccSeries, yearFactor)}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </Modal>
    );


}
