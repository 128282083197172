import {useEffect, useRef, useState} from "react";
import LoadingOverlay from 'react-loading-overlay';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {CAlert} from "@coreui/react";
import {monitor} from "../../../event_handler/KeyPressMonitor";

function IntradayMarketFactorChart({marketFactors, isError, onSelectTime, timezoneOffset = 240}) {
    const [currentTooltip, setCurrentTooltip] = useState(null);
    const currentTooltipRef = useRef(currentTooltip);
    let chartData;
    let loading = marketFactors == null;

    useEffect(() => {
        currentTooltipRef.current = currentTooltip;
    }, [currentTooltip]);

    const handleTimeSelection = (e) => {
        console.log(`Key pressed: ${e.key}`);
        console.log("currentToolTip", currentTooltipRef.current);
        if (e.key === 's' && currentTooltipRef.current) {
            onSelectTime(currentTooltipRef.current);
        }
    };


    const getColor = (series) => {
        switch (series) {
            case "profitability":
                return "#88c44c";
            case "divYield":
                return "#d2cb5c";
            case "size":
                return "#e5812c";
            case "leverage":
                return "#d72020";
            case "value":
                return "#1266ef";
            case "momentum":
                return "#FFFFFF";
            case "growth":
                return "#66cebd";
            case "volume":
                return "#0324be";
            case "earnYield":
                return "#b575bd";
            case "volatility":
                return "#ad3ad3";
            case "si":
                return "#e566b5";
            default:
                return "#FFFFFF";
        }
    }

    const getSeriesName = (series) => {
        switch (series) {
            case "profitability":
                return "Profitability";
            case "divYield":
                return "Div Yield";
            case "size":
                return "Size";
            case "leverage":
                return "Leverage";
            case "value":
                return "Value";
            case "momentum":
                return "Momentum";
            case "growth":
                return "Growth";
            case "volume":
                return "Volume";
            case "earnYield":
                return "Earn Yield";
            case "volatility":
                return "Volatility";
            case "si":
                return "Short Interest";
            default:
                return "";
        }
    }

    const resolveChartData = (data) => {
        const series = {};
        if (data.length <= 0) {
            return [];
        }

        data.sort((a, b) => Date.parse(a.asOfTime) - Date.parse(b.asOfTime));

        data.forEach(dataItem => {
            let date = Date.parse(dataItem.asOfTime);
            Object.entries(dataItem).forEach(([key, value]) => {
                if (key !== 'asOfTime') {
                    if (!series[key]) {
                        series[key] = {
                            name: getSeriesName(key),
                            data: [],
                            color: getColor(key),
                            lineWidth: 2
                        };
                    }
                    series[key].data.push([date, Math.round((value + Number.EPSILON) * 100000) / 100000]);
                }
            });
        });

        return Object.values(series);
    }

    if (marketFactors && marketFactors.length > 0) {
        chartData = {
            title: {
                text: 'Intraday Factor Returns'
            },
            navigator: {
                enabled: false
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    enabled: true
                },
                title: {
                    text: 'Time'
                },
                overscroll: '60px',
            },
            yAxis: {
                visible: true,
                opposite: false,
                gridLineWidth: 0.1,
                labels: {
                    format: '{value:,.2f}',
                }
            },
            tooltip: {
                shared: true,
                split: true,
                inactiveOtherSeries: false,
                xDateFormat: '%H%M',
                valueSuffix: ' %',
                formatter: function (fargs) {
                    setCurrentTooltip(this.x);
                    let mytimezone = new Date();
                    let dateWithOffset = new Date(this.x - timezoneOffset * 60000 + mytimezone.getTimezoneOffset() * 60000);
                    let formattedTime = dateWithOffset.toLocaleTimeString();
                    return ['<b>' + formattedTime + '</b>'].concat(
                        this.points ?
                            this.points.map(function (point) {
                                return point.series.name + ': ' + point.y + ' %';
                            }) : []
                    );
                },
            },
            series: resolveChartData(marketFactors),
            rangeSelector: {
                enabled: false
            },
            chart: {
                type: 'line',
                zooming: {
                    mouseWheel: {
                        enabled: false
                    }
                },
                zoomType: null,
                height: 700,
                plotBackgroundColor: '#000000',
                panning: {
                    enabled: true,
                    type: 'x'
                },
                panKey: 'shift',
                events: {
                    load: function () {
                        console.log("LOAD CALLED");
                        // Define the keydown event listener function

                        // Add the keydown event listener to the document
                        document.addEventListener('keydown', handleTimeSelection);

                        // Store the reference to the listener function for later removal
                        this.keydownListener = handleTimeSelection;
                    },
                    destroy: function () {
                        console.log("DESTROY CALLED");
                        // Remove the keydown event listener from the document when the chart is destroyed
                        document.removeEventListener('keydown', this.keydownListener);
                    }
                }
            },
            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                itemStyle: {
                    color: "#000000"
                },
                itemHiddenStyle: {
                    textDecoration: 'none'  // Disable strikethrough effect
                }
            },
            time: {
                timezoneOffset: timezoneOffset,
                useUTC: true
            },
            plotOptions: {
                series: {
                    animation: false, // Disable series animations
                    events: {
                        legendItemClick: function () {
                            const series = this.chart.series;
                            if (monitor.isPressed('z')) { // 16 is code for shift key
                                series.forEach(s => {
                                    s !== this ? s.hide() : s.show()
                                }, this);

                                return false;
                            }

                            if (monitor.isPressed('x')) {
                                const yAxisIndex = this.yAxis.index;
                                const yAxis = this.chart.yAxis[yAxisIndex];
                                if (yAxis.visible) {
                                    yAxis.update({visible: false});
                                } else {
                                    yAxis.update({visible: true});
                                }

                                return false;
                            }

                            if (monitor.isPressed('c')) {
                                series.forEach(s => {
                                    const yAxisIndex = s.yAxis.index;
                                    const yAxis = this.chart.yAxis[yAxisIndex];
                                    yAxis.update({visible: false});
                                }, this);

                                return false;
                            }

                            return true;
                        }
                    },
                    marker: {
                        enabled: false
                    }
                }
            }
        };
    } else {
        chartData = null;
    }
    // console.log("chartdata", chartData, isError);
    return (
        <>
            <a id={'intradayMarketFactors'} href={""}> </a>
            <LoadingOverlay
                active={loading && !isError}
                spinner={!isError}
                text={"Loading..."}
            >
                {isError && <div>
                    <CAlert color="danger">
                        Failed to load
                        Data Not Configured
                    </CAlert>
                </div>}
                {chartData == null && !isError && <p style={{height: "400px", width: "90%"}}> ...</p>}
                {chartData != null && !isError && chartData.series.length === 0 && <div>
                    <CAlert color="warning">
                        Data Not Available
                    </CAlert>
                </div>}
                {chartData != null && !isError && chartData.series.length !== 0 &&
                        <div style={{marginTop: 10, paddingLeft: 10}} onKeyDown={(e) => {
                            console.log("onKeyDown", e.key, e);
                        }
                        }>
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'stockChart'}
                                options={chartData}/>
                        </div>
                    }
            </LoadingOverlay>

        </>
    )
}

export default IntradayMarketFactorChart;