import {useContext, useEffect, useState} from "react";
import {ViewContextStore} from "../DashboardReport/views/ViewContext";
import {Divider, Select} from "antd";
import {Chip, Grid} from "@mui/material";
import Switch from '@mui/material/Switch';
import Checkbox from "@mui/material/Checkbox";
import useMessage from "antd/es/message/useMessage";


export const SeriesControlPane = () => {
    const {
        completeMetadata,
        bulkToggle
    } = useContext(ViewContextStore);
    const [messageApi, context] = useMessage();

    //Generate an array of series names and chart names:
    let chartNames, chartOptions, uniqueSeriesNames, seriesOptions;

    chartNames =  new Set(
        Object.keys(completeMetadata)
    );

    chartOptions = Array.from(chartNames.keys())
        .map(chart => ({
            label: chart,
            value: chart
        }));

    // Don't know if I should be proud or embarrassed of this. But it's fking 5 AM.
    // Gets a set of unique series across all charts.
    uniqueSeriesNames = new Set(
        [].concat(...Object.values(completeMetadata).filter(Boolean).map(
            arr => arr.map((item) => item.seriesName).filter(item => !item.includes('ghost'))
        ))
    );
    seriesOptions = Array.from(uniqueSeriesNames.keys())
        .map(series => ({
            label: series,
            value: series
        }));


    const functionalTagsMap = {};

    Object.keys(completeMetadata).forEach(chart => {
        completeMetadata[chart]?.forEach(series => {
            series?.functionalTags?.forEach(tag => {
                if(tag === 'INTERNAL_REFERENCE') return;
                if (!functionalTagsMap[tag]) {
                    functionalTagsMap[tag] = new Set();
                }
                functionalTagsMap[tag].add(series.seriesName);
            })
        })
    });

    const functionalTagsOptions = Object.keys(functionalTagsMap).map(tag => ({
        label: tag,
        value: tag
    }));

    const geoTagsMap = {};

    Object.keys(completeMetadata).forEach(chart => {
        completeMetadata[chart]?.forEach(series => {
            series?.geoTags?.forEach(tag => {
                if (!geoTagsMap[tag]) {
                    geoTagsMap[tag] = new Set();
                }
                geoTagsMap[tag].add(series.seriesName);
            })
        })
    });

    const geoTagsOptions = Object.keys(geoTagsMap).map(
        tag => ({
            label: tag,
            value: tag
        })
    );

    const vendorTagsMap = {};

    Object.keys(completeMetadata).forEach(chart => {
        completeMetadata[chart]?.forEach(series => {
            series?.vendorTags?.forEach(tag => {
                if (!vendorTagsMap[tag]) {
                    vendorTagsMap[tag] = new Set();
                }
                vendorTagsMap[tag].add(series.seriesName);
            })
        })
    });

    const vendorTagsOptions = Object.keys(vendorTagsMap).map(
        tag => ({
            label: tag,
            value: tag
        })
    );


    const [selectedSeries, setSelectedSeries] = useState([]);
    const [allSeries, setAllSeries] = useState(false);
    const [selectedCharts, setSelectedCharts] = useState([]);
    const [isEnableSeries, setIsEnableSeries] = useState(false);
    const [isEnableAxis, setIsEnableAxis] = useState(false);

    const [selectedFunctionalTags, setSelectedFunctionalTags] = useState([]);
    const [selectedGeoTags, setSelectedGeoTags] = useState([]);
    const [selectedVendorTags, setSelectedVendorTags] = useState([]);

    const [combineOperatorIsOr, setCombineOperator] = useState(true);

    const [metadataSelectedSeries, setMetadatSelectedSeries] = useState([]);

    const [allCharts, setAllCharts] = useState(false);
    const [weeklyCharts, setWeeklyCharts] = useState(false);
    const [mosaics, setMosaics] = useState(false);

    useEffect(() => {
        if (allSeries) {
            setUnionSeriesList([...uniqueSeriesNames]);
            return;
        }
        if (selectedGeoTags.length === 0 &&
            selectedFunctionalTags.length === 0 &&
            selectedVendorTags.length === 0
        ) {
            setMetadatSelectedSeries([]);
            return;
        } // If no tags are selected, ignore change.
        let seriesToBeSelected = new Set();
        if (combineOperatorIsOr) {
            selectedFunctionalTags.forEach(tag => {
                seriesToBeSelected = new Set(
                    [...seriesToBeSelected, ...functionalTagsMap[tag]]);
            });
            selectedGeoTags.forEach(tag => {
                seriesToBeSelected = new Set(
                    [...seriesToBeSelected, ...geoTagsMap[tag]]);
            });
            selectedVendorTags.forEach(tag => {
                seriesToBeSelected = new Set(
                    [...seriesToBeSelected, ...vendorTagsMap[tag]]);
            })
        } else {
            //Combine operator is AND.

            // We only use lists where at least one tag is selected for intersection:
            if (selectedFunctionalTags.length > 0) {
                selectedFunctionalTags.forEach(tag => {
                    if (seriesToBeSelected.size === 0) {
                        seriesToBeSelected = new Set(functionalTagsMap[tag]);
                    } else
                        seriesToBeSelected = new Set(
                            [...seriesToBeSelected].filter(x => functionalTagsMap[tag].has(x))
                        );
                })
            }

            if (selectedGeoTags.length > 0) {
                selectedGeoTags.forEach(tag => {
                    if (selectedFunctionalTags.length === 0 && seriesToBeSelected.size === 0) {
                        seriesToBeSelected = new Set(geoTagsMap[tag]);
                    }
                    seriesToBeSelected = new Set(
                        [...seriesToBeSelected].filter(x => geoTagsMap[tag].has(x))
                    );
                })
            }

            if (selectedVendorTags.length > 0) {
                selectedVendorTags.forEach(tag => {
                    if (selectedFunctionalTags.length === 0 && selectedGeoTags.length === 0 && seriesToBeSelected.size === 0) {
                        seriesToBeSelected = new Set(vendorTagsMap[tag])
                    }
                    seriesToBeSelected = new Set(
                        [...seriesToBeSelected].filter(x => vendorTagsMap[tag].has(x))
                    );
                })
            }
        }
        setMetadatSelectedSeries(Array.from(seriesToBeSelected));


    }, [selectedGeoTags, selectedVendorTags, selectedFunctionalTags, combineOperatorIsOr, allSeries]);


    useEffect(() => {
        // When series mode is for disabling, axis must also be disabled.
        if (!isEnableSeries) setIsEnableAxis(false);
    }, [isEnableSeries])

    const [unionSeriesList, setUnionSeriesList] = useState([]);

    useEffect(() => {
        const union = [...new Set([...selectedSeries, ...metadataSelectedSeries])]
        setUnionSeriesList(union);
    }, [selectedSeries, metadataSelectedSeries]);


    const [unionCharts, setUnionCharts] = useState([]);

    useEffect(
        () => {
            if (allCharts) {
                setUnionCharts([...chartNames]);
                return;
            }
            let chartsList = [...selectedCharts];
            if (weeklyCharts) {
                const weeklyCharts = [...chartNames].filter(name => name.includes('WEEKLY'));
                chartsList = [...new Set([...chartsList, ...weeklyCharts])];
            }
            if (mosaics) {
                const mosaicCharts = [...chartNames].filter(name => name.includes('QTR'));
                chartsList = [...new Set([...chartsList, ...mosaicCharts])];
            }
            setUnionCharts(chartsList);
        },
        [selectedCharts, allCharts, weeklyCharts, mosaics]
    )

    const clearAll = () => {
        setSelectedSeries([]);
        setMetadatSelectedSeries([]);
        setSelectedGeoTags([]);
        setSelectedVendorTags([]);
        setSelectedFunctionalTags([]);
        setIsEnableAxis(false);
        setIsEnableSeries(false);
        setCombineOperator(true);

        // Charts clear:
        setSelectedCharts([]);
        setAllCharts(false);
        setWeeklyCharts(false);
        setMosaics(false);
        setUnionCharts([]);
    }

    const apply = () => {
        if(unionSeriesList.length === 0){
            messageApi.open({
                type: 'warning',
                content: 'No Series in target list!',
                duration: 2
            });
            return;
        }
        if(unionCharts.length === 0){
            messageApi.open({
                type: 'warning',
                content: 'No Charts in target list!',
                duration: 2
            });
            return;
        }
        bulkToggle(unionSeriesList, unionCharts, isEnableSeries, isEnableAxis);
        messageApi.open({
            type: 'success',
            content: 'Applied Changes!',
            duration: 2
        });
        clearAll();
    }


    return (
        <Grid container spacing={2}>
            {context}
            <Grid item xs={6}>
                {isEnableSeries ? 'Enable Series' : 'Disable Series'}
            </Grid>
            <Grid item xs={6}>
                <Switch
                    checked={isEnableSeries}
                    onChange={e => setIsEnableSeries(e.target.checked)}
                />
            </Grid>
            <Grid item xs={6}>
                {isEnableAxis ? 'Show Axis' : 'Hide Axis'}
            </Grid>
            <Grid item xs={6}>
                <Switch
                    checked={isEnableAxis}
                    onChange={e => setIsEnableAxis(e.target.checked)}
                />
            </Grid>
            <Divider textAlign="left">Series Selection</Divider>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    All Series:
                </Grid>
                <Grid item xs={2}>
                    <Checkbox
                        checked={allSeries}
                        onChange={e => setAllSeries(e.target.checked)}
                    />
                </Grid>
            </Grid>
            {!allSeries && <>
                <Grid item xs={12}>
                    Pick Series by Name:
                </Grid>
                <Grid item xs={12}>
                    <Select
                        mode="multiple"
                        allowClear
                        placeholder={'Select Series or Metadata'}
                        onChange={(value) => setSelectedSeries(value)}
                        options={seriesOptions}
                        autoClearSearchValue={true}
                        defaultValue={selectedSeries}
                        style={{
                            width: '80%'
                        }}
                    />
                </Grid>
                <Divider variant="middle" component="li"> + </Divider>
                <Grid item xs={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={2}>
                            AND
                        </Grid>
                        <Grid item xs={2}>
                            <Switch
                                checked={combineOperatorIsOr}
                                onChange={e => setCombineOperator(e.target.checked)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            OR
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={6}>
                    {(combineOperatorIsOr ? 'Match series with ANY of the below tags' : 'Match series with ALL of the below tags')}
                </Grid>


                <Grid item xs={12}>
                    Functional Tags:
                </Grid>
                <Grid item xs={12}>
                    <Select
                        mode="multiple"
                        allowClear
                        placeholder={'Select functional tags'}
                        onChange={(value) => setSelectedFunctionalTags(value)}
                        options={functionalTagsOptions}
                        style={{
                            width: '80%'
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    Geographic Tags:
                </Grid>
                <Grid item xs={12}>
                    <Select
                        mode="multiple"
                        allowClear
                        placeholder={'Select geographical tags'}
                        onChange={(value) => setSelectedGeoTags(value)}
                        options={geoTagsOptions}
                        style={{
                            width: '80%'
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    Vendor Tags:
                </Grid>
                <Grid item xs={12}>
                    <Select
                        mode="multiple"
                        allowClear
                        placeholder={'Select vendor tags'}
                        onChange={(value) => setSelectedVendorTags(value)}
                        options={vendorTagsOptions}
                        style={{
                            width: '80%'
                        }}
                    />
                </Grid>
            </>}
            <Divider variant="middle" component="li"> Chart Selection </Divider>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    All Charts:
                </Grid>
                <Grid item xs={2}>
                    <Checkbox
                        checked={allCharts}
                        onChange={e => setAllCharts(e.target.checked)}
                    />
                </Grid>
            </Grid>
            {!allCharts && <>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        Mosaics Series:
                    </Grid>
                    <Grid item xs={2}>
                        <Checkbox
                            checked={mosaics}
                            onChange={e => setMosaics(e.target.checked)}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        Weekly Series:
                    </Grid>
                    <Grid item xs={2}>
                        <Checkbox
                            checked={weeklyCharts}
                            onChange={e => setWeeklyCharts(e.target.checked)}
                        />
                    </Grid>
                </Grid>
                <Divider variant="middle" component="li"> + </Divider>
                <Grid item xs={12}>
                    Pick Charts by Name:
                </Grid>
                <Grid item xs={12}>
                    <Select
                        mode="multiple"
                        allowClear
                        placeholder={'Select target charts'}
                        onChange={(value) => setSelectedCharts(value)}
                        options={chartOptions}
                        style={{
                            width: '80%'
                        }}
                    />
                </Grid>
            </>}

            <Divider variant="middle" component="li"/>
            <Grid item xs={6}>
                <button className={'button-factors'} onClick={apply}>Apply</button>
            </Grid>
            <Grid item xs={6}>
                <button className={'button-cancel'} onClick={clearAll}>Clear</button>
            </Grid>
            <Divider variant="middle" component="li"/>
            {unionSeriesList.length > 0 && <><Grid item xs={12}>Selected Series to toggle: </Grid>
                <Grid item xs={12}>
                    {unionSeriesList.map(item => <Chip label={item} variant="outlined"/>)}
                </Grid></>}
            {unionCharts.length > 0 && <><Grid item xs={12}>Selected Target Charts: </Grid>
                <Grid item xs={12}>
                    {unionCharts.map(item => <Chip label={item} variant="outlined"/>)}
                </Grid></>}

        </Grid>
    )
}