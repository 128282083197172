export const monitor = {
    init: function () {
        window.keyPresses = {
            'z': false,
            'x': false,
            'c': false
        };

        registerListeners(['z','c','x']);
    },

    isPressed: function (key) {
      return window.keyPresses[key];
    },
    enableHotKeys: function (isEnable) {
        window.isEnableHotKeys = isEnable;
    }
}

function registerListeners(keys) {
    document.addEventListener("keydown", (e) => {
        keys.forEach(key => {
            if (e.key === key) {
                window.keyPresses[key] = true;
            }
        })
    });

    document.addEventListener("keyup", (e) => {
        keys.forEach(key => {
            if (e.key === key) {
                window.keyPresses[key] = false;
            }
        })
    });
}