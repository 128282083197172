import {createContext, useContext, useEffect, useRef, useState} from "react";
import {getCorrelationData} from "../../api/data/DataProvider";
import {RefreshContext} from "./commonContexts/RefreshContextProvider";

export const CorrelationReportContext = createContext(null);


export const CorrelationReportProvider = ({ children, tick,  showMiniView }) => {

    const {refresh} = useContext(RefreshContext);

    const correlReportScrollPosition = useRef(-1);

    const [dataState, setDataState] = useState({

            chartData: null,
            loading: true,
            isError: false

    })

    const setCorrelReportScrollPosition = (position) => {correlReportScrollPosition.current = position}

    //State management for the Correlation Report API Calls:
    const [correlReportSelectedDepVariable, setCorrelReportSelectedDepVariable] = useState('revenue_reported');

    // Reload the data to the  default on a ticker change:
    useEffect(() => {
        setCorrelReportSelectedDepVariable('revenue_reported');
    }, [tick.id]);

    const [correlReportAbortController, setCorrelReportAbortController] = useState(null);

    // Correlation report is dependent on correlReportSelectedDepVariable -> Hence we have explicitly hook for it here, rather than wiring it through the same logic as other APIs.
    useEffect(() => {
        if(tick.id == null || showMiniView) return;
        if(correlReportAbortController != null ) correlReportAbortController.abort();
        const abortController = new AbortController();
        setCorrelReportAbortController(abortController);
        setDataState(prev => ({

                chartData: null,
                loading: true,
                isError: false

        }))
        getCorrelationData(tick.name, correlReportSelectedDepVariable, abortController, null)
            .then((res) => {
                setDataState({
                        chartData: res,
                        loading: false,
                        isError: false

                })
            })
            .catch(err => {
                if (err.code === 'ERR_CANCELED') {
                    console.log("cancellation error on the correlation report.")
                } else {
                    console.log('Error faced while getting correlation data from backend')
                    console.log(err);
                    setDataState({
                            chartData: null,
                            loading: false,
                            isError: true

                    })
                }
            })
    }, [tick.id, correlReportSelectedDepVariable, refresh, showMiniView]);

    return (
        <CorrelationReportContext.Provider value={ {dataState, correlReportSelectedDepVariable, setCorrelReportSelectedDepVariable, correlReportScrollPosition, setCorrelReportScrollPosition} }>
            {children}
        </CorrelationReportContext.Provider>
    )
}