import {ArrowDownOutlined, ArrowUpOutlined, SearchOutlined} from "@ant-design/icons";
import {Button, Input, Space} from "antd";

export const SALES_COMPOSITE_SCREENER_TABLE_COLUMNS = [
    {
        title: 'Ticker',
        dataIndex: 'ticker',
        key: 'ticker',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder="Search Ticker"
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => confirm()}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => {
                            clearFilters();
                            confirm();
                        }
                    } size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => record.ticker.toLowerCase().includes(value.toLowerCase())
    },
    {
        title: 'Current Quarter Estimate (Revenue)',
        dataIndex: 'currentQtrEstYoYValue',
        key: 'currentQtrEstYoYValue',
        render: text => renderColorForValues(text),
        sorter: (a,b) => sorterMethod(a, b, 'currentQtrEstYoYValue')
    },
    {
        title: 'Current Quarter End Date',
        dataIndex: 'currentQtrEndDt',
        key: 'currentQtrEndDt'
    },
    {
        title: 'Next Quarter Estimate (Revenue)',
        dataIndex: 'nextQtrEstYoYValue',
        key: 'nextQtrEstYoYValue',
        render: text => renderColorForValues(text),
        sorter: (a,b) => sorterMethod(a, b, 'nextQtrEstYoYValue')
    },
    {
        title: 'Next Quarter End Date',
        dataIndex: 'nextQtrEndDt',
        key: 'nextQtrEndDt'
    },
    {
        title: '1 Wk YoY',
        dataIndex: 'oneWkYoYValue',
        key: 'oneWkYoYValue',
        render: text => renderColorForValues(text),
        sorter: (a,b) => sorterMethod(a, b, 'oneWkYoYValue')
    },
    {
        title: '2 Wk YoY',
        dataIndex: 'twoWkYoYValue',
        key: 'twoWkYoYValue',
        render: text => renderColorForValues(text),
        sorter: (a,b) => sorterMethod(a, b, 'twoWkYoYValue')
    },
    {
        title: '4 Wk YoY',
        dataIndex: 'fourWkYoYValue',
        key: 'fourWkYoYValue',
        render: text => renderColorForValues(text),
        sorter: (a,b) => sorterMethod(a, b, 'fourWkYoYValue')
    },
    {
        title: 'QTD YoY',
        dataIndex: 'qtdSalesEstYoYValue',
        key: 'qtdSalesEstYoYValue',
        render: text => renderColorForValues(text),
        sorter: (a,b) => sorterMethod(a, b, 'qtdSalesEstYoYValue')
    },
    {
        title: 'Studio Index Guidance',
        dataIndex: 'studioCompositeIndexGuidance',
        key: 'studioCompositeIndexGuidance',
        render: text => renderColorForValues(text),
        sorter: (a,b) => sorterMethod(a, b, 'studioCompositeIndexGuidance')
    }
];

const renderColorForValues = (text) => {
    if (text > 0) {
        return <span style={{color: "green"}}><ArrowUpOutlined style={{marginRight: 5}} />{text.toFixed(2)}%</span>
    } else if (text < 0) {
        return <span style={{color: "red"}}><ArrowDownOutlined style={{marginRight: 5}} />{text.toFixed(2)}%</span>
    } else {
        return text;
    }
}

const sorterMethod = (a, b, key) => {
    return a[key] - b[key];
}