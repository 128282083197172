import React, {useContext} from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import LoadingOverlay from "react-loading-overlay";
import {HistoricalPriceChartContext} from "./contextsForCharts/HistoricalPriceDataProvider";
import _ from "lodash";

export const StudioHistoricalPriceChart = ({chartHeight, chartWidth}) => {

    const {data, loading, isError} = useContext(HistoricalPriceChartContext);
    const localData = _.cloneDeep(data);
    let height = 850;

    if(chartHeight != null) {
         if(localData?.chart != null) localData.chart.height = chartHeight;
        height = chartHeight;
    }

    if(chartWidth != null  && localData?.chart != null) {
        localData.chart.width = chartWidth;
    }


    return (
        <LoadingOverlay
            active={loading || isError}
            spinner={!isError}
            text={isError ? "Failed to load the data for the chart" : "Loading..."}
            styles={{
                overlay: (base) => ({
                    ...base,
                    height: height,
                    width: '100%'
                })
            }}
        >
            {localData != null && !loading && !isError && <HighchartsReact
                highcharts={Highcharts}
                constructorType={'stockChart'}
                options={localData}
            />}

        </LoadingOverlay>
    )
}